import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import { getInvestorsTypes } from '../../../InvestorRelations/Services/IRServices';
import VerficationQuestions from './KYCAMLQuestions';
import KYCAMLQuestions from './KYCAMLQuestions';
import { LinearProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { rolesAndfeatureKYCTabToastMessages } from '../../../../utils/AppConstants';

const KYCAMLMain = () => {

  //#region variables
  const [investorTypes, setInvestorTypes] = useState(null);
  const [expanded, setExpanded] = useState({});

  //#region click events
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  //#region api get calls
  const getInvestorsType = async () => {
    const data = await getInvestorsTypes('InvestorType');
    if (data?.responseCode === 200) {
      setInvestorTypes(data?.responseData);
    } else {
      // Handle error if needed
      /* console.log("Error in getting investor types"); */
      toast.warning(rolesAndfeatureKYCTabToastMessages.DATE_FETCHING_ERROR,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  useEffect(() => {
    getInvestorsType();
  }, []);

  return (
    <div className='margin-top-20'>
      <h4>KYC/AML Questions</h4>
      <div className='dispaly-column white-card margin-top-10'>
        {
          investorTypes ? investorTypes.map((item, index) => {
            return (
              <div className='margin-top-10'>
                <Accordion expanded={expanded[item.listItemID] || false}
                  onChange={handleAccordionChange(item.listItemID)}
                  style={{ border: '1px lightgray solid' }}>
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1bh-header">
                    {item.listItemValue}
                  </AccordionSummary>
                  <AccordionDetails>
                    {
                      expanded[item.listItemID] &&
                      <KYCAMLQuestions investorTypeName={item.listItemValue} />
                    }
                  </AccordionDetails>
                </Accordion>
              </div>
            )
          }) : <LinearProgress />
        }
      </div>
    </div>
  )
}

export default KYCAMLMain