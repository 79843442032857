import { Field, useFormik } from 'formik';
import React, { forwardRef, useImperativeHandle } from 'react'
import * as yup from 'yup';
import { validationsConstants } from '../../../../utils/AppConstants';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';

const GovernanceDetails = forwardRef((props, ref) => {
  
  //#region variables
  const { companyDetails,setCompanyDetails } = props;

  //#region change events
  const handleFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleChange(name, file);
    }
    else {
      handleChange(name, '');
    }
  }

  const handleChange = (field,value) => {
    governanceDetails.setFieldValue(field,value)
  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    fundingRequirement: yup
          .string()
          .required(validationsConstants.REQUIRED),
    legalDocuments: yup
          .string()
          .required(validationsConstants.REQUIRED),
    complianceDocuments: yup
          .string()
          .required(validationsConstants.REQUIRED),
    shareHoldingDocuments: yup
          .string()
          .required(validationsConstants.REQUIRED),
    additionalDocuments: yup
          .string()
          .required(validationsConstants.REQUIRED),
    pinkDeckReport: yup
          .string()
          .required(validationsConstants.REQUIRED),
    valuation: yup
          .string()
          .required(validationsConstants.REQUIRED),
    sharePrice: yup
          .string()
          .required(validationsConstants.REQUIRED),
    otherForecastedFinancials: yup
          .string()
          .required(validationsConstants.REQUIRED),
  })

  const governanceDetails = useFormik({  
      initialValues: companyDetails.governanceDetails,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        return true;
      },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await governanceDetails.validateForm();
      if (Object.keys(errors).length === 0) {
        //need to update main set of values
        setCompanyDetails((prevValues) => {
          return { ...prevValues, 
            governanceDetails: governanceDetails?.values
        };
        });
        return true;
      }
      else {
        governanceDetails?.handleSubmit()
      }
    },
    updateFields: async () => {
      setCompanyDetails((prevValues) => {
        return { ...prevValues, 
          governanceDetails: governanceDetails?.values
        };
      });
    }
  }));

  //#region return
  return (
    <div className='child-margin-10'>
      <div className='white-card child-margin-10'>
        <b>Funding Requirement</b>
        <div className='display-row-items-flex'>
          <div className='width-98'>
            <div className="legalFieldWrapper mt1 align-center">
              <FileUploadBtn
                name='fundingRequirement'
                label="UPLOAD FUNDING REQUIREMENT"
                fileType={uploadFileTypes.PDF}
                required={true}
                defaultFile={governanceDetails.values.fundingRequirement}
                onChange={(name, value) => handleFileUpload(name, value)}
                parentError={governanceDetails.touched.fundingRequirement && Boolean(governanceDetails.errors.fundingRequirement)}
                validationMessage={governanceDetails.touched.fundingRequirement && governanceDetails.errors.fundingRequirement} />
            </div>
          </div>
        </div>
      </div>
      <div className='white-card'>
        <b>Corporate Governance Documents</b>
        <div className='display-row-items-flex'>
          <div className='width-48'>
            <div className="legalFieldWrapper mt1 space-between align-item-center">
              <div className=''>
                Legal Documents <span className='error-mark'>*</span>
              </div>
              <div>
                <FileUploadBtn
                    name='legalDocuments'
                    label="UPLOAD DOCUMENT"
                    fileType={uploadFileTypes.PDF}
                    required={true}
                    defaultFile={governanceDetails.values.legalDocuments}
                    onChange={(name, value) => handleFileUpload(name, value)}
                    parentError={governanceDetails.touched.legalDocuments && Boolean(governanceDetails.errors.legalDocuments)}
                    validationMessage={governanceDetails.touched.legalDocuments && governanceDetails.errors.legalDocuments} />
              </div>
            </div>
          </div>
          <div className='width-48'>
            <div className="legalFieldWrapper mt1 space-between align-item-center ">
              <div className=''>
                Compliance Documents  <span className='error-mark'>*</span>
              </div>
              <div>
                <FileUploadBtn
                    name='complianceDocuments'
                    label="UPLOAD DOCUMENT"
                    fileType={uploadFileTypes.PDF}
                    required={true}
                    defaultFile={governanceDetails.values.complianceDocuments}
                    onChange={(name, value) => handleFileUpload(name, value)}
                    parentError={governanceDetails.touched.complianceDocuments && Boolean(governanceDetails.errors.complianceDocuments)}
                    validationMessage={governanceDetails.touched.complianceDocuments && governanceDetails.errors.complianceDocuments} />
              </div>
            </div>
          </div>
          <div className='width-48'>
            <div className="legalFieldWrapper mt1 space-between align-item-center ">
              <div className=''>
                Share Holding Documents <span className='error-mark'>*</span>
              </div>
              <div>
                <FileUploadBtn
                    name='shareHoldingDocuments'
                    label="UPLOAD DOCUMENT"
                    fileType={uploadFileTypes.PDF}
                    required={true}
                    defaultFile={governanceDetails.values.shareHoldingDocuments}
                    onChange={(name, value) => handleFileUpload(name, value)}
                    parentError={governanceDetails.touched.shareHoldingDocuments && Boolean(governanceDetails.errors.shareHoldingDocuments)}
                    validationMessage={governanceDetails.touched.shareHoldingDocuments && governanceDetails.errors.shareHoldingDocuments} />
              </div>
            </div>
          </div>
          <div className='width-48'>
            <div className="legalFieldWrapper mt1 space-between align-item-center ">
              <div className=''>
                Additional Documents <span className='error-mark'>*</span>
              </div>
              <div>
                <FileUploadBtn
                    name='additionalDocuments'
                    label="UPLOAD DOCUMENT"
                    fileType={uploadFileTypes.PDF}
                    required={true}
                    defaultFile={governanceDetails.values.additionalDocuments}
                    onChange={(name, value) => handleFileUpload(name, value)}
                    parentError={governanceDetails.touched.additionalDocuments && Boolean(governanceDetails.errors.additionalDocuments)}
                    validationMessage={governanceDetails.touched.additionalDocuments && governanceDetails.errors.additionalDocuments} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='white-card'>
        <b>Growth Blueprint</b>
        <div className='display-row-items-flex'>
          <div className='width-48'>
            <div className="legalFieldWrapper mt1 space-between align-item-center ">
              <div className=''>
                Pink Deck Report <span className='error-mark'>*</span>
              </div>
              <div>
                <FileUploadBtn
                    name='pinkDeckReport'
                    label="UPLOAD DOCUMENT"
                    fileType={uploadFileTypes.PDF}
                    required={true}
                    defaultFile={governanceDetails.values.pinkDeckReport}
                    onChange={(name, value) => handleFileUpload(name, value)}
                    parentError={governanceDetails.touched.pinkDeckReport && Boolean(governanceDetails.errors.pinkDeckReport)}
                    validationMessage={governanceDetails.touched.pinkDeckReport && governanceDetails.errors.pinkDeckReport} />
              </div>
            </div>
          </div>
          <div className='width-48'>
            <div className="legalFieldWrapper mt1 space-between align-item-center ">
              <div className=''>
                Valuation <span className='error-mark'>*</span>
              </div>
              <div>
                <FileUploadBtn
                    name='valuation'
                    label="UPLOAD DOCUMENT"
                    fileType={uploadFileTypes.PDF}
                    required={true}
                    defaultFile={governanceDetails.values.valuation}
                    onChange={(name, value) => handleFileUpload(name, value)}
                    parentError={governanceDetails.touched.valuation && Boolean(governanceDetails.errors.valuation)}
                    validationMessage={governanceDetails.touched.valuation && governanceDetails.errors.valuation} />
              </div>
            </div>
          </div>
          <div className='width-48'>
            <div className="legalFieldWrapper mt1 space-between align-item-center ">
              <div className=''>
                Share Price <span className='error-mark'>*</span>
              </div>
              <div>
                <FileUploadBtn
                    name='sharePrice'
                    label="UPLOAD DOCUMENT"
                    fileType={uploadFileTypes.PDF}
                    required={true}
                    defaultFile={governanceDetails.values.sharePrice}
                    onChange={(name, value) => handleFileUpload(name, value)}
                    parentError={governanceDetails.touched.sharePrice && Boolean(governanceDetails.errors.sharePrice)}
                    validationMessage={governanceDetails.touched.sharePrice && governanceDetails.errors.sharePrice} />
              </div>
            </div>
          </div>
          <div className='width-48'>
            <div className="legalFieldWrapper mt1 space-between align-item-center ">
              <div className=''>
                Other Forecasted Financials <span className='error-mark'>*</span>
              </div>
              <div>
                <FileUploadBtn
                    name='otherForecastedFinancials'
                    label="UPLOAD DOCUMENT"
                    fileType={uploadFileTypes.PDF}
                    required={true}
                    defaultFile={governanceDetails.values.otherForecastedFinancials}
                    onChange={(name, value) => handleFileUpload(name, value)}
                    parentError={governanceDetails.touched.otherForecastedFinancials && Boolean(governanceDetails.errors.otherForecastedFinancials)}
                    validationMessage={governanceDetails.touched.otherForecastedFinancials && governanceDetails.errors.otherForecastedFinancials} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)})

export default GovernanceDetails