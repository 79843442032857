import React, { useEffect, useState } from 'react'
import { getEventsByFundId } from '../../services/services';
import AIPDataGrid from '../../../common/DataGrid/AIPDataGrid';
import { monthsDropDownValues, yearsDropDownValues } from '../../../common/FundPerformance/apiDataTemplate';
import SelectField from '../../../common/input-fields/SelectField';
import { FieldVariants } from '../../../common/TextInput/appInputenum';
import CheckBoxField from '../../../common/input-fields/CheckBoxField';
import { formatDate } from '../../../common/Functions/TimeConvestion';

const InvestorsEvents = (props) => {

  //#region props
  const { fundId } = props;

  //#region initialdata
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const filterInitialData = {
    'year': currentYear,
    'month': currentMonth,
    'showPastEvents': false
  }

  //#region variables
  const [eventRowsData, setEventRowsData] = useState([]);
  const [filteredEventsData, setFilteredEventsData] = useState([]);
  const [filtersData, setFiltersData] = useState(filterInitialData)

  //#region cloumns
  const eventColumnsData = [
    { "id": 1, "field": "title", "headerName": "Meeting / Event", "width": 350 },
    {
      "id": 2, "field": "startDate", "headerName": "Date", "width": 350,
      renderCell: (params) => {
        return (
          <>{formatDate(params?.value)}</>
        );
      }
    },
  ]

  //#region change events
  const handleChange = (name, value) => {
    setFiltersData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  //#region api get calls
  const getCalendarEventsByFundId = async () => {
    const data = await getEventsByFundId(fundId);
    if (data.responseCode === 200) {
      setEventRowsData(data.responseData);
    }
    else {
      setEventRowsData([]);
    }
  }

  //#region useeffect
  useEffect(() => {
    getCalendarEventsByFundId();
  }, [])

  useEffect(() => {

    let sortedEvents = [...eventRowsData].sort(
      (a, b) => new Date(b.startDate) - new Date(a.startDate)
    );

    if (filtersData?.showPastEvents) {
      sortedEvents = sortedEvents.filter(event => new Date(event.startDate) < new Date());
    } else {
      sortedEvents = sortedEvents.filter(event => new Date(event.startDate) >= new Date());
    }

    if (filtersData?.month) {
      sortedEvents = sortedEvents.filter(event => new Date(event.startDate).getMonth() + 1 === filtersData.month);
    }

    if (filtersData?.year) {
      sortedEvents = sortedEvents.filter(event => new Date(event.startDate).getFullYear() === filtersData.year);
    }

    setFilteredEventsData(sortedEvents);

  }, [filtersData, eventRowsData])

  //#Region return
  return (
    <div className="white-card">
      <div className="space-between" >
        <div className='child-margin-5'>
          <h6>Investors Meetings and Events</h6>
          <div>
            <CheckBoxField
              name="showPastEvents"
              label="Show Past Events"
              value={filtersData?.showPastEvents}
              onChange={(name, value) => handleChange(name, value)} />
          </div>
        </div>
        <div className='child-row-margin-5'>
          <div className="min-width-150">
            <SelectField
              name='month'
              label="Month"
              size='small'
              value={filtersData?.month}
              fullWidth
              variant={FieldVariants?.OUTLINED}
              options={monthsDropDownValues}
              onChange={(name, value) => handleChange(name, value)} />
          </div>
          <div className="min-width-130">
            <SelectField
              name='year'
              label="Year"
              size='small'
              value={filtersData?.year}
              fullWidth
              variant={FieldVariants?.OUTLINED}
              options={yearsDropDownValues}
              onChange={(name, value) => handleChange(name, value)} />
          </div>
        </div>
      </div>
      <div className="height-370">
        <AIPDataGrid
          columns={eventColumnsData}
          rows={filteredEventsData?.map((row, index) => ({ ...row, id: index + 1 }))}
          onRowsSelectionHandler={() => { }} />
      </div>
    </div>
  )
}

export default InvestorsEvents