import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
const RemoveFundPopUp = (props) => {

    //#region props
    const { open, content, onClose, onConfirm } = props;

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>

                <h6> <InfoIcon className='info-i' />Confirmation</h6>
            </DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <Button variant='outlined'
                            startIcon={<CancelIcon />}
                            onClick={onClose}>
                            Cancel
                        </Button>
                    </div>
                    <div className='margin-left-10'>
                        <Button
                            variant='contained'
                            startIcon={<RemoveCircleIcon />}
                            onClick={onConfirm}>
                            Remove
                        </Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default RemoveFundPopUp;