import React, { useEffect, useState } from 'react'
import AIPDataGrid from '../../../../common/DataGrid/AIPDataGrid';
import { Grid, LinearProgress, MenuItem, TextField } from '@mui/material';
import { getPCTransactions, getPCTransactionsByFilter } from '../../../services/services';
import { ConverttoIST } from '../../../../common/Functions/TimeConvestion';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DatePickerInputTypes, FieldVariants, InputTypes } from '../../../../common/TextInput/appInputenum';
import AppTextInput from '../../../../common/TextInput/AppTextField';
import { periodDropDownDetails, periodDropDownEnum, transactionMonths } from '../../../APIDataTemplate';
import prevYearsValues, { YearsDropDownValues } from '../../../../common/Functions/YearsData';
import DatePickerField from '../../../../common/DatePicker/DatePickerField';
import ActionButton from '../../../../common/ActionButton/ActionButton';
import secureLocalStorage from 'react-secure-storage';
import moment from "moment/moment";
import dayjs from 'dayjs';
import FilterListIcon from '@mui/icons-material/FilterList';
import AltDataGridPro from '../../../../common/DataGridPro/AltDataGridPro';
const TransactionsGrid = (props) => {

    //#region props
    const { shareTypes, transactionsData, pinnedrows, setPinnedRows, setTransactionsData, getAllTransactions } = props;

    //#region initialdata
    const filterInitialData = {
        'selectedFilter': '',
        'selectedShareType': ''
    }
    const initalData = {
        'selectedYear': '',
        'selectedMonth': '',
        'customFromDate': '',
        'customToDate': '',
    }

    //#region variables
    const [filtersData, setFiltersData] = useState(filterInitialData);
    const [subFiltersData, setSubFiltersData] = useState(initalData);
    const columns = [
        { field: 'transactionTypeName', headerName: 'Transaction type', width: 200, },
        {
            field: 'transactionDate', headerName: 'Date', width: 200,
            renderCell: (params) => {
                let formattedDate = moment(params.value).format('YYYY-MM-DD');
                return <div>{!params.value || params.value === "1900-01-01" || params.value === "9999-12-31" ? '' : formattedDate}</div>
            }
        },
        { field: 'quantity', headerName: 'Quantity', width: 100, },
        { field: 'sharePrice', headerName: 'Price', width: 200 },
        { field: 'transactionCost', headerName: 'Transaction Cost', width: 200, },
        { field: 'amount', headerName: 'Amount', width: 200, },
    ]

    //#region change events
    const handleFilterChange = (ev) => {
        const { name, value } = ev.target;
        setSubFiltersData(initalData);
        if (value === "currentYear") {
            setSubFiltersData((prevData) => ({
                ...prevData,
                ["selectedYear"]: new Date().getFullYear(),
            }));
        }
        if (value === "previousYear") {
            setSubFiltersData((prevData) => ({
                ...prevData,
                ["selectedYear"]: new Date().getFullYear() - 1,
            }));
        }
        setFiltersData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubFiltersChange = (ev) => {
        const { name, value } = ev.target;
        setSubFiltersData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleShareTypeChange = (ev) => {
        const { name, value } = ev.target;
        setFiltersData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleChangeDate = (name, value) => {
        setSubFiltersData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    //#region click events
    const onResetFilters = () => {
        setFiltersData(filterInitialData);
        setSubFiltersData(initalData);
        getAllTransactions();
    }
    const onFiltersClick = () => {
        if (filtersData.selectedFilter === 'all') {
            getAllTransactions()
        }
        else {
            getFilteredTransactions();
        }
    }

    //#region api get calls
    const getFilteredTransactions = async () => {
        let data = '';
        const companyID = secureLocalStorage.getItem("companyID");
        data = await getPCTransactionsByFilter(companyID, filtersData, subFiltersData);
        if (data.responseCode === 200) {
            const apiData = data?.responseData || [];
            const dataWithId = apiData.map((row, index) => ({
                id: index,
                ...row,
            }));
            const pinnedrows = {
                top: dataWithId?.length > 0 ? [dataWithId[0]] : [],
                bottom: dataWithId?.length > 0 ? [dataWithId[dataWithId.length - 1]] : [],
            };
            setPinnedRows(pinnedrows)
            setTransactionsData(dataWithId);
        }
        else {
            //console.log('unable to fetch data)
        }
    }

    //#region return
    return (
        <div className='child-margin-15'>
            <div className='space-between'>
                <div className='child-row-margin-10'>
                    <div className='width-150'>
                        <TextField
                            select
                            name='selectedFilter'
                            label="Periods"
                            size='small'
                            fullWidth
                            variant={FieldVariants?.OUTLINED}
                            value={filtersData?.selectedFilter}
                            onChange={handleFilterChange}>
                            {periodDropDownDetails?.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    {filtersData?.selectedFilter === periodDropDownEnum.YEAR &&
                        <div className='width-150'>
                            <TextField
                                select
                                name='selectedYear'
                                label="Year"
                                size='small'
                                fullWidth
                                variant={FieldVariants?.OUTLINED}
                                value={subFiltersData?.selectedYear}
                                onChange={handleSubFiltersChange}>
                                {prevYearsValues?.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    }
                    {filtersData?.selectedFilter === periodDropDownEnum.MONTH &&
                        <div className='width-150'>
                            <TextField
                                select
                                name='selectedMonth'
                                label="Month"
                                size='small'
                                fullWidth
                                variant={FieldVariants?.OUTLINED}
                                value={subFiltersData?.selectedMonth}
                                onChange={handleSubFiltersChange}>
                                {transactionMonths?.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    }
                    {filtersData?.selectedFilter === periodDropDownEnum.CUSTOM_DATE &&
                        <div className='child-row-margin-10'>
                            <div className='width-150'>
                                <DatePickerField
                                    type={DatePickerInputTypes.DATE}
                                    name='customFromDate'
                                    label="From Date"
                                    isActive={false}
                                    variant={FieldVariants.OUTLINED}
                                    defaultValue={subFiltersData?.customFromDate}
                                    onChange={(name, value) => handleChangeDate(name, value)}
                                    maxDate={dayjs(moment(subFiltersData?.customToDate).format("YYYY-MM-DD"))} />
                            </div>
                            <div className='width-150'>
                                <DatePickerField
                                    type={DatePickerInputTypes.DATE}
                                    name='customToDate'
                                    label="To Date"
                                    isActive={false}
                                    variant={FieldVariants.OUTLINED}
                                    defaultValue={subFiltersData?.customToDate}
                                    onChange={(name, value) => handleChangeDate(name, value)}
                                    minDate={dayjs(moment(subFiltersData?.customFromDate).format("YYYY-MM-DD"))} />
                            </div>
                        </div>
                    }
                    <div className='width-150'>
                        <TextField
                            select
                            name='selectedShareType'
                            label="Share Type"
                            size='small'
                            fullWidth
                            variant={FieldVariants?.OUTLINED}
                            value={filtersData?.selectedShareType}
                            onChange={handleShareTypeChange}>
                            {shareTypes?.length > 0 && shareTypes?.map((item) => (
                                <MenuItem key={item.listItemID} value={item.listItemID}>
                                    {item.listItemValue}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>
                <div className='child-row-margin-10'>
                    <div>
                        <ActionButton
                            icon={<FilterListIcon />}
                            label="RESET FILTERS"
                            variant="outlined"
                            className="btn-primary"
                            onClick={onResetFilters}>
                        </ActionButton>
                    </div>
                    <div>
                        <ActionButton
                            icon={<FilterListIcon />}
                            label="APPLY FILTERS"
                            variant="contained"
                            className="btn-primary"
                            onClick={onFiltersClick}>
                        </ActionButton>
                    </div>
                </div>
            </div>
            <div className='whiteCard height-400-scroll'>
                {
                    columns && transactionsData ?
                        <AltDataGridPro
                            columns={columns}
                            rows={transactionsData || []}
                            pinnedRows={pinnedrows}
                            sortByLatestFirst={false}
                            rowHeight={39}
                            sx={{
                                '& .MuiDataGrid-row': {
                                    backgroundColor: 'inherit',
                                },
                                '& .MuiDataGrid-pinnedRows': {
                                    backgroundColor: '#CDCDCD',
                                },
                            }}
                            hideFooterPagination={true}
                            disableColumnMenu={false}
                            hideFooter={true} />
                        :
                        <Grid xs={12}>
                            <LinearProgress />
                        </Grid>
                }
            </div>
        </div>
    )
}

export default TransactionsGrid