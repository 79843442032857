import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectField from '../../../common/input-fields/SelectField';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import ActionButton from '../../../common/ActionButton/ActionButton';
import AutoSelectField from '../../../common/input-fields/AutoSelectFiled';
import { FieldVariants, textFormatTypeEnum } from '../../../common/TextInput/appInputenum';
import { yearsDropDownValues } from '../../../common/FundPerformance/apiDataTemplate';
import { FundCreationModes, visibilityDropdownValues } from '../../services/apiDataDemplate';
import { Tooltip } from '@mui/material';
import { fundTypesEnum } from '../../../marketplace-dashboard/jsonData';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { updateFund } from '../../../marketplace/services/MarketplaceService';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import InfoIcon from '@mui/icons-material/Info';
import { fundToolTipMessages } from '../../services/toolTipMessages';

const FundPrimaryDetails = forwardRef((props, ref) => {

  //#region props
  const { type,fundDetails, setFundDetails, fundFilterData, managementStaffDetails,getFundDetails } = props;

  //#region intialData
  const initialData = {
    investmentStrategyId: fundDetails?.strategyName !== "" ? -1 : 0,
    ...fundDetails,
  };
  
  //#region variable
  const componentRef = useRef(null);
  const [loading,setLoading] = useState(false);
  const [isSaveEnabled,setIsSaveEnabled] = useState(type === FundCreationModes.EDIT ? false : true);

  //#region change events
  const handleFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleChange(name, file);
    }
    else {
      handleChange(name, '');
    }
  }

  const handleChange = (field, value) => {
    primaryDetails.setFieldValue(field, value);
  }

  //#region click events
  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const onSaveClick = async() => {
    const errors = await primaryDetails.validateForm();
    if (Object.keys(errors).length === 0) {
      updateFundDetails();
    }
    else {
      primaryDetails?.handleSubmit()
    }
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    primaryDetails?.resetForm();
  }

  //#region update calls
  const updateFundDetails = async () => {
      setLoading(true);
      const requestedData = {
        "fundId": primaryDetails?.values?.fundID,
        "fundName": primaryDetails?.values?.fundName,
        "fundManager": primaryDetails?.values?.fundManager,
        "fundDescription": primaryDetails?.values?.fundDescription,
        "investmentStrategyId": primaryDetails?.values?.investmentStrategyId,
        "fundTargetIndustryId": primaryDetails?.values?.fundTargetIndustryId,
        "geographicFocusId": primaryDetails?.values?.geographicFocusId,
        "fundStatus": primaryDetails?.values?.fundStatus,
        "vintage": primaryDetails?.values?.vintage,
        "investmentPeriodCriteria": primaryDetails?.values?.investmentPeriodCriteria,
        "investmentCycle": primaryDetails?.values?.investmentCycle,
        "visibility": primaryDetails?.values?.visibility,
        "bgImage": "",
        "iconImage": "",
        "fundSize": primaryDetails?.values?.fundSize,
        "minimumInvestmentAmount": primaryDetails?.values?.minimumInvestmentAmount,
        "targettedIRR": 0,
        "createdBy": 1,
        "createdDate": new Date(),
        "updatedBy": 1,
        "updatedDate": new Date(),
        "userId": secureLocalStorage.getItem("userId"),
        "isFundMatured": primaryDetails?.values?.isFundMatured,
        "isFirmAsGP": primaryDetails?.values?.isFirmAsGP,
        "legalStructureId": 1,
        "fundStaff": primaryDetails?.values?.fundStaff /* convertArrayToString(primaryDetails?.values?.fundStaff?.map((a) => a.answer)) || "" */
      };
      
      const formDataBody = new FormData();
      formDataBody.append("fundDetails", JSON.stringify(requestedData));

      const response = await updateFund(formDataBody);
      if (response?.responseCode === 200) {
        setIsSaveEnabled(false);
        setLoading(false);
        getFundDetails();
        toast.success("Successfully Saved",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
      else {
        setLoading(false);
        toast.error("Unable to update fund details", 
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
      }
  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    investmentStrategyId: yup.number()
      .min(1, validationsConstants.REQUIRED)
      .required(validationsConstants.REQUIRED),
    fundTargetIndustryId: yup
      .string()
      .required(validationsConstants.REQUIRED),
    geographicFocusId: yup
      .string()
      .required(validationsConstants.REQUIRED),
    fundSize: yup
      .string()
      .required(validationsConstants.REQUIRED),
      minimumInvestmentAmount: yup
      .string()
      .required(validationsConstants.REQUIRED)
      .test(
        'is-less-than-fundSize',
        'Minimum investment amount should be less than fund size',
        function(value) {
          const { fundSize } = this.parent;
          return value && fundSize ? parseFloat(value) < parseFloat(fundSize) : true;
        }
    ),
    fundStatus: yup
      .string()
      .required(validationsConstants.REQUIRED),
    vintage: yup
      .string()
      .required(validationsConstants.REQUIRED),
    /* investmentPeriodCriteria: yup
        .string()
        .required(validationsConstants.REQUIRED),
    investmentCycle: yup
        .string()
        .required(validationsConstants.REQUIRED), */
    visibility: yup
      .string()
      .required(validationsConstants.REQUIRED),
    legalStructureID: yup
      .string()
      .required(validationsConstants.REQUIRED),
  });

  const primaryDetails = useFormik({
    initialValues: {
      ...initialData
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return true;
    },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await primaryDetails.validateForm();
      if (Object.keys(errors).length === 0) {
        //need to update main set of values
        setFundDetails((prevValues) => {
          return { ...prevValues, ...primaryDetails?.values };
        });
        return true;
      }
      else {
        primaryDetails?.handleSubmit()
      }
    },
    updateFields: async () => {
      setFundDetails((prevValues) => {
        return { ...prevValues, ...primaryDetails?.values };
      });
    }
  }));

  useEffect(() => {
    componentRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  //#region return
  return (
    <>
    {
      type === FundCreationModes.EDIT &&
      <div className='space-between '>
          <div></div>
          <div className='child-row-margin-5 margin-right-10'>
              {isSaveEnabled ?
                <>
                  <div>
                    <ActionButton 
                        label="Cancel"
                        variant={FieldVariants.OUTLINED}
                        disabled={loading}
                        icon={<CancelIcon />}
                        onClick={() => onCancelClick()}/>
                  </div>
                  <div>
                    <ActionButton
                      label="SAVE"
                      icon={<SaveIcon />}
                      loading={loading}
                      onClick={() => onSaveClick()} />
                  </div>
                </> 
                :
                <div>
                  <ActionButton 
                      label="Edit"
                      icon={<EditIcon />}
                      onClick={() => onEditClick()}/>
                </div>}
            
            <div></div>
          </div>
      </div>
      }
      <div className='display-row-items-flex margin-top-10' ref={componentRef}>
        {
          type === FundCreationModes.EDIT &&
          <>
            <div className='width-48'>
              <ALTTextField
                name="fundType"
                label="Fund Type"
                value={primaryDetails?.values?.fundTypeName}
                onChange={(name, value) => handleChange(name, value)}
                /* options={fundTypes?.map(option => ({ label: option.strategyName, value: option.investmentStrategyID }))} */
                required={true}
                readOnly={true}
                onBlur={primaryDetails.handleBlur}
                error={primaryDetails.touched.fundType && Boolean(primaryDetails.errors.fundType)}
                errorMessage={primaryDetails.touched.fundType && primaryDetails.errors.fundType} />
            </div>
            <div className='width-48'>
              <ALTTextField
                  name="fundName"
                  label="Fund Name"
                  value={primaryDetails?.values?.fundName}
                  onChange={(name, value) => handleChange(name, value)}
                  required={true}
                  readOnly={!isSaveEnabled}
                  onBlur={primaryDetails.handleBlur}
                  error={primaryDetails.touched.fundName && Boolean(primaryDetails.errors.fundName)}
                  errorMessage={primaryDetails.touched.fundName && primaryDetails.errors.fundName} />
            </div>
          </>
        }
        <div className='width-98'>
          <Tooltip title={fundToolTipMessages.fundLogo}>
                <InfoIcon color="action" />
          </Tooltip>
          <div className="legalFieldWrapper mt1 align-center">
            <FileUploadBtn
              name="iconImage"
              label="UPLOAD LOGO"
              defaultFile={primaryDetails?.values?.iconImage}
              isImage={true}
              maxSize={2}
              fileType={uploadFileTypes.IMAGE}
              disabled={!isSaveEnabled}
              onChange={(name, value) => handleFileUpload(name, value)}
              /* imgClassName="create-fund-icon-img" */ />
            <div className="margin-top-5 color-gray">
                    Size: 2 MB. Accepted file types: PNG, SVG, JPEG, JPG
            </div>
          </div>
        </div>
        <div className='width-98'>
          <Tooltip title={fundToolTipMessages.fundBanner}>
                <InfoIcon color="action" />
          </Tooltip>
          <div className="legalFieldWrapper mt1 align-center">
            <FileUploadBtn
              name="bgImage"
              label="UPLOAD FUND BANNER IMAGE"
              defaultFile={primaryDetails?.values?.bgImage}
              isImage={true}
              maxSize={2}
              disabled={!isSaveEnabled}
              fileType={uploadFileTypes.IMAGE}
              onChange={(name, value) => handleFileUpload(name, value)}
              /* imgClassName="create-fund-banner-img" */ />
            <div className="margin-top-5 color-gray">
                    Size: 2 MB. Accepted file types: PNG, SVG, JPEG, JPG
            </div>
          </div>
        </div>
        <div className='width-98'>
          <Tooltip title={fundToolTipMessages.fundDescription}>
                <InfoIcon color="action" />
          </Tooltip>
          <div className='mt1'>
            <ALTTextField
              name="fundDescription"
              placeholder="Brief description about the fund"
              value={primaryDetails?.values?.fundDescription}
              onChange={(name, value) => handleChange(name, value)}
              multiline={true}
              rows={2}
              required={true}
              readOnly={!isSaveEnabled}
              onBlur={primaryDetails.handleBlur}
              error={primaryDetails.touched.fundDescription && Boolean(primaryDetails.errors.fundDescription)}
              errorMessage={primaryDetails.touched.fundDescription && primaryDetails.errors.fundDescription} />
          </div>
        </div>
        <div className='width-98 internalText margin-top-15 margin-bottom-15'>
          What is the fund’s investment strategy
        </div>
        <div className='width-48'>
          <AutoSelectField
            name="investmentStrategyId"
            childName="strategyName"
            label="Investment Strategy"
            value={primaryDetails?.values?.investmentStrategyId}
            childValue={primaryDetails?.values?.strategyName}
            onChange={(name, value) => handleChange(name, value)}
            options={fundFilterData?.strategy?.map(option => ({ label: option.strategyName, value: option.investmentStrategyID }))}
            required={true}
            readOnly={!isSaveEnabled}
            toolTip={fundToolTipMessages.investmentStrategy}
            onBlur={primaryDetails.handleBlur}
            error={primaryDetails.touched.investmentStrategyId && Boolean(primaryDetails.errors.investmentStrategyId)}
            errorMessage={primaryDetails.touched.investmentStrategyId && primaryDetails.errors.investmentStrategyId} />
        </div>
        <div className='width-48'>
          <AutoSelectField
            name="fundTargetIndustryId"
            childName="targetIndustry"
            label="Target Industry/Sector"
            value={primaryDetails?.values?.fundTargetIndustryId}
            childValue={primaryDetails?.values?.targetIndustry}
            onChange={(name, value) => handleChange(name, value)}
            options={fundFilterData?.industry?.map(option => ({ label: option.targetIndustry, value: option.fundTargetIndustryID }))}
            required={true}
            readOnly={!isSaveEnabled}
            toolTip={fundToolTipMessages.targetIndustry}
            onBlur={primaryDetails.handleBlur}
            error={primaryDetails.touched.fundTargetIndustryId && Boolean(primaryDetails.errors.fundTargetIndustryId)}
            errorMessage={primaryDetails.touched.fundTargetIndustryId && primaryDetails.errors.fundTargetIndustryId} />
        </div>
        <div className='width-48'>
          <AutoSelectField
            name="geographicFocusId"
            childName="geographicFocusName"
            label="Geographic Focus"
            value={primaryDetails?.values?.geographicFocusId}
            childValue={primaryDetails?.values?.geographicFocusName}
            onChange={(name, value) => handleChange(name, value)}
            options={props?.fundFilterData?.geographicFocus?.map(option => ({ label: option.geographicFocusName, value: option.geographicFocusId }))}
            required={true}
            readOnly={!isSaveEnabled}
            toolTip={fundToolTipMessages.geographicsFocus}
            onBlur={primaryDetails.handleBlur}
            error={primaryDetails.touched.geographicFocusId && Boolean(primaryDetails.errors.geographicFocusId)}
            errorMessage={primaryDetails.touched.geographicFocusId && primaryDetails.errors.geographicFocusId} />
        </div>
        <div className='width-48'>
          <ALTTextField
            textFormatType={textFormatTypeEnum.US_CURRENCY}
            name="fundSize"
            label="Target Raise"
            value={primaryDetails?.values?.fundSize}
            onChange={(name, value) => handleChange(name, value)}
            required={true}
            readOnly={!isSaveEnabled}
            toolTip={fundToolTipMessages.targetRaise}
            onBlur={primaryDetails.handleBlur}
            error={primaryDetails.touched.fundSize && Boolean(primaryDetails.errors.fundSize)}
            errorMessage={primaryDetails.touched.fundSize && primaryDetails.errors.fundName} />
        </div>
        <div className='width-48'>
          <ALTTextField
            textFormatType={textFormatTypeEnum.US_CURRENCY}
            name="minimumInvestmentAmount"
            label="Minimum Investment Amount"
            value={primaryDetails?.values?.minimumInvestmentAmount}
            onChange={(name, value) => handleChange(name, value)}
            required={true}
            readOnly={!isSaveEnabled}
            toolTip={fundToolTipMessages.minimumInvestmentAmount}
            onBlur={primaryDetails.handleBlur}
            error={primaryDetails.touched.minimumInvestmentAmount && Boolean(primaryDetails.errors.minimumInvestmentAmount)}
            errorMessage={primaryDetails.touched.minimumInvestmentAmount && primaryDetails.errors.minimumInvestmentAmount} />
        </div>
        <div className='width-48'>
          <SelectField
            name="fundStatus"
            label="Fund Status"
            value={primaryDetails?.values?.fundStatus}
            onChange={(name, value) => handleChange(name, value)}
            options={fundFilterData?.fundStatus?.map(option => ({ label: option.fundStatusName, value: option.fundStatusId }))}
            required={true}
            readOnly={!isSaveEnabled}
            toolTip={fundToolTipMessages.fundStatus}
            onBlur={primaryDetails.handleBlur}
            error={primaryDetails.touched.fundStatus && Boolean(primaryDetails.errors.fundStatus)}
            errorMessage={primaryDetails.touched.fundStatus && primaryDetails.errors.fundStatus} />
        </div>
        <div className='width-48'>
          <SelectField
            name="vintage"
            label="Vintage Year"
            value={primaryDetails?.values?.vintage}
            onChange={(name, value) => handleChange(name, value)}
            options={yearsDropDownValues}
            required={true}
            readOnly={!isSaveEnabled}
            toolTip={fundToolTipMessages.vintageYear}
            onBlur={primaryDetails.handleBlur}
            error={primaryDetails.touched.vintage && Boolean(primaryDetails.errors.vintage)}
            errorMessage={primaryDetails.touched.vintage && primaryDetails.errors.vintage} />
        </div>
        {
          (fundDetails?.fundTypeName === fundTypesEnum.PRIVATE_EQUITY || fundDetails?.fundTypeName === fundTypesEnum.VENTURE_CAPITAL) &&
          <div className='width-48'>
            <ALTTextField
              name="investmentPeriodCriteria"
              label="Term"
              value={primaryDetails?.values?.investmentPeriodCriteria}
              onChange={(name, value) => handleChange(name, value)}
              onBlur={primaryDetails.handleBlur}
              readOnly={!isSaveEnabled}
              toolTip={fundToolTipMessages.term}
              error={primaryDetails.touched.investmentPeriodCriteria && Boolean(primaryDetails.errors.investmentPeriodCriteria)}
              errorMessage={primaryDetails.touched.investmentPeriodCriteria && primaryDetails.errors.investmentPeriodCriteria} />
          </div>
        }
        <div className='width-48'>
          <AutoSelectField
            name="investmentCycle"
            childName="liquidityProvisionName"
            label="Liquidity"
            value={primaryDetails?.values?.investmentCycle}
            childValue={primaryDetails?.values?.liquidityProvisionName}
            onChange={(name, value) => handleChange(name, value)}
            options={props?.fundFilterData?.liquidityProvision?.map(option => ({ label: option.liquidityProvisionName, value: option.liquidityProvisionId }))}
            /* required={true} */
            readOnly={!isSaveEnabled}
            toolTip={fundToolTipMessages.liquidity}
            onBlur={primaryDetails.handleBlur}
            error={primaryDetails.touched.investmentCycle && Boolean(primaryDetails.errors.investmentCycle)}
            errorMessage={primaryDetails.touched.investmentCycle && primaryDetails.errors.investmentCycle} />
        </div>
        <div className='width-48'>
          <SelectField
            name="visibility"
            label="Visibility"
            value={primaryDetails?.values?.visibility}
            onChange={(name, value) => handleChange(name, value)}
            options={visibilityDropdownValues}
            required={true}
            readOnly={!isSaveEnabled}
            toolTip={fundToolTipMessages.fundVisibility}
            onBlur={primaryDetails.handleBlur}
            error={primaryDetails.touched.visibility && Boolean(primaryDetails.errors.visibility)}
            errorMessage={primaryDetails.touched.visibility && primaryDetails.errors.visibility} />
        </div>
        <div className='width-48'>
          <SelectField
            name="legalStructureID"
            label="Legal Structure"
            value={primaryDetails?.values?.legalStructureID}
            onChange={(name, value) => handleChange(name, value)}
            options={fundFilterData?.legalStructure.map(option => ({ label: option.legalStructureName, value: option.legalStructureID }))}
            required={true}
            readOnly={!isSaveEnabled}
            toolTip={fundToolTipMessages.legalStructure}
            onBlur={primaryDetails.handleBlur}
            error={primaryDetails.touched.legalStructureID && Boolean(primaryDetails.errors.legalStructureID)}
            errorMessage={primaryDetails.touched.legalStructureID && primaryDetails.errors.legalStructureID} />
        </div>
      </div>
    </>
  );
});

export default FundPrimaryDetails;

/* <div className='width-98 margin-top-minus-50'>
              <CrudDataGrid
                name="fundStaff"
                addLabel="ADD FUND MANAGER"
                initialColumns={optionsGridColumns}
                initialRows={fetchGridInitialData(primaryDetails.values.
                )}
                onRowChange={(name, value) => handleChange(name, value)}
                height={200}
                disabled={!isSaveEnabled} />
</div> 
const optionsGridColumns = [
    {
      field: 'answer', headerName: 'FundManager Name', editable: true, flex: 1
      , type: 'singleSelect',
      valueOptions: managementStaffDetails,
      renderEditCell: (params) => (
        <Select
          value={params.value || ''}
          onChange={(event) => params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value })}
          fullWidth>
          {managementStaffDetails.map((option) => (
            <MenuItem key={option.value} value={option.value}
              disabled={(Array.isArray(primaryDetails?.values?.fundStaff) && primaryDetails?.values.fundStaff?.find((item) => item?.answer === option?.value)) ||
                (Array.isArray(fundDetails?.managementDetails) && fundDetails?.managementDetails?.find((item) => item?.answer === option?.value))}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: 'description', headerName: 'Description', editable: false, flex: 1,
      renderCell: (params) =>
        <div>
          {managementStaffDetails?.find((item) => item?.value === params.value?.answer)?.briefDescription}
        </div>,
    },
  ]
    */