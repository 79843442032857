import {
  MenuButton,
  RichTextEditor
} from "mui-tiptap";
import { useEffect, useRef, useState } from "react";
import EditorMenuControls from "../RichText/EditorMenuControls";
import { useExtensions } from "../RichText/useExtensions";
import { TextFields } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { validationsTypeEnum } from "../TextInput/appInputenum";
import propTypes from 'prop-types';

const RichTextInput = (props) => {

  //#region props
  const {
    name,
    variant,
    defaultValue,
    onChange,
    size,
    validateField,
    validationType,
    validationMessage,
    checkIfValid,
    setcheckIfValid,
    checknoofValidFields,
    placeholder,
    hideTextControllers,
    disabled
  } = props;

  //#region variables
  const rteRef = useRef(null);
  const editor = rteRef.current?.editor;
  const [content,setContent] = useState(defaultValue || '');
  const [showMenuBar,setShowMenuBar] = useState(true);
  const [iserror, setError] = useState(false);
  const extensions = useExtensions({
    placeholder: placeholder,

  });

  //#region change events
  const handleChange = (event) => {
    const eventContent = event.editor.getHTML()
    const isValid = ValidateField(name, eventContent);
    setError(!isValid);
    onChange(name, eventContent);
    setContent(eventContent);
  };

  //#region validations
  const ValidateField = (name, value) => {
    if(value === ''){
      return false
    }
    var content = new DOMParser().parseFromString(value, "text/xml");
    if (validateField) {
        if (validationType === validationsTypeEnum.REQUIRED) {
            const isValid = content?.firstChild?.textContent.trim() !== '';
            return isValid;
        }
    }
    
    else{
      return true;
    }
  }

  //#region useEffect
  useEffect(() => {
    if(checkIfValid) {
        const isValid = ValidateField(name, content);  
        if(isValid) {
                checknoofValidFields(isValid,name,defaultValue);   
                setcheckIfValid(false);
        } else {
            checknoofValidFields(isValid,name,defaultValue);   
            setError(!isValid); 
            setcheckIfValid(false);
        }
        //checkIfValidRef.current = true;
    }
  },[checkIfValid])

  //#region return
  return (
    <div>
      <RichTextEditor
        ref={rteRef}
        extensions={extensions} 
        content={content}
        onUpdate={handleChange}
        renderControls={() => (
          <EditorMenuControls />
        )}
        RichTextFieldProps={{
          variant: variant,
          MenuBarProps: {
            hide: hideTextControllers || !showMenuBar,
          },
          style: {
            border: `1px solid ${true ? 'red' : 'initial'}` // Change border color to red on error
          },
          footer: (
            !hideTextControllers && (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: (theme) => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                }}
              >
                <MenuButton
                  value="formatting"
                  tooltipLabel={showMenuBar ? "Hide formatting" : "Show formatting"}
                  size={size}
                  sx={{
                    borderColor: 'red !important'
                  }}
                  onClick={() => setShowMenuBar((currentState) => !currentState)}
                  selected={showMenuBar}
                  IconComponent={TextFields}
                />
              </Stack>
            )
          ),
          
        }}
      />
      {iserror && <p style={{ color: 'red' }}>{validationMessage}</p>}
    </div>
  );
}

RichTextInput.propTypes = {
  variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
  onChange: propTypes.func.isRequired,
  required: propTypes.bool,
  placeholder: propTypes.string,
  defaultValue: propTypes.string,
  disabled: propTypes.bool,
  name: propTypes.string,
  size: propTypes.string,
  validateField: propTypes.bool,
  hideTextControllers: propTypes.bool,
};

RichTextInput.defaultProps = {
  variant: 'outlined',
  size: 'small',
  required: false,
  defaultValue: '',
  placeholder: 'Add your own content here...',
  disabled: false,
  name: '',
  validateField: false,
  hideTextControllers: false
};

export default RichTextInput;
