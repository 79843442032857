import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const RevenueEbitdaChart = () => {
  // Data to display
  const data = [
    { name: "Q1 '22", Revenue: 120, EBITDA: 35 },
    { name: "Q2 '22", Revenue: 130, EBITDA: 37 },
    { name: "Q3 '22", Revenue: 140, EBITDA: 38 },
    { name: "Q4 '22", Revenue: 150, EBITDA: 42 },
  ];

  return (
    <div style={{ width: '100%', height: '600px', padding: '16px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" label={{ value: 'Revenue ($M)', angle: -90, position: 'insideLeft' }} />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{ value: 'EBITDA ($M)', angle: -90, position: 'insideRight' }}
          />
          <Tooltip />
          <Legend />
          <Bar yAxisId="left" dataKey="Revenue" barSize={70} fill="#1F61C5" />
          <Line yAxisId="right" type="monotone" dataKey="EBITDA" stroke="#AB1D1D" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RevenueEbitdaChart;
