import { Button, Divider, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { useDispatch, useSelector } from 'react-redux';
import { addCompare, getAllFunds, removeCompare, removeFavourite, updateAllFunds } from '../../../../state/slices/MarketPlaceSlice';
import { FundAddTypes } from '../../mpenum';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { updateFavouriteFund } from '../../services/mpservices';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { AppDashboards, FeatureAccess, RolesAndPermissions } from '../../../../utils/enum';
import { checkFeaturePermissions } from '../../../../utils/common';
import RemoveFundPopUp from '../../popups/RemoveFundPopUp';
import { fundSubscriptionStatues } from '../../../CODashboard/Services/apiDataTempate';
import { fundTypesEnum } from '../../jsonData';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

const FundBoxView = (props) => {
  //#region props
  const { fundDetails,allFunds,type,allInvestors,fetchDataforfavfunds } = props;
  
  //#region variables
  const dispatch = useDispatch();
  const fundDescriptionMaxLength = 150;
  const navigate = useNavigate();
  const [open,setOpen] = useState(false);
  const subscribebtn = secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?
    (fundDetails?.isFundMatured
      || secureLocalStorage.getItem("InvestorFundIDs")?.includes(fundDetails?.fundID)
      || fundDetails.fundStatusName === 'Closed to New Investors' || fundDetails.fundStatusName === 'Launching Soon' || fundDetails.fundStatusName === 'Closed' || fundDetails.fundStatusName === 'Terminated'
    ) :
    true;
  
  //#region click events
  const handleRemoveCompare = (fund) => {
    const updatedFunds = allFunds.map(item => {
      if (item.fundID === fund.fundID) {
          return {...item, compare: false}; 
      }
      return item;
    });
    dispatch(updateAllFunds({ value : updatedFunds }));
    dispatch(removeCompare({ fundID : fund.fundID }));
    handleClose();
  }

  const handleRemoveFavourite = async(fund) => {
    const data = await updateFavouriteFund(fund.fundID,false);
    if(data.responseCode === 200){
      const updatedFunds = allFunds.map(item => {
        if (item.fundID === fund.fundID) {
            return {...item, favourite: false}; 
        }
        return item;
      });
      const fundID = fund.fundID;
      dispatch(updateAllFunds({ value : updatedFunds }));
      dispatch(removeFavourite({ fundID : fundID }));
      fetchDataforfavfunds();
    }
    else{
      /* console.log("Unable to remove Fund") */
    }
    handleClose();
  }

  const handlesubscribe = (item,itemId, fundname, event) => {
    if (event) {
      event.stopPropagation();
    }
    secureLocalStorage.setItem("FundName", fundname);
    secureLocalStorage.setItem("FundId", itemId);
    secureLocalStorage.setItem("FundSPIcon", item.iconImage);
    secureLocalStorage.setItem("fundDescription", item.fundDescription);

   /*  console.log(item); */
    /*   */
    navigate('/investmentProfile', { state: { FundId: { itemId } } });
    window.scrollTo(0, 0);
  };

  const handleViewDetail = (item,itemIdObj, fundname,fundStatusName, disableSubscriptionButton,event ) => {
    if (event) {
      event.stopPropagation();
    }
    let parsedFundId;
    try {
      parsedFundId = JSON.parse(itemIdObj);
    } catch (error) {
      console.error("Error parsing fundDetail:", error);
    }
    secureLocalStorage.setItem("FundId", parsedFundId);
    secureLocalStorage.setItem("FundName", fundname);
    const havepermissiontoread =  checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.READ);
    if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
      /* navigate('/fund-details', { parsedFundId }, { fundStatusName }); */
      navigate('/fund-details', {
        state: {
          FundID: parsedFundId,
          InvestorID: secureLocalStorage.getItem("userId"),
          SubscriptionID: 0,
          navigatefrommarketplace: true,
          disableSubscriptionButton:disableSubscriptionButton
        }
      });
      
    } else if (havepermissiontoread) {
      
      const SelectedFund = {
        "FundMaturity": item.isFundMatured,
        "FundTermination": item.fundTerminated,
        BGImage: item.bgImage,
        IconImage: item.iconImage,
      }
      secureLocalStorage.setItem("navigatingFromMarketPlace", true);
      secureLocalStorage.setItem("selectedFund", SelectedFund);
      navigate('/marketplace/OverviewPage', { parsedFundId });
    }
    
    secureLocalStorage.setItem("FundId", JSON.stringify(parsedFundId));
    secureLocalStorage.setItem('fundStatusName',fundStatusName);
    window.scrollTo(0, 0);
  };

  const onInvestmentFundClick = () => {
    secureLocalStorage.setItem("FundId", fundDetails.fundID);
    if (allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.APPROVED))) {
      navigate('/Investor-Overview-Page', {
        state: {
          FundId: fundDetails.fundID,
          subscriptionId: fundDetails.subscriptionID
        }
      });
    }
    else {
      secureLocalStorage.setItem("FundId", fundDetails.fundID);
      navigate('/subscription-details', {
        state: {
          FundId: fundDetails.fundID,
          subscriptionId: fundDetails.subscriptionID,
          selectedRow: fundDetails,
        }
      });
    }
  }

  const handleOpen = () =>{
    setOpen(true);
   }
   const handleClose = () => {
    setOpen(false);
   }

  //#region more details fields
  const moreFundDetailsTop = [
    {name : 'Equity' , value : '70%'},
    {name : 'Credit' , value : '30%'}
  ]

  const moreFundDetailsBottom = [
    {name : 'Vinatge' , value : fundDetails?.vintage},
    {name : 'Target Raise' , value : fundDetails?.fundSize},
    {name : 'Capital Called' , value : fundDetails?.capitalCalled},
    {name : 'Number of Investments' , value : fundDetails.noOfInvestors ? fundDetails.noOfInvestors : 0}
  ]


  //#region return
  return (
    <div className={fundDetails?.isFundMatured === true || fundDetails?.fundTerminated === true ? "box-view" : 'box-view'}>
      <div className='card-img-container'>
        <img
          className='bg-img-container'
          src={fundDetails?.bgImage || '/DefaultBackgroundImage.png'}
            alt="Background"
            onError={(e) => {
              e.target.src = '/DefaultBackgroundImage.png';
          }}
        />
        <img
          className='icon-img-container'
          src={fundDetails?.iconImage || '/DefaultLogo.png'}
          alt="Icon"
          onError={(e) => {
            e.target.src = '/DefaultLogo.png';
          }}
        />
        <div className='status-container'>
          <span className='status-text'>{fundDetails?.fundStatusName}</span>
        </div>
      </div>
      <div className='card-type space-between items-center margin-top-5'>
        <div className='card-details-header'>
          {fundDetails?.fundTypeName}
        </div>
        <div className='mp_fav_icon'>
              {
                type === FundAddTypes.COMPARE ?
                <Tooltip title="Click to Remove">
                                           <CompareArrowsIcon 
                                            onClick={()=>handleOpen()}
                                            color="primary" className='remove-Icon'
                                            />
                                           </Tooltip> :
                                            <Tooltip title="Click to Remove">
                                          <FavoriteIcon 
                                            onClick={()=>handleOpen()}
                                            color="primary"
                                            className='remove-Icon'
                                            />
                                            </Tooltip>
              }

        </div>
      </div>
      <div className='card-header space-between margin-top-5'>
        <div>
          {fundDetails.fundName}
        </div>
      </div>
      <div className='card-desc margin-top-10'>
        {/*  {fundDetails.fundName} */}
        {fundDetails.fundDescription?.length > fundDescriptionMaxLength ? fundDetails.fundDescription?.slice(0, fundDescriptionMaxLength) + '...' : fundDetails.fundDescription}
      </div>
      <div className='card-details display-row space-around'>
       {/* {
        fundDetails?.fundTypeName === fundTypesEnum.PRIVATE_EQUITY && 
        <div className='card-details-header display-column'>
          <div>
            Target Return
          </div>
          <div className='card-details-value'>
            {fundDetails.targettedIRR}%
          </div>
        </div>
       } */}
        <div className='card-details-header display-column'>
          <div>
            Minimum
          </div>
          <div className='card-details-value'>
            ${fundDetails.minimumInvestmentAmount ? fundDetails.minimumInvestmentAmount.toLocaleString() : ''}
          </div>
        </div>
        {
          fundDetails?.fundTypeName === fundTypesEnum.REAL_ESTATE_FUND ? <></> :
          <div className='card-details-header display-column'>
            <div>
              Liquidity
            </div>
            <div className='card-details-value'>
              {fundDetails.liquidityProvision}
            </div>
          </div> 
        }
      </div>
        <b className='color-gray'>
           Portfolio By Asset Type
        </b>
        <div>
          {
            moreFundDetailsTop?.map((item,index)=>(
              <>
                <div className='display-row space-between margin-top-15 margin-bottom-15' key={index}>
                  <div>
                      {item.name}
                  </div>
                  <div>
                      {item.value}
                  </div>
                </div>
                <Divider />
              </>
            ))
          }
        </div>
        <b className='margin-top-10 color-gray'>
           Fund Metrics
        </b>
        <div>
          {
            moreFundDetailsBottom?.map((item,index)=>(
              <>
                <div className='display-row space-between margin-top-15 margin-bottom-15' key={index}>
                  <div>
                      {item.name}
                  </div>
                  <div>
                      {item.value}
                  </div>
                </div>
                <Divider />
              </>
            ))
           }
        </div>
      <div className='card-btns space-between margin-top-15'>
        <div>
          <Button
            component="label"
            variant="outlined"
            className="btn-primary"
            style={{ minwidth: '150px', minHeight: '37px', }}
            onClick={() => handleViewDetail(fundDetails, fundDetails.fundID, fundDetails.fundName, fundDetails.fundStatusName, allInvestors.some(u => u.fundID === fundDetails?.fundID))}
          >
            VIEW DETAIL
          </Button>
        </div>
        {
          allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.COMPLIANCE_REVIEW)
          || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.FM_REVIEW)
          ||(u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.APPROVED)
          ||(u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.REJECTED) ) ? 
          <div>
            <Button
              component="label"
              variant="contained" 
              onClick={onInvestmentFundClick}>
              MY INVESTMENT
            </Button>
          </div> : 
          <div>
           <Button
            component="label"
            variant="contained"
            /* className="btn-primary" */
            style={
              allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.INVESTOR_SIGN_PENDING))
                ? { width: '150px', backgroundColor: '#F9C023', color: 'black' } :
                subscribebtn
                  ? { width: '150px' }
                  : { width: '150px', backgroundColor: '#F9C023', color: 'black' }
            }


            onClick={() => {
              handlesubscribe(fundDetails, fundDetails.fundID, fundDetails.fundName)
            }}
            disabled={subscribebtn}>
            {
              
              allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.INVESTOR_SIGN_PENDING)) ? "E-SIGN"
                : allInvestors.some(u => u.fundID === fundDetails?.fundID) ? 'SUBSCRIBED' : 'SUBSCRIBE'}
           </Button>
          </div>
        }
      </div>
      {
         open && <RemoveFundPopUp 
                     open={open} 
                     onConfirm={type === FundAddTypes.COMPARE ? ()=>handleRemoveCompare(fundDetails) : ()=>handleRemoveFavourite(fundDetails)} 
                     onClose={handleClose} 
                     content={type === FundAddTypes.COMPARE ? "Are you sure you would like to remove this fund from your Compare list?" :
                             "Are you sure you would like to remove this fund from your Favorite Funds list?"} />
       }
    </div>
  )
}

export default FundBoxView