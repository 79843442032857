// HelpAndSupportFAQ.jsx
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const faqs = [
    {
        question: "What is a fund?",
        answer: ["In Alt360, a fund is an abstract entity which invests in several assets.",
            " You can work with as many funds as you have in your AIP Portal, though you always work with a single fund at a time to avoid unintended changes. Follow these simple steps to create a fund."]
    },
    {
        question: "What Is an Alternative Investment?",
        answer: ["Alternative investments are financial assets that are different from traditional investments such as stocks, bonds, and cash. They include assets such as real estate, hedge funds, private equity, commodities, and art. Alternative investments are often less liquid and more difficult to value than traditional investments, and they may carry a higher level of risk. They are generally sought by investors as a way to diversify their portfolios and potentially achieve higher returns.",
            " In short, more and more investors are shifting to alternatives to boost returns, generate income, provide diversification from traditional investments, and achieve their goals."]
    },
    {
        question: "Who can invest in an AIF?",
        answer: "AIFs are typically open to high-net-worth individuals (HNIs), institutional investors, and other qualified investors who meet specific eligibility criteria set by the fund manager or regulatory authorities."
    },
    {
        question: "What is the Risk vs. Return in Alternative Investments?",
        answer: [
            "Volatility Risk: Prices of alternative investments can go up and down a lot, making it hard to predict how much you'll make or lose.",
            "Lock-in Risk: Your money might be tied up for a long time, and you can't get it back quickly if you need it.",
            "Transparency Risk: It's not always clear how well your alternative investment is doing because there's less information available compared to traditional investments.",
            "Regulatory Risk: Changes in rules and regulations can affect how your alternative investment works and how much money you can make.",
            "Market and Economic Risk: The performance of your alternative investment can be affected by overall market conditions and changes in the economy.",
            "Managerial Risk: If the people managing your investment don't do a good job, you might not make as much money as you hoped, or even lose money."
        ]
    },
    {
        question: "What is the role of the fund manager in an AIF?",
        answer: "The fund manager is responsible for making investment decisions and overseeing the fund’s operations."
    },
    {
        question: "What are the Pros and Cons of Alternative Investment?",
        answer: [
            "Pros:",
            "Counterweight to conventional assets.",
            "Portfolio diversification.",
            "Inflation hedge.",
            "High rewards.",
            "Cons:",
            "Difficult to value.",
            "Illiquid.",
            "Fewer regulatory requirements.",
            "High risk."
        ]
    }
];


const HelpAndSupportFAQ = (props) => {

    //#region props
    const { setActiveBodySection } = props

    //#region click events
    const handleBackToList = () => {
        setActiveBodySection(null)
    };

    //#rgeion return
    return (
        <div className='help-Faqs'>
            <Box>
                <Button onClick={() => handleBackToList()}>
                    <ArrowBackIcon className='helpbackicon' />
                    Go Back
                </Button>
                <h2 className='margin-top-10'>FAQs</h2>
                {faqs.map((faq, index) => (
                    <Accordion key={index} sx={{ mb: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='help-accordian'>
                            <h6>{faq.question}</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {Array.isArray(faq.answer) ? (
                                    <ul>
                                        {faq.answer.map((item, idx) => {
                                            if (item.startsWith("Pros:") || item.startsWith("Cons:")) {
                                                return (
                                                    <li key={idx} style={{ listStyleType: "none", marginTop: "10px" }}>
                                                        <strong>{item.replace(":", "")}:</strong>
                                                    </li>
                                                );
                                            }
                                            return <li key={idx}>{item}</li>;
                                        })}
                                    </ul>
                                ) : (
                                    faq.answer
                                )}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </div>
    );
};

export default HelpAndSupportFAQ;

