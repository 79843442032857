import React, { useEffect, useState } from 'react'
import { userProfileToastMessages, validationsConstants } from '../../../../utils/AppConstants';
import { getOTPAttemptsIn24Hours, PostOTP, updateUserProfileIconData } from '../../../user-profile/services/UserProfileService';
import { getInvestorProfileDetails, updateUserProfile } from '../../services/services';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Grid, LinearProgress } from '@mui/material';
import VerificationOtp from '../../popups/VerificationOtp';
import ActionButton from '../../../common/ActionButton/ActionButton';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import { textFormatTypeEnum } from '../../../common/TextInput/appInputenum';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import DateField from '../../../common/input-fields/DateField';
import secureLocalStorage from 'react-secure-storage';
import { addCustomYupMethods } from '../../../common/input-fields/utlis/CustomYupValidations';
import moment from 'moment';
const FirmPersonalInformation = (props) => {

  //#region props
  const { firmUserDetails, getFirmUserInfo } = props;

  //#region variables
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verificationPopup, setVerificationPopup] = useState(false);
  const [otpAttemptsLeft, setOTPAttemptsLeft] = useState(0);

  //#region change events
  const handleFileUpload = async (name, file) => {
    if (file) {
      handleChange(name, file);
    }
    else {
      handleChange(name, "");
    }
  }

  const handleChange = (name, value) => {
    personalInformationDetails.setFieldValue(name, value);
  }

  //#region click events
  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const onOpenVerificationPopup = () => {
    setVerificationPopup(true);
  }

  const onCloseVerificationPopup = () => {
    setVerificationPopup(false);
  }

  const onSaveClick = () => {
    if (firmUserDetails?.personalInformation?.emailAddress === personalInformationDetails.values?.emailAddress) {
      updatePersonalInformation();
    }
    else {
      if (otpAttemptsLeft <= 0) {
        toast.error(userProfileToastMessages.ATTEMPTS_REACHED, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      }
      else {
        checkEmail();
      }
    }
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    getFirmUserInfo();
    setLoading(false)
  }

  const otpAttemptsIn24Hours = async () => {
    const data = await getOTPAttemptsIn24Hours();
    if (data?.responseCode === 200) {
      setOTPAttemptsLeft(data?.responseData);
    }
    else {
      setOTPAttemptsLeft(0);
    }
  }

  const checkEmail = async () => {
    const emailCheck = await getInvestorProfileDetails(personalInformationDetails.values.emailAddress);
    if (emailCheck.responseCode === 200) {
      toast.error(userProfileToastMessages.EXCISTING_EMIAL, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else {
      if (emailCheck.responseData.pendingDaysToChangeEmailAddress > 60
      ) {
        onOpenVerificationPopup();
      }
      else {
        toast.error(userProfileToastMessages.OLD_EMAIL_CANNOT_USE, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      }
    }
  }

  //#region api post calls
  const updatePersonalInformation = async (fromOtp, verifiedOtp, otpID) => {
    setLoading(true);
    const requestData = {
      "personalInformation": {
        ...personalInformationDetails.values,
        dateOfBirth: moment(personalInformationDetails.values?.dateOfBirth).format("YYYY-MM-DD") || null,
        "tenantGUID": secureLocalStorage.getItem('tenantID')
      },
      // "personalInformation": personalInformationDetails.values,
      "bankingInformation": null,
      "investmentProfile": null
    };
    const newImage = personalInformationDetails.values.profileIcon
    if (personalInformationDetails.values.profileIcon !== firmUserDetails?.personalInformation?.profileIcon) {
      requestData.personalInformation.profileIcon = personalInformationDetails.values.profileIcon.name
    }
    const data = await updateUserProfile(requestData, personalInformationDetails.values.userId)
    if (data.responseCode === 200) {
      if (personalInformationDetails.values.profileIcon !== firmUserDetails?.personalInformation?.profileIcon) {
        uploadProfileIcon(newImage, fromOtp);
      }
      else {
        if (fromOtp) {
          //#for verification popup
          const requestData = {
            "otpId": otpID,
            "otp": verifiedOtp,
            "newEmailAddress": personalInformationDetails.values.emailAddress,
            "oldEmailAddress": secureLocalStorage.getItem("userEmail"),
            "adb2cUserID": secureLocalStorage.getItem("tenantUserId"),
            "userName": secureLocalStorage.getItem("userName")
          };
          const data = await PostOTP(requestData);
          return true;
        }
        onCancelClick();
        toast.success(userProfileToastMessages.INFORMATION_SUCESSFUL, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      }
    }
    else {
      setLoading(false);
      onCancelClick();
      toast.error(userProfileToastMessages.INFORMATION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      //#for verification popup
      return false;
    }
  }

  //#region update calls
  const uploadProfileIcon = async (newImage, fromOtp) => {
    const formDataBody = new FormData();
    formDataBody.append("file", newImage);
    const imgResponse = await updateUserProfileIconData(formDataBody, personalInformationDetails.values.emailAddress);
    if (imgResponse.responseCode === 200) {
      if (fromOtp) {
        //#for verification popup
        return true;
      }
      onCancelClick();
      toast.success(userProfileToastMessages.INFORMATION_SUCESSFUL, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else {
      onCancelClick();
      toast.error(userProfileToastMessages.PROFILE_IMG_ERROR, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      //#for verification popup
      return false;
    }
  }

  //#region formik validations
  addCustomYupMethods();

  const personalInformationValidationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(validationsConstants.REQUIRED),
    lastName: yup
      .string()
      .required(validationsConstants.REQUIRED),
    emailAddress: yup
      .string()
      .customEmail()
      .required(validationsConstants.REQUIRED),
    phoneNo: yup.string().required(validationsConstants.REQUIRED)
      .required(validationsConstants.REQUIRED)
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, validationsConstants.US_PHONE_NUMBER),
    dateOfBirth: yup
      .date(validationsConstants.DOB)
      .noFutureDate(validationsConstants.FUTURE_DATE)
      .required(validationsConstants.REQUIRED),
    briefDescription: yup
      .string()
      .required(validationsConstants.REQUIRED),
  });

  const personalInformationDetails = useFormik({
    initialValues: {
      ...firmUserDetails
    },
    validationSchema: personalInformationValidationSchema,
    onSubmit: (values) => {
      onSaveClick();
    },
  });

  //#region useeffect
  useEffect(() => {
    otpAttemptsIn24Hours();
  }, [])

  useEffect(() => {
    if (firmUserDetails) {
      personalInformationDetails.setValues(firmUserDetails?.personalInformation);
    }
  }, [firmUserDetails])

  useEffect(() => {
    const isDobError = personalInformationDetails?.errors?.dateOfBirth;
    if (isDobError) {
      personalInformationDetails.setFieldTouched("dateOfBirth", true)
    }
  }, [personalInformationDetails.errors])

  return (
    <div className='child-margin-15'>
      {
        firmUserDetails ?
          <>
            <div className='space-between'>
              <div></div>
              <div className='child-row-margin-10'>
                {
                  isSaveEnabled ?
                    <>
                      <ActionButton
                        label="CANCEL"
                        icon={<CancelIcon />}
                        disabled={loading}
                        variant='outlined'
                        onClick={onCancelClick} />
                      <ActionButton
                        label="SAVE"
                        loading={loading}
                        icon={<SaveIcon />}
                        onClick={personalInformationDetails.handleSubmit} />
                    </> :
                    <>
                      <ActionButton
                        label="EDIT"
                        icon={<EditIcon />}
                        onClick={onEditClick} />
                    </>
                }
              </div>
            </div>
            <form className='white-card child-margin-15' novalidate="novalidate">
              <Grid container spacing={2} alignItems="center">
                <Grid item lg='4' md='4' sm='12' xs='12'>
                  <FileUploadBtn
                    name="profileIcon"
                    label="UPLOAD PROFILE ICON"
                    defaultFile={personalInformationDetails?.values?.profileIcon ? personalInformationDetails?.values?.profileIcon : '/icons/defaultpropic.jpg'}
                    fileType={uploadFileTypes.IMAGE}
                    readOnly={isSaveEnabled ? false : true}
                    fromWhiteLabeling={isSaveEnabled ? true : false}
                    isSize={true}
                    onChange={(name, value) => handleFileUpload(name, value)} />
                </Grid>
                <Grid item lg='4' md='4' sm='6' xs='12'>
                  <ALTTextField
                    textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                    name="firstName"
                    label="First Name"
                    value={personalInformationDetails?.values?.firstName}
                    onChange={(name, value) => handleChange(name, value)}
                    required={true}
                    readOnly={!isSaveEnabled}
                    onBlur={personalInformationDetails.handleBlur}
                    error={personalInformationDetails.touched.firstName && Boolean(personalInformationDetails.errors.firstName)}
                    errorMessage={personalInformationDetails.touched.firstName && personalInformationDetails.errors.firstName} />
                </Grid>
                <Grid item lg='4' md='4' sm='6' xs='12'>
                  <ALTTextField
                    textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                    name="lastName"
                    label="Last Name"
                    value={personalInformationDetails?.values?.lastName}
                    onChange={(name, value) => handleChange(name, value)}
                    required={true}
                    readOnly={!isSaveEnabled}
                    onBlur={personalInformationDetails.handleBlur}
                    error={personalInformationDetails.touched.lastName && Boolean(personalInformationDetails.errors.lastName)}
                    errorMessage={personalInformationDetails.touched.lastName && personalInformationDetails.errors.lastName} />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2} alignItems="center">
                <Grid item lg='4' md='4' sm='6' xs='12'>
                  <ALTTextField
                    textFormatType={textFormatTypeEnum.EMAIL_ADDRESS}
                    name="emailAddress"
                    label="Email Address"
                    value={personalInformationDetails?.values?.emailAddress}
                    onChange={(name, value) => handleChange(name, value)}
                    required={true}
                    readOnly={!isSaveEnabled}
                    onBlur={personalInformationDetails.handleBlur}
                    error={personalInformationDetails.touched.emailAddress && Boolean(personalInformationDetails.errors.emailAddress)}
                    errorMessage={personalInformationDetails.touched.emailAddress && personalInformationDetails.errors.emailAddress} />
                </Grid>
                <Grid item lg='4' md='4' sm='6' xs='12'>
                  <ALTTextField
                    textFormatType={textFormatTypeEnum.US_PHONE_NUMBER}
                    name="phoneNo"
                    label="Phone Number"
                    value={personalInformationDetails?.values?.phoneNo}
                    onChange={(name, value) => handleChange(name, value)}
                    required={true}
                    readOnly={!isSaveEnabled}
                    onBlur={personalInformationDetails.handleBlur}
                    error={personalInformationDetails.touched.phoneNo && Boolean(personalInformationDetails.errors.phoneNo)}
                    errorMessage={personalInformationDetails.touched.phoneNo && personalInformationDetails.errors.phoneNo} />
                </Grid>
                <Grid item lg='4' md='4' sm='6' xs='12'>
                  <DateField
                    textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                    name="dateOfBirth"
                    label="Date Of Birth"
                    value={personalInformationDetails?.values?.dateOfBirth}
                    onChange={(name, value) => handleChange(name, value)}
                    required={true}
                    readOnly={!isSaveEnabled}
                    disableFuture={true}
                    onBlur={personalInformationDetails.handleBlur}
                    error={personalInformationDetails.touched.dateOfBirth && Boolean(personalInformationDetails.errors.dateOfBirth)}
                    errorMessage={personalInformationDetails.touched.dateOfBirth && personalInformationDetails.errors.dateOfBirth} />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2} alignItems="center">
                <Grid item xs='12'>
                  <ALTTextField
                    name="briefDescription"
                    label="Description"
                    value={personalInformationDetails?.values?.briefDescription}
                    onChange={(name, value) => handleChange(name, value)}
                    required={true}
                    readOnly={!isSaveEnabled}
                    multiline={true}
                    rows={4}
                    onBlur={personalInformationDetails.handleBlur}
                    error={personalInformationDetails.touched.briefDescription && Boolean(personalInformationDetails.errors.briefDescription)}
                    errorMessage={personalInformationDetails.touched.briefDescription && personalInformationDetails.errors.briefDescription} />
                </Grid>
              </Grid>
            </form>
          </> :
          <div>
            <LinearProgress />
          </div>
      }
      {
        verificationPopup &&
        <VerificationOtp
          open={verificationPopup}
          onClose={onCloseVerificationPopup}
          emailID={personalInformationDetails.values.emailAddress}
          otpAttemptsLeft={otpAttemptsLeft}
          getOTPAttemptsIn24Hours={otpAttemptsIn24Hours}
          updateFunction={updatePersonalInformation}
          loading={loading}
        />
      }
    </div>
  )
}

export default FirmPersonalInformation