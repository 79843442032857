import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const SingleLineChart = ({ data, metricKey, metricLabel, yAxisLabel, yAxisTicks, yAxisDomain }) => {
  return (
    <div style={{ width: '100%', height: '600px', padding: '16px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 40 }} />
          <YAxis
            label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }}
            ticks={yAxisTicks} // Custom Y-axis ticks
            domain={yAxisDomain} // Custom Y-axis range
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey={metricKey}
            stroke="#FFBE00"
            activeDot={{ r: 8 }}
            name={metricLabel}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SingleLineChart;
