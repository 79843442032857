import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { uploadFileTypes } from './uploadComponentenum';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './styles.css';
import { useEffect } from 'react';
import { validationMessages, validationsTypeEnum } from '../TextInput/appInputenum';
import { ToastContainer, toast } from 'react-toastify';

const FileUploadBtn = (props) => {

    //#region Props
    const {
        name,
        label,
        onChange,
        disabled,
        defaultFile,
        fileType,
        clearFile,
        required,
        checkIfValid,
        setcheckIfValid,
        maxSize,
        validationType,
        readOnly,
        validationMessage,
        checknoofValidFields,
        imgClassName,
        parentError } = props;

    //#region variables
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const fileInputRef = React.createRef();

    //#region change events
    const handleChange = (ev) => {
        const { name, files } = ev.target;
        const file = files[0];
        const maxFileSize = maxSize ? maxSize * 1024 * 1024 : 2 * 1024 * 1024; // Default to 2MB
        const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];
    
        // Reset input if no file is selected
        if (!file) {
            resetInput();
            return;
        }
    
        // PDF File Validation
        if (fileType === uploadFileTypes.PDF && file.type !== 'application/pdf') {
            toast.warning("Only PDF files are allowed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "colored",
            });
            setError(true);
            resetInput();
            return;
        }
    
        // Image File Validation
        if (fileType === uploadFileTypes.IMAGE) {
            if (!allowedImageTypes.includes(file.type)) {
                toast.warning("The image should be in SVG, JPEG, JPG or PNG format and should not exceed 2MB in size.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    theme: "colored",
                });
                resetInput();
                return;
            }
    
            if (file.size > maxFileSize) {
                toast.warning("The image should be in SVG, JPEG, JPG or PNG format and should not exceed 2MB in size.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    theme: "colored",
                });
                resetInput();
                return;
            }
        }
    
        // CSV File Validation
        if (fileType === uploadFileTypes.CSV) {
            const isCSV = file.type === 'text/csv' || file.type.includes('excel') || file.type.includes('spreadsheetml');
            if (!isCSV) {
                toast.warning("Only CSV or Excel files are allowed.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    theme: "colored",
                });
                setError(true);
                resetInput();
                return;
            }
    
            if (file.size > maxFileSize) {
                const maxFileSizeInMB = maxFileSize / (1024 * 1024); // Convert maxFileSize to MB
                toast.warning(`The file size should not exceed ${maxFileSizeInMB}MB.`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    theme: "colored",
                });
                setError(true);
                resetInput();
                return;
            }
        }
    
        // Valid File Handling
        setFile(fileType === uploadFileTypes.IMAGE ? URL.createObjectURL(file) : file);
        onChange(name, file);
    };
    
    // Helper function to reset file input
    const resetInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null; // Clear the input value
        }
    };
    

    //#region click events
    const handleClearFile = () => {
        setFile(null);
        onChange(name, null);
    }

    //#region Validations useEffect
    useEffect(() => {
        if (checkIfValid) {
            const isValid = file === '' || file === null ? false : true;
            if (isValid) {
                checknoofValidFields(isValid, name, file);
                setcheckIfValid(false);
            } else {
                setError(!isValid);
                setcheckIfValid(false);
            }
        }
    }, [checkIfValid])

    useEffect(() => {
        if (defaultFile && defaultFile !== '') {
            if (fileType === uploadFileTypes.IMAGE) {
                if (defaultFile instanceof Blob || defaultFile instanceof File) {
                    const fileURL = URL.createObjectURL(defaultFile);
                    setFile(fileURL);
                } else {
                    setFile(defaultFile);
                }
            }
            else {
                setFile(defaultFile);
            }
        }
    }
        , [defaultFile])

    useEffect(() => {
        if (clearFile || disabled) {
            setFile(null);
        }
    }, [clearFile, disabled])

    useEffect(() => {
       if(parentError) {
          setError(parentError);
       }
    },[parentError])

    //#region render accept type
    const renderAcceptType = () => {
        switch (fileType) {
            case uploadFileTypes.PDF:
                return uploadFileTypes.PDF_TYPES;
            case uploadFileTypes.IMAGE:
                return uploadFileTypes.IMAGE_TYPES;
            default:
                return '';
        }
    }

    //#region render File Preview
    const renderFilePreview = () => {
        if (file) {
            return (
                <div className='fileUploadBtn previewWrapper'>
                    {
                        fileType === uploadFileTypes.IMAGE ? 
                            <img 
                                src={file} 
                                alt="Preview" 
                                className={imgClassName && imgClassName !== "" ? imgClassName : 'file-preview'} 
                                /> :
                            <div className='upload-preview'>{file?.name}</div>
                    }
                    {!readOnly && <CloseOutlinedIcon onClick={handleClearFile} />}
                </div>
            )
        }
    }

    //region render upload file button
    const renderFilebutton = () => {
        const classses = disabled ? (error ? 'filebtn-disabled-error' : 'filebtn-disabled') : (error ? 'fileUploadBtn file-error' : 'fileUploadBtn');
        return (
            <div className='display-column'>
                <div className={classses}>
                    <CloudUploadIcon />
                    <input
                        type="file"
                        id={name}
                        name={name}
                        onChange={handleChange}
                        disabled={disabled}
                        required={required}
                        ref={fileInputRef}
                        accept={renderAcceptType()}
                        hidden />
                    <label htmlFor={name}>{label}</label>
                    <br />
                </div>
                {error && <span className='errorMessage'>{
                    validationMessage
                }</span>}
            </div>
        )
    };

    //#region end
    return (
        <>
            {
                file ? renderFilePreview() : renderFilebutton()
            }
        </>
    )
}

FileUploadBtn.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    defaultFile: PropTypes.string,
    clearFile: PropTypes.bool,
    fileType: PropTypes.string,
    required: PropTypes.bool,
    helperText: PropTypes.string,
}

FileUploadBtn.defaultProps = {
    label: 'Upload Document',
    name: 'file',
    disabled: false,
    defaultFile: '',
    clearFile: false,
    fileType: uploadFileTypes.ANY,
    required: false,
    helperText: '',
}


export default FileUploadBtn