import React, { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { Grid } from '@mui/material';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { getKeyValuePairs } from '../../../portfolio-companies/services/services';
import { updateFirmDetails } from '../../../user-profile/FirmUserProfile/services/firmdetaailsservices';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import SelectField from '../../../common/input-fields/SelectField';
import { AppDashboards } from '../../../../utils/enum';

const FirmDetails = (props) => {

  //#region props
  const { firmDetails, getTenantDetails } = props;

  //#region variables
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdownDetails, setDropdownDetails] = useState({});

  //#region change events
  const handleFileUpload = async (name, file) => {
    if (file) {
      handleChange(name, file);
    }
    else {
      handleChange(name, "");
    }
  }

  const handleChange = (name, value) => {
    firmInformationDetails.setFieldValue(name, value);
  }

  //#region click events
  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    setLoading(false);
    getTenantDetails();
  }

  //#region api get calls
  const getDropDownDetails = async () => {

    const firmTypeData = await getKeyValuePairs('FirmType');
    const registrationBodyData = await getKeyValuePairs('RegulatoryComplianceStatus');
    const structureOptionsData = await getKeyValuePairs('FirmStructure');

    let firmTypeDropDownOptions = []
    let registrationBodyOptions = []
    let structureOptions = []

    if (firmTypeData?.responseCode === 200) {
      firmTypeDropDownOptions = firmTypeData.responseCode === 200
        ? firmTypeData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
        : [];
    }

    if (registrationBodyData?.responseCode === 200) {
      registrationBodyOptions = registrationBodyData.responseCode === 200
        ? registrationBodyData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
        : [];
    }
    if (structureOptionsData?.responseCode === 200) {
      structureOptions = structureOptionsData.responseCode === 200
        ? structureOptionsData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
        : [];
    }

    const updatedData = {
      "firmTypeOptions": firmTypeDropDownOptions,
      "registrationBodyOptions": registrationBodyOptions,
      "structureOptions": structureOptions,
    }

    setDropdownDetails(updatedData);
  }

  //#region update calls
  const updateEntityDetails = async () => {
    setLoading(true);
    const requestBody =
    {
      "firmId": firmInformationDetails.values.firmId,
      "firmName": firmInformationDetails.values.firmName,
      "firmTypeId": firmInformationDetails.values.firmTypeId,
      "firmLogo": firmInformationDetails.values.firmLogo instanceof Blob ? "" : firmInformationDetails.values.firmLogo?.split("firm/")[1] || "",
      "registrationNumber": firmInformationDetails.values.registrationNumber,
      "regulatoryComplianceStatusId": firmInformationDetails.values.regulatoryComplianceStatusId,
      "firmDescription": firmInformationDetails.values.firmDescription,
      "website": firmInformationDetails.values.website,
      "jurisdiction": firmInformationDetails.values.jurisdiction,
      "firmStructureId": firmInformationDetails.values.firmStructureId,
      "firmAddress": firmInformationDetails.values.firmAddress,
      "tenantGUID": secureLocalStorage.getItem("tenantID"),
      "regulatoryComplianceOtherStatus": firmInformationDetails.values.regulatoryComplianceOtherStatus || "",
    }
    const formData = new FormData();
    // debugger
    formData.append('firmDetails', JSON.stringify(requestBody));
    if (firmInformationDetails.values.firmLogo !== null && firmInformationDetails.values.firmLogo !== undefined && firmInformationDetails.values.firmLogo !== "") {
      if (firmInformationDetails.values.firmLogo instanceof Blob) {
        formData.append('firmFile', firmInformationDetails.values.firmLogo, firmInformationDetails.values.firmLogo.name);
      }
    }
    const data = await updateFirmDetails(formData);
    if (data.responseCode === 200) {
      toast.success("Entity Details Update Sucessfully",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      onCancelClick();
    }
    else {
      toast.warning("Entity Details Update Failed",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      onCancelClick();
    }
  }

  //#region formik validations
  const questionValidationSchema = yup.object().shape({
    firmName: yup
      .string()
      .trim()
      .required(validationsConstants.REQUIRED),
    firmTypeId: yup
      .number()
      .min(1, validationsConstants.REQUIRED)
      .required(validationsConstants.REQUIRED),
    firmId: yup
      .string()
      .trim()
      .required(validationsConstants.REQUIRED),
    registrationNumber: yup
      .string()
      .required(validationsConstants.REQUIRED),
    regulatoryComplianceStatusId: yup
      .number()
      .min(1, validationsConstants.REQUIRED)
      .required(validationsConstants.REQUIRED),
    /* jurisdiction: yup
            .string()
            .trim()
            .required(validationsConstants.REQUIRED),
    firmAddress: yup
            .string()
            .trim()
            .required(validationsConstants.REQUIRED),
    website: yup
            .string()
            .required(validationsConstants.REQUIRED), */
    firmDescription: yup
      .string()
      .trim()
      .required(validationsConstants.REQUIRED),
    firmStructureId: yup
      .number()
      .min(1, validationsConstants.REQUIRED)
      .required(validationsConstants.REQUIRED),
    /*  otherregulatoryBody:  
         yup.string().when([], (questionPatternTypeID, schema) => {
             if (currentquestionPatternTypeID() === questionPatterns.TEXT_BOX) {
                 return schema.required(validationsConstants.REQUIRED);
             }
             return schema;
     }), */
  });

  const firmInformationDetails = useFormik({
    initialValues: firmDetails,
    validationSchema: questionValidationSchema,
    onSubmit: (values) => {
      updateEntityDetails(values);
    },
  });

  //#region useeffect
  useEffect(() => {
    firmInformationDetails.setValues(firmDetails);
  }, [firmDetails])

  useEffect(() => {
    getDropDownDetails();
  }, [])

  useEffect(() => {
    getDropDownDetails();
  }, [])

  //#region return
  return (
    <div className='child-margin-15'>
      {
        <>
          <div className='investor-save-cancel-btns space-between'>
            <div></div>
            <div className='child-row-margin-10'>
              {
                secureLocalStorage.getItem("roleName") === AppDashboards.SUPERADMIN_DASHBOARD 
                ? <>
                    {
                    isSaveEnabled ?
                      <>
                        <ActionButton
                          label="CANCEL"
                          icon={<CancelIcon />}
                          disabled={loading}
                          variant='outlined'
                          onClick={onCancelClick} />
                        <ActionButton
                          label="SAVE"
                          loading={loading}
                          icon={<SaveIcon />}
                          onClick={firmInformationDetails.handleSubmit} />
                      </> :
                      <>
                        <ActionButton
                          label="EDIT"
                          disabled={secureLocalStorage.getItem('userrole') !== AppDashboards.SUPERADMIN_DASHBOARD}
                          icon={<EditIcon />}
                          onClick={onEditClick} />
                      </>
                    }
                  </>
                : null
              }
            </div>
          </div>
          <form className='white-card child-margin-15' novalidate="novalidate">
            <Grid container spacing={2} alignItems="center">
              <Grid item lg='4' md='4' sm='12' xs='12'>
                <FileUploadBtn
                  name="firmLogo"
                  label="UPLOAD ICON"
                  defaultFile={firmDetails?.firmLogo ? firmDetails?.firmLogo : '/icons/defaultpropic.jpg'}
                  fileType={uploadFileTypes.IMAGE}
                  fromWhiteLabeling={isSaveEnabled ? true : false}
                  isSize={true}
                  readOnly={!isSaveEnabled}
                  onChange={(name, value) => handleFileUpload(name, value)} />
              </Grid>
              <Grid item lg='4' md='4' sm='12' xs='12'>
                <ALTTextField
                  name="firmName"
                  label="Firm Name"
                  value={firmInformationDetails?.values?.firmName || ""}
                  onChange={(name, value) => handleChange(name, value)}
                  onBlur={firmInformationDetails.handleBlur}
                  required={true}
                  readOnly={true}
                  error={firmInformationDetails.touched.firmName && Boolean(firmInformationDetails.errors.firmName)}
                  errorMessage={firmInformationDetails.touched.firmName && firmInformationDetails.errors.firmName} />
              </Grid>
              <Grid item lg='4' md='4' sm='12' xs='12'>
                <SelectField
                  name="firmTypeId"
                  label="Firm Type"
                  value={firmInformationDetails?.values?.firmTypeId || ""}
                  onChange={(name, value) => handleChange(name, value)}
                  onBlur={firmInformationDetails.handleBlur}
                  options={dropdownDetails?.firmTypeOptions}
                  required={true}
                  readOnly={!isSaveEnabled}
                  error={firmInformationDetails.touched.firmTypeId && Boolean(firmInformationDetails.errors.firmTypeId)}
                  errorMessage={firmInformationDetails.touched.firmTypeId && firmInformationDetails.errors.firmTypeId} />
              </Grid>
              <Grid item lg='4' md='4' sm='12' xs='12'>
                <ALTTextField
                  name="firmId"
                  label="Entity ID"
                  value={firmInformationDetails?.values?.firmId || ""}
                  onChange={(name, value) => handleChange(name, value)}
                  onBlur={firmInformationDetails.handleBlur}
                  required={true}
                  readOnly={true}
                  error={firmInformationDetails.touched.firmId && Boolean(firmInformationDetails.errors.firmId)}
                  errorMessage={firmInformationDetails.touched.firmId && firmInformationDetails.errors.firmId} />
              </Grid>
              <Grid item lg='4' md='4' sm='12' xs='12'>
                <ALTTextField
                  name="registrationNumber"
                  label="Firm Registration Number"
                  value={firmInformationDetails?.values?.registrationNumber || ""}
                  onChange={(name, value) => handleChange(name, value)}
                  onBlur={firmInformationDetails.handleBlur}
                  required={true}
                  readOnly={true}
                  error={firmInformationDetails.touched.registrationNumber && Boolean(firmInformationDetails.errors.registrationNumber)}
                  errorMessage={firmInformationDetails.touched.registrationNumber && firmInformationDetails.errors.registrationNumber} />
              </Grid>
              <Grid item lg='4' md='4' sm='12' xs='12'>
                <SelectField
                  name="regulatoryComplianceStatusId"
                  label="Regulatory Body"
                  value={firmInformationDetails?.values?.regulatoryComplianceStatusId || ""}
                  onChange={(name, value) => handleChange(name, value)}
                  onBlur={firmInformationDetails.handleBlur}
                  options={dropdownDetails?.registrationBodyOptions}
                  required={true}
                  readOnly={!isSaveEnabled}
                  error={firmInformationDetails.touched.regulatoryComplianceStatusId && Boolean(firmInformationDetails.errors.regulatoryComplianceStatusId)}
                  errorMessage={firmInformationDetails.touched.regulatoryComplianceStatusId && firmInformationDetails.errors.regulatoryComplianceStatusId} />
              </Grid>
              {
                dropdownDetails?.registrationBodyOptions?.find((option) => option.value === firmInformationDetails?.values?.regulatoryComplianceStatusId)?.label === "Others" &&
                <Grid item lg='4' md='4' sm='12' xs='12'>
                  <ALTTextField
                    name="otherregulatoryBody"
                    label="Other"
                    value={firmInformationDetails?.values?.otherregulatoryBody || ""}
                    onChange={(name, value) => handleChange(name, value)}
                    onBlur={firmInformationDetails.handleBlur}
                    required={true}
                    readOnly={!isSaveEnabled}
                    error={firmInformationDetails.touched.otherregulatoryBody && Boolean(firmInformationDetails.errors.otherregulatoryBody)}
                    errorMessage={firmInformationDetails.touched.otherregulatoryBody && firmInformationDetails.errors.otherregulatoryBody} />
                </Grid>
              }
              <Grid item lg='4' md='4' sm='12' xs='12'>
                <ALTTextField
                  name="jurisdiction"
                  label="Firm Jurisdiction"
                  value={firmInformationDetails?.values?.jurisdiction || ""}
                  onChange={(name, value) => handleChange(name, value)}
                  onBlur={firmInformationDetails.handleBlur}
                  /* required={true} */
                  readOnly={!isSaveEnabled}
                  error={firmInformationDetails.touched.jurisdiction && Boolean(firmInformationDetails.errors.jurisdiction)}
                  errorMessage={firmInformationDetails.touched.jurisdiction && firmInformationDetails.errors.jurisdiction} />
              </Grid>
              <Grid item lg='4' md='4' sm='12' xs='12'>
                <ALTTextField
                  name="firmAddress"
                  label="Address"
                  value={firmInformationDetails?.values?.firmAddress || ""}
                  onChange={(name, value) => handleChange(name, value)}
                  onBlur={firmInformationDetails.handleBlur}
                  /* required={true} */
                  readOnly={!isSaveEnabled}
                  error={firmInformationDetails.touched.firmAddress && Boolean(firmInformationDetails.errors.firmAddress)}
                  errorMessage={firmInformationDetails.touched.firmAddress && firmInformationDetails.errors.firmAddress} />
              </Grid>
              <Grid item lg='4' md='4' sm='12' xs='12'>
                <ALTTextField
                  name="website"
                  label="Website URL"
                  value={firmInformationDetails?.values?.website || ""}
                  onChange={(name, value) => handleChange(name, value)}
                  onBlur={firmInformationDetails.handleBlur}
                  /* required={true} */
                  readOnly={!isSaveEnabled}
                  error={firmInformationDetails.touched.website && Boolean(firmInformationDetails.errors.website)}
                  errorMessage={firmInformationDetails.touched.website && firmInformationDetails.errors.website} />
              </Grid>
              <Grid item lg='4' md='4' sm='12' xs='12'>
                <SelectField
                  name="firmStructureId"
                  label="Firm Structure"
                  value={firmInformationDetails?.values?.firmStructureId || ""}
                  onChange={(name, value) => handleChange(name, value)}
                  options={dropdownDetails?.structureOptions}
                  onBlur={firmInformationDetails.handleBlur}
                  required={true}
                  readOnly={!isSaveEnabled}
                  error={firmInformationDetails.touched.firmStructureId && Boolean(firmInformationDetails.errors.firmStructureId)}
                  errorMessage={firmInformationDetails.touched.firmStructureId && firmInformationDetails.errors.firmStructureId} />
              </Grid>
              <Grid item xs='12'>
                <ALTTextField
                  name="firmDescription"
                  label="Firm Description"
                  value={firmInformationDetails?.values?.firmDescription || ""}
                  onChange={(name, value) => handleChange(name, value)}
                  onBlur={firmInformationDetails.handleBlur}
                  multiline={true}
                  rows={3}
                  required={true}
                  readOnly={!isSaveEnabled}
                  error={firmInformationDetails.touched.firmDescription && Boolean(firmInformationDetails.errors.firmDescription)}
                  errorMessage={firmInformationDetails.touched.firmDescription && firmInformationDetails.errors.firmDescription} />
              </Grid>
            </Grid>
          </form>
        </>
      }
    </div>
  )
}

export default FirmDetails