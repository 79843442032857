import React, { useState } from 'react';
import { Button, MenuItem, Select, Typography, Box, TextField, FormControl, InputLabel } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ActionButton from '../../../common/ActionButton/ActionButton';

function CustomNotificationFilters({ onApplyFilter, onResetFilter }) {

    const [filterType, setFilterType] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState('');

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
        setError('');
    };

    const handleApply = () => {
        if (filterType === 'Custom Range') {
            if (!startDate || !endDate) {
                setError('Please select both start and end dates.');
                return;
            }
            if (startDate > endDate) {
                setError('Invalid date range: The start date cannot be later than the end date. Please select a valid date range');
                return;
            }
        }
        setError('');
        onApplyFilter({ filterType, startDate, endDate });
    };

    const handleReset = () => {
        setFilterType('');
        setStartDate(null);
        setEndDate(null);
        setError('');
        onResetFilter();
    };

    const filterOptions = [
        { value: 'Today', label: 'Today' },
        { value: 'Last 7 Days', label: 'Last 7 Days' },
        { value: 'This Month', label: 'This Month' },
        { value: 'Custom Range', label: 'Custom Range' }
    ];

    return (
        <>
            <div className='space-between'>
                <div className='child-row-margin-10'>
                    <div>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label" style={{marginTop:'4px'}}>Filter By</InputLabel>
                            <Select
                                value={filterType}
                                labelId="demo-simple-select-helper-label "
                                id="demo-simple-select-helper"
                                onChange={handleFilterChange}
                                displayEmpty
                                className='background-color-white min-width-100'
                                label='Filter By'
                                variant="outlined"
                                style={{marginTop:'4px',height:'57px'}}
                            //style={{ minWidth: 120, height: '36px', backgroundColor: 'white' }}
                            >
                                {/* <MenuItem value="">Filter By</MenuItem> */}
                                {filterOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {filterType === 'Custom Range' && (
                        <div className='child-row-margin-10'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    className='background-color-white'
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    maxDate={new Date()}
                                    sx={{height:'57px',marginTop:'4px'}}
                                    renderInput={(params) => <TextField {...params} size="small" />} />
                                <DatePicker
                                    className='background-color-white'
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    maxDate={new Date()}
                                    sx={{ marginLeft: '10px',height:'57px',marginTop:'4px'}}
                                    renderInput={(params) => <TextField {...params} size="small" />} />
                            </LocalizationProvider>
                        </div>

                    )}
                </div>
                <div className='child-row-margin-10'>
                    <ActionButton
                        variant="outlined"
                        color="primary"
                        label="RESET FILTERS"
                        onClick={handleReset}
                        icon={<FilterAltIcon />}
                        sx={{ height: '36px', borderColor: '#3f51b5', color: '#3f51b5' }} >
                    </ActionButton>
                    <ActionButton
                        variant="contained"
                        color="primary"
                        onClick={handleApply}
                        disabled={filterType === ''}
                        label="APPLY FILTERS"
                        icon={<FilterAltIcon />}
                        sx={{ height: '36px' }} >
                    </ActionButton>
                </div>
            </div>
            <div>
                {error && <Typography color="error" variant="body2">{error}</Typography>}
            </div>
        </>
    );
}

export default CustomNotificationFilters;
