import React from 'react'
import SingleLineChart from './SingleLineChart';
import ValuationBarChart from './WaterFallChart';
import WaterFallChart from './WaterFallChart';

const ValuationGraph = () => {

  //#region variables
  const valuationData = [
    { name: "Q1 '22", Valuation: 500 },
    { name: "Q2 '22", Valuation: 620 },
    { name: "Q3 '22", Valuation: 570 },
    { name: "Q4 '22", Valuation: 600 },
  ];  

  //#region return
  return (
    <div className='child-margin-15'>
        <b>Valuation Over Time</b>
        <div>
            <SingleLineChart
                data={valuationData}
                metricKey="Valuation"
                metricLabel="Valuation ($M)"
                yAxisLabel="Value ($M)"
                yAxisTicks={[500,510,520,530,540,550,560,570,580,590,600,610,620]} 
                yAxisDomain={[500, 620]} 
                />
        </div>
        <b>Valuation Waterfall</b>
        <div>
            <WaterFallChart />
        </div>
    </div>
  )
}

export default ValuationGraph