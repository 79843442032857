import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, MenuItem, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import secureLocalStorage from 'react-secure-storage';
import { LoadingButton } from '@mui/lab';
import DateField from '../../../../common/input-fields/DateField';
import { createTransaction } from '../../../services/services';

const OtherTransactions = (props) => {
  const { transactionTypes, getAllTransactions } = props;
  const [loading, setLoading] = useState(false);

  const transactionDropDownValues = transactionTypes?.filter(item =>
    item.listItemValue.toLowerCase() === 'income' || item.listItemValue.toLowerCase() === 'expenses'
  );

  const handleChange = (name, value) => {
    investmentValues.setFieldValue(name, value);
  };

  const executeAdditionalTransaction = async () => {
    setLoading(true);
    const companyID = secureLocalStorage.getItem("companyID");
    investmentValues.setFieldValue('companyID', companyID);
    investmentValues.setFieldValue('amount', parseInt(investmentValues.values.amount));

    const data = await createTransaction(companyID, investmentValues.values);
    if (data.responseCode === 200) {
      getAllTransactions();
      setLoading(false);
      investmentValues.resetForm();
      toast.success("New Transaction Completed Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    } else {
      setLoading(false);
      toast.warning("Unable to Create Transaction", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  };

  const InvestmentValidationSchema = yup.object().shape({
    //transactionDate: yup.string().required('This Field is required'),
    transactionTypeID: yup.string().required('This Field is required'),
    particulars: yup.string().required('This Field is required'),
    amount: yup.string().required('This Field is required'),
    //narration: yup.string().required('This Field is required'),
  });

  const onResetClick = () => {
    investmentValues.resetForm();
  };

  const investmentValues = useFormik({
    initialValues: {
      // transactionDate: '',
      transactionTypeID: '',
      particulars: '',
      amount: '',
      //narration: ''
    },
    validationSchema: InvestmentValidationSchema,
    onSubmit: () => {
      executeAdditionalTransaction();
    },
  });

  return (
    <div>
      <form className='child-margin-15' onSubmit={investmentValues.handleSubmit} noValidate>
        <div className='width-percent-30'>
          <DateField
            name="transactionDate"
            label="Date"
            value={investmentValues.values.transactionDate}
            onChange={(name, value) => handleChange(name, value)}
            onBlur={investmentValues.handleBlur}
            error={investmentValues.touched.transactionDate && Boolean(investmentValues.errors.transactionDate)}
            errorMessage={investmentValues.touched.transactionDate && investmentValues.errors.transactionDate}
          />
        </div>
        <div className='width-percent-30'>
          <TextField
            select
            id="transactionTypeID"
            name="transactionTypeID"
            label="Income / Expense"
            variant='standard'
            required={true}
            fullWidth
            value={investmentValues.values.transactionTypeID}
            onChange={investmentValues.handleChange}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={investmentValues.handleBlur}
            error={investmentValues.touched.transactionTypeID && Boolean(investmentValues.errors.transactionTypeID)}
            helperText={investmentValues.touched.transactionTypeID && investmentValues.errors.transactionTypeID}
          >
            {transactionDropDownValues?.length > 0 && transactionDropDownValues.map((item) => (
              <MenuItem key={item.listItemID} value={item.listItemID}>
                {item.listItemValue}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className='width-percent-30'>
          <TextField
            id="particulars"
            name="particulars"
            label="Particulars"
            variant='standard'
            required={true}
            fullWidth
            value={investmentValues.values.particulars}
            onChange={investmentValues.handleChange}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={investmentValues.handleBlur}
            error={investmentValues.touched.particulars && Boolean(investmentValues.errors.particulars)}
            helperText={investmentValues.touched.particulars && investmentValues.errors.particulars}
          />
        </div>
        <div className='width-percent-30'>
          <TextField
            id="amount"
            name="amount"
            label="Amount"
            variant='standard'
            required={true}
            fullWidth
            value={investmentValues.values.amount}
            onChange={investmentValues.handleChange}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={investmentValues.handleBlur}
            error={investmentValues.touched.amount && Boolean(investmentValues.errors.amount)}
            helperText={investmentValues.touched.amount && investmentValues.errors.amount}
          />
        </div>
        <div className='width-percent-30'>
          <TextField
            id="narration"
            name="narration"
            label="Narration"
            variant='standard'
            required={false}
            fullWidth
            value={investmentValues.values.narration}
            onChange={investmentValues.handleChange}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={investmentValues.handleBlur}
            error={investmentValues.touched.narration && Boolean(investmentValues.errors.narration)}
            helperText={investmentValues.touched.narration && investmentValues.errors.narration}
          />
        </div>
        <div className='space-between width-percent-30'>
          <div></div>
          <div className='space-between'>
            <div>
              <Button
                label="Cancel"
                variant="outlined"
                className="btn-primary"
                onClick={onResetClick}
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
            </div>
            <div className='margin-left-10'>
              <LoadingButton
                variant='contained'
                className="btn-primary"
                startIcon={<SaveIcon />}
                loading={loading}
                type='submit'
                loadingPosition="start"
              >
                <p className={`actionButtonLabel`}>SAVE</p>
              </LoadingButton>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default OtherTransactions;
