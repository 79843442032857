import React, { useEffect, useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import AltDataGridPro from '../DataGridPro/AltDataGridPro';

//#this component will display fund performance history
const PerformanceHistory = (props) => {

  //#region props
  const { selectedYear,selectedFund } = props;

  //#region variables
  const [rows,setSelectedRows] = useState();
  const columns = [
    { field: 'Quarter', headerName: 'Quarter', width: 100 },
    { field: 'Called', headerName: 'Called', width: 100 },
    { field: 'DPI', headerName: 'DPI', width: 100 },
    { field: 'RPVI', headerName: 'RPVI', width: 100 },
    { field: 'TVPI', headerName: 'TVPI', width: 100 },
    { field: 'IRR', headerName: 'IRR', width: 100 },
    { field: 'Commitment', headerName: 'Commitment', width: 100 },
    { field: 'Called Amount', headerName: 'Called Amount', width: 100 },
    { field: 'Distributed', headerName: 'Distributed', width: 100 },
    { field: 'Remaining', headerName: 'Remaining value', width: 200 },
  ];
  
 //#region functions
function generateRandomPercentage() {
  return (Math.random() * 100).toFixed(2) + '%';
}

function generateRandomAmount() {
  return '$' + (Math.random() * 1000000).toFixed(2);
}

function generateRandomQuarter() {
  const year = selectedYear || (2000 + Math.floor(Math.random() * 25));
  const quarter = 1 + Math.floor(Math.random() * 4);
  return `Q${quarter} ${year}`;
}

function generateRows(numRows) {
  const rows = [];

  for (let i = 0; i < numRows; i++) {
    rows.push({
      id: i + 1,
      Quarter: generateRandomQuarter(),
      Called: generateRandomAmount(),
      DPI: generateRandomPercentage(),
      RPVI: generateRandomPercentage(),
      TVPI: generateRandomPercentage(),
      IRR: generateRandomPercentage(),
      Commitment: generateRandomAmount(),
      'Called Amount': generateRandomAmount(),
      Distributed: generateRandomAmount(),
      Remaining: generateRandomAmount(),
    });
  }

  return rows;
}

  useEffect(()=>{
    setSelectedRows(generateRows(10))
  },[])

  useEffect(()=>{
    setSelectedRows(generateRows(10),selectedYear)
  },[selectedFund,selectedYear])

  //#region return
  return (
    <div>
        <AltDataGridPro 
          columns={columns}
          rowHeight={39}
          rows={rows || []}
          hideFooter/>
    </div>
  )
}

export default PerformanceHistory