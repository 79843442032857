import React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { readingsArchieveRows } from '../apiDataTemplate';
import { Checkbox, Radio } from '@mui/material';
import { width } from '@mui/system';
import ContextMenu from '../../common/ContextMenu/ContextMenu';
import DownloadIcon from '@mui/icons-material/Download';
import { toast,ToastContainer } from 'react-toastify';
import { formatDate } from '../../common/Functions/TimeConvestion';
import AltDataGridPro from '../../common/DataGridPro/AltDataGridPro';

const ArchiveReadingsGrid = (props) => {

  //#region props
  const { newsArticles } = props;

  //#region columns
  const { selectedArticle,setSelectedArticle } = props;

  //#region change events
  const handleRowSelection = (row) => {
    if (selectedArticle && row.id === selectedArticle?.suggestedArticleID) {
      setSelectedArticle(0);
    } else {
      setSelectedArticle(row);
    }
  };

  //#region api get calls 
  //TODO : Need to Shift This API Call to services
  const handleDownload = (SelectedNews) =>{
    if (SelectedNews.suggestedArticleID !== 0) {
      if (SelectedNews.uploadFile === true && SelectedNews.articleUploadFileName !== "") {
          fetch(SelectedNews.articleUploadFileName)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${SelectedNews.articleTitle}.pdf`; 
            link.click();
            URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.log('Error fetching data:', error);
          }); 
      }
      else {
        toast.warning("The selected news item does not have any associated file.",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }
    else {
      toast.warning("Please Select the News Item",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region menu items
  const MenuItemsTop = [
    {
        id: 1,
        label: 'Download',
        icon: <DownloadIcon />,
        onClick: () => {
          handleDownload(selectedArticle)
        }
    },
 ]
  
  //#region grid columns
  const readingArchieveColumns = [
  {"id": 1,"field": "","headerName": "",width: 50,
      renderCell: (params) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Radio
                  checked={params.row.id === selectedArticle?.suggestedArticleID}
                  onChange={() => handleRowSelection(params.row)} />
        </div>
      )
  },
  {"id": 2,"field": "articleTitle","headerName": "Title","width": 240},
  {"id": 2,"field": "articleAuthor","headerName": "Author","width": 140},
  {"id": 3,"field": "regardingFunds","headerName": "Funds","width": 320},
  {"id": 4,"field": "type","headerName": "Type","width": 100},
  {"id": 5,"field": "hidden","headerName": "Visibility","width": 100,
    renderCell: (params) => {
        return (
            <div style={{ textAlign: 'center' }}>
                {params.value === true ? <span>&#10006;</span> : <span>&#10004;</span>}
            </div>
        );
    },
  },
  {
      "id": 6,
      "field": "createdDate",
      "headerName": "Date",
      "width": 130,
      renderCell: (params) => {
        return(
          formatDate(params.value)
        )
      }
  }
  ]

  return (
    <div className='whiteCard uploadreadings'>
       <div className='space-between'>
        <h6>Suggested Readings Archive</h6>
        <div>
          <ContextMenu MenuItemsTop={MenuItemsTop}/>
        </div>
       </div>
       <div className='suggestRead-archive-grid'>
        <AltDataGridPro
            columns={readingArchieveColumns} 
            rows={newsArticles ? newsArticles?.map((news) => ({
              id: news.suggestedArticleID,
              ...news
            })) : []}        
            hideFooter={true}/>
       </div>
       <ToastContainer />
    </div>
  )
}

export default ArchiveReadingsGrid