import React, { useEffect, useState } from 'react'
import CrudDataGrid from '../../../../common/DataGrid/CrudDataGrid';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { DataGrid } from '@mui/x-data-grid';
import { CreatePortfolioCompanyValue, GetPortfolioCompanyValue } from '../../../services/services';
import secureLocalStorage from 'react-secure-storage';

const ValueGrid = (props) => {
  //#region props
  const { shareTypes } = props;
  //#region variables
  const columns = [
    { field: 'shareTypeName', headerName: 'Share Type', width: 180, editable: true },
    { field: 'shares', headerName: 'Shares', width: 180, editable: false },
    { field: 'sharePrice', headerName: 'Share Prices', width: 180, editable: true },
    { field: 'amount', headerName: 'Amount', width: 180, editable: true },
  ]
  const [rows, setRows] = useState([])

  const handleSaveClick = () => {
    // debugger;
    const data = CreatePortfolioCompanyValue(secureLocalStorage.getItem("companyID"), rows);
  };

  const getAllPCValues = async () => {
    const data = await GetPortfolioCompanyValue(secureLocalStorage.getItem("companyID"));
    if (data.responseCode === 200) {

      const availableRows = data.responseData?.map((item, index) => ({
        id: item.shareTypeID,
        shareTypeID: item.shareTypeID,
        shareTypeName: item.shareTypeName,
        shares: item.quantity,
        sharePrice: item.sharePrice,
        amount: item.amount,
        companyID: secureLocalStorage.getItem("companyID")
      }));
      // debugger
      setRows(availableRows);
    }
    else {
      console.error(data.error);
    }
  }

  const handleProcessRowUpdate = (updatedRow, originalRow) => {
    // Find the index of the row that was edited
    const rowIndex = rows.findIndex((row) => row.id === updatedRow.id);

    // Replace the old row with the updated row
    const updatedRows = [...rows];
    if (updatedRow.amount == originalRow.amount) {
      updatedRows[rowIndex] = { ...updatedRow, amount: parseInt(updatedRow.shares) * parseFloat(updatedRow.sharePrice) };
    }
    else {
      updatedRows[rowIndex] = { ...updatedRow, sharePrice: parseInt(updatedRow.amount) / parseFloat(updatedRow.shares) };
    }

    // Update the state with the new rows
    setRows(updatedRows);

    // Return the updated row to update the internal state of the DataGrid
    return updatedRow;
  };

  useEffect(() => {
    getAllPCValues();
  }, []);


  return (
    <>
      {
        rows?.length > 0 ?
          <div className='height-400'>
            <DataGrid
              key={JSON.stringify(rows)} // Force re-render whenever rows change
              columns={columns}
              rows={rows}
              processRowUpdate={handleProcessRowUpdate}
              disableRowSelectionOnClick />
          </div> : ""
      }
      <div>
        <LoadingButton
          variant='contained'
          size="large"
          startIcon={<SaveIcon />}
          onClick={handleSaveClick}
          loadingPosition="start">
          <p className={`actionButtonLabel`}>SAVE</p>
        </LoadingButton>
      </div>
    </>
  )
}

export default ValueGrid