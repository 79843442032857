import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Accordian from '../../../common/Accordian/Accordian';
import { getDraftNotifications, getInvestorsandGroupsList } from '../../Services/GroupsServices';
import { CircularProgress, LinearProgress, TablePagination } from '@mui/material';
import ContextMenu from '../../../common/ContextMenu/ContextMenu';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import secureLocalStorage from 'react-secure-storage';
import CustomNotificationFilters from './CustomNotificationFilters';
import CustomEmail from '../CustomEmail';
import { CommunicationType } from '../../../../utils/enum';
import { deleteDraftNotifications } from '../../Services/IRServices';
import { InvestorCommunicarionToastMessages } from '../../../../utils/AppConstants';

const DraftNotifications = (props) => {
  const { getNotificationsCount, renderComponent, setRenderComponent } = props;

  const [draftNotifications, setDraftNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]); // For filtered results
  const [investorsList, setInvestorsList] = useState(null);
  const [selectedParentDraftNotification, setSelectedParentDraftNotification] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getDraft();
    getInvestors();
  }, []);

  const getDraft = async () => {
    const data = await getDraftNotifications(secureLocalStorage.getItem('userId'), 'Draft');
    if (data?.responseCode === 200) {
      setDraftNotifications(data.responseData);
      setFilteredNotifications(data.responseData);
    } else {
      setDraftNotifications([]);
      setFilteredNotifications([]);
      toast.warning(data?.responseData || "No Data Available", { position: toast.POSITION.BOTTOM_RIGHT });
    }
  };

  const getInvestors = async () => {
    const data = await getInvestorsandGroupsList();
    if (data?.responseCode === 200) {
      setInvestorsList(data.responseData);
    }
  };
  const handleApplyFilter = ({ filterType, startDate, endDate }) => {
    let filtered = [];

    // Convert startDate and endDate to Date objects if they are strings
    if (filterType === 'Custom Range' && startDate && endDate) {
      startDate = new Date(startDate);
      endDate = new Date(endDate);
      // Ensure that the end date is inclusive by setting it to the end of the day
      endDate.setHours(23, 59, 59, 999);
    }

    if (filterType === 'Today') {
      filtered = draftNotifications.filter(item => new Date(item.updatedDate).toDateString() === new Date().toDateString());
    } else if (filterType === 'Last 7 Days') {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      filtered = draftNotifications.filter(item => new Date(item.updatedDate) >= sevenDaysAgo);
    } else if (filterType === 'This Month') {
      const now = new Date();
      filtered = draftNotifications.filter(item => {
        const updatedDate = new Date(item.updatedDate);
        return updatedDate.getMonth() === now.getMonth() && updatedDate.getFullYear() === now.getFullYear();
      });
    } else if (filterType === 'Custom Range' && startDate && endDate) {
      filtered = draftNotifications.filter(item => {
        const updatedDate = new Date(item.updatedDate);
        return updatedDate >= startDate && updatedDate <= endDate;
      });
    }

    setFilteredNotifications(filtered);
    setPage(0); // Reset to first page on filter
  };

  const handleResetFilter = () => {
    setFilteredNotifications(draftNotifications);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectedInvestors = (crntSelectedNotification) => {
    const oldSelectedUsersAndGroups = [
      ...(crntSelectedNotification?.notificationToUserIDs?.split(",") || []),
      ...(crntSelectedNotification?.notificationToGroupIDs?.split(",") || []),
    ];
    return (investorsList?.filter((u) =>
      oldSelectedUsersAndGroups?.some((id) => id.trim() === u.id.toString())
    ));
  }


  const notificationFiles = (crntSelectedNotification) => {
    if (!crntSelectedNotification?.notificationHasAction) {
      const fileNamesArray = crntSelectedNotification?.fileNames
        ? crntSelectedNotification.fileNames.split(",").map((fileName) => fileName.trim())
        : [];
      const filesData = fileNamesArray.map((fileName, index) => ({
        id: index + 1,
        file: fileName,
        path: `${crntSelectedNotification.containerPath}/${crntSelectedNotification.notificationDetailsID}/${crntSelectedNotification.fileNames}`
      }));
      return (filesData);
    } else {
      return (null);
    }
  }

  const renderBody = (item) => {
    debugger
    return (
      <CustomEmail
        CommunicationType={CommunicationType.Drafts}
        DraftNotification={item?.notificationSubject}
        DraftNotificationsDetails={draftNotifications}
        InvestorsItems={investorsList}
        getDraft={getDraft}
        selectedInvestorsFromParent={selectedInvestors(item)}
        selectedTabFromParent={item?.notificationHasAction === true ? 1 : 2}
        selectedActionItem={item?.notificationOnID === 0 ? item?.notificationType : item?.notificationOnID}
        selectedParentDraftNotification={selectedParentDraftNotification}
        setDraftNotifications={setDraftNotifications}
        selectedSubjectFromParent={item?.notificationSubject}
        selectedContentFromParent={item?.notificationBody}
        getNotificationsCount={getNotificationsCount}
        renderComponent={renderComponent}
        setRenderComponent={setRenderComponent}
        notificationFiles={notificationFiles(item)}
      />
    );
  };

  //#region timestamp function ( eg. 1 hour ago , 2hrs ago)
  // const createdTimestamp = (time) => {
  //   const updatedDate = new Date(time);
  //   const now = new Date();

  //   // Calculate the difference in milliseconds
  //   const diffMs = now - updatedDate;

  //   // Convert milliseconds to hours and days
  //   const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  //   const diffDays = Math.floor(diffHours / 24);

  //   if (diffDays > 0) {
  //     return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  //   } else {
  //     return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
  //   }
  // } ##TODO : need to find better solution for time conversion

  const handleDelete = async () => {
    const data = await deleteDraftNotifications(notificationToDelete.notificationDetailsID, secureLocalStorage.getItem('userId'), 'Draft');
    if (data.responseCode === 200) {
      setOpenDialog(false);
      toast.success(InvestorCommunicarionToastMessages.NOTIFICATION_DELETED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
      getDraft();
      getNotificationsCount();
    } else if (data.responseCode === 404) {
      setOpenDialog(false);
      toast.success(InvestorCommunicarionToastMessages.NOTIFICATION_DELETED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
      getDraft();
      getNotificationsCount();
    }
    else {
      toast.warning(InvestorCommunicarionToastMessages.ACTION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
  };

  //#region Context Menu Items
  const MenuItemsTop = [
    {
      id: 1,
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: (item) => {
        setNotificationToDelete(item);
        setOpenDialog(true);
      },
    },
  ];

  useEffect(() => {
    getDraft()
  }, [renderComponent])

  return (
    <>
      {renderComponent && (
        <div className='notifications-accordian-wrapper'>
          <Box>
            <CustomNotificationFilters
              onApplyFilter={handleApplyFilter}
              onResetFilter={handleResetFilter}
            />
          </Box>

          {filteredNotifications.length === 0 ? (
            'No Data Available'
          ) : (
            filteredNotifications
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <div key={item.notificationDetailsID} className='space-between'>
                  <div className='groupview-accordian'>
                    <Accordian
                      title={item?.notificationSubject}
                      body={renderBody(item)}
                      showtimestamp={true}
                      timestamp={(item?.updatedDate)}
                    />
                  </div>
                  <div className='margin-top-9'>
                    <ContextMenu MenuItemsTop={MenuItemsTop} ItemDetails={item} />
                  </div>
                </div>
              ))
          )}

          <div className='margin-top-10'>
            <TablePagination
              component="div"
              count={filteredNotifications.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>
              <h6><InfoIcon className='info-i' /> Confirmation</h6>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this draft notification?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)}>
                <CancelIcon /> Cancel
              </Button>
              <Button onClick={handleDelete} autoFocus>
                <DeleteIcon /> Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default DraftNotifications;
