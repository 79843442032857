import resolve from '../../resolve';
import Configurations from '../../../../Configurations';
import axios from 'axios';
import axiosApiCall from '../../../Services/axiosApiCall';
import { getAPIResponse } from '../../../../utils/common';

export async function VDRFileUpload(formDataBody, vdrID, Portfolioid, PGUId) {
    return await resolve(fetch(`${Configurations.apiBaseUri}/v1/VirtualDataRooms/UploadFileToVDR?vdrID=${vdrID}&fundID=198&subscriptionID=0&PortfolioID=${Portfolioid}&portfolioGUID=${PGUId}`, {
        method: 'POST',
        body: formDataBody
    }).then(res => res.data));
}

export async function VdrUploadFile(formDataBody, selectedVDRID, vdrtype) {
    return await axiosApiCall.post(`/v1/VirtualDataRooms/AddNewFileToVDR?parentFolderID=${selectedVDRID}&entityType=${vdrtype}&replaceExcisting=${false}`, formDataBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });

}

export async function VDRRequestFile(vdrID, fundID, PortfolioID, isRequired, newFileName, portfolioGUID) {
    try {
        const response = await axios.put(`${Configurations.apiBaseUri}/v1/VirtualDataRooms/RequestFile?vdrID=${vdrID}&fundID=${fundID}&subscriptionID=0&PortfolioID=${PortfolioID}&IsRequired=${isRequired}&newFileName=${newFileName}&portfolioGUID=${portfolioGUID}`);
        return response.data;
    }
    catch (error) {
        console.error('Error updating leadership team details:', error);
        throw error;
    }
}
export async function QuarterlyUpdatesDropdownValues() {
    try {
        const response = await axios.get(`${Configurations.apiBaseUri}/v1/VirtualDataRooms/GetQuarterCodes`);
        return response.data;
    }
    catch (error) {
        console.error('Error');
        throw error;
    }
}

export async function QuarterlyUpdatesUpload(formDataBody, vdrID, FundID, subscriptionID, Portfolioid, IsFundManager, PortfolioGUID, QuarterName) {
    return await resolve(fetch(`${Configurations.apiBaseUri}/v1/VirtualDataRooms/UploadQuarterlyUpdates?vdrID=${vdrID}&fundID=${FundID}&subscriptionID=${subscriptionID}&PortfolioID=${Portfolioid}&IsFundManager=${IsFundManager}&PortfolioGUID=${PortfolioGUID}&QuarterName=${QuarterName}`, {
        method: 'POST',
        body: formDataBody
    }).then(res => res.data));
}

export async function VDRMakeItemPrivate(vdrID, fundID, PortfolioID, PortfolioGUID, isPrivate) {
    try {
        const response = await axios.put(`${Configurations.apiBaseUri}/v1/VirtualDataRooms/SetVDRItemPrivateStatus?vdrID=${vdrID}&fundID=${fundID}&subscriptionID=0&PortfolioID=${PortfolioID}&PortfolioGUID=${PortfolioGUID}&isPrivate=${isPrivate}`);
        return response.data;
    }
    catch (error) {
        console.error('Error updating leadership team details:', error);
        throw error;
    }
}

export async function VdrItemRequired(vdrID, fundID, PortfolioID, PortfolioGUID, IsRequired) {
    try {
        const response = await axios.put(`${Configurations.apiBaseUri}/v1/VirtualDataRooms/SetVDRItemRequiredStatus?vdrID=${vdrID}&fundID=${fundID}&subscriptionID=0&PortfolioID=${PortfolioID}&PortfolioGUID=${PortfolioGUID}&isRequired=${IsRequired}`);
        return response.data;
    }
    catch (error) {
        console.error('Error updating leadership team details:', error);
        throw error;
    }
}


export async function RenameVDRFileName(vdrID, fundID, newFileName) {
    try {
        const response = await axios.put(`${Configurations.apiBaseUri}/v1/VirtualDataRooms/RenameFileByID?vdrID=${vdrID}&fundID=${fundID}&subscriptionID=0&PortfolioID=0&newFileName=${newFileName}`);
        return response.data;
    }
    catch (error) {
        console.error('Error updating leadership team details:', error);
        throw error;
    }
}

export async function ComplianceVDR(cnrtFundID, crntSubscriptionID, crntUserRole) {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/GetCOVirtualDataRooms?fundID=${cnrtFundID}&subscriptionID=${crntSubscriptionID}&investorID=${crntUserRole}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });

}

export async function KYCAMLVDR(crntInvestorID) {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/GetKYCAMLVirtualDataRooms?userID=${crntInvestorID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });


}

export async function GetVDRoom(cnrtFundID, crntSubscriptionID, Portfolioid, IsFundManager) {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/GetVirtualDataRooms?fundID=${cnrtFundID}&subscriptionID=${crntSubscriptionID}&portfolioID=${Portfolioid}&portfolioGUID="0"&isFundManager=${IsFundManager}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });

}

export async function GetFinalVDRoom(cnrtFundID, crntSubscriptionID, Portfolioid, cnrtInvestorID, VDRType) {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/Get?fundID=${cnrtFundID}&subscriptionID=${crntSubscriptionID}&portfolioID=${Portfolioid}&investorID=${cnrtInvestorID}&selectedVDRType=${VDRType}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });

}

export async function GetDocumentTypes(VDRType) {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/GetFoldersAndDocumentTypesByVDRType?VDRType=${VDRType}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });

}

export async function VDRItemVersionHistory(VDRTypeid) {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/GetVDRItemVersions?vdrItemID=${VDRTypeid}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}


export async function GetVDRSharedItems(userID) {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/GetUserSharedVDRItems?userID=${userID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });

}

export async function GetSharedWithUsersData(VdrID) {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/GetVDRItemSharedUserDetails?vdrItemID=${VdrID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });

}

export async function UpdatingVDR(formData) {
    return await axiosApiCall.post(`/v1/VirtualDataRooms/UpdateVDR`, formData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function downloadVDRFile(vdrId, title) {
    try {
        const response = await axiosApiCall.get(`/v1/VirtualDataRooms/DownloadVDRItem?vdrID=${vdrId}`, {
            responseType: 'blob', // Important to get the response as a Blob
        });
        // Create a Blob URL and trigger a download
        const blobUrl = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = title; // Use the actual document name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
        return {
            responseCode: response.status,
            responseData: 'File downloaded successfully',
        };
    } catch (error) {
        return {
            responseCode: error.response?.status || 500,
            responseData: error.response?.data || 'Error downloading file',
        };
    }
}

export async function downloadSharedLinkVDRItem(vdrId) {
    return await axiosApiCall.get(`/v1/VirtualDataRooms/DownloadVDRItem?vdrID=${vdrId}`, {
        responseType: 'blob',
    })
    .then(res => {
        return getAPIResponse(res)
    }).catch(error => {
        return getAPIResponse(error)
    })
}

//https://alt360devapi.azurewebsites.net/v1/FundSubscription/AcceptNDAAgreement?fundID=434&investorID=43444

export async function NdaAgreed(fundid,investorid) {
    return await axiosApiCall.post(`/v1/FundSubscription/AcceptNDAAgreement?fundID=${fundid}&investorID=${investorid}`, {
        //responseType: 'blob',
    })
    .then(res => {
        return getAPIResponse(res)
    }).catch(error => {
        return getAPIResponse(error)
    })
}