import React, { useEffect, useState } from 'react'
import TabSwitch from '../../../common/Tabs/TabSwitch'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import StockBuy from './investment-structure/StockBuy';
import StockSell from './investment-structure/StockSell';
import OtherTransactions from './investment-structure/OtherTransactions';
import TransactionsGrid from './investment-structure/TransactionsGrid';
import { getKeyValuePairs, getPCTransactions } from '../../services/services';
import secureLocalStorage from 'react-secure-storage';
import { Grid, LinearProgress } from '@mui/material';
import ValueGrid from './investment-structure/ValueGrid';
import SellIcon from '@mui/icons-material/Sell';

const InvestmentStructureMain = (props) => {

  //#region props
  const { investmentDetails } = props;

  //#region variables
  const [selectedTab, setSelectedTab] = useState(0);
  const [shareTypes, setShareTypes] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [roundInvestments, setRoundInvestments] = useState([]);
  const [transactionsData, setTransactionsData] = useState(null);
  const [pinnedrows, setPinnedRows] = useState(null);
  const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;
  const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;

  //#region api get calls
  const getShareTypeValues = async () => {
    const data = await getKeyValuePairs("ShareType");
    if (data?.responseCode === 200) {
      setShareTypes(data?.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getTransactionTypes = async () => {
    // debugger
    const data = await getKeyValuePairs("TransactionType");
    if (data?.responseCode === 200) {
      setTransactionTypes(data?.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getRoundInvestments = async () => {
    const data = await getKeyValuePairs("RoundOfInvestment");
    if (data?.responseCode === 200) {
      setRoundInvestments(data?.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getAllTransactions = async () => {
    let data = '';
    const companyID = secureLocalStorage.getItem("companyID");
    data = await getPCTransactions(companyID);
    if (data?.responseCode === 200) {
      const apiData = data?.responseData || [];
      const dataWithId = apiData.map((row, index) => ({
        id: index,
        ...row,
      }));
      const pinnedrows = {
        top: dataWithId?.length > 0 ? [dataWithId[0]] : [],
        bottom: dataWithId?.length > 0 ? [dataWithId[dataWithId.length - 1]] : [],
      };
      setPinnedRows(pinnedrows);
      setTransactionsData(dataWithId);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  //#region useEffect
  useEffect(() => {
    getAllTransactions();
    getShareTypeValues();
    getTransactionTypes();
    getRoundInvestments();
  }, [])

  //#region tabs
  const tabs = [
    {
      key: 0,
      icon: <MonetizationOnIcon />,
      label: "Invest",
      component: (!isFundMatured && !isFundTerminated) ?
        <StockBuy
          shareTypes={shareTypes}
          getAllTransactions={getAllTransactions}
          transactionTypes={transactionTypes}
          roundInvestments={roundInvestments}
          getRoundInvestments={getRoundInvestments}
          getShareTypeValues={getShareTypeValues} />
        :
        <div>
          This fund is no longer active and cannot receive new investments.
        </div>
    },
    {
      key: 1,
      icon: <SellIcon />,
      label: "Sell",
      component: <StockSell shareTypes={shareTypes} getAllTransactions={getAllTransactions} transactionTypes={transactionTypes} />
    },
    {
      key: 2,
      icon: <AddShoppingCartIcon />,
      label: "Value",
      component: <ValueGrid shareTypes={shareTypes} />
    },
    {
      key: 3,
      icon: <StorefrontIcon />,
      label: "Other Transactions",
      component: <OtherTransactions transactionTypes={transactionTypes} getAllTransactions={getAllTransactions} />
    },
  ]

  return (
    <div className='margin-top-15 child-margin-15'>
      <h6>Investment</h6>
      <div className='transaction-tabs white-card margin-top-15'>
        <TabSwitch
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className='transactions-grid'>
        <h6>Previous Transactions</h6>
        {
          pinnedrows ?
            <TransactionsGrid shareTypes={shareTypes} transactionsData={transactionsData} setTransactionsData={setTransactionsData} pinnedrows={pinnedrows} setPinnedRows={setPinnedRows} getAllTransactions={getAllTransactions} /> :
            <Grid xs={12}>
              <LinearProgress />
            </Grid>
        }
      </div>
    </div>
  )
}

export default InvestmentStructureMain