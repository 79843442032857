import React from 'react'
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import jsondata from '../UserDetails.json';
import { useCallback, useState, useEffect, useContext } from "react";
import LinearProgress from '@mui/material/LinearProgress';
import { getTenantFeatures } from '../Services/TabsData';
import { ToastContainer, toast } from 'react-toastify';
import { rolesAndfeatureKYCTabToastMessages, rolesAndfeatureTabToastMessages } from '../../../utils/AppConstants';

function Featues() {

  //#region variables
  const [features, setFeatures] = useState();
  const [featureRowsData, setFeaturesRowsData] = useState(null);

  //#region api get calls
  const getfeature = async () => {
    const data = await getTenantFeatures();
    if (data.responseCode === 200) {
      setFeatures(data.responseData);
    }
    else {
      toast.warning(rolesAndfeatureKYCTabToastMessages.DATE_FETCHING_ERROR,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region useeffect
  useEffect(() => {
    getfeature();
  }, []);

  useEffect(() => {
    const rows = features ? features.map(user => ({
      id: user.applicationFeatureId,
      "FEATURENAME": user.title,
    })) : [];
    setFeaturesRowsData(rows);
  }, [features])

  //#region return
  return (
    <div>
      <h4>Features</h4>
      {
        featureRowsData ?
          <div className='white-card margin-top-5 height-400-scroll'>
            <AIPDataGrid
              columns={jsondata.FeatureColumns}
              rows={featureRowsData}
              onRowsSelectionHandler={() => { }} />
          </div>
          : <LinearProgress />
      }
    </div>
  )
}

export default Featues
