export  const NewObqoptionsTemplate = {
    "userProfileID": 0,
    "userID": 0,
    "questionBankID": 0,
    "questionAnswerID": 0,
    "createdBy": 1,
    "createdDate": "2023-12-08T07:40:42.187",
    "updatedBy": 1,
    "updatedDate": "2023-12-08T07:40:42.187",
    "answer": ""
  }