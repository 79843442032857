import secureLocalStorage from "react-secure-storage"


export const readingsInitialData = {
    "suggestedArticleID": 0,
    "articleTitle": "",
    "articleSubtitle": "",
    "articleAuthor": "",
    "type": "",
    "uploadFile": false,
    "articleUploadFileName": "",
    "addLink": false,
    "articleLinkName": "",
    "hidden": "false",
    "regardingFunds": [],
    "addTags": [],
    "autoCuration": [],
    "createdBy": secureLocalStorage.getItem("userId"),
    "createdDate": new Date(),
    "updatedBy": 1,
    "updatedDate": new Date(),
    "sections": [],
    // "investorSubscriptionStatus": 0,
    // "fundManagement": [],
    "fundTypes": [],
    "suggestedArticleAudiences": []
  }

export const sectionTypesEnum = {
    FUND_MANAGER_DASHBOARD: "Fund Manager Dashboard",
    INVESTOR_DASHOBARD: "Investor Dashboard",
    FUND_OVERVIEW: "Fund Overview"
}

export const articleTypesEnum = {
    UPLOAD_FILE: 'uploadfile',
    ADD_LINK: 'addLink'
}

export const visibilityTypes = [
    {label: 'Show',value: "false"},
    {label: 'Hide',value: "true"}
]

export const articleTypes = [
    {label: 'Add Link',value: articleTypesEnum.ADD_LINK},
    {label: 'Upload File',value: articleTypesEnum.UPLOAD_FILE}
]



export const readingsArchieveRows =[
    
    {
        "suggestedArticleID": 1,
        "Title" :"Israel IVF startup looks to decromatize infertility coverage in th US",
        "articlesubtitle": "Revolutionizing Infertility Care: Israeli IVF Startup Aims to Democratize Coverage in the US",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": true,
        "Date": "01/01/24",
        "articleurl": "www.example.com"
    },
    {
        "suggestedArticleID": 2,
        "Title" :"Israel News 2",
        "articlesubtitle":"News2",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": false,
        "Date": "01/01/24",
        "articleurl": "www.example1.com"
    },
    {
        "suggestedArticleID": 3,
        "Title" :"Israel News 3",
        "articlesubtitle":"News3",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": true,
        "Date": "01/01/24",
        "articleurl": "www.example2.com"
    },
    {
        "suggestedArticleID": 4,
        "Title" :"Israel News 4",
        "articlesubtitle":"News4",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": true,
        "Date": "01/01/24",
        "articleurl": "www.example3.com"
    },
    {
        "suggestedArticleID": 5,
        "Title" :"Israel IVF startup looks to decromatize infertility coverage in th US",
        "articlesubtitle":"News4",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": true,
        "Date": "01/01/24",
        "articleurl": "www.example4.com"
    },
    {
        "suggestedArticleID": 6,
        "Title" :"Israel IVF startup looks to decromatize infertility coverage in th US",
        "articlesubtitle":"News4",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": true,
        "Date": "01/01/24",
        "articleurl": "www.example5.com"
    },
    {
        "suggestedArticleID": 7,
        "Title" :"Israel IVF startup looks to decromatize infertility coverage in th US",
        "articlesubtitle":"News4",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": false,
        "Date": "01/01/24",
        "articleurl": "www.example1.com"
    },
    {
        "suggestedArticleID": 8,
        "Title" :"Israel IVF startup looks to decromatize infertility coverage in th US",
        "articlesubtitle":"News4",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": true,
        "Date": "01/01/24",
        "articleurl": "www.example1.com"
    },
    {
        "suggestedArticleID": 9,
        "Title" :"Israel IVF startup looks to decromatize infertility coverage in th US",
        "articlesubtitle":"News4",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": true,
        "Date": "01/01/24",
        "articleurl": "www.example1.com"
    },
    {
        "suggestedArticleID": 10,
        "Title" :"Israel IVF startup looks to decromatize infertility coverage in th US",
        "articlesubtitle":"News4",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": true,
        "Date": "01/01/24",
        "articleurl": "www.example1.com"
    },
    {
        "suggestedArticleID": 11,
        "Title" :"Israel IVF startup looks to decromatize infertility coverage in th US",
        "articlesubtitle":"News4",
        "Author": "Dobarah Bech",
        "Regarding Funds": "Health Care",
        "Type": "File",
        "HsuggestedArticleIDden": true,
        "Date": "01/01/24",
        "articleurl": "www.example1.com"
    }
]