import { useNavigate } from "react-router-dom";
import styles from "../FundDetailPortfolio.module.css";
import { useState,useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { checkFeaturePermissions } from "../../../utils/common";
import { FeatureAccess, RolesAndPermissions } from "../../../utils/enum";
import { fundTypesEnum } from "../../marketplace-dashboard/jsonData";
import { getportfoliosDetails } from "../services/services";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";

const PortfolioTab = (props) => {

  //#region props
  const { fundDetails } = props;

  //#region variables
  const navigate = useNavigate();
  const moment = require('moment');
  const fundTypeName = fundDetails?.fundTypeName;
  const[ispopupopen,setispopupopen] = useState(false);
  const [notifyPopupopen,setNotifyPopupopen] = useState (false);
  const [portfolioCompanies,setPortfoliocompanies]=useState([]);

  //#region columns
  const columns = [
    { field: 'companyName', headerName: 'CATEGORY OF INVESTMENT', flex: 1 },
    { field: 'companyType', headerName: 'INVESTMENT DATE', flex: 1 },
    { field: 'companySize', headerName: 'COMPANY NAME', flex: 1 },
    { field: 'companyCountry', headerName: 'INVESTMENT PROVIDED ($)', flex: 1 },
    { field: 'companySector', headerName: 'PORTFOLIO ALLOCATION (%)', flex: 1 },
    { field: 'companySector1', headerName: 'EQUITY STAKE IN PORTFOLIO (%)', flex: 1 },
    { field: 'SECTOR', headerName: 'SECTOR',flex: 1 },
    { field: 'RISKLEVEL', headerName: 'RISK LEVEL',flex: 1 },
    { field: 'EXPECTEDSECTORRETURNS', headerName: 'EXPECTED SECTOR RETURNS (%)',flex: 1 },
    { field: 'INVESTMENTHORIZON', headerName: 'INVESTMENT HORIZON (YEARS)',flex: 1 },
    { field: 'CURRENTINVESTMENTVALUE', headerName: 'CURRENT INVESTMENT VALUE',flex: 1 },
    { field: 'roi', headerName: 'ROI',flex: 1 },
    { field: 'forecatsedvalue', headerName: 'FORECASTED VALUE ON MATURITY',flex: 1 },
    { field: 'P/L', headerName: 'P/L',flex: 1 },
    { field: 'exit',headerName: 'EXPECTED DATE OF EXIT',flex: 1 }
  ];

  const dummyRows = [
    {
      id: 1,
      companyName: 'Tech Innovators',
      companyType: 'Private',
      companySize: 'Medium',
      companyCountry: 'USA',
      companySector: 'Technology',
      companySector1: 'Software',
      SECTOR: 'IT',
      RISKLEVEL: 'High',
      EXPECTEDSECTORRETURNS: '15%',
      INVESTMENTHORIZON: '5 years',
      CURRENTINVESTMENTVALUE: '$1,000,000',
      roi: '12%',
      forecatsedvalue: '$1,120,000',
      'P/L': '$120,000',
      exit: 'No',
    },
    {
      id: 2,
      companyName: 'Green Energy Ltd.',
      companyType: 'Public',
      companySize: 'Large',
      companyCountry: 'Germany',
      companySector: 'Energy',
      companySector1: 'Renewable',
      SECTOR: 'Utilities',
      RISKLEVEL: 'Medium',
      EXPECTEDSECTORRETURNS: '10%',
      INVESTMENTHORIZON: '7 years',
      CURRENTINVESTMENTVALUE: '$2,500,000',
      roi: '8%',
      forecatsedvalue: '$2,700,000',
      'P/L': '$200,000',
      exit: 'No',
    },
    {
      id: 3,
      companyName: 'Global Retailers',
      companyType: 'Private',
      companySize: 'Small',
      companyCountry: 'India',
      companySector: 'Retail',
      companySector1: 'E-commerce',
      SECTOR: 'Consumer',
      RISKLEVEL: 'Low',
      EXPECTEDSECTORRETURNS: '7%',
      INVESTMENTHORIZON: '3 years',
      CURRENTINVESTMENTVALUE: '$500,000',
      roi: '6%',
      forecatsedvalue: '$530,000',
      'P/L': '$30,000',
      exit: 'No',
    },
    {
      id: 4,
      companyName: 'HealthTech Corp.',
      companyType: 'Startup',
      companySize: 'Small',
      companyCountry: 'Canada',
      companySector: 'Healthcare',
      companySector1: 'Biotechnology',
      SECTOR: 'Healthcare',
      RISKLEVEL: 'Very High',
      EXPECTEDSECTORRETURNS: '20%',
      INVESTMENTHORIZON: '10 years',
      CURRENTINVESTMENTVALUE: '$750,000',
      roi: '18%',
      forecatsedvalue: '$885,000',
      'P/L': '$135,000',
      exit: 'No',
    },
    {
      id: 5,
      companyName: 'Future Mobility',
      companyType: 'Public',
      companySize: 'Large',
      companyCountry: 'Japan',
      companySector: 'Automotive',
      companySector1: 'Electric Vehicles',
      SECTOR: 'Industrials',
      RISKLEVEL: 'High',
      EXPECTEDSECTORRETURNS: '12%',
      INVESTMENTHORIZON: '8 years',
      CURRENTINVESTMENTVALUE: '$3,000,000',
      roi: '10%',
      forecatsedvalue: '$3,300,000',
      'P/L': '$300,000',
      exit: 'No',
    },
  ];
  

  //#region click events
  const onClicknavigate = (companyId) =>
  {
    secureLocalStorage.setItem("companyID", companyId);
    secureLocalStorage.setItem("CreateCompany", false);
    navigate('/portfolio-company-overview');
  }

  //#region api get calls
  const getCompaniesDetails = async () => {
    const fundID = secureLocalStorage.getItem("FundId");
    const data = await getportfoliosDetails(fundID);
    if (data.responseCode === 200) {
      console.log(data.responseData);
      const reversedData = [...data.responseData].reverse();
      setPortfoliocompanies(reversedData);      
    } else {
      setPortfoliocompanies([]);
      console.log("data.responseData");
    }
  }
  
  //#region useeffect
  useEffect(() => {
    getCompaniesDetails();
  }, [notifyPopupopen]);

  //#region return 
  return (
    <>
    {
      portfolioCompanies && 
        checkFeaturePermissions(RolesAndPermissions.PORTFOLIO_COMPANY,FeatureAccess.READ) &&
         <div className="child-margin-15">
           <div className="child-margin-5 margin-top-minus-15">
              <h4>Portfolio</h4>
              <b>Driving growth through strategic investments</b>
           </div>
           <div className="white-card">
             <AIPDataGrid 
                columns={columns}
                rows={dummyRows}
                onRowsSelectionHandler={onClicknavigate} />
           </div>
         </div>
    } 
    </>
  );
};

export default PortfolioTab;
