import { Button, Divider, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { addCompare, removeCompare, updateAllFunds } from '../../../state/slices/MarketPlaceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFunds } from '../../FundManagerDashboard/Services/FundNewsService';
import secureLocalStorage from 'react-secure-storage';
import { checkFeaturePermissions } from '../../../utils/common';
import { AppDashboards, FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { addFavourite, removeFavourite } from '../../../state/slices/MarketPlaceSlice';
import { updateFavouriteFund } from '../services/mpservices';
import { fundSubscriptionStatues } from '../../CODashboard/Services/apiDataTempate';
import { fundTypesEnum } from '../jsonData';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AwaitingPopup from '../../investor-dashboard/popups/AwaitingPopup';
import { FundAddTypes } from '../mpenum';
import RemoveFundPopUp from '../popups/RemoveFundPopUp';
import InviteInvestorsPopup from '../../InvestorRelations/Popups/InviteInvestorsPopup';
import { inviteInvestorTypeEnum } from '../../InvestorRelations/Services/apiDataTemplate';
import { lab } from 'd3';
import { ToastContainer } from 'react-toastify';
const FundCardView = (props) => {

  //#region props
  const { fundDetails, allFunds, allInvestors, comparefunds, getFunds, favoritefunds, type, fetchDataforfavfunds } = props;


  //#region variables
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fundDescriptionMaxLength = 150;
  const [awaitingPopup, setAwaitingPopup] = useState(false);
  const [currFundName, setCurrFundName] = useState("");
  const [removePopup, setRemovePopup] = useState(false);
  const [inviteInvestorsPopup,setInviteInvestorsPopup] = useState(false);

  const subscribebtn = secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?
    (fundDetails?.isFundMatured
      || secureLocalStorage.getItem("InvestorFundIDs")?.includes(fundDetails?.fundID) || allInvestors.some(u =>
        u.fundID === fundDetails?.fundID &&
        u.subscriptionStatus === fundSubscriptionStatues.Awaiting_Online_Processing)
      || fundDetails.fundStatusName === 'Closed to New Investors' || fundDetails.fundStatusName === 'Launching Soon'
    ) :
    true;

  //  (item?.isFundMatured )

  //#region click events
  const openRemovePopup = (fundName) => {
    setRemovePopup(true);
  }

  const onCloseRemovePopup = () => {
    setRemovePopup(false);
  }

  //#region compare clicks
  const handleAddCompare = (fund) => {
    if (comparefunds?.length >= 3) {
      return
    }
    const updatedFunds = allFunds.map(item => {
      if (item.fundID === fund.fundID) {
        return { ...item, compare: true };
      }
      return item;
    });
    const compareFund = updatedFunds.find((item) => item.fundID === fund.fundID)
    dispatch(updateAllFunds({ value: updatedFunds }));
    dispatch(addCompare({ fund: compareFund }));
  }

  const handleRemoveCompare = (fund) => {
    if (type === FundAddTypes.COMPARE && removePopup === false) {
      openRemovePopup(fund.fundName);
      return
    }
    onCloseRemovePopup();
    const updatedFunds = allFunds.map(item => {
      if (item.fundID === fund.fundID) {
        return { ...item, compare: false };
      }
      return item;
    });
    const fundID = fund.fundID;
    dispatch(updateAllFunds({ value: updatedFunds }));
    dispatch(removeCompare({ fundID: fundID }));
  }

  //#region favorite clicks
  const handleAddFavourite = async (fund) => {
    const data = await updateFavouriteFund(fund.fundID, true);
    const updatedFunds = allFunds.map(item => {
      if (item.fundID === fund.fundID) {
        return { ...item, favourite: true };
      }
      return item;
    });
    const favFund = updatedFunds.find((item) => item.fundID === fund.fundID)
    /*  dispatch(updateAllFunds({ value: updatedFunds })); */
    if (data.responseCode === 200) {
      dispatch(addFavourite({ fund: favFund }));
      getFunds();
    }
    else {
      /* console.log("Unable to add Fund") */
    }
    if (fetchDataforfavfunds) {
      fetchDataforfavfunds();
    }
  }

  const handleRemoveFavourite = async (fund) => {
    if (type === FundAddTypes.FAVOURITE && removePopup === false) {
      openRemovePopup(fund.fundName);
      return
    }
    onCloseRemovePopup();
    const data = await updateFavouriteFund(fund.fundID, false);
    if (data.responseCode === 200) {
      const updatedFunds = allFunds.map(item => {
        if (item.fundID === fund.fundID) {
          return { ...item, favourite: false };
        }
        return item;
      });
      const fundID = fund.fundID;
      /*  dispatch(updateAllFunds({ value: updatedFunds })); */
      getFunds();
      dispatch(removeFavourite({ fundID: fundID }));
    }
    else {
      /* console.log("Unable to remove Fund") */
    }
    if (fetchDataforfavfunds) {
      fetchDataforfavfunds();
    }
  }

  //#region subscribe btn
  const handlesubscribe = (item, itemId, fundname, event) => {
    if (event) {
      event.stopPropagation();
    }
    secureLocalStorage.setItem("FundName", fundname);
    secureLocalStorage.setItem("FundId", itemId);
    secureLocalStorage.setItem("FundSPIcon", item.iconImage);
    secureLocalStorage.setItem("fundDescription", item.fundDescription);

    //#need to find subscription status
    const investor = allInvestors.find(u =>
    (u.fundID === fundDetails?.fundID &&
      u.investorID === secureLocalStorage.getItem("userId"))
    );

    const subscriptionId = investor ? investor?.subscriptionsID : 0;

    secureLocalStorage.setItem("subscriptionId", subscriptionId);

    /*  console.log(item); */
    /*   */
    navigate('/FundSubscriptionPage', { state: { FundId: { itemId } } });
    window.scrollTo(0, 0);
  };

  //#region view detail btn
  const handleViewDetail = (item, itemIdObj, fundname, fundStatusName, disableSubscriptionButton, event) => {
    if (event) {
      event.stopPropagation();
    }
    let parsedFundId;
    try {
      parsedFundId = JSON.parse(itemIdObj);
    } catch (error) {
      console.error("Error parsing fundDetail:", error);
    }
    secureLocalStorage.setItem("FundId", parsedFundId);
    secureLocalStorage.setItem("FundName", fundname);
    const havepermissiontoread = checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.READ);
    if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
      // debugger
      secureLocalStorage.setItem('FundTypeName', (item.fundTypeName))
      /* navigate('/fund-details', { parsedFundId }, { fundStatusName }); */
      navigate('/fund-details', {
        state: {
          FundID: parsedFundId,
          InvestorID: secureLocalStorage.getItem("userId"),
          SubscriptionID: 0,
          navigatefrommarketplace: true,
          disableSubscriptionButton: disableSubscriptionButton,
          FundType: fundDetails?.fundTypeName,
          renderSubscribeBtnLabel: renderSubscribeBtnLabel()
        }
      });

    } else if (havepermissiontoread) {

      const SelectedFund = {
        "FundMaturity": item.isFundMatured,
        "FundTermination": item.fundStatusName === 'Terminated',
        BGImage: item.bgImage,
        IconImage: item.iconImage,
      }
      secureLocalStorage.setItem("navigatingFromMarketPlace", true);
      secureLocalStorage.setItem("selectedFund", SelectedFund);
      navigate('/marketplace/OverviewPage', { parsedFundId });
    }

    secureLocalStorage.setItem("FundId", JSON.stringify(parsedFundId));
    secureLocalStorage.setItem('fundStatusName', fundStatusName);
    window.scrollTo(0, 0);
  };

  //#region investment fund click
  const onInvestmentFundClick = () => {
    if (allInvestors.find(u => (u.fundID === fundDetails?.fundID))?.subscriptionStatus === fundSubscriptionStatues.Awaiting_Online_Processing) {
      setCurrFundName(fundDetails?.fundName)
      onOpenAwaitingPopup();
      return;
    }
    secureLocalStorage.setItem("FundId", fundDetails.fundID);
    if (allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.APPROVED))) {
      navigate('/Investor-Overview-Page', {
        state: {
          FundId: fundDetails.fundID,
          subscriptionId: fundDetails.subscriptionID
        }
      });
    }
    else {
      secureLocalStorage.setItem("FundId", fundDetails.fundID);
      navigate('/subscription-details', {
        state: {
          FundId: fundDetails.fundID,
          subscriptionId: fundDetails.subscriptionID,
          selectedRow: fundDetails,
        }
      });
    }
  }

  //#region popup events
  const onOpenAwaitingPopup = () => {
    setAwaitingPopup(true);
  }

  const onCloseAwaitingPopup = () => {
    setCurrFundName("");
    setAwaitingPopup(false);
  }

  const onOpenInviteInvestorsPopup = () => {
    setInviteInvestorsPopup(true);
  }

  const onCloseInviteInvestorsPopup = () => {
    setInviteInvestorsPopup(false);
  }

  //#region render functions
  const renderSubscribeBtnLabel = () => {
    if (allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.COMPLIANCE_REVIEW)
      || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.FM_REVIEW)
      || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.APPROVED)
    )) {
      return 'MY INVESTMENT'
    }
    else if (allInvestors.some(u =>
      u.fundID === fundDetails?.fundID &&
      u.subscriptionStatus === fundSubscriptionStatues.Awaiting_Online_Processing)) {
      return 'SUBSCRIBED'
    }
    else if (allInvestors.some(u =>
      u.fundID === fundDetails?.fundID &&
      u.subscriptionStatus === fundSubscriptionStatues.INVESTOR_SIGN_PENDING &&
      u.isOfflineSubscription !== true)) {
      return "SUBSCRIBE"
    }
    else if (allInvestors.some(u =>
      u.fundID === fundDetails?.fundID &&
      u.subscriptionStatus === fundSubscriptionStatues.REJECTED)) {
      return 'SUBSCRIBE'
    }
    else {
      return 'SUBSCRIBE'
    }
  }

  //#region fav and compare fields
  const moreFundDetailsTop = [
    { name: 'Equity', value: '70%' },
    { name: 'Credit', value: '30%' }
  ]

  const moreFundDetailsBottom = [
    { name: 'Vinatge', value: fundDetails?.vintage },
    { name: 'Target Raise', value: fundDetails?.fundSize },
    { name: 'Capital Called', value: fundDetails?.capitalCalled },
    { name: 'Number of Investments', value: fundDetails.noOfInvestors ? fundDetails.noOfInvestors : 0 }
  ]


  //#region return
  return (
    <div className={
      fundDetails?.isFundMatured === true || fundDetails?.fundTerminated === true
        ? type === FundAddTypes.FAVOURITE || type === FundAddTypes.COMPARE ? 'box-view' : 'card-view-grayed-out'
        : type === FundAddTypes.FAVOURITE || type === FundAddTypes.COMPARE ? 'box-view' : 'card-view'}>
      <div className='card-img-container'>
        <img
          className='bg-img-container'
          src={fundDetails?.bgImage || '/DefaultBackgroundImage.png'}
          alt="Background"
          onError={(e) => {
            e.target.src = '/DefaultBackgroundImage.png';
          }}
        />
        <img
          className='icon-img-container'
          src={fundDetails?.iconImage || '/DefaultLogo.png'}
          alt="Icon"
          onError={(e) => {
            e.target.src = '/DefaultLogo.png';
          }}
        />
        <div className='status-container'>
          <span className='status-text'>{fundDetails?.fundStatusName}</span>
        </div>
      </div>
      <div className='card-type space-between items-center margin-top-5'>
        <div className='card-details-header'>
          {fundDetails?.fundTypeName}
        </div>
        <div className='mp_fav_icon'>

          {
            type !== FundAddTypes.COMPARE ?
              favoritefunds?.find((item) => item?.fundID === fundDetails?.fundID) ?
                <Tooltip title="Click to Remove">
                  <FavoriteIcon
                    onClick={() => handleRemoveFavourite(fundDetails)}
                    color="primary"
                  />
                </Tooltip> :
                <Tooltip title="Add to Favorite">
                  <FavoriteBorderIcon title="Favorite"
                    onClick={() => handleAddFavourite(fundDetails)}
                    sx={{ color: '#D2DAE0' }} />
                </Tooltip>
              : <></>
          }

          {
            type !== FundAddTypes.FAVOURITE ?
              comparefunds?.find((item) => item?.fundID === fundDetails?.fundID) ?
                <Tooltip title="Click to Remove">
                  <CompareArrowsIcon
                    onClick={() => handleRemoveCompare(fundDetails)}
                    color="primary"
                  />
                </Tooltip> :
                <Tooltip title={comparefunds?.length >= 3 ?
                  <div>
                    Please deselect a fund to add a new one.
                    <br />You can compare a maximum of 3 funds
                  </div>
                  : "Add to Compare"}>
                  <CompareArrowsIcon
                    onClick={() => handleAddCompare(fundDetails)}
                    disabled={comparefunds?.length >= 3}
                    sx={{ color: '#D2DAE0' }} />
                </Tooltip>
              : <></>
          }

        </div>
      </div>
      <div className='card-header space-between margin-top-5'>
        <div>
          {fundDetails.fundName}
        </div>
      </div>
      <div className='card-desc margin-top-10'>
        {/*  {fundDetails.fundName} */}
        {fundDetails.fundDescription?.length > fundDescriptionMaxLength ? fundDetails.fundDescription?.slice(0, fundDescriptionMaxLength) + '...' : fundDetails.fundDescription}
      </div>
      <div className='card-details display-row space-around'>
        {/* {
          fundDetails?.fundTypeName === fundTypesEnum.PRIVATE_EQUITY &&
          <div className='card-details-header display-column'>
            <div>
              Target Return
            </div>
            <div className='card-details-value'>
              {fundDetails.targettedIRR}%
            </div>
          </div>
        } */}
        <div className='card-details-header display-column'>
          <div>
            Minimum
          </div>
          <div className='card-details-value'>
            ${fundDetails.minimumInvestmentAmount ? fundDetails.minimumInvestmentAmount.toLocaleString() : ''}
          </div>
        </div>
        {
          fundDetails?.fundTypeName === fundTypesEnum.REAL_ESTATE_FUND ? <></> :
            <div className='card-details-header display-column'>
              <div>
                Liquidity
              </div>
              <div className='card-details-value'>
                {fundDetails.liquidityProvision}
              </div>
            </div>
        }
      </div>
      {
        type === FundAddTypes.FAVOURITE || type === FundAddTypes.COMPARE ?
          <>
            <b className='color-gray'>
              Portfolio By Asset Type
            </b>
            <div>
              {
                moreFundDetailsTop?.map((item, index) => (
                  <>
                    <div className='display-row space-between margin-top-15 margin-bottom-15' key={index}>
                      <div>
                        {item.name}
                      </div>
                      <div>
                        {item.value}
                      </div>
                    </div>
                    <Divider />
                  </>
                ))
              }
            </div>
            <b className='margin-top-10 color-gray'>
              Fund Metrics
            </b>
            <div>
              {
                moreFundDetailsBottom?.map((item, index) => (
                  <>
                    <div className='display-row space-between margin-top-15 margin-bottom-15' key={index}>
                      <div>
                        {item.name}
                      </div>
                      <div>
                        {item.value}
                      </div>
                    </div>
                    <Divider />
                  </>
                ))
              }
            </div>
            <span className='margin-bottom-20'></span>
          </>
          : <></>
      }
      <div className='card-btns space-between'>
        <div>
          <Button
            component="label"
            variant="outlined"
            className="btn-primary"
            style={{ minWidth: '150px', minHeight: '37px' }}
            onClick={() => handleViewDetail(fundDetails, fundDetails.fundID, fundDetails.fundName, fundDetails.fundStatusName, allInvestors.some(u => u.fundID === fundDetails?.fundID))}>
            VIEW DETAIL
          </Button>
        </div>
        <>
          {
            secureLocalStorage.getItem('userrole') !== AppDashboards.INV_DASHBOARD 
            ? <div className='invite-investor-btn-sec'>
                <Button
                  component="label"
                  variant="contained"
                  onClick={onOpenInviteInvestorsPopup}>
                  Invite Investor
                </Button>
              </div>
            : allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.COMPLIANCE_REVIEW)
              || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.FM_REVIEW)
              || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.APPROVED)
              || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.Awaiting_Online_Processing)
              )
              ? <div className='my-investment-btn-sec'>
                <Button
                  component="label"
                  variant="contained"
                  onClick={onInvestmentFundClick}>
                  MY INVESTMENT
                </Button>
                </div> 
              : <div className='subscribe-btn-sec'>
                <Button
                  component="label"
                  variant="contained"
                  /* className="btn-primary" */
                  style={
                    allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.INVESTOR_SIGN_PENDING))
                      ? { width: '150px', backgroundColor: '#F9C023', color: 'black', minHeight: '35px' } :
                      subscribebtn
                        ? { minWidth: '150px', minHeight: '35px' }
                        : { width: '150px', backgroundColor: '#F9C023', color: 'black', minHeight: '35px' }
                  }


                  onClick={() => {
                    handlesubscribe(fundDetails, fundDetails.fundID, fundDetails.fundName)
                  }}
                  disabled={subscribebtn}>
                  {
                    renderSubscribeBtnLabel()
                  }
                </Button>
                </div>
          }
        </>
      </div>
      {
        awaitingPopup &&
        <AwaitingPopup open={awaitingPopup} fundName={currFundName} onClose={onCloseAwaitingPopup} />
      }
      {
        removePopup && <RemoveFundPopUp
          open={removePopup}
          onConfirm={type === FundAddTypes.COMPARE ? () => handleRemoveCompare(fundDetails) : () => handleRemoveFavourite(fundDetails)}
          onClose={onCloseRemovePopup}
          content={type === FundAddTypes.COMPARE ? "Are you sure you would like to remove this fund from your compare list?" :
            "Are you sure you would like to remove this fund from your Favorite Funds list?"} />
      }
      {
        inviteInvestorsPopup && 
        <InviteInvestorsPopup
          open={inviteInvestorsPopup}
          onClose={onCloseInviteInvestorsPopup}
          selectedFund={fundDetails?.fundID}
          fundsDropdown={allFunds?.map((fund) => ({ label: fund.fundName, value: fund.fundID }))}
          inviteInvestorType={inviteInvestorTypeEnum.MARKETPLACE} />
      }
      <ToastContainer />
    </div>
  )
}

export default FundCardView