import { getAPIResponse } from "../../../utils/common";
import axiosApiCall from "../../Services/axiosApiCall";


export async function updateFundManagementTeamAndStaff(isFirmAsGP,requestedData) {
    return await axiosApiCall.put(`/v1/Fund/UpdateFundManagementTeamAndStaff?isFirmAsGP=${isFirmAsGP}`, requestedData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateFundFeesAndExpenses(requestedData) {
    return await axiosApiCall.put(`/v1/Fund/UpdateFeesExpenses`, requestedData)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}