import React, { useCallback, useEffect, useState } from "react";
import MiniDrawer from "../common/navigation-bar/NavBarGrid";
import { TopNavBar } from "../common/navigation-bar/TopNavBar";
import { Outlet, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast, ToastContainer } from "react-toastify";
import LinkIcon from '@mui/icons-material/Link';
import ActionButton from "../common/ActionButton/ActionButton";
import { GetUserReSendLinkDetails, ReSendActivationLinkToUser } from "../registration-profiling/services/RegistrationProfilingService";
import timeconvertion from "../timeconvertion";
import moment from "moment";

function NonActiveUserLogin() {
  const msalInstance = new PublicClientApplication(msalConfig);
  const location = useLocation();
  const { state } = location;
  const [noOfAttempts, setNoOfAttempts] = useState(0);
  const [showResentButton, setShowResentButton] = useState(false);

  const handleOkButtonClick = () => {
    secureLocalStorage.setItem("fullName", "");
    secureLocalStorage.clear();
    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
  };

  useEffect(async () => {
    const data = await GetUserReSendLinkDetails(state?.emailAddress);
    if (data?.responseCode === 200) {
      if (data?.responseData?.activationEmailResentAttempts == 5) {
        setNoOfAttempts(data?.responseData?.activationEmailResentAttempts);
        setShowResentButton(true);
      }
      else {
        const lastResentDate = timeconvertion(data?.responseData?.lastLoginDate, "UTC");
        const lastResendedDate = moment(lastResentDate);
        const nowDate = moment(moment().format("YYYY-MM-DD HH:mm:ss"));
        var duration = moment.duration(nowDate.diff(lastResendedDate));
        var hours = duration.asHours();
        if (hours > 24 || data?.responseData?.activationEmailResentAttempts == 0) {
          setShowResentButton(true);
        }
      }
    }
    else {
      toast.warning("Something went wrong. Please try again later.",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
    }
  }, []);

  const [loading, setLoading] = useState(false);

  const reSendLink = async () => {
    setLoading(true);
    if (noOfAttempts == 5) {
      toast.warning("You have exceeded your number of resend attempts. Please contact administrator",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
    } else {
      if (state?.emailAddress !== undefined &&
        state?.emailAddress !== null) {
        const data = await ReSendActivationLinkToUser(state?.emailAddress);
        if (data?.responseCode === 200) {
          toast.success("A new activation link has been sent to your email.",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          setLoading(false);
          setShowResentButton(false);
        }
        else {
          toast.warning("We’re unable to resend the activation email at the moment. Please try again later.",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          setLoading(false);
        }
      }
      else {
        toast.error("We’re unable to resend the activation email at the moment. Please try again later.",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setLoading(false);
      }
    }
  }

  return (<div style={{ display: 'flex' }}>
    {/* <MiniDrawer /> */}
    <div style={{ width: '100%' }}>
      <TopNavBar fullname={secureLocalStorage.getItem("fullName")} hideMenuButton={true} />
      <div className="alertMessage">
        <h1> <ReportProblemIcon fontSize="large" /> You can't login</h1>
        <p> {state?.errorMessage}</p>
        <div>
          <Button
            variant="contained"
            className={showResentButton ? "btn-primary float-left" : "btn-primary"}
            onClick={handleOkButtonClick}>
            <ArrowBackIcon />
            Go Back
          </Button>
          {showResentButton && <ActionButton
            label='Resend Activation Link'
            loading={loading}
            startIconName=''
            icon={<LinkIcon />}
            onClick={reSendLink}
            className="activation-button-right" />
          }
        </div>
      </div>
    </div>

    <ToastContainer />
  </div>
  )
}

export default NonActiveUserLogin
