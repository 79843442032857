import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import JsonData from "../../Servicing/Services/JsonData.json"
import { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import secureLocalStorage from "react-secure-storage";
import { GetCapitalCallByID, GetCapitalCallsForFund, getCurrentDistributions } from '../services/services.js';
import { useEffect } from 'react';
import moment from 'moment';
import { AppDashboards, FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { checkFeaturePermissions } from '../../../utils/common';
import { getFundDistributions, getFundDistributionsStatus } from '../../fund-services/services/services.js';
import { getRepotingFoldersbyFundID } from '../../InvestorReporting/FirmEmployees/services/services.js';
import { GetFundSubscriptions } from '../../InvestorFundSubscription/Services/Services.js';
import { fundTypesEnum } from '../../marketplace-dashboard/jsonData.js';
import { formatDate } from '../../common/Functions/TimeConvestion.jsx';
import K1Main from '../../Servicing/Services/K-1\'s/K-1Main.jsx';
import DocumentsDasboard from '../../InvestorReporting/FirmEmployees/Components/DocumentsDasboard.jsx';
import { DocumentsGETType } from '../../InvestorReporting/InvestorReportingEnum.js';

const ServicingAccordians = (props) => {

  //#region variables
  const navigate = useNavigate();
  const fundId = secureLocalStorage.getItem("FundId");
  const [CCSummaryData, setCCSummaryData] = useState();
  const [DistributionData, SetDistributionData] = useState();
  const [subscriptionsList, setSubscriptionsList] = useState(null);
  const [foldersList, setFoldersList] = useState(null); 
  const [distributionAccordianStatus, setDistributionAccordianStatus] = useState(false);
  const enblDistributions = (parseInt(props?.fundDetails?.fundSize) - parseInt(props?.fundDetails?.capitalCalled) === 0) 
  const [distributionDetails, setDistributionDetails] = useState();
  const currFundManagerID = secureLocalStorage.getItem("selectedFund")?.fundManagerID;
  const currUserId = secureLocalStorage.getItem("userId");

  //#region click events
  const onCCSeeMoreClick = useCallback(() => {
    navigate("/capitalActivity", { state: { fundDetails: props?.fundDetails } });
  }, [navigate, props?.fundDetails]);
  

  const onDDSeeMoreClick = useCallback(() => {
    navigate("/distributions-page");
  }, [navigate]);

  //#region api get calls
  const GetFundSubscriptionsAsync = async () => {
    try {
      const apiResponse = await GetFundSubscriptions(0);

      if (apiResponse.responseCode === 200) {
        const allSubscriptions = apiResponse.responseData
          ? apiResponse.responseData
            .map((u) => ({
              id: u.subscriptionsID,
              investor: u.investor,
              investorType: u.investorType ?? "Individual Investor",
              fundName: u.fundName,
              subscribed: (u.subscribed),
              subscriptionStatus: u.subscriptionStatus,
              subscriptionID: u.subscriptionsID,
              fundID: u.fundID,
              investorID: u.investorID,
              isOfflineSubscription: u.isOfflineSubscription,
              subscriptionType: u.isOfflineSubscription ? "Offline" : "Online",
              offlineFile: u.offlineFile,
              offlineFilePath: u.offlineFilePath,
              fundLogo: u.fundLogo,
              userProfileIcon: u.userProfileIcon,
              isInvestorVerified: u.isInvestorVerified
            }))
          : [];

        setSubscriptionsList(allSubscriptions.filter((u) => u.fundID === secureLocalStorage.getItem("FundId")));

      } else {
        console.log('error happened');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const getCCSummaryData = async () => {
    const responsedata = await GetCapitalCallsForFund(fundId);
    if (responsedata.responseCode === 200) {
      let ccid;
      if (responsedata.responseData.length === 0) {
        ccid = 0;
      } else {
        ccid = responsedata.responseData[0].capitalCallID;
      }
      const data = await GetCapitalCallByID(fundId, ccid)
      if (data.responseCode === 200) {
        setCCSummaryData(data.responseData)
      }
      else {
        //error log
      }
    }
    else {
      //error log
    }
  }

  const getDistributionData = async () => {
    const data = await getCurrentDistributions(fundId);
    if (data.responseCode === 200) {

      // console.log(data);
      SetDistributionData(data);
      /*   */
    }
  }

  const getFolders = async () => {
    const currFundID = secureLocalStorage.getItem("FundId");
    const data = await getRepotingFoldersbyFundID(currFundID);
    if (data?.responseCode === 200) {
      //TODO: Check if the parentTypeID is always 86
      const filteredData = data?.responseData?.filter((item) => item.title !== 'Other');

      // Need to add 'item.name' to each filtered item for tab component
      filteredData.forEach((item) => {
        item.name = item.title;
        item.relatedFiles = [];
      });

      const folderNamesWithComponent = filteredData?.map((folder) => {
        return {
          ...folder,
          component: "No Documents"
        }
      });

      ;
      setFoldersList(folderNamesWithComponent);
    } else {
      // Handle error if needed
    }
  }

  const getDistributionAccordianStatus = async () => {
    const fundId = secureLocalStorage.getItem("FundId");
    const data = await getFundDistributionsStatus(fundId);
    if (data.responseCode === 200) {
      setDistributionAccordianStatus(data.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getFundRelatedDistributions = async () => {
    const fundId = secureLocalStorage.getItem("FundId");
    const data = await getFundDistributions(fundId, 0);
    if (data.responseCode === 200) {
      const latestDistribution = data.responseData.reduce((max, item) => {
        return item.distributionID > (max.distributionID || 0) ? item : max;
      }, {});
      setDistributionDetails(latestDistribution);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  //#region useeffect
  useEffect(() => {
    GetFundSubscriptionsAsync();
    getCCSummaryData();
    getDistributionData();
    getFolders();
    getDistributionAccordianStatus();
    getFundRelatedDistributions();
  }, []);

  //#region styles
  const accordionStyle = {
    marginBottom: '16px',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  };

  //#region return

  const disableCapitalCallAccordian = () => {
    if(parseInt(props.fundDetails.minimumInvestmentAmount) > (parseInt(props?.fundDetails.fundSize) - parseInt(props?.fundDetails.capitalCommitted))){
      return false
    }else{
      return true
    }
  }


  return (
    <div className="mt2">
      {
        props.approvedinvestorsoverview === true &&
        <div style={{ marginTop: '10px' }}><h4>Servicing</h4></div>
      }
      {checkFeaturePermissions(RolesAndPermissions.CAPITAL_CALL, FeatureAccess.READ) && !props.approvedinvestorsoverview && (secureLocalStorage.getItem('FundTypeName') !== fundTypesEnum.PRIVATE_CREDIT) &&
        <Accordion style={accordionStyle} disabled={disableCapitalCallAccordian()}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ alignItems: "center" }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={7} sm={9} md={11} lg={11} style={{ textTransform: 'uppercase', fontWeight: '500', letterSpacing: '1.4px' }}>
                Capital Call
              </Grid>
              {CCSummaryData && CCSummaryData?.capitalCallID > 0 &&
                <Grid item xs={7} sm={3} md={1} lg={1}>
                  <Box
                    bgcolor="info.main"
                    color="white"
                    borderRadius="20px"
                    fontSize={12}
                    style={{ textAlign: "center" }}
                  >
                    <label>
                      {CCSummaryData?.capitalCallStatus}
                    </label>
                  </Box>
                </Grid>}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={5}>

              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                  .
                </div>
                {JsonData.CapitalCallIds.map((data, index) => (
                  <React.Fragment key={index}>
                    <Divider sx={{ color: 'gray', opacity: '1' }} />
                    <div className='porfolio-subheadings'>
                      <div className='font-cresta'>{data.name}</div>
                      {
                        CCSummaryData?.capitalCallID > 0 ?
                          <div>
                            {data.internalName === 'ccDate' && CCSummaryData?.ccDate ? moment(CCSummaryData?.ccDate).format("MM-DD-YYYY")
                              : ''}
                            {data.internalName !== 'ccDate' && (CCSummaryData?.capitalCallID > 0 ? CCSummaryData[data.internalName] : "-")}
                          </div> : '-'}
                    </div>
                  </React.Fragment>
                ))}
              </Grid>
              <Grid item xs={12} sm={6}>
                <React.Fragment>
                  <div className='porfolio-subheadings'>
                    <div className='font-cresta'></div>
                    <div className='capitalheadrow'>
                      <div className='capital-numberofInvest'># of Investors</div>
                      <div className='capital-amounthead'>Capital Amount</div>
                    </div>

                  </div>
                </React.Fragment>
                {JsonData.CapitalCallAmounts.map((data, index) => (
                  <React.Fragment key={index}>
                    <Divider sx={{ color: 'gray', opacity: '1' }} />
                    <div className='porfolio-subheadings' style={{ width: '100%' }}>
                      <div className='capitalName'>{data.name}</div>
                      <div className='capitalDetails'>
                        <div>
                          {
                            CCSummaryData?.capitalCallID === 0 ? "-"
                              : CCSummaryData?.capitalCallInvestors?.filter(a => a.ccInvstrID > 0)?.length
                          }
                        </div>
                        <div>
                          {
                            CCSummaryData?.capitalCallID === 0 ? "-"
                              : CCSummaryData?.capitalCallInvestors?.map(a => a[data.internalName]).reduce((a, b) => a + b, 0)?.toLocaleString()
                          }
                        </div>

                      </div>


                    </div>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>

            {
              // currFundManagerID === currUserId ?
                <Grid container>
                  <Grid item xs={12}>
                    <button onClick={onCCSeeMoreClick} className='seemore'>
                      See more   <ArrowForwardIcon />
                    </button>
                  </Grid>
                </Grid> 
                // : ""
            }

          </AccordionDetails>
        </Accordion>
      }

      {checkFeaturePermissions(RolesAndPermissions.Distributions, FeatureAccess.READ) && !props.approvedinvestorsoverview && 
        <Accordion style={accordionStyle}
          disabled={!(parseInt(props.fundDetails.capitalCommitted) > 0 && (parseFloat(props.fundDetails.capitalCommitted) === parseFloat(props.fundDetails.capitalCalled)))}
            /* disabled={CCSummaryData?.capitalCallStatus === "Executed" ? false : true} */>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={11} style={{
                textTransform: 'uppercase', fontWeight: '500', letterSpacing: '1.4px'
              }}>
                Distribution
              </Grid>
              {distributionDetails?.distributionID > 0 &&
                <Grid item xs={1} >
                  <Box
                    bgcolor="info.main"
                    color="white"
                    borderRadius="20px"
                    fontSize={12}
                    style={{ textAlign: "center" }}
                  >
                    <label>
                      {distributionDetails?.fdStatus}
                    </label>
                  </Box>
                </Grid>}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={5}>

              <Grid item xs={6}>
                <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                  .
                </div>
                {JsonData.DistributionCallIds.map((data, index) => (
                  <React.Fragment key={index}>
                    <Divider sx={{ color: 'gray', opacity: '1' }} />
                    <div className='porfolio-subheadings'>
                      <div className='font-cresta'>{data.name}</div>
                      {
                        distributionDetails?.distributionID > 0 ?
                          <div>
                            {
                              data?.type === "date" ?
                                formatDate(distributionDetails?.[data.internalName]) :
                                distributionDetails?.[data.internalName]
                            }
                          </div> : '-'}
                    </div>
                  </React.Fragment>
                ))}
              </Grid>
              <Grid item xs={6}>
                <React.Fragment>
                  <div className='porfolio-subheadings'>
                    <div className='font-cresta'></div>
                    <div className='capitalheadrow'>
                      <div className='capital-numberofInvest'># of Investors</div>
                      <div className='capital-amounthead'>Capital Amount</div>
                    </div>
                  </div>
                </React.Fragment>
                {JsonData.DistributionCallAmounts.map((data, index) => (
                  <React.Fragment key={index}>
                    <Divider sx={{ color: 'gray', opacity: '1' }} />
                    <div className='porfolio-subheadings'>
                      <div className='capitalName'>{data.name}</div>
                      <div className='capitalDetails'>
                        <div>
                          {
                            distributionDetails?.distributionID === 0 ? "-"
                              : distributionDetails?.noOfInvestors
                          }
                        </div>
                        <div>
                          {
                            distributionDetails?.distributionID === 0 ? "-"
                              : distributionDetails?.[data.internalName]?.toLocaleString() || "-"
                          }
                        </div>
                      </div>

                    </div>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>

            {
              // currFundManagerID === currUserId ?
                <Grid container>
                  <Grid item xs={12}>
                    <button className='seemore' onClick={onDDSeeMoreClick}>
                      See more <ArrowForwardIcon />
                    </button>
                  </Grid>
                </Grid> 
                // : ""
            }

          </AccordionDetails>
        </Accordion>
      }
      {
        checkFeaturePermissions(RolesAndPermissions.K_1_s, FeatureAccess.READ) &&
        <Accordion style={accordionStyle} disabled={(secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD) ? false :
          (props.fundDetails?.legalStructureName === "C-Corporate")}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={11} style={{
                textTransform: 'uppercase', fontWeight: '500', letterSpacing: '1.4px'
              }}>
                K-1s
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <K1Main />
          </AccordionDetails>
        </Accordion>
      }

      {
        checkFeaturePermissions(RolesAndPermissions.INVESTOR_REPORTS, FeatureAccess.READ) &&
        <Accordion style={accordionStyle} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={11} style={{
                textTransform: 'uppercase', fontWeight: '500', letterSpacing: '1.4px'
              }}>
                Investor Reporting
              </Grid>

            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item>

              {
                foldersList ?
                  <DocumentsDasboard isfileUpload={false} reports={foldersList} isheadingrequired={true} setFoldersList={setFoldersList} documentsFor={DocumentsGETType.FUND} /> :
                  "No Data Available."
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      }
    </div>
  )
}

export default ServicingAccordians