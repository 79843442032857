import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, MenuItem, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { sellDetails } from '../../../APIDataTemplate';
import { createTransaction, getTotalBuyQuantity } from '../../../services/services';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { LoadingButton } from '@mui/lab';
import DateField from '../../../../common/input-fields/DateField';


const StockSell = (props) => {

  //#region props
  const { shareTypes, transactionTypes, getAllTransactions } = props;

  //#region variables
  const [loading, setLoading] = useState(false);
  const [availableQty, setAvailableQuantity] = useState();

  //#region functions
  const calculateTotalTransactionCost = () => {
    let quantity = sellingValues.values.quantity === '' ? 0 : sellingValues.values.quantity;
    let sharePrice = sellingValues.values.sharePrice === '' ? 0 : sellingValues.values.sharePrice;
    let transactionCost = sellingValues.values.transactionCost === '' ? 0 : sellingValues.values.transactionCost;
    const totalTransactionCost = (parseInt(quantity) * parseInt(sharePrice)) - parseInt(transactionCost)
    return totalTransactionCost || 0;
  };

  //#region change events 
  const handleChange = (name, value) => {
    if (name === 'sharePrice') {
      let quantity = sellingValues.values.quantity === '' ? 0 : sellingValues.values.quantity;
      let sharePrice = value === '' ? 0 : value;
      let transactionCost = sellingValues.values.transactionCost === '' ? 0 : sellingValues.values.transactionCost;
      const amount = (parseFloat(quantity) * parseFloat(sharePrice)) - parseFloat(transactionCost)
      sellingValues.setFieldValue('amount', Math.round(amount * 100) / 100);
    }
    if (name === 'amount') {
      let quantity = sellingValues.values.quantity === '' ? 0 : sellingValues.values.quantity;
      let amount = value === '' ? 0 : value;
      let transactionCost = sellingValues.values.transactionCost === '' ? 0 : sellingValues.values.transactionCost;
      const sharePrice = quantity === 0 ? 0 : (parseFloat(amount) + parseFloat(transactionCost)) / parseFloat(quantity);
      sellingValues.setFieldValue('sharePrice', Math.round(sharePrice * 100) / 100);
    }
    if (name === 'transactionCost') {
      let quantity = sellingValues.values.quantity === '' ? 0 : sellingValues.values.quantity;
      let sharePrice = sellingValues.values.sharePrice === '' ? 0 : sellingValues.values.sharePrice;
      let transactionCost = value === '' ? 0 : value;
      const amount = (parseFloat(quantity) * parseFloat(sharePrice)) - parseFloat(transactionCost)
      sellingValues.setFieldValue('amount', Math.round(amount * 100) / 100);
    }
    if (name === 'quantity') {
      let quantity = value === '' ? 0 : value;
      let sharePrice = sellingValues.values.sharePrice === '' ? 0 : sellingValues.values.sharePrice;
      let transactionCost = sellingValues.values.transactionCost === '' ? 0 : sellingValues.values.transactionCost;
      const amount = (parseFloat(quantity) * parseFloat(sharePrice)) - parseFloat(transactionCost)
      sellingValues.setFieldValue('amount', Math.round(amount * 100) / 100);
    }
    if (name === "shareTypeID") {
      getAvailableQuantity(value);
    }
    sellingValues.setFieldValue(name, value)
  }

  //#region api get calls
  const getAvailableQuantity = async (value) => {
    const companyID = secureLocalStorage.getItem("companyID");
    if (value > 0) {
      const data = await getTotalBuyQuantity(companyID, value);
      if (data.responseCode === 200) {
        setAvailableQuantity(data.responseData);
      }
      else {
        //console.log('unable to fetch data)
      }
    }
  }

  //#region api post calls
  const executeSellTransaction = async () => {
    if (availableQty <= 0) {
      toast.warning("You don't have a sufficient quantity available for sell.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      return;
    }
    setLoading(true);
    const companyID = secureLocalStorage.getItem("companyID");
    sellingValues.values.companyID = companyID;

    //convert string to integers
    // sellingValues.values.quantity = parseFloat(sellingValues.values.quantity);
    // sellingValues.values.sharePrice = parseFloat(sellingValues.values.sharePrice);
    // sellingValues.values.transactionCost = parseFloat(sellingValues.values.transactionCost);
    // sellingValues.values.amount = parseFloat(sellingValues.values.amount);

    //Transaction Type --Buy
    const sellTransaction = transactionTypes.find((trans) => trans.listItemValue.toLowerCase() === "sell")
    sellingValues.values.transactionTypeID = sellTransaction.listItemID;
    sellingValues.values.transactionTypeName = sellTransaction.listItemValue;

    if (sellingValues.values.quantity > availableQty) {
      setLoading(false);
      toast.warning("Number Of Shares cannot be more than current holdings.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else {
      const data = await createTransaction(companyID, sellingValues.values);
      if (data.responseCode === 200) {
        /*  setShareTypes(data.responseData); */
        setLoading(false);
        getAllTransactions();
        getAvailableQuantity(sellingValues.shareTypeID);
        sellingValues.resetForm();
        toast.success("New Transaction Completed Succesfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      }
      else {
        setLoading(false);
        toast.warning("Unable to Create Transaction", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      }
    }
  }

  //#region useeffect
  useEffect(() => {
    getAvailableQuantity(0);
  }, [])

  //#region formik validations
  const sellingValidationSchema = yup.object().shape({
    transactionDate: yup.string().required('This Field is required'),
    shareTypeID: yup.string().required('This Field is required'),
    quantity: yup.string().required('This Field is required')
      .test('is-positive', 'Number of Shares must be a positive value.', value => {
        if (!value) return true;
        return parseFloat(value) >= 0;
      }),
    sharePrice: yup.string().required('This Field is required')
      .matches(/^\d+(\.\d{1,2})?$/, 'Share Price accepts only 2 decimal values.')
      .test('is-positive', 'Share Price must be a positive value.', value => {
        if (!value) return true;
        return parseFloat(value) >= 0;
      }),
    transactionCost: yup.string().required('This Field is required')
      .matches(/^\d+(\.\d{1,2})?$/, 'Transaction Cost accepts only 2 decimal values.')
      .test('is-positive', 'Transaction Cost must be a positive value.', value => {
        if (!value) return true;
        return parseFloat(value) >= 0;
      }),
    amount: yup.string()
      .required('This Field is required.')
      .matches(/^\d+(\.\d{1,2})?$/, 'Total Sell Amount accepts only 2 decimal values.')
      .test('is-positive', 'Total Sell Amount must be a positive value.', value => {
        if (!value) return true; // Allow empty value as it's handled by 'required'
        return parseFloat(value) >= 0;
      }),
  });

  const onResetClick = () => {
    sellingValues.resetForm();
  }

  const sellingValues = useFormik({
    initialValues: {
      ...sellDetails
    },
    validationSchema: sellingValidationSchema,
    onSubmit: (values) => {
      executeSellTransaction();
    },
  });

  //#region return
  return (
    <div>
      <form className='child-margin-15' onSubmit={sellingValues.handleSubmit} novalidate="novalidate">
        <div className='width-percent-30'>
          <DateField
            name="transactionDate"
            label="Date"
            value={sellingValues.values.transactionDate || []}
            onChange={(name, value) => handleChange(name, value)}
            onBlur={sellingValues.handleBlur}
            error={sellingValues.touched.transactionDate && Boolean(sellingValues.errors.transactionDate)}
            errorMessage={sellingValues.touched.transactionDate && sellingValues.errors.transactionDate} />
        </div>
        <div className='width-percent-30'>
          <TextField
            select
            id="shareTypeID"
            name="shareTypeID"
            label="Share Type"
            variant='standard'
            required={true}
            fullWidth
            value={sellingValues.values.sectorId}
            // onChange={sellingValues.handleChange}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={sellingValues.handleBlur}
            error={sellingValues.touched.shareTypeID && Boolean(sellingValues.errors.shareTypeID)}
            helperText={sellingValues.touched.shareTypeID && sellingValues.errors.shareTypeID}>
            {shareTypes?.length > 0 && shareTypes?.map((item) => (
              <MenuItem key={item.listItemID} value={item.listItemID}>
                {item.listItemValue}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className='width-percent-30'>
          <TextField
            id="quantity"
            name="quantity"
            label="Number of Shares"
            variant='standard'
            required={true}
            fullWidth
            value={sellingValues.values.quantity}
            // onChange={sellingValues.handleChange}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={sellingValues.handleBlur}
            error={sellingValues.touched.quantity && Boolean(sellingValues.errors.quantity)}
            helperText={sellingValues.touched.quantity && sellingValues.errors.quantity} />
          <div className='margin-top-5'>
            Available Quantity : {availableQty ? availableQty : 0}
          </div>
        </div>
        <div className='width-percent-30'>
          <TextField
            id="sharePrice"
            name="sharePrice"
            label="Share Price"
            variant='standard'
            required={true}
            fullWidth
            value={sellingValues.values.sharePrice}
            // onChange={sellingValues.handleChange}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={sellingValues.handleBlur}
            error={sellingValues.touched.sharePrice && Boolean(sellingValues.errors.sharePrice)}
            helperText={sellingValues.touched.sharePrice && sellingValues.errors.sharePrice} />
        </div>
        <div className='width-percent-30'>
          <TextField
            id="transactionCost"
            name="transactionCost"
            label="Transaction Cost"
            variant='standard'
            required={true}
            fullWidth
            value={sellingValues.values.transactionCost}
            // onChange={sellingValues.handleChange}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={sellingValues.handleBlur}
            error={sellingValues.touched.transactionCost && Boolean(sellingValues.errors.transactionCost)}
            helperText={sellingValues.touched.transactionCost && sellingValues.errors.transactionCost} />
        </div>
        <div className='width-percent-30'>
          <TextField
            id="amount"
            name="amount"
            label="Total Sell Amount"
            variant='standard'
            required={true}
            fullWidth
            value={sellingValues.values.amount}
            // onChange={sellingValues.handleChange}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={sellingValues.handleBlur}
            error={sellingValues.touched.amount && Boolean(sellingValues.errors.amount)}
            helperText={sellingValues.touched.amount && sellingValues.errors.amount} />
        </div>
        <div className='space-between width-percent-30'>
          <div></div>
          <div className='space-between'>
            <div>
              <Button
                label="Cancel"
                variant="outlined"
                className="btn-primary"
                onClick={onResetClick}
                startIcon={<CancelIcon />}>Cancel</Button>
            </div>
            <div className='margin-left-10'>
              <LoadingButton
                variant='contained'
                className="btn-primary"
                startIcon={<SaveIcon />}
                loading={loading}
                type='submit'
                loadingPosition="start">
                <p className={`actionButtonLabel`}>SAVE</p>
              </LoadingButton>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}

export default StockSell