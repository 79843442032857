import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { fundTypesEnum } from '../../jsonData';

const InvestmentFilter = (props) => {

  //#region props
  const { filterDetails, filterOptions, setFilterOptions, setActiveButton, currType, selectedFundType } = props;

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  //#region click events
  const onResetClick = () => {
    investmentValues.resetForm();
    setActiveButton(null);
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      minimumInvestmentOptions: {
        ...filterOptions[filterDetails?.apiName],
        minimumvalue: -1,
        maximumvalue: -1
      }
    }));
  }

  //#region formik validations
  const InvestmentvalidationSchema = yup.object().shape({
    minimum: yup
      .number('Enter a valid number')
      .positive('Minimum value must be positive')
      .integer('Minimum value must be an integer')
      .min(0, 'Minimum value must be greater than or equal to zero') // Changed to min
      .required('Minimum value is required'),
    maximum: yup
      .number('Enter a valid number')
      .positive('Maximum value must be positive')
      .integer('Maximum value must be an integer')
      .min(yup.ref('minimum'), 'Maximum value must be greater than or equal to minimum value') // Changed to min
      .required('Maximum value is required')
  });


  const investmentValues = useFormik({
    initialValues: {
      minimum: filterOptions[filterDetails.apiName].minimumvalue === -1 ? '' : filterOptions[filterDetails.apiName].minimumvalue,
      maximum: filterOptions[filterDetails.apiName].maximumvalue === -1 ? '' : filterOptions[filterDetails.apiName].maximumvalue,
    },
    validationSchema: InvestmentvalidationSchema,
    onSubmit: (values) => {
      handleApplyClick(values);
    },
  });

  useEffect(() => {
    if (investmentValues.isValid && investmentValues.dirty) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [investmentValues.isValid, investmentValues.dirty]);

  const handleApplyClick = (values) => {
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      minimumInvestmentOptions: {
        ...filterOptions[filterDetails?.apiName],
        minimumvalue: parseInt(values.minimum),
        maximumvalue: parseInt(values.maximum)
      }
    }));
  }

  //#region TODO : we need to define new classes for every filters, this is temprory solution
  const renderfilterLeftAllignonFundType = () => {
    if (
      filterDetails.fundtypes.includes(fundTypesEnum.PRIVATE_EQUITY) &&
      filterOptions?.fundType.find((option) => option.value === selectedFundType)?.label === fundTypesEnum.PRIVATE_EQUITY
    ) {
      if (filterDetails.id === 5) {
        return 'left-614';
      } else if (filterDetails.id === 6) {
        return 'left-793';
      } else {
        return '';
      }
    } else if (
      filterDetails.fundtypes.includes(fundTypesEnum.REAL_ESTATE_FUND) &&
      filterOptions?.fundType.find((option) => option.value === selectedFundType)?.label === fundTypesEnum.REAL_ESTATE_FUND
    ) {
      if (filterDetails.id === 5) {
        return 'left-299';
      } else if (filterDetails.id === 6) {
        return 'left-656';
      } else {
        return '';
      }
    } else if (
      filterDetails.fundtypes.includes(fundTypesEnum.VENTURE_CAPITAL) &&
      filterOptions?.fundType.find((option) => option.value === selectedFundType)?.label === fundTypesEnum.VENTURE_CAPITAL
    ) {
      if (filterDetails.id === 5) {
        return 'left-474';
      } else if (filterDetails.id === 6) {
        return 'left-656';
      } else {
        return '';
      }
    } else {
      return 'left-197'
    }
  };


  return (
    <div className={currType === fundTypesEnum.PRIVATE_EQUITY ? `ul-options-left-4 ${renderfilterLeftAllignonFundType()}` : `ul-options-left-pc-4 ${renderfilterLeftAllignonFundType()}`}>
      <div className='space-between'>
        <p className='filter-matter margin-top-5 margin-left-10'>Enter your investment amount</p>
        <p className='reset-button margin-top-5 margin-right-5' onClick={onResetClick}>RESET</p>
      </div>
      <form onSubmit={investmentValues.handleSubmit}>
        <div className='display-row margin-left-10 space-between'>
          <div className='margin-right-5'>
            <TextField
              id="minimum"
              name="minimum"
              label="Min"
              value={investmentValues.values.minimum}
              onChange={(event) => investmentValues.setFieldValue('minimum', event.target.value.replace(/\D/g, ''))}
              onBlur={investmentValues.handleBlur}
              error={investmentValues.touched.minimum && Boolean(investmentValues.errors.minimum)}
              helperText={investmentValues.touched.minimum && investmentValues.errors.minimum}
              inputProps={{ inputMode: 'numeric' }}
            />
          </div>
          <div className='margin-right-5'>
            <TextField
              id="maximum"
              name="maximum"
              label="Max"
              value={investmentValues.values.maximum}
              onChange={(event) => investmentValues.setFieldValue('maximum', event.target.value.replace(/\D/g, ''))}
              onBlur={investmentValues.handleBlur}
              error={investmentValues.touched.maximum && Boolean(investmentValues.errors.maximum)}
              helperText={investmentValues.touched.maximum && investmentValues.errors.maximum}
              inputProps={{ inputMode: 'numeric' }}
            />
          </div>
        </div>
        <div className='space-between margin-top-10 margin-bottom-5'>
          <div></div>
          <div className='margin-right-5'>
            <Button
              label="APPLY"
              variant="contained"
              className="btn-primary"
              type="submit"
              disabled={isButtonDisabled}>
              APPLY
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default InvestmentFilter;
