import React, { useEffect , useRef} from 'react'
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import BasicTab from '../../../administration/TabComponent';
import { useState } from 'react';
import { ReportsNames } from '../jsonData';
import FileUploadButton from '../../../common/FileUploadButton/FileUploadButton';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import FoldersAccordian from './FoldersAccordian';
import AddNewDocument from '../Popups/AddNewDocument';
import { getRepotingDocuments } from '../services/services';
import { ToastContainer, toast } from 'react-toastify';
import { DocumentsGETType } from '../../InvestorReportingEnum';
import { getInvetsorRepotingDocuments } from '../services/services';
import { getRepotingDocumentsbyFundID } from '../services/services';
import secureLocalStorage from 'react-secure-storage';
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader';

const DocumentsDasboard = (props) => {

  //#region props
  const { isfileUpload,reports,setFoldersList,getFoldersDetails,isheadingrequired,documentsFor,componentRefresh,setcomponentRefresh} = props;

  //#region variables
  const [currenttab, setCurrentTab] = useState(0);
  const [adddocumentpopup,setAddDocumentPopup] = useState(false);
  const [tabs, setTabs] = useState(reports);

  //#region click events
  const onOpenPopup = () =>{
    setAddDocumentPopup(true);
  }
  const onClose = () =>{
    setAddDocumentPopup(false)
  }

  //#region api get calls
  const getAccordianData = async (id) => {
    // debugger
    const updatedFoldersList = [...reports];
    if(updatedFoldersList?.length > 0){
      const getDocuments = async (documentID) => {
        let data;
        if (documentsFor === DocumentsGETType.FIRM_USERS) {
          data = await getRepotingDocuments(documentID);//for firm users
        } else if (documentsFor === DocumentsGETType.INVESTOR) {
          data = await getInvetsorRepotingDocuments(documentID);//for investors
        } else if (documentsFor === DocumentsGETType.FUND) {
          const currFundID = secureLocalStorage.getItem("FundId");
          data = await getRepotingDocumentsbyFundID(currFundID,documentID);//for fundView
        }
        else{
          data = await getRepotingDocuments(documentID);
        }
        if (data?.responseCode === 200) {
          updatedFoldersList[currenttab].relatedFiles = data?.responseData;
          updatedFoldersList[currenttab].component = <FoldersAccordian reports={data?.responseData} isfileUpload={isfileUpload} refreshAccordians={getAccordianData}/>
          setFoldersList(updatedFoldersList);
        } else { 
          updatedFoldersList[currenttab].relatedFiles = [];
          updatedFoldersList[currenttab].component = <FoldersAccordian reports={[]} isfileUpload={isfileUpload} refreshAccordians={getAccordianData}/>
          setFoldersList(updatedFoldersList);
        }
      }
  
      getDocuments(updatedFoldersList[currenttab]?.documentTypeID);
    }
  }

  //#region useEffect
  useEffect(()=> {
    if(reports.length > 0){
      getAccordianData();
    }
  },[currenttab])

  return (
    <div className=''>
         {
            !isheadingrequired && 
            <Grid item xs={12}>
              {/* <div className='space-between'>
                  <h1>Investor Reporting</h1>
                <div>
                  {
                    isfileUpload && 
                    <Button variant="outlined"
                        className="btn-primary" 
                        style={{ cursor: 'pointer'}} 
                        onClick={onOpenPopup}>
                        <AddIcon/>
                        <span>Add Document</span>
                    </Button>
                  }
                </div>
              </div> */}
              <PageHeader
                title="Investor Reporting"
                template={HeaderTemplates.NORMAL_VIEW}
                isHideBackbtn={true}
                isShowButton={isfileUpload ? true : false}
                btnIcon={ <AddIcon/>}
                className="btn-primary"
                btnLabel = "Add Document"
                onBtnClick={onOpenPopup}/>
            </Grid>
         }
          <Box sx={{ flexGrow: 1 }} overflowx={'auto'}>
            <Grid container>
              <Grid container>
                 </Grid>
                 </Grid>
                 <div className='wrapper margin-top-20'>
                  <Grid container xs={12} spacing={1} marginTop={1}>
                    {/* <Grid item xs={12} marginLeft={17}>
                        <Button variant="contained"
                              className="btn-primary" 
                              style={{ cursor: 'pointer'}} 
                              onClick={() => {}}>
                              <AddIcon/>
                              <span>Add Folder</span>
                          </Button>
                      </Grid> */}
                    {
                      reports?.length > 0 ? 
                      <Grid item xs={12} className='vTabs_L'>
                          <BasicTab
                            tabs={tabs}
                            currentTab={currenttab}
                            setCurrentTab={setCurrentTab}
                            //backgroundcolor={true}
                            isVertical='vertical' />
                      </Grid> : 
                      <b className='loading-container'>
                        No Data Available
                      </b>
                    }
                  </Grid>
                 </div>
          </Box>
          {adddocumentpopup && 
               <AddNewDocument 
               setCurrentTab={setCurrentTab}
               open={adddocumentpopup} 
               onClose={onClose}
               componentRefresh = {componentRefresh}
               setcomponentRefresh = {setcomponentRefresh} 
               reports={reports} 
               currDocumentType={tabs[currenttab]?.documentTypeID}
               refreshFolders={getFoldersDetails}
               refreshAccordians={getAccordianData}/>}
        <ToastContainer />
    </div>
  )
}

export default DocumentsDasboard