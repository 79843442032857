import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'
import AppNotifications from '../../common/AppNotification/AppNotificationComponent'
import { checkFeaturePermissions } from '../../../utils/common'
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum'
import FundNews from '../../common/FundNews/FundNews'
import { GetSuggestedArticlesByInvestorID } from '../../FundManagerDashboard/Services/FundNewsService'
import InvestorInvestmentsGrid from './InvestorInvestmentsGrid'

const InvestorDashboard = () => {

  //#region variables
  const userID = secureLocalStorage.getItem("userId")
  const [fundNews, setFundNews] = useState();

  //#region api get calls
  const getFundNews = async () => {
    const data = await GetSuggestedArticlesByInvestorID(userID);
    if (data.responseCode === 200) {
      setFundNews(data.responseData);
    }
    else {
    }
  }

  //#region useeffect
  useEffect(() => {
    getFundNews();
  }, [])

  //#region return
  return (
    <Box sx={{ flexGrow: 1 }} className='wrapper mt2-page'>
      <Grid container>
        <Grid xs={12} className=" font-size-xsmall font-size-color welcomeuser">
          Welcome back, {secureLocalStorage.getItem("fullName")}
        </Grid>
        <Grid xs={12} className="heading-dashboard font-size-colorblack margin-bottom-15">
          Investor Dashboard
        </Grid>
        <Grid container spacing={2} className='margin-bottom-20'>
          <Grid item xs={12} md={6}>
            <div className="white-card">
              <h6>Notifications</h6>
              <div className='height-500'>
                {
                  checkFeaturePermissions(RolesAndPermissions.FUND_SUBSCRIPTION, FeatureAccess.READ) &&
                  <AppNotifications crntFundID={0} crntInvestorid={secureLocalStorage.getItem("userId")} />
                }
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} >
            <div className='white-card height-560'>
              <FundNews FundNewsData={fundNews} role={"FundManager"} FundNewsByUserID={true} />
            </div>
          </Grid>
        </Grid >
        <Grid container xs={12} className='margin-top-15'>
          <InvestorInvestmentsGrid investorID={secureLocalStorage.getItem("userId")} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default InvestorDashboard