import { useEffect, useState } from "react";
import styles from '../../../user-profile/FundManagerUserProfile.module.css'
import { ToastContainer, toast } from 'react-toastify';
import TextInput from "../../../common/TextInput/TextInput";
import { Grid } from "@mui/material";
import OBQPopup from "../../../user-profile/InvestorUserProfile/OBQPopup";
import { getInvestorUserProfile, getobqQuestionOptions } from "../../../user-profile/services/UserProfileService";
import secureLocalStorage from "react-secure-storage";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';
import FileUploadBtn from "../../../common/FileUploadButton/FileUploadBtn";
import { uploadFileTypes } from "../../../common/FileUploadButton/uploadComponentenum";
import { InvestorTypesEnum } from "../../services/apiDataTemplate";
import ALTTextField from "../../../common/input-fields/ALTTextField";
import { textFormatTypeEnum } from "../../../common/TextInput/appInputenum";
import { getOnbordingQuestionPatterns } from "../../../administration/InvestorOnBoardingQues/Services/OBQServices";
import { OnboardingQuestionPattern } from "../../../administration/InvestorOnBoardingQues/OnBoardingEnum";

const InvestmentProfile = (props) => {

  //#region variables
  const { investorDetails, getInvestorInfo, investorTypesValues, isViewOnly } = props;

  //#region variables
  const [investmentData, setinvestmentData] = useState();
  const [userProfileData, setUserProfileData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupdetails, setPopUpDetails] = useState();
  const [popupoptions, setPopUpOptions] = useState();
  const [isSaveEnabled, setSaveEnabled] = useState(false);
  const [mcqType, setMcqType] = useState(null);
  const [textType, setTextType] = useState(null);

  //#region click events
  const onPopupClick = async (details, options) => {

    if (isViewOnly) {
      return;
    }

    try {
      const response = await getobqQuestionOptions(details);
      /*   */
      if (response?.responseCode === 200) {
        setPopUpOptions(response?.responseData);
      }
      else {
        throw new Error('Network response was not ok');
        setPopUpOptions("");
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }

    setShowPopup(true);
    setPopUpDetails(details)
    console.log(popupdetails)
  }

  const handlePopUpCancel = () => {
    // Close the popup without removing the item
    setShowPopup(false);
  };

  //#region api get calls
  const getUserProfileDetails = async () => {
    const data = await getInvestorUserProfile(secureLocalStorage.getItem("userEmail"));
    if (data.responseCode === 200) {
      setUserProfileData(data?.responseData);
    }
    else {
      setUserProfileData(null);
    }
  }

  const getOnbordingQuestionOptions = async () => {
    const data = await getOnbordingQuestionPatterns();
    if (data?.responseCode === 200) {
      const questionPattrenData = data?.responseData?.map((item) => {
        return {
          value: item?.listItemID,
          label: item?.listItemValue,
        };
      });
      setMcqType(questionPattrenData?.find(item => item?.label === OnboardingQuestionPattern.MCQ_TYPE)?.value);
      setTextType(questionPattrenData?.find(item => item?.label === OnboardingQuestionPattern.TEXT_TYPE)?.value);
    }
    else {
      /* console.log('error in getOnbordingQuestionPatterns:', data?.responseData); */
    }
  }

  useEffect(() => {
    getOnbordingQuestionOptions();
  }, [])

  //#region useeffect
  useEffect(() => {
    getUserProfileDetails();
  }, [])

  useEffect(() => {
    setinvestmentData(investorDetails?.investmentProfile)
  }, [investorDetails])

  //#region component functions
  const renderInvestmentDetail = (shortName, answer, item) => {
    return (
      <div className={styles.investmentDetailWrapper}>
        <div>
          {/* {<span className="requiredField">{shortName}</span>} */}
          {shortName}
        </div>
        <div className={styles.flexSpaceBetween}>
          <div className={styles.contentContainer}>
            {item.multipleAnswers ?
              (item.answerValue ? item.answerValue : null) : (
                item.answer ? item.answer : null
              )}
          </div>
          {
            props.fromFm ? null : <EditOutlinedIcon /* sx={{marginTop: '-10px'}} */ />
          }
        </div>
      </div>
    )
  }

  //#region return
  return (
    <div className={props.fromFm ? 'investmentprofile-fm' : ''}>
      {
        <div>
          {
            props.fromFm ? null :
              <Grid item xs={12} marginBottom={2}>
                <div className='space-between'>
                  <div>

                  </div>
                  <div className='space-between'>
                    {/* <div style={{ marginRight: '5px' }}>
                      {
                        isSaveEnabled &&
                        <ActionButton variant='outlined' className='btn-primary'
                          onClick={() => onCancel()} 
                          label="Cancel"
                          icon={<CancelIcon />}>
                         
                        </ActionButton>
                      }

                    </div>
                    <div>
                      <ActionButton variant='contained' className='btn-primary' 
                        icon={isSaveEnabled ? <SaveIcon /> : <EditIcon />}
                        onClick={() => onEditSaveButtonClick()} 
                        label={isSaveEnabled ? 'Save' : 'Edit'} 
                       >
                      </ActionButton>
                    </div> */}
                  </div>
                </div>
              </Grid>
          }

          <Box>

            {
              props.fromFm ? null :
                <Grid container spacing={2} xs={12} marginTop={1} alignItems="center">

                  <Grid item xs={12} sm={6} md={0.7} lg={1} marginTop={-1} marginLeft={1}>

                    {/*  <img
                      className={userprofilestyles.avatarIcons}
                      alt=""
                      src={investorDetails?.personalInformation?.profileIcon  ? investorDetails?.personalInformation?.profileIcon  : '/icons/defaultpropic.jpg'}
                      /> */}
                    <FileUploadBtn
                      name="selectedImage"
                      label="UPLOAD PROFILE ICON"
                      defaultFile={investorDetails?.personalInformation?.profileIcon ? investorDetails?.personalInformation?.profileIcon : '/icons/defaultpropic.jpg'}
                      fileType={uploadFileTypes.IMAGE}
                      readOnly={true}
                      fromWhiteLabeling={isSaveEnabled ? true : false}
                      isSize={true} />
                  </Grid>

                  <>
                    {
                      investorTypesValues?.
                        find((item) => item?.listItemID === investorDetails?.personalInformation?.investorTypeID)?.listItemValue
                        === InvestorTypesEnum.INDIVIDUAL_INVESTOR
                        ? <>
                          <Grid item lg='4' md='4' sm='6' xs='12'>
                            <ALTTextField
                              textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                              name="firstName"
                              label="First Name"
                              value={investorDetails?.personalInformation?.firstName}
                              onChange={() => { }}
                              disabled={true} />
                          </Grid>
                          <Grid item lg='4' md='4' sm='6' xs='12'>
                            <ALTTextField
                              textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                              name="lastName"
                              label="Last Name"
                              value={investorDetails?.personalInformation?.lastName}
                              onChange={() => { }}
                              disabled={true} />
                          </Grid>
                        </>
                        : <>
                          <Grid item lg='4' md='4' sm='6' xs='12'>
                            <ALTTextField
                              name="firstName"
                              label="Legal Entity Name"
                              value={investorDetails?.personalInformation?.firstName}
                              onChange={() => { }}
                              disabled={true} />
                          </Grid>
                        </>
                    }
                  </>

                </Grid>
            }
            <Grid container xs={12} alignItems="center">
              {investmentData?.map((item, index) => (
                <Grid xs={12} sm={6} md={4} onClick={() => onPopupClick(item, item.questionID)}>
                  {renderInvestmentDetail(item?.shortName, item?.answer, item)}
                </Grid>
              ))}
            </Grid>

            {/* {showPopup && (
                        <UserProfilePopUp PopupQuestion={popupdetails} PopupOptions={popupoptions} onCancel={handlePopUpCancel} investment={investmentData}
                          onOptionSelected={(index, option) => { handleOptionSelected(index, option) }}
                        />
                      )} */}

            {showPopup && (
              <OBQPopup data={popupdetails} investmentData={investmentData} setData={setinvestmentData}
                onCancel={handlePopUpCancel} popupoptions={popupoptions}
                open={showPopup} userProfileData={userProfileData} getUserProfileDetails={getUserProfileDetails}
                getInvestorInfo={getInvestorInfo}
                mcqType={mcqType}
                textType={textType}
              />
            )}

          </Box>
          <ToastContainer />
        </div>

      }
    </div>
  )
}

export default InvestmentProfile