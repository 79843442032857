import React from "react";
import { HeaderTemplates, PageHeader } from "../../common/PageHeader/PageHeader";
import secureLocalStorage from "react-secure-storage";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { useSelector } from "react-redux";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { BoxbackgroundWhite } from "../../CommonCss";
import { Box } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { CurrencyFormatter } from "../../../utils/Formater";
import { DataGrid } from "@mui/x-data-grid";
import AltDataGridPro from "../../common/DataGridPro/AltDataGridPro";

function ExecutiveSummaryReports() {
    const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);

    const [data, setData] = useState(null);

    useEffect(() => {
    }, []);

    const firstrows = data ? data.executiveReportData.map(rows => ({
        id: rows.executiveReportID,
        header: rows.erProperty,
        value: CurrencyFormatter(rows.erPropertyValue),

    })).slice(0, 3)
        : [];

    const columns = [
        { field: 'header', headerName: 'General Partner', width: 600 },
        { field: 'value', headerName: 'YTD: DECEMBER 31, 2022', width: 250 },
    ];
    const rows = [
        { id: 1, header: 'Assets Under Management', value: '$12,000,000,000' },
        { id: 2, header: 'Active Funds', value: '8' },
        { id: 3, header: 'Active Portfolio Companies', value: '212' },
    ];
    const secondrows = data ? data.executiveReportData.map(rows => ({
        id: rows.executiveReportID,
        header: rows.erProperty,
        value: CurrencyFormatter(rows.erPropertyValue),



    })).slice(4, 15)
        : [];
    const Fundrows = [
        { id: 1, header: 'Total Commitments (Commenced on Jan. 5 2007)', value: '$858,300,000' },
        { id: 2, header: 'Total Drawdowns Since Inception', value: '$684,700,000' },
        { id: 3, header: 'Remaining Commitments', value: '$173,600,000' },
        { id: 4, header: 'Total Number of Investments Since Inception', value: '17' },
        { id: 5, header: 'Number of Portfolio Companies at Dec. 31, 2022 ', value: '17' },
        { id: 6, header: '% of Total Drawdowns', value: '32%' },
        { id: 7, header: '% of Committed Capital', value: '25%' },
        { id: 8, header: 'Key Fund Valuation Metrics', value: ' ' },
        { id: 9, header: 'DPI (Distribution to Paid-in-Capital)', value: '0.3x' },
        { id: 10, header: 'RVPI (Residual Value to Paid-in-Capital)', value: '0.9x' },
        { id: 11, header: 'TVPI (Total Value to Paid-in-Capital)', value: '1.2x' },

    ];


    return (
        <div style={{ backgroundColor: rootBackColorConfig.color }} className="page">
            <PageHeader
                template={HeaderTemplates.Standard}
                fundName={secureLocalStorage.getItem("FundName")}
                headerImage={secureLocalStorage.getItem("selectedFund")?.IconImage}
                customBackgroundImage={secureLocalStorage.getItem("selectedFund")?.BGImage ? secureLocalStorage.getItem("selectedFund")?.BGImage : '/DefaultBackgroundImage.png'}

            />
            <Grid item container xs={12} marginTop={'20px'} fontSize={'24px'} textAlign={'flex-end'}>
                Portfolio Executive Summary
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item container xs={12} marginTop={'50px'} width={'95%'} marginLeft={'30px'} spacing={2} sx={BoxbackgroundWhite}>
                        <Grid item xs={12}>
                            Executive Summary - Firm and Fund Level Data
                        </Grid>
                        <Grid item xs={12}>
                            <AltDataGridPro
                                rows={firstrows}
                                columns={columns}
                                disableColumnMenu
                                autoHeight
                                // rowHeight={rowHeight}
                                pagination
                                pageSize={rows.length}
                                components={{
                                    Footer: () => null, // Hide the footer containing pagination and rows per page
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            Next Health Equity Fund
                        </Grid>
                        <Grid item xs={12}>
                            <AltDataGridPro
                                rows={secondrows}
                                columns={columns}
                                disableColumnMenu
                                autoHeight
                                // rowHeight={rowHeight}
                                pagination
                                pageSize={Fundrows.length}
                                components={{
                                    Footer: () => null, // Hide the footer containing pagination and rows per page
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )

}

export default ExecutiveSummaryReports