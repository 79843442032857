import moment from "moment/moment";

const timeconvertion = (dateTime, sourceTimeZone) => {

  // Convert dateTime from sourceTimeZone to targetTimeZone 
  const convertedTime = moment.tz(dateTime, sourceTimeZone).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');
  return convertedTime;

};

export default timeconvertion;