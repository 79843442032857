import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DataGridPro } from "@mui/x-data-grid-pro";

const AltDataGridPro = ({
    columns,
    rows,
    loading,
    pageSize,
    onPageSizeChange,
    onRowClick,
    checkboxSelection,
    disableSelectionOnClick,
    paginationMode,
    hideFooter,
    sortingMode,
    filterModel,
    onFilterModelChange,
    onRowSelectionModelChange,
    rowSelectionModel,
    onSortModelChange,
    rowHeight,
    onCellClick,
    styleProps,
    headerHeight,
    columnVisibilityModel,
    onColumnVisibilityModelChange,
    disableRowSelectionOnClick,
    rowReordering,
    onRowOrderChange,
    localeText,
    isRowSelectable,
    disableColumnMenu,
    ...otherProps
}) => {
    const noRowsLabel =  "No Data Available";
    const noResultsOverlayLabel =
         "No Data Available";
    //To Do : Remove this post MUI License
    useEffect(() => {
       // Find all divs with the text "Open new page"
       const divs = Array.from(document.querySelectorAll("div"));
       divs.forEach((div) => {
         if (div.textContent === "MUI X Missing license key") {
            div.textContent = ""; // Replace the content
         }
       });
     }, [rows]);
         
    return (
        <DataGridPro
            rows={rows}
            columns={columns}
            loading={loading}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onRowClick={onRowClick}
            checkboxSelection={checkboxSelection}
            disableSelectionOnClick={disableSelectionOnClick}
            paginationMode={paginationMode}
            sortingMode={sortingMode}
            hideFooter={hideFooter}
            filterModel={filterModel}
            onCellClick={onCellClick}
            isRowSelectable={isRowSelectable}
            disableRowSelectionOnClick={disableRowSelectionOnClick}
            disableMultipleRowSelection={true}
            rowSelectionModel={rowSelectionModel}
            sx={{ styleProps }}
            onFilterModelChange={onFilterModelChange}
            onSortModelChange={onSortModelChange}
            onRowSelectionModelChange={onRowSelectionModelChange}
            rowHeight={rowHeight}
            headerHeight={headerHeight}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            rowReordering={rowReordering}
            onRowOrderChange={onRowOrderChange}
            localeText={{
                ...localeText,
                noRowsLabel, // Displayed when rows are empty and no filter is applied
                noResultsOverlayLabel, // Displayed when filter yields no results
            }}
            disableColumnMenu={disableColumnMenu}
            {...otherProps}
        />
    );
};

// Define default props for the component
AltDataGridPro.defaultProps = {
    rows: [],
    columns: [],
    loading: false,
    pageSize: 5,
    styleProps: '',
    hideFooter: true,
    checkboxSelection: false,
    disableSelectionOnClick: true,
    paginationMode: "client", // client or server
    sortingMode: "client", // client or server
    rowHeight: 52, // Default row height
    headerHeight: 56, // Default header height
};

// Add prop types to help with validation
AltDataGridPro.propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    pageSize: PropTypes.number,
    onPageSizeChange: PropTypes.func,
    onRowClick: PropTypes.func,
    checkboxSelection: PropTypes.bool,
    hideFooter: PropTypes.bool,
    disableSelectionOnClick: PropTypes.bool,
    paginationMode: PropTypes.oneOf(["client", "server"]),
    sortingMode: PropTypes.oneOf(["client", "server"]),
    filterModel: PropTypes.object,
    onFilterModelChange: PropTypes.func,
    onSortModelChange: PropTypes.func,
    rowHeight: PropTypes.number,
    headerHeight: PropTypes.number,
    columnVisibilityModel: PropTypes.object,
    onColumnVisibilityModelChange: PropTypes.func,
    rowReordering: PropTypes.bool,
    onRowOrderChange: PropTypes.func,
    localeText: PropTypes.object,
    disableColumnMenu: PropTypes.bool,
};

export default AltDataGridPro;

