import React, { useEffect, useState } from 'react'
import VDRoom from '../../../common/VirtualDataRoom/VDRoom';
import { Accordion, AccordionDetails, AccordionSummary, Grid, LinearProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader';
import secureLocalStorage from 'react-secure-storage';
import PrimaryDetails from '../create-fund/FundPrimaryDetails';
import FundPrimaryDetails from '../create-fund/FundPrimaryDetails';
import { getFundbyId } from '../../../investor-fund-subscription/services/services';
import { getFundFilterData } from '../../../marketplace/services/MarketplaceService';
import { getRoles } from '../../../FundManagerDashboard/Services/FundNewsService';
import { AppDashboards } from '../../../../utils/enum';
import { FundCreationModes } from '../../services/apiDataDemplate';
import InvestmentStrategy from '../create-fund/InvestmentStrategy';
import ManagementTeam from '../create-fund/ManagementTeam';
import { convertStringToArray } from '../../../common/Functions/ConvertStringtoArray';
import { ToastContainer, toast } from 'react-toastify';
import FeesandExpenses from '../create-fund/FeesandExpenses';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const EditFundModel = () => {

  //#region variables
  const fundName = secureLocalStorage.getItem("FundName");
  const fundId = secureLocalStorage.getItem("FundId");
  const [expanded, setExpanded] = useState({});
  const [fundDetails, setFundDetails] = useState();
  const [fundFilterData, setFundFilterData] = useState();
  const [managementStaffDetails, setManagementStaffDetails] = useState(null);

  //#region click events
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if(fundDetails){
      setExpanded({ ...expanded, [panel]: isExpanded });
    }
  }
  
  //#region api get calls
  const getFundDetails = async () => {
    const data = await getFundbyId(fundId);
    if (data.responseCode === 200) {
        /* const convertedArray = convertStringToArray(data.responseData.fundStaff);
        const arrayToGrid = convertedArray.map((item,index) => ({ id: index, answer: item }))
        data.responseData.fundStaff = arrayToGrid; */
        setFundDetails(data.responseData);
        setExpanded({});
    }
    else {

    }
  };

  const getFundFilters = async () => {
    const data = await getFundFilterData();
    if (data.responseCode === 200) {
      setFundFilterData(data.responseData)
    }
    else {
    }
  }

  const getManagementTeam = async () => {
    const data = await getRoles("");
    if (data?.responseCode === 200) {
      const fundStaffOptions = data?.responseData?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD)
        .map(user => ({
          label: user.userFullName,
          value: user.userId,
          disabled: false,
          title: user.userRoleName,
          briefDescription: user.briefDescription,
          roleDashboardName: user.roleDashboardName
        }));
      setManagementStaffDetails(fundStaffOptions);
    } else {
      console.log("Error fetching user details");
    }
  }

  //#region useeffect
  useEffect(()=>{
    getFundDetails();
    getFundFilters();
    getManagementTeam();
  },[])

  //#region render components
  const fundAccordians = [
    {
      listItemID: 1,
      listItemValue: "Fund Details",
      component: <FundPrimaryDetails 
                         fundDetails={fundDetails} 
                         fundFilterData={fundFilterData} 
                         managementStaffDetails={managementStaffDetails}
                         type={FundCreationModes.EDIT}
                         getFundDetails={getFundDetails} />
    },
    {
      listItemID: 2,
      listItemValue: "Management Team",
      component: <ManagementTeam 
                    fundDetails={fundDetails} 
                    fundFilterData={fundFilterData} 
                    managementStaffDetails={managementStaffDetails}
                    type={FundCreationModes.EDIT}
                    getFundDetails={getFundDetails}/>
    },
    {
      listItemID: 3,
      listItemValue: "Investment Strategy and Philosophy",
      component: <InvestmentStrategy 
                         fundDetails={fundDetails} 
                         type={FundCreationModes.EDIT}
                         getFundDetails={getFundDetails} />
    },
    {
      listItemID: 4,
      listItemValue: "Fees and Expenses",
      component: <FeesandExpenses 
                         fundDetails={fundDetails} 
                         type={FundCreationModes.EDIT}
                         getFundDetails={getFundDetails} />
    }
  ];

  //#region return
  return (
    <>
       <div>
        <PageHeader
            title={`Edit Fund : ${fundName}`}
            template={HeaderTemplates.NORMAL_VIEW} />
       </div>
       <div className="wrapper3 margin-left-50">
            <Grid container xs={12} spacing={2}>
                <Grid item xs={12} sm={12} md={6.9} className='whiteCard mtb2'>
                        {
                            fundAccordians ? fundAccordians.map((item,index) => {
                            return (
                                <div className='margin-top-10'>
                                    <Accordion expanded={expanded[item.listItemID] || false}
                                                onChange={handleAccordionChange(item.listItemID)}
                                                style={{border:'1px lightgray solid'}}>
                                        <AccordionSummary
                                                aria-controls="panel1bh-content"
                                                expandIcon={fundDetails ? <ExpandMoreIcon /> : <AutorenewIcon /> }
                                                id="panel1bh-header">
                                                {item.listItemValue}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {
                                            expanded[item.listItemID] && 
                                            <>
                                              {
                                                 item.component 
                                              }
                                            </>
                                        }
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )
                            }) : <LinearProgress />
                        }
                </Grid>
                <Grid item xs={12} sm={12} md={5} className='whiteCard height-450-noscroll editfund-vdr'>
                    <VDRoom VDRType={'Fund'} cnrtFundID={fundId} crntSubscriptionID={0} />
                </Grid>
            </Grid>
       </div> 
       <ToastContainer />
    </>
  )
}

export default EditFundModel