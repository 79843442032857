import React from 'react'
import {
    Dialog,
    DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import InviteExcistingInvestor from './InviteExcistingInvestor';
import { InviteNewInvestor } from './InviteNewInvestor';
import Grid from '@mui/material/Grid';
import RowRadioButtonsField from '../../common/RadioButtonGroup/RadiobuttonsField';
import { inviteInvestorTypeEnum } from '../Services/apiDataTemplate';

const InviteInvestorsPopup = (props) => {

  //#region props
  const {open,onClose,inviteInvestorType,selectedFund,fundsDropdown,getInvestors/*  optional prop */ } = props;

  //#region variables
  const [selectedTab,setSelectedTab] = useState(1);
  const radibuttonLabels = [
    {label: "Existing investor",value: 1},
    {label: "New investor",value: 2},
    {label: "New investor for a specific fund",value: 3}
  ]

  const marketPlaceLabels = [
    {label: "Existing investor",value: 1},
    {label: "New investor",value: 3}
  ]

  //#region click events
  const handleChange = (value) => {
    const intValue = parseInt(value, 10);
    setSelectedTab(intValue);
  }

  //#region return
  return (
    <Dialog open={open} fullWidth>
         <DialogTitle>
             <Grid xs={12}>
                <h6>Invite Investors </h6> 
             </Grid>
             <Grid container xs={12} marginTop={2}>
               <RowRadioButtonsField 
                  label={"select type"}
                  value={selectedTab}
                  options={inviteInvestorType === inviteInvestorTypeEnum.MARKETPLACE ? marketPlaceLabels : radibuttonLabels}
                  onChange={handleChange}/>
             </Grid>
              
         </DialogTitle>
            {selectedTab === 1 ? (
                    <InviteExcistingInvestor selectedFundMP={selectedFund} inviteInvestorType={inviteInvestorType} onClose={onClose} fundsDropdown={fundsDropdown} />
                    ) : null}
                    {selectedTab === 2 || selectedTab === 3? (
                    <InviteNewInvestor selectedFund={selectedFund} inviteInvestorType={inviteInvestorType} onClose={onClose} type={selectedTab} getInvestors={getInvestors} fundsDropdown={fundsDropdown}/>
                    ) : null}
    </Dialog>
  )
}

export default InviteInvestorsPopup;