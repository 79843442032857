import { Edit } from "@mui/icons-material";
import secureLocalStorage from "react-secure-storage";

export const initialFundDataTemplate = {
    fundID: 0,
    fundName: "",
    fundTypeID: 0,
    fundTypeName: "",
    investmentStrategyId: "",
    fundTargetIndustryId: "",
    fundSize: "",
    minimumInvestmentAmount: "",
    fundRaisingStatus: 1,
    inceptionDate: "2023-07-05T11:58:21.539Z",
    fundTypeName: 1,
    geographicFocusId: "",
    fundStatus: "",
    firstInvestmentDate: "2023-07-05T11:58:21.539Z",
    fundClosingDate: "2023-07-05T11:58:21.539Z",
    targetFundSize: 0,
    numberofPortfolioCompanies: 33,
    targettedIRR: 10,
    targetedreturn: 10,
    investmentCycle: "",
    term: 0,
    liquidity: "",
    maximumInvestmentSize: 10,
    legalForm: "",
    fundCurrency: 0,
    userID: secureLocalStorage.getItem("userId"),
    investorRelationsContact: "",
    valuationPolicy: "",
    esgPolicy: "",
    carriedInterest: 0,
    capitalCallDate: "2023-07-05T11:58:21.539Z",
    totalCapitalCallAmount: 0,
    fundDescription: "",
    fundInceptionDate: "2023-07-05T11:58:21.539Z",
    lastOfferingDate: "2023-07-05T11:58:21.539Z",
    fundStaff: "",
    equityInvestment: 70,
    creditInvestment: 30,
    vintage: "",
    capitalCalled: "100% and Fully Deployed",
    bgImage: "",
    iconImage: "",
    visibility: "",
    dscInvestmentStrategy: "",
    dscInvestmentCriteria: "",
    dscInvestmentProcess: "",
    dscRiskManagementApproach: "",
    dscValueCreationAndOperImprovementStrategies: "",
    dscExitStrategies: "",
    aum: 3000000.00,
    capitalCommitted: 2000000.00,
    strategyName: "",
    targetIndustry: "",
    fundTypeName: "",
    fundRaisingStatusName: "1",
    fundCompanyName: "",
    favourite: true,
    compare: true,
    investmentPeriodCriteria: "",
    strategyDocument: "",
    startegyfilepath: "",
    legalStructureID: "",
    TenantID: secureLocalStorage.getItem("tenantID"),
    isFirmAsGP: false,
    managementDetails: "",
    managementTeamDetails: [
      {
        fundManagementTeamID: "",
        fundID: "",
        managementTeamStaff: "",
        description: "",
        title: "",
      }
    ],
    authorizedSignatory: "",
    portfolioCompanies: [
      {
        companyId: 0,
        fundID: 0,
        companyName: "",
        investmentStrategy: 1,
        targetIndustry: 1,
        yearFounded: "2023-07-05T11:58:21.539Z",
        dscInvestmentHighlightsAndRationle: "",
        dscKPIsAndProgessUpdates: "",
        dscExitsAndRealizedReturns: "",
        headQuartersLocation: "US",
        investmentStage: "C Stage",
        investmentCommitment: "27000000.00",
        portfolioCompanyStatus: "Performing",
        fundOwnershipPercentage: "38.00"
      }
    ],
    feesExpenses: [
      {
        id: 1,
        fundID: 0,
        managementFees: "",
        performanceFeesOrCarriedInterest: "",
        additionalFeesAndExpenses: ""
      }
    ],
    legalCompliance: {
      offeringDocuments: {},
      subscriptionDocuments: {},
      complianceDocuments: {}
    }
  }

  export const visibilityDropdownValues = [
          {
            "id": "show",
            "value": "1",
            "label": "show"
          },
          {
            "id": "hide",
            "value": "0",
            "label": "hide"
          }
  ]

  export const percentageDropdownValues =  [
    {
      "id": "1",
      "label": "20%",
      "value": 20,
      "checked": true
    },
    {
      "id": "2",
      "label": "40%",
      "value": 40,
      "checked": true
    },
    {
      "id": "3",
      "label": "60%",
      "value": 60,
      "checked": true
    }

  ]

export const FundCreationModes = {
    CREATE: 'create',
    EDIT: 'edit'
};

