import { useState } from "react";
import LoginContainer from "../../../../../Layouts/LoginContainer";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import ActionButton from "../../../../common/ActionButton/ActionButton";
import CircleIcon from "../../../../chatbot/Icon";

//#region default Props
const defaultProps = {
  Passwords: {
    newpassword: '',
    confirmpassword: ''
  },
  btnloading: false,
  skipbtnloading: false,
  fromBulkupload: false,
  handleSubmit: () => { },
  handleNavigation: () => { },
  setPasswords: () => { }
};

const OBChangePassword = (props) => {

  //#region props
  const { handleNavigation, Passwords, setPasswords, btnloading, handleSubmit, skipbtnloading, fromBulkupload } = props;

  //#region usestate variables
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  //#region click events
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickshowConfirmPassword = () => setshowConfirmPassword(!showConfirmPassword);
  const handleMouseDownshowConfirmPassword = () => setshowConfirmPassword(!showConfirmPassword);

  //#region onchange events
  const handleChange = (ev) => {
    const field = ev.target.name;
    const value = ev.target.value;
    const currState = { ...Passwords, [field]: value };
    setPasswords(currState);
    const validationResult = validateField(field, value, currState);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: validationResult.error }));

    if (field === 'newpassword') {
      const confirmValidationResult = validateField('confirmpassword', currState.confirmpassword, currState);
      setErrors((prevErrors) => ({ ...prevErrors, confirmpassword: confirmValidationResult.error }));
    }
  };
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = (event.clipboardData || window.clipboardData).getData('text');

  };

  //#region validations
  const validateField = (field, value, currState) => {
    const validationRules = {
      newpassword:(value) => {
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const digitRegex = /\d/;
        const symbolRegex = /[@#$%^&*()_+={}\[\]:;"'<>,.?/|\\\-~]/;
        const trimmedValue = value.trim(); // Trims leading and trailing whitespace
      
        const hasLowercase = lowercaseRegex.test(trimmedValue);
        const hasUppercase = uppercaseRegex.test(trimmedValue);
        const hasDigit = digitRegex.test(trimmedValue);
        const hasSymbol = symbolRegex.test(trimmedValue);
      
        const isValid =
          hasLowercase && // At least one lowercase letter
          hasUppercase && // At least one uppercase letter
          hasDigit && // At least one digit
          hasSymbol && // At least one special character
          trimmedValue.length >= 12 && // Minimum length of 12 characters
          trimmedValue.length <= 16 && // Maximum length of 16 characters
          value.charAt(0) !== ' ' && // Password does not begin with whitespace
          value.charAt(value.length - 1) !== ' '; // Password does not end with whitespace
      
        return {
          isValid,
          error: isValid
            ? null
            : (
              <ul>
                <li>Password must be 12-16 characters long.</li>
                <li>
                    Must contain at least one lowercase letter, one uppercase letter, one digit, and one special character.
                    (! @ # $ % ^ &amp; * ( ) - _ = + &#123; &#125; [ ] | \ : ; " ' &lt; &gt; , . ? /)
                </li>
                <li>Password must not begin or end with whitespace.</li>
              </ul>
            ),
        };
    },
      confirmpassword: (value) => {
        const isValid = value === currState.newpassword;
        return {
          isValid,
          error: isValid ? null : "Passwords do not match.",
        };
      },
    };
    return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
  };

  const MandatoryFieldErrors = () => {
    const fieldsToKeep = ["newpassword", "confirmpassword"];
    const trimmedValues = { ...Passwords };
    const filteredFields = fieldsToKeep.map((field) => {
      if (trimmedValues.hasOwnProperty(field)) {
        return { field, value: trimmedValues[field] };
      }
      return null;
    }).filter(Boolean);

    const Mandatory = filteredFields.map(({ field, value }) => ({
      field,
      value: value === "" ? null : value,
    }));

    /* console.log(Mandatory); */
    const EmptyFields = Mandatory.filter(entry => entry.value === "" || entry.value === null).map(entry => entry.field);
    const error = `This field is required.`;
    //  
    EmptyFields.length > 0 && EmptyFields.map((item) => {
      //           
      setErrors(prevErrors => ({ ...prevErrors, [item]: error }));
    })
  };

  //#region click events
  const handleSave = (skip) => {
    if (skip) {
      handleSubmit(skip);
    }
    else {
      const errorsArray = Object.values(errors);
      const hasErrors = errorsArray.some(error => error !== null);
      if (hasErrors || Passwords.newpassword === '' || Passwords.confirmpassword === '') {
        MandatoryFieldErrors();
        return;
      } else {
        handleSubmit(skip);
      }
    }
  };

  return (
    <LoginContainer>
      <div className="login d-flex align-items-center py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-lg-8 mx-auto">
              <div className="login-header">
                <h3 className="login-heading text-center">
                  Create a new password
                </h3>
              </div>
              <div className="login-form">
                <div className="d-flex position-relative my-3 text-center">
                  <TextField
                    label='New Password'
                    variant="outlined"
                    name='newpassword'
                    type={showPassword ? "text" : "password"}
                    defaultValue={Passwords.newpassword}
                    onChange={handleChange}
                    error={!!errors.newpassword}
                    helperText={errors.newpassword}
                    InputProps={{
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            // autoComplete='off'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                  />
                </div>
                <div className="d-flex position-relative my-3 text-center">
                  <TextField
                    label='Confirm New Password'
                    variant="outlined"
                    name='confirmpassword'
                    type={showConfirmPassword ? "text" : "password"}
                    error={!!errors.confirmpassword}
                    helperText={errors.confirmpassword}
                    onPaste={handlePaste}
                    onChange={handleChange}
                    autoComplete='false'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickshowConfirmPassword}
                            onMouseDown={handleMouseDownshowConfirmPassword}
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                  />
                </div>
                <div>
                  <div className="items-center">
                    <ActionButton
                      className="btn w-100 my-1 shadow-none my-4 btn-login"
                      label="CONFIRM PASSWORD"
                      loading={btnloading}
                      onClick={() => { handleSave(false) }}
                      styleProps={{ padding: '8px 56px' }} />
                  </div>
                </div>
                <div className={"items-center margin-top-10"}>
                  {!fromBulkupload &&
                    <ActionButton
                      className="btn w-100 my-1 shadow-none my-4"
                      variant="contained"
                      label="Previous"
                      onClick={() => { handleNavigation(false) }} />
                  }
                  {/*  <ActionButton 
                      className="btn w-100 my-1 shadow-none my-4 margin-left-5"
                      variant="outlined"
                      loading={skipbtnloading}
                      label="Skip For Now"
                      onClick={() => {handleSave(true)}}
                      styleProps={{ padding: '8px 36px' }} /> */}
                </div>
              </div>
              <div className="recaptcha1 mt-5 text-center">
                This site is protected by reCAPTCHA and the
                <p> Google
                  <a className="privacy_TC-links" href="#"> Privacy Policy </a>
                  and
                  <a className="privacy_TC-links" href="#"> Terms of Service </a>
                  Apply
                </p>


              </div>
              <CircleIcon />

            </div>
          </div>
        </div>
      </div>
    </LoginContainer>
  );
};

// Make sure to assign defaultProps to the component
OBChangePassword.defaultProps = defaultProps;

export default OBChangePassword;
