import React, { useState } from 'react';
import { Grid, Button, Chip, Checkbox } from '@mui/material';
import { useEffect } from 'react';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
// import { useSelector } from "react-redux";
// import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import TextInput from '../../common/TextInput/TextInput';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { justifyContentAllignItem } from '../../CommonCss';
import Text from '../../common/Text/Text';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ActionButton from '../../common/ActionButton/ActionButton'
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { getInvestorsList } from '../Services/IRServices';
import { getGroupsList } from '../Services/GroupsServices';
import { CreateGroup } from '../Services/GroupsServices';
import { ToastContainer, toast } from 'react-toastify';
import { getGroupByID } from '../Services/GroupsServices';
import secureLocalStorage from 'react-secure-storage';
import { updateGroupData } from '../Services/GroupsServices';
import SaveIcon from '@mui/icons-material/Save';
function AddGroups() {

    const [GroupList,setGroupList] = useState([])
    const [isAddGroupVisible, setIsAddGroupVisible] = useState(false);
    const [selectedInvestors, setSelectedInvestors] = useState([]);
    const [componentRefresh,setComponentRefresh] = useState(true)
    const [newGroupAdding,setnewGroupAdding] = useState(false)
    const [GroupID,setGroupID] = useState(null)
    const getList = async () => {
        const data = await getGroupsList(secureLocalStorage.getItem('tenantID'));
        if (data?.responseCode == 200) {
            setGroupList(data?.responseData);
        } else {
          toast.warning(data?.responseData,
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      }
    
      useEffect(() => {
        getList()
      }, []);
   
    //Group Data mapping 
    const groupsData = GroupList ? GroupList.map((u) => ({
        id: u.userGroupID,
        name: u.userGroupName,
      })) : [];
      
      const columns = [
          { field: 'id', headerName: 'ID', width: 70 },
          { field: 'name', headerName: 'Group Name', width: 150 },
      ];

      const [formData, setFormData] = useState({
        groupName: '',
        groupDescription: '',
    });

    const investorsData = selectedInvestors.map((investor) => ({
        groupMemberID: 0, 
        userGroupID: 0, 
        groupMemberUserID: investor.userId,
        groupMemberName: investor.userFullName, 
        groupMemberEmail: "", 
        isDeleted: false, 
        createdBy: secureLocalStorage.getItem("userId"),
        createdDate: "2024-01-30T12:12:43.732Z", 
        updatedBy: secureLocalStorage.getItem("userId"), 
        updatedDate: "2024-01-30T12:12:43.732Z", 
      }));

      const CreatGroupRequestData = {
        "userGroupID": 0,
        "userGroupName": formData.groupName,
        "userGroupDescription": formData.groupDescription,
        "tenantGUID" : secureLocalStorage.getItem('tenantID'),
        "isActive": true,
        "isDeleted": false, 
        "createdBy": secureLocalStorage.getItem("userId"),
        "createdDate": "2024-01-30T12:12:43.732Z",
        "updatedBy": secureLocalStorage.getItem("userId"),
        "updatedDate": "2024-01-30T12:12:43.732Z",
        "userGroupMembers": investorsData
      };


    const handleSaveGroupClick = async () => {
        // debugger
        if(GroupID > 0) {
            const UpdateGroupRequestData = {
                "userGroupID": GroupID,
                "userGroupName": formData.groupName,
                "userGroupDescription": formData.groupDescription,
                "tenantGUID" : secureLocalStorage.getItem('tenantID'),
                "isActive": true,
                "isDeleted": false, 
                "noOfGroupMembers" : 0,
                "createdBy": secureLocalStorage.getItem("userId"),
                "createdDate": "2024-01-30T12:12:43.732Z",
                "updatedBy": secureLocalStorage.getItem("userId"),
                "updatedDate": "2024-01-30T12:12:43.732Z",
                "userGroupMembers": investorsData
              }
            const data = await updateGroupData(UpdateGroupRequestData);
            if (data?.responseCode == 200) {
                toast.success(data?.responseData,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });

                setSelectedInvestors([])
            } else {
                toast.warning(data?.responseData,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
        }else {
            // debugger;
            const data = await CreateGroup(CreatGroupRequestData);
        if (data?.responseCode == 200) {
            toast.success(data?.responseData,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            setSelectedInvestors([])
        } else {
            toast.warning(data?.responseData,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        }
        getList();
         setIsAddGroupVisible(false);
    };

      const onRowsSelectionHandler = async (ids) => {
        setSelectedInvestors([])
        setComponentRefresh(false)
        const data = await getGroupByID(ids[0]);
        if (data?.responseCode == 200) {
            setFormData({
            groupName: data?.responseData.userGroupName, 
            groupDescription: data?.responseData.userGroupDescription,
            });
            setGroupID(data?.responseData?.userGroupID)
            setSelectedInvestors(prevSelectedInvestors => {
                const selectedInvestorsSet = new Set(prevSelectedInvestors.map(investor => investor.userId));
                data?.responseData.userGroupMembers?.forEach(param => {
                  selectedInvestorsSet.add(param.groupMemberUserID);
                });
                const updatedSelectedInvestors = Array.from(selectedInvestorsSet).map(userId => {
                 const matchingInvestor = investorsList.find(investor => investor.userId === userId);
                  return matchingInvestor;
                });
                return updatedSelectedInvestors.filter(Boolean);
              });

        } else {
          toast.warning(data?.responseData,
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setIsAddGroupVisible(true)
        setComponentRefresh(true)
        setFormData({
            groupName: '' , 
            groupDescription: '',
            })
        }
        setIsAddGroupVisible(true)
        setComponentRefresh(true)
      };

   

    const [openDialog, setOpenDialog] = useState(false);
    const [investorsList, setInvestorsList] = useState([]);

    
    const handleAddGroupClick = () => {
        setnewGroupAdding(true)
        setSelectedInvestors([]); 
        setIsAddGroupVisible(true);
    };

    const handleInputChange = (name, value) => {
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleLinkClick = () => {
        setOpenDialog(true);
    };

    const Columns = [
        {
            "id": 1,
            "field": 'id',
            "headerName": '_',
            "width": 10,
            renderCell: (params) => (
                <Checkbox
                    checked={(selectedInvestors?.filter(u => u.userId === params.id)?.length > 0)} />
            )
        },
        {
            "id": 2,
            "field": 'userFullName',
            "headerName": 'Name',
            "width": 250,
        },
        {
            "id": 3,
            "field": 'userEmailAddress',
            "headerName": 'Email Address',
            "width": 250
        },
        {
            "id": 3,
            "field": 'linkedFunds',
            "headerName": 'Funds invested in',
            "width": 250
        },
    ];

    const getInvestors = async () => {
        const data = await getInvestorsList();
        if (data?.responseCode === 200) {
            setInvestorsList(data?.responseData);
        } else {
            // Handle error if needed
        }
    }

    useEffect(() => {
        getInvestors();
    }, []);

    const InvestorSelectedClick = (params) => {
        setSelectedInvestors(prevSelectedInvestors => [
            ...prevSelectedInvestors,
            ...investorsList?.filter(u => params.id === u.userId)
        ]);
    };

    const onAutoCompleteChange = (e, value) => {
        const latestSelection = value.map(u => u.userId);

        const newItems = investorsList.filter(u => latestSelection?.includes(u.userId));
        setSelectedInvestors(investorsList.filter(u => latestSelection?.includes(u.userId)));
    }

    const handleClose = () => {
        setOpenDialog(false);
    };
    const handleSaveDialog = () => {
        setOpenDialog(false);
    };


    return (
        <div>
                <div className='InvestorCommAddgroup-btn'>
                                <Button variant="outlined" className="btn-primary3" style={{ cursor: 'pointer' }} onClick={handleAddGroupClick}>
                                    <AddIcon />
                                    <span>Add Group</span>
                                </Button>
                            </div>
            <Grid container xs={12} marginTop={3}>
                <Grid item container xs={12} spacing={2} className='whiteCard'>
                    <Grid item xs={isAddGroupVisible === false ? 12 : 5}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h6>Group Names</h6>
                        </div>
                        <AIPDataGrid rows={groupsData} columns={columns} onRowsSelectionHandler={onRowsSelectionHandler} />
                    </Grid>
                    {isAddGroupVisible && componentRefresh && (
                        <Grid item xs={6.5} className='mr2' sx={{marginLeft:'1px'}}>
                            <div>
                                <TextInput
                                    label="Group Name"
                                    name="groupName"
                                   defaultValue={formData.groupName}
                                    fullWidth
                                    onChange={(name, value) => handleInputChange(name, value)}
                                />
                            </div>
                            <div className='mt2'> 
                                <TextInput
                                    label="Group Description"
                                    name='groupDescription'
                                    multiline={true}
                                    defaultValue={formData?.groupDescription}
                                    onChange={(name, value) => handleInputChange(name, value)}
                                />
                            </div>
                            <Grid item container xs={12}>
                                <Grid item xs={11}>
                                    <div className='mt2'>
                                        <Autocomplete
                                            multiple
                                            id="emailTo"
                                            size="small"
                                            sx={{ width: "100%" }}
                                            options={investorsList}
                                            getOptionLabel={(option) => option?.userEmailAddress}
                                            value={selectedInvestors}
                                            onChange={onAutoCompleteChange}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={option?.userFullName}
                                                        size="small"
                                                        {...getTagProps({ index })}

                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    label="Investors"
                                                    placeholder="investors"
                                                    sx={{
                                                        color: 'blue', // Set the color for the link
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                    }}
                                                //onClick={handleLinkClick}

                                                />
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={1}>
                                    <div className='mt2' style={{
                                        // width: "100%",
                                        // height: "80%",
                                        backgroundColor: "#1f61c5",
                                        borderTopRightRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                        verticalAlign: "center",
                                        padding:'7px 0px'
                                    }}>
                                        <SearchIcon sx={{
                                            color: 'white',
                                           
                                            // height: '80%',
                                            // width: '80%',
                                            // marginTop: '5px',
                                            // marginLeft: '5px',
                                        }} onClick={handleLinkClick} fontSize='large' />
                                    </div>
                                </Grid>
                            </Grid>
                            <div className='mt2'>
                                <h5>Group Members</h5>
                                <div className='flexspacebetween'>
                                    <div className='alignright margin-bottom-10' >
                                        <ActionButton variant="contained" label="Add Members" onClick={handleLinkClick} />
                                        <ActionButton variant="contained" label="Delete Members" styleProps={{ marginLeft: '10px' }} />
                                    </div>
                                    <div className='margin-bottom-10'>
                                        <AIPDataGrid
                                            columns={Columns}
                                            rows={selectedInvestors?.map((u) => {
                                                return {
                                                    id: u.userId, ...u
                                                }
                                            }) || []}
                                            handleRowClick={() => { }}
                                            onRowsSelectionHandler={() => { }}
                                        />
                                    </div>
                                </div>


                            </div>
                            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="md" >
                                <DialogTitle sx={justifyContentAllignItem}>
                                    <Text label="Investors" />
                                    <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <AIPDataGrid
                                        columns={Columns}
                                        rows={investorsList?.map((u) => {
                                            return {
                                                id: u.userId, ...u
                                            }
                                        }) || []}
                                        handleRowClick={InvestorSelectedClick}
                                        onRowsSelectionHandler={() => { }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleSaveDialog} color="primary">
                                        Ok
                                    </Button>
                                    <Button onClick={handleClose} color="secondary">
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <div className='mt2 btnsRight'>
                                <Button variant="contained" className="btn-primary2" onClick={handleSaveGroupClick}>
                                  <SaveIcon/>  Save
                                </Button>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <ToastContainer/>
        </div>
    );
}

export default AddGroups;
