import { Field, useFormik } from 'formik';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import * as yup from 'yup';
import { validationsConstants } from '../../../../utils/AppConstants';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import AddIcon from '@mui/icons-material/Add';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { FieldVariants } from '../../../common/TextInput/appInputenum';
import DeleteIcon from '@mui/icons-material/Delete';

const FundamentalDetails = forwardRef((props, ref) => {
  
  //#region variables
  const { companyDetails, setCompanyDetails } = props;

  //#region change events
  const handleBoardMemberChange = (field, value) => {
    const fieldSplit = field.split(".");//for validation feesexpenses
    const currIndex = fieldSplit[1].split("-")[0];
    const fieldName = fieldSplit[1].split("-")[1];
    const currState = fundamentalDetails?.values?.boardMembers;
    const item = { ...currState[currIndex] };
    item[fieldName] = value;
    currState[currIndex] = item;
    handleChange("boardMembers", currState);
  }

  const handleChange = (field,value) => {
    fundamentalDetails.setFieldValue(field,value)
  }

  //#region click events
  const addBoardMember = () => {
      const newMembers= [...fundamentalDetails.values?.boardMembers, 
        {
          "id": 0,
          "name": "",
          "designation": "",
          "linkedinUrl": "",
          "description": ""
        }
      ];
      handleChange("boardMembers", newMembers);
  }
  
  const deleteBoardMember = (value) => {
      const deletedmembers = fundamentalDetails.values?.boardMembers?.filter((item,index) => index !== value);
      handleChange("boardMembers", deletedmembers);
  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    companyName: yup
          .string()
          .required(validationsConstants.REQUIRED),
    industryId: yup
          .string()
          .required(validationsConstants.REQUIRED),
  })

  const fundamentalDetails = useFormik({
      initialValues: {
        ...companyDetails
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        return true;
      },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await fundamentalDetails.validateForm();
      if (Object.keys(errors).length === 0) {
        //need to update main set of values
        setCompanyDetails((prevValues) => {
          return { ...prevValues, ...fundamentalDetails?.values };
        });
        return true;
      }
      else {
        fundamentalDetails?.handleSubmit()
      }
    },
    updateFields: async () => {
      setCompanyDetails((prevValues) => {
        return { ...prevValues, ...fundamentalDetails?.values };
      });
    }
  }));

  //#region return
  return (
    <div className='child-margin-10'>
      <div className='white-card child-margin-10'>
        <b>Company Details</b>
        <div className='display-row-items-flex'>
          <div className='width-33'>
            <ALTTextField 
              name="companyName"
              label="Company Name"
              value={fundamentalDetails.values.companyName}
              onChange={(name, value) => handleChange(name, value)}
              required={true}
              onBlur={fundamentalDetails.handleBlur}
              error={fundamentalDetails.touched.companyName && Boolean(fundamentalDetails.errors.companyName)}
              errorMessage={fundamentalDetails.touched.companyName && fundamentalDetails.errors.companyName} />
          </div>
          <div className='width-33'>
            <ALTTextField 
              name="industryId"
              label="Industry/Sector"
              value={fundamentalDetails.values.industryId}
              onChange={(name, value) => handleChange(name, value)}
              required={true}
              onBlur={fundamentalDetails.handleBlur}
              error={fundamentalDetails.touched.industryId && Boolean(fundamentalDetails.errors.industryId)}
              errorMessage={fundamentalDetails.touched.industryId && fundamentalDetails.errors.industryId}/>
          </div>
        </div>
      </div>
      <div className='white-card'>
        <b>Legal Counsel</b>
      </div>
      <div className='white-card'>
        <b>Accounting Firm</b>
      </div>
      <div className='white-card'>
        <b>Board Members</b>
        {
          fundamentalDetails.values.boardMembers?.map((item,index)=>{
            return(
              <div className='child-margin-10' key={index}>
              <div className='space-between'>
                <div></div>
                <div>
                  <ActionButton
                    icon={<DeleteIcon />}
                    variant={FieldVariants.OUTLINED}
                    onClick={() => {deleteBoardMember(index)}} />
                </div>
              </div>
              <div className='display-row-items-flex'>
                <div className='width-33'>
                <ALTTextField 
                  name={`boardMembers.${index}-name`}
                  label="Name"
                  value={item.name}
                  onChange={(name, value) => handleBoardMemberChange(name, value)}
                  required={false}
                  onBlur={fundamentalDetails.handleBlur}
                  error={fundamentalDetails.touched[`boardMembers.${index}-name`] && Boolean(fundamentalDetails.errors[`boardMembers.${index}-name`])}
                  errorMessage={fundamentalDetails.touched[`boardMembers.${index}-name`] && fundamentalDetails.errors[`boardMembers.${index}-name`]} />
                </div>
                <div className='width-33'>
                <ALTTextField 
                  name={`boardMembers.${index}-designation`}
                  label="Designation"
                  value={item.designation}
                  onChange={(name, value) => handleBoardMemberChange(name, value)}
                  required={false}
                  onBlur={fundamentalDetails.handleBlur}
                  error={fundamentalDetails.touched[`boardMembers.${index}-designation`] && Boolean(fundamentalDetails.errors[`boardMembers.${index}-designation`])}
                  errorMessage={fundamentalDetails.touched[`boardMembers.${index}-designation`] && fundamentalDetails.errors[`boardMembers.${index}-designation`]} />
                </div>
                <div className='width-33'>
                <ALTTextField 
                  name={`boardMembers.${index}-linkedinUrl`}
                  label="LinkedIn URL"
                  value={item.linkedinUrl}
                  onChange={(name, value) => handleBoardMemberChange(name, value)}
                  required={false}
                  onBlur={fundamentalDetails.handleBlur}
                  error={fundamentalDetails.touched[`boardMembers.${index}-linkedinUrl`] && Boolean(fundamentalDetails.errors[`boardMembers.${index}-linkedinUrl`])}
                  errorMessage={fundamentalDetails.touched[`boardMembers.${index}-linkedinUrl`] && fundamentalDetails.errors[`boardMembers.${index}-linkedinUrl`]} />
                </div>
                <div className='width-98'>
                <ALTTextField 
                  name={`boardMembers.${index}-description`}
                  label="Description"
                  value={item.description}
                  onChange={(name, value) => handleBoardMemberChange(name, value)}
                  required={false}
                  multiline={true}
                  rows={3}
                  onBlur={fundamentalDetails.handleBlur}
                  error={fundamentalDetails.touched[`boardMembers.${index}-description`] && Boolean(fundamentalDetails.errors[`boardMembers.${index}-description`])}
                  errorMessage={fundamentalDetails.touched[`boardMembers.${index}-description`] && fundamentalDetails.errors[`boardMembers.${index}-description`]} />
                </div>
              </div>
              </div>
            )
          })
        }
        <div className='margin-top-15 margin-left-5'>
          <ActionButton 
             label="Add Member"
             icon={<AddIcon />}
             variant={FieldVariants.OUTLINED}
             onClick={addBoardMember} />
        </div>
      </div>
    </div>
  )
})

export default FundamentalDetails