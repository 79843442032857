import React, { useState } from 'react'
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, Button, MenuItem } from '@mui/material';
import TextInput from '../../../common/TextInput/TextInput';
import ActionButton from '../../../common/ActionButton/ActionButton';
import FileUploadButton from '../../../common/FileUploadButton/FileUploadButton';
import { quatarsdropdown } from '../jsonData';
import { getAllFunds } from '../../../InvestorRelations/Services/IRServices';
import { useEffect } from 'react';
import MultiSelectDropdown from '../../../common/Multi-Select-Dropdown/MultiSelectDropdown';
import { ReportsNames } from '../jsonData';
import { getDocumentTypeForInvestorReporting, getReportTypeData, postDocumnet } from '../services/services';
import { ToastContainer, toast } from 'react-toastify';
import { Replacemsg } from './Replacemsg';
import ReportsDropdownEnum from '../../InvestorReportingEnum';
import { getFundsbyDashboard } from '../services/services';
import { getRepotingFolders } from '../services/services';
import CancelIcon from '@mui/icons-material/Cancel';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { InputTypes, validationMessages, validationsTypeEnum } from '../../../common/TextInput/appInputenum';
import AppTextInput from '../../../common/TextInput/AppTextField';
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import { getFileExcistsorNot } from '../services/services';
import { convertArrayToString } from '../../../common/Functions/ConvertStringtoArray';
import { getFundbyId } from '../../../investor-fund-subscription/services/services';
import AutoSelectField from '../../../common/input-fields/AutoSelectFiled';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import SelectField from '../../../common/input-fields/SelectField';
import { InvestorReportingToastMessages, validationsConstants } from '../../../../utils/AppConstants';
import MultiSelectField from '../../../common/input-fields/MultiSelectField';

const AddNewDocument = (props) => {

  //#region props
  const {open,onClose,reports,refreshAccordians,currDocumentType,refreshFolders,componentRefresh,setcomponentRefresh,setCurrentTab} = props;

  //#region initialdata
  const initialData = {
    quarter : '',
    year : '',
    reporttype : [],
    selectedfund : '',
    document : '',
    other : ''
  }

  //#region variables
  const [otherOptionID,setOtherOptionID] = useState('');
  const [loading,setLoading] = useState(false);
  const [replaceDocumentPopup,setReplaceDocumentPopup] = useState(false);
  const [existingDocuments,setExistingDocument] = useState(null);
  const [fundsDropdown,setfundsDropdown] = useState(null);
  const [reportsDropdown,setReportsDropdown] = useState(null);
  const [yearsDropDown,setYearsDropdown] = useState([]);

//#region change events
const handleChange = (field, value) => {
      documentDetails.setFieldValue(field,value)
}

const handleFileUpload = (name, file) => {
  if (file) {
    const formData = new FormData();
    formData.append(name, file, file.name);
    handleChange(name, file);
  }
}

  //#region click events
  const openreplacePopup = () => {
      setReplaceDocumentPopup(true);
  }
  const closereplacePopup = () => {
      setReplaceDocumentPopup(false);
      setcomponentRefresh(true)
        setTimeout(() => {
          setcomponentRefresh(false)
        }, 600);
  }

  const handleSubmit = () => {
    if(documentDetails.values.document === ""){
      toast.warning(InvestorReportingToastMessages.UPLOAD_DOCUMENT,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
    else{
      postDocument();
    }
  }
  
  //#region api get calls
  const getFundNames = async () => {   
    const data = await getFundsbyDashboard();
      if (data?.responseCode == 200) {
        const fundNameOptions = data?.responseData?.map(fund => ({
          label: fund.fundName,
          value: fund.fundID,  
      }));
      setfundsDropdown(fundNameOptions);
      } else {
         console.log("Unable to Load the fundnames")
      }
  }

  const getFoldersNames = async () => {
    const data = await getReportTypeData();
    if (data?.responseCode === 200) {
      const filteredData = data?.responseData;
      const dropDownData = Array.isArray(filteredData)
                          ? filteredData.map((item) => {
                              return { value: item.documentTypeID, label: item.title };
                            })
                          : [];
      setOtherOptionID(filteredData?.find(item => item.title === 'Other'));
      setReportsDropdown(dropDownData);
    }
  }

  const getFundDetails = async (fundID) => {
    const data = await getFundbyId(fundID)
    if (data.responseCode === 200) {

      const currentYear = new Date().getFullYear();
      const yearsArray = [];
      for (let year = currentYear; year >= data.responseData?.vintage ; year--) {
        yearsArray.push(year);
      }

      const formattedOptions = yearsArray.map(year => ({
        label: year.toString(),
        value: year
      }));

      setYearsDropdown(formattedOptions)
    } else {
      toast.warning(data.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region api post calls
  const postDocument = async() => {
    
    if(documentDetails.values?.other?.toLowerCase() === "investor reporting"){
      toast.warn("Report name cannot be investor reporting",
      { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      return;
    }
    if(reports.some(r => r.title?.toLowerCase() === documentDetails.values?.other?.toLowerCase())){
      toast.warn("Report name already exists",
      { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      return;
    }

    const documentsList = reportsDropdown
                            .filter(item => documentDetails.values.reporttype?.includes(item.value))
                            .map(item => ({
                              documentTypeID: otherOptionID?.documentTypeID === item.value ? 0 : item.value,
                              documentTypeName: otherOptionID?.documentTypeID === item.value ? documentDetails.values.other : item.label
                            }));
    
    setLoading(true);
    const requestBody = 
        {
          "fundID": documentDetails.values.selectedfund,
          "fundName": fundsDropdown?.find(item => item.value === documentDetails.values.selectedfund).label,
          "documentTypeID": 0,
          "documentTypeName": "",
          "yearNumber": documentDetails.values.year,
          "quarterNumber": documentDetails.values.quarter,
          "otherName": "",
          "reportDocumentList": documentsList
        }
     
    const selectedReportTypes = requestBody.reportDocumentList.map((item) => item.documentTypeID)
    const selectedFundName = fundsDropdown?.find((item) => requestBody.fundID === item.value)?.label
    let excistedReportTypes = []
    
    const ExcistResponse = await getFileExcistsorNot(requestBody,convertArrayToString(selectedReportTypes));
    {
      if(ExcistResponse.responseCode === 200){

        //#region excisting checks
       excistedReportTypes = ExcistResponse.responseData.length > 0 ? ExcistResponse.responseData?.map((item => item.documentTypeID)) : []
        const filteredReportTypes = requestBody.reportDocumentList.filter((item) => !excistedReportTypes?.includes(item.documentTypeID))
        requestBody["reportDocumentList"] = filteredReportTypes;

        //#filtering for steps
        const progressSteps = reportsDropdown
        ?.map((item) => 
          selectedReportTypes.includes(item.value) ? 
          {
            'label': item.label,
            'description': excistedReportTypes.includes(item.value) 
                            ? `The ${item.label} Report of ${selectedFundName} already exists for [Q${requestBody.quarterNumber}, ${requestBody.yearNumber}]` 
                            : "Successfully Uploaded",
            'error': excistedReportTypes.includes(item.value) 
          } 
          : null
        )
        .filter(item => item !== null)
        .sort((a, b) => (a.error === b.error) ? 0 : a.error ? 1 : -1);;
    
        setExistingDocument(progressSteps);
      }
      //#region API
      const formData = new FormData();
      formData.append('file', documentDetails.values.document, documentDetails.values.document.name);
      formData.append('investorReporting', JSON.stringify(requestBody));
      const APIresponse = await postDocumnet(formData);
      if(APIresponse?.responseCode === 200){
        toast.success("Document Uploaded Successfully",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        if(excistedReportTypes.length > 0){
          openreplacePopup();
          setLoading(false);
          /* onClose(); */
        }
        else{
          setLoading(false);
          onClose();
          setcomponentRefresh(true)
          setTimeout(() => {
            setcomponentRefresh(false)
          }, 600);
        }
      }
      else{
        if(excistedReportTypes.length > 0){
          openreplacePopup();
          setLoading(false);
          /* onClose(); */
          toast.warning("Document already exists",
             { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        else{
          toast.warning("Unable to Upload the Document. Please try again later.",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          setLoading(false);
          onClose();
        }
      }
    }
  };

  //#region formik validations
  const validationSchema = yup.object().shape({
      selectedfund: yup
                .string()
                .trim()
                .required(validationsConstants.REQUIRED),
      reporttype: yup.array()
                .required(validationsConstants.REQUIRED)
                .min(1, validationsConstants.REQUIRED),
      other: yup.mixed().when([], (reporttype, schema) => {
        if (documentDetails.values.reporttype?.includes(otherOptionID?.documentTypeID)) {
            return schema.required(validationsConstants.REQUIRED);
        }
        return schema;
      }),
      year: yup
                .string()
                .trim()
                .required(validationsConstants.REQUIRED),
      quarter: yup
                .string()
                .trim()
                .required(validationsConstants.REQUIRED),
    });

    const documentDetails = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema, 
        onSubmit: (values) => {
          /* handleSubmit(values); */
          handleSubmit()
        },
      });
  
  useEffect(() => {
    getFundNames();
    getFoldersNames();
  }, []);

  useEffect(()=>{
    getFundDetails(documentDetails.values.selectedfund) 
  },[documentDetails.values.selectedfund])
  
  return (
    <>
      <Dialog open={open} xs={12} sm={12} props={{sx: {width: '100%'}}} fullWidth>
          <DialogTitle>
             <h6> Add New Document </h6>
          </DialogTitle>
          <DialogContent>
            <div className='space-between'>
                <div></div>
                <div>
                  <FileUploadBtn 
                      name='document'
                      label="UPLOAD DOCUMENT"
                      fileType={uploadFileTypes.PDF}
                      required={true}
                      defaultFile={documentDetails.values.document}
                      onChange={(name, value) => handleFileUpload(name, value)} />
                </div>
            </div>
            <form className='child-margin-15'>
               <div>
                  <AutoSelectField 
                      name="selectedfund"
                      label="Select Fund"
                      value={documentDetails?.values?.selectedfund}
                      onChange={(name, value) => handleChange(name, value)}
                      options={fundsDropdown ? fundsDropdown.map(fundName => ({ value: fundName.value, label: fundName.label })) : []}
                      onBlur={documentDetails.handleBlur}
                      required={true}
                      isAddOption={false}
                      error={documentDetails.touched.selectedfund && Boolean(documentDetails.errors.selectedfund)}
                      errorMessage={documentDetails.touched.selectedfund && documentDetails.errors.selectedfund}/>
               </div>
               <div>
                  <AutoSelectField 
                      name="reporttype"
                      label="Report Type"
                      value={documentDetails?.values?.reporttype}
                      onChange={(name, value) => handleChange(name, value)}
                      options={reportsDropdown ? reportsDropdown.map(option => ({ value: option.value, label: option.label })) : []}
                      onBlur={documentDetails.handleBlur}
                      required={true}
                      isMultiSelect={true}
                      isAddOption={false}
                      error={documentDetails.touched.reporttype && Boolean(documentDetails.errors.reporttype)}
                      errorMessage={documentDetails.touched.reporttype && documentDetails.errors.reporttype}/>
               </div>
               {documentDetails.values.reporttype?.includes(otherOptionID?.documentTypeID) &&
                  <div className='margin-top-15'>
                      <ALTTextField 
                        name='other'   
                        label="Report Name"
                        value={documentDetails.values?.other}
                        onChange={(name, value) => handleChange(name, value)}
                        required={true}
                        error={documentDetails.touched.other && Boolean(documentDetails.errors.other)}
                        errorMessage={documentDetails.touched.other && documentDetails.errors.other} />
                  </div>
                }
               <div>
                  <SelectField 
                      name="year"
                      label="Year"
                      value={documentDetails?.values?.year}
                      onChange={(name, value) => handleChange(name, value)}
                      options={yearsDropDown}
                      onBlur={documentDetails.handleBlur}
                      required={true}
                      disabled={documentDetails.values.selectedfund === ""}
                      error={documentDetails.touched.year && Boolean(documentDetails.errors.year)}
                      errorMessage={documentDetails.touched.year && documentDetails.errors.year}/>
               </div>
               <div>
                  <SelectField 
                      name="quarter"
                      label="Quarter"
                      value={documentDetails?.values?.quarter}
                      onChange={(name, value) => handleChange(name, value)}
                      options={quatarsdropdown?.map(option => ({ label: option.name, value: option.id }))}
                      onBlur={documentDetails.handleBlur}
                      required={true}
                      isAddOption={false}
                      error={documentDetails.touched.quarter && Boolean(documentDetails.errors.quarter)}
                      errorMessage={documentDetails.touched.quarter && documentDetails.errors.quarter}/>
               </div>
            </form>
          </DialogContent>
          <DialogActions>
          <div className="space-between">
                    <div>
                        <ActionButton 
                          className="btn-primary"
                          icon={<CancelIcon/>}
                          component="label"
                          variant="outlined"
                          label="Cancel"
                          disabled={loading}            
                          onClick={onClose}/>
                            
                    </div>
                    <div className="margin-left-10">
                      <ActionButton
                      icon={<TouchAppIcon/>}
                          label="Submit"
                          loading={loading}
                          startIconName="" 
                          onClick={documentDetails.handleSubmit} />
                    </div>
                </div>
          </DialogActions>
          <ToastContainer />
          {
            replaceDocumentPopup && 
            <Replacemsg open={replaceDocumentPopup} steps={existingDocuments} onParentClose={onClose} onClose={closereplacePopup} details={documentDetails.values} />
          }
      </Dialog>
    </>
  )
}

export default AddNewDocument