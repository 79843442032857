import * as React from 'react';
import {
  Typography,
  Select,
  MenuItem,
  Button,
  Box,
  FormControl,
  InputLabel,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import Configurations from '../../Configurations';
import WhiteLabelingConfigConfirmation from './WhiteLabelingConfigConfirmation';
import ResetConfirmationPopup from './ResetConfirmationPopup';
import { whiteLabellingToastMessages } from '../../utils/AppConstants';
import { getColorConfig, updateColorConfig } from '../../state/slices/colorConfigSlice';
import { WhileLabelingInputField } from './InputField';
import { ApplyConfig, LabelingConfig, ResetWhiteLabelingConfig } from './Constants';
import { getTenantCustomCSS } from '../registration-profiling/services/RegistrationProfilingService';
import CircularProgress from '@mui/material/CircularProgress';
import AddThemePopup from './Addtheme';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';


export const WhiteLabeling = () => {
  //#region variables
  const dispatch = useDispatch();
  const DefaultLabelingConfig = useSelector(getColorConfig);
  const [currConfig, setConfig] = React.useState(DefaultLabelingConfig);
  const [confirmation, setConfirmation] = React.useState(false);
  const [resetConfirmation, setResetConfirmation] = React.useState(false);
  const [resetSectionName, setResetSectionName] = React.useState('');
  const [labelingDataSet, setLabelingDataSet] = React.useState([]);
  const [selectedTheme, setSelectedTheme] = React.useState('default');
  const [addtheme, setaddtheme] = React.useState(false);
  const [themeName, setThemeName] = React.useState('');
  const [themeColor, setThemeColor] = React.useState('#000000');
  const [mirroredFrom, setMirroredFrom] = React.useState('');
  const [enableButtons, setenableButtons] = React.useState(false)

  //#region handle change events
  const handleThemeChange = (event) => {
    //setShowSaveButtons(true)
    const selectedValue = event.target.value;
    setSelectedTheme(selectedValue);

    // Find the selected theme in the dataset
    const selectedThemeData = labelingDataSet.find((theme) => theme.themeName === selectedValue);

    if (selectedThemeData) {
      // Update currConfig with the new theme's sections
      setConfig({
        whiteLabellingID: selectedThemeData.whiteLabellingID,
        tenantGuid: selectedThemeData.tenantGuid,
        mainNavigation: selectedThemeData.mainNavigation,
        topNavigation: selectedThemeData.topNavigation,
        header: selectedThemeData.header,
        rootBackground: selectedThemeData.rootBackground,
        themeName: selectedThemeData.themeName,
        themeColor: selectedThemeData.themeColor,
        activeTheme: selectedThemeData.activeTheme,
        defaultTheme: selectedThemeData.defaultTheme,
      });
    }
  };

  const handleAddTheme = () => {
    setaddtheme(true);
  };

  const handleSaveTheme = (newTheme) => {
    setSelectedTheme(newTheme.themeName);
    if (mirroredFrom) {
      const mirroredThemeData = labelingDataSet.find(
        (theme) => theme.themeName === mirroredFrom
      );

      if (mirroredThemeData) {
        // Update the config with the mirrored theme data
        setConfig({
          mainNavigation: mirroredThemeData.mainNavigation,
          topNavigation: mirroredThemeData.topNavigation,
          header: mirroredThemeData.header,
          rootBackground: mirroredThemeData.rootBackground,
        });

        // Add the new entry to the labelingDataSet with the current theme data
        setLabelingDataSet((prev) => [
          ...prev,
          {
            whiteLabellingID: 0,
            tenantGuid: secureLocalStorage.getItem("tenantID"),
            mainNavigation: mirroredThemeData.mainNavigation,
            topNavigation: mirroredThemeData.topNavigation,
            header: mirroredThemeData.header,
            rootBackground: mirroredThemeData.rootBackground,
            themeName: themeName,
            themeColor: themeColor,
            activeTheme: false,
            defaultTheme: false
          }
        ]);
      }
    }

    setaddtheme(false);
  };

  const handlePopupClose = () => {
    setaddtheme(false);
    setThemeName('');
    setThemeColor('#000000');
    setMirroredFrom('');
  };

  const handleUpdateConfig = (root, name, value) => {
    setConfig((prev) => ({
      ...prev,
      [root]: {
        ...prev[root],
        [name]: value,
      },
    }));

    const updatedConfig = {
      ...currConfig,
      [root]: {
        ...currConfig[root],
        [name]: value,
      },
    };

    const matchingTheme = labelingDataSet.find((u) => u.themeName === selectedTheme);
    if (matchingTheme) {
      const isEqual = JSON.stringify(matchingTheme[root]) === JSON.stringify(updatedConfig[root]);
      setenableButtons(isEqual);
    } else {
      setenableButtons(false);
    }
  };


  //#region apply config
  const handleApplyConfig = async () => {
    let finalConfig = labelingDataSet.find(u => u.themeName === selectedTheme)
    if (finalConfig.whiteLabellingID === 0) {
      currConfig.whiteLabellingID = finalConfig.whiteLabellingID
      currConfig.tenantGuid = finalConfig.tenantGuid
      currConfig.themeName = finalConfig.themeName
      currConfig.themeColor = finalConfig.themeColor
      currConfig.defaultTheme = finalConfig.defaultTheme
      currConfig.activeTheme = finalConfig.activeTheme
      // const formData = new FormData();
      // formData.append('tenantNewCustomCSS ', JSON.stringify(labelingDataSet));
      const data = await ApplyConfig(currConfig)
      if (data.responseCode === 200) {
        fetchWhiteLabellingConfig()
        window.location.reload(true);
      }
      setConfirmation(false)
    } else {
      currConfig.activeTheme = true
      currConfig.whiteLabellingID = finalConfig.whiteLabellingID
      currConfig.tenantGuid = finalConfig.tenantGuid
      currConfig.themeName = finalConfig.themeName
      currConfig.themeColor = finalConfig.themeColor
      currConfig.defaultTheme = finalConfig.defaultTheme


      // const formData = new FormData();
      // formData.append('tenantNewCustomCSS ', JSON.stringify(labelingDataSet));
      const data = await ApplyConfig(currConfig)
      if (data.responseCode === 200) {
        fetchWhiteLabellingConfig()
        dispatch(updateColorConfig({ value: finalConfig }))
        window.location.reload(true);
      }
      setConfirmation(false)
    }


  };

  //#region reset whitelabeling config
  const ResetConfig = async () => {
    const data = await ResetWhiteLabelingConfig(
      secureLocalStorage.getItem('tenantID'),
      resetSectionName
    );

    if (data?.responseCode === 200) {
      dispatch(updateColorConfig(data?.responseData));
      toast.success(whiteLabellingToastMessages.CONFIG_APPLIED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
      setResetSectionName('');
      window.location.reload(true);
    } else {
      toast.warning(whiteLabellingToastMessages.CONFIG_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
  };

  const onCancelClick = () => {
    fetchWhiteLabellingConfig()
  }

  //#region - service call to store total dataset
  const fetchWhiteLabellingConfig = async () => {
    const data = await getTenantCustomCSS();
    if (data.responseCode === 200) {
      setLabelingDataSet(data?.responseData);
      setSelectedTheme(data?.responseData.find((u) => u.activeTheme === true).themeName);
    } else {
    }
  };

  // useEffect(() => {
  //   if (mirroredFrom) {
  //     const mirroredThemeData = labelingDataSet.find(
  //       (theme) => theme.themeName === mirroredFrom
  //     );

  //     if (mirroredThemeData) {
  //       setConfig({
  //         mainNavigation: mirroredThemeData.mainNavigation,
  //         topNavigation: mirroredThemeData.topNavigation,
  //         header: mirroredThemeData.header,
  //         rootBackground: mirroredThemeData.rootBackground,
  //       });
  //       setLabelingDataSet((prev) => [...prev,  {
  //         "whiteLabellingID": 0,
  //         "tenantGuid": secureLocalStorage.getItem("tenantID"),
  //         "mainNavigation": mirroredThemeData.mainNavigation,
  //         "topNavigation":  mirroredThemeData.topNavigation,
  //         "header": mirroredThemeData.header,
  //         "rootBackground": mirroredThemeData.rootBackground,
  //         "themeName": themeName,
  //         "themeColor": themeColor,
  //         "activeTheme": false,
  //         "defaultTheme": false
  //       }])
  //     }
  //   }
  // }, [mirroredFrom]);

  //#region useeffect
  useEffect(() => {
    fetchWhiteLabellingConfig();
  }, []);

  //#render save and apply buttons
  const renderSaveAndApplyButtons = () => {
    if (labelingDataSet?.find(u => u.themeName === selectedTheme)?.activeTheme) {
      setenableButtons(true)
    } else {
      setenableButtons(false)
    }
  }

  useEffect(() => {
    renderSaveAndApplyButtons()
  }, [selectedTheme])

  //#region return
  return (
    <div>
      <div>
        {/* Heading */}
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', marginBottom: '15px' }}
        >
          White Labeling
        </Typography>

        {/* Theme Selection and Add Button Section */}
        {labelingDataSet?.length > 0 ? (
          <Box
            display="flex"
            flexDirection={'row'}
            alignItems="center"
            justifyContent="space-between"
            gap="15px"
            sx={{ marginBottom: '20px', width: '100%' }}
          >
            <Box display="flex" gap="15px" alignItems="center">
              <FormControl
                variant="outlined"
                size="small"
                sx={{
                  minWidth: '200px',
                  '& .MuiOutlinedInput-root': {
                    height: '32px',
                    '& .MuiSelect-select': {
                      height: '32px',
                      padding: '0 32px 0 14px',
                      display: 'flex',
                      alignItems: 'center',
                      lineHeight: '1.5',
                    },
                  },
                  '& .MuiInputLabel-outlined': {
                    transform: 'translate(14px, 8px) scale(1)',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -6px) scale(0.75)',
                    },
                  },
                }}
              >
                <InputLabel id="choose-theme-label">Choose Theme</InputLabel>
                <Select
                  labelId="choose-theme-label"
                  value={selectedTheme}
                  onChange={handleThemeChange}
                  label="Choose Theme"
                >
                  {labelingDataSet.map((theme) => (
                    <MenuItem
                      key={theme.whiteLabellingID}
                      value={theme.themeName}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <div
                        style={{
                          width: '12px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: theme.themeColor,
                        }}
                      />
                      {theme.themeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Add Theme Button */}
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleAddTheme}
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  height: '40px',
                  borderRadius: '4px',
                }}
              >
                + Add Theme
              </Button>
            </Box>
            {
              <><Box display="flex" gap="15px" >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onCancelClick()}
                //disabled={selectedTheme.toLocaleLowerCase()===}
                >
                  <CancelIcon /> Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={enableButtons}
                  onClick={() => setConfirmation(true)}
                >
                  <SaveIcon /> Save
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={enableButtons}
                  onClick={() => setConfirmation(true)}
                >
                  <SaveIcon /> Save and Apply
                </Button>
              </Box></>
            }
          </Box>
        ) : (
          'loading...'
        )}

        <div className='white-card'>
          <div style={{ marginBottom: '5px' }}>
            <b style={{ fontSize: '15px', marginBottom: '5px' }}>{selectedTheme}</b>
          </div>
          <div className={selectedTheme.toLowerCase() === 'default theme' ? "white-card-container disablediv" : "white-card-container"}>
            {LabelingConfig.map((config) => (
              <div className="white-card-section" key={config.title}>
                <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                  <b>{config.title}</b>
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: '20px' }}>
                  {config.subText}
                </Typography>
                <div className="white-card-fields">
                  {config.fields.map((field) => (
                    <WhileLabelingInputField
                      key={field.name}
                      value={currConfig[config.name][field.name]}
                      onChange={(val) => handleUpdateConfig(config.name, field.name, val)}
                      {...field}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <ToastContainer />
        {confirmation && (
          <WhiteLabelingConfigConfirmation
            confirmation={confirmation}
            handleApplyConfig={handleApplyConfig}
            onClose={() => setConfirmation(false)}
          />
        )}
        {resetConfirmation && (
          <ResetConfirmationPopup
            confirmation={resetConfirmation}
            handleApplyConfig={ResetConfig}
            onClose={() => setResetConfirmation(false)}
          />
        )}

        {addtheme && (
          <AddThemePopup
            open={addtheme}
            themeName={themeName}
            setThemeName={setThemeName}
            setThemeColor={setThemeColor}
            themeColor={themeColor}
            mirroredFrom={mirroredFrom}
            setMirroredFrom={setMirroredFrom}
            onSave={handleSaveTheme}
            onClose={handlePopupClose}
            themes={labelingDataSet}
          />
        )}
      </div>
    </div>

  );
};
