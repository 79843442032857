import React, { useState, useEffect } from 'react';
import { Dialog, Grid } from "@mui/material";
import TextInput from '../../common/TextInput/TextInput';
import Button from '@mui/material/Button';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Password, Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import secureLocalStorage from 'react-secure-storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UpdateUserPassword } from '../Services/TabsData';
import { useNavigate } from 'react-router-dom';
import { msalConfig } from '../../AppAuthentication/authConfig';
import { useCallback } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import SaveIcon from '@mui/icons-material/Save';
import OTPVerificationDialog from '../../common/OTPVerification/OtpVerification';
import { getOtpID } from '../../user-profile/services/UserProfileService';
import { changePasswordToastMessages } from '../../../utils/AppConstants';
const ChangePassword = () => {
    const InitialData = {
        newpassword: '',
        confirmpassword: ''
    }
    const handlePaste = (event) => {
        event.preventDefault();
        const pastedText = (event.clipboardData || window.clipboardData).getData('text');

    };
    useEffect(() => {
        // Clear password fields when the component mounts
        setPasswords(InitialData);
    }, []);
    const msalInstance = new PublicClientApplication(msalConfig);
    const [Passwords, setPasswords] = useState(InitialData);
    const navigate = useNavigate();
    const [openpopup, setopenpopup] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [showConfirmPassword, setshowConfirmPassword] = useState(false);
    const handleClickshowConfirmPassword = () => setshowConfirmPassword(!showConfirmPassword);
    const handleMouseDownshowConfirmPassword = () => setshowConfirmPassword(!showConfirmPassword);


    const [errors, setErrors] = useState({});


    const handleChange = (ev) => {
        const field = ev.target.name;
        const value = ev.target.value;
        const currState = { ...Passwords, [field]: value };
        setPasswords(currState);
        const validationResult = validateField(field, value, currState);
        setErrors((prevErrors) => ({ ...prevErrors, [field]: validationResult.error }));

        if (field === 'newpassword') {
            const confirmValidationResult = validateField('confirmpassword', currState.confirmpassword, currState);
            setErrors((prevErrors) => ({ ...prevErrors, confirmpassword: confirmValidationResult.error }));
        }
    };

    const validateField = (field, value, currState) => {
        const validationRules = {
            newpassword: (value) => {
                const lowercaseRegex = /[a-z]/;
                const uppercaseRegex = /[A-Z]/;
                const digitRegex = /\d/;
                const symbolRegex = /[@#$%^&*()_+={}\[\]:;"'<>,.?/|\\\-~]/;
                const trimmedValue = value.trim(); // Trims leading and trailing whitespace

                const hasLowercase = lowercaseRegex.test(trimmedValue);
                const hasUppercase = uppercaseRegex.test(trimmedValue);
                const hasDigit = digitRegex.test(trimmedValue);
                const hasSymbol = symbolRegex.test(trimmedValue);

                const isValid =
                    hasLowercase && // At least one lowercase letter
                    hasUppercase && // At least one uppercase letter
                    hasDigit && // At least one digit
                    hasSymbol && // At least one special character
                    trimmedValue.length >= 12 && // Minimum length of 12 characters
                    trimmedValue.length <= 16 && // Maximum length of 16 characters
                    value.charAt(0) !== ' ' && // Password does not begin with whitespace
                    value.charAt(value.length - 1) !== ' '; // Password does not end with whitespace

                return {
                    isValid,
                    error: isValid
                        ? null
                        : (
                            <ul>
                                <li>Password must be 12-16 characters long.</li>
                                <li>
                                    Must contain at least one lowercase letter, one uppercase letter, one digit, and one special character.
                                    (! @ # $ % ^ &amp; * ( ) - _ = + &#123; &#125; [ ] | \ : ; " ' &lt; &gt; , . ? /)
                                </li>
                                <li>Password must not begin or end with whitespace.</li>
                            </ul>
                        ),
                };
            },
            confirmpassword: (value) => {
                const isValid = value === currState.newpassword;
                return {
                    isValid,
                    error: isValid ? null : "Passwords do not match.",
                };
            },
        };
        return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
    };

    const MandatoryFieldErrors = () => {
        const fieldsToInclude = ['newpassword', 'confirmpassword'
        ];
        // 
        const trimmedValues = { ...Passwords };
        Object.keys(trimmedValues).forEach(field => {
            if (!fieldsToInclude.includes(field)) {
                delete trimmedValues[field];
            }
        });
        const Mandatory = Object.entries(trimmedValues).map(([field, value]) => ({ field, value }));
        const EmptyFields = Mandatory.filter(entry => entry.value === "" || entry.value === null || entry.value === 0).map(entry => entry.field);
        const error = `This field is required.`;
        // 

        EmptyFields.length > 0 && EmptyFields.map((item) => {
            //          
            setErrors(prevErrors => ({ ...prevErrors, [item]: error }));
        })

        return EmptyFields.length > 0 ? true : false;
    };

    const handleonSave = async () => {
        const errorsArray = Object.values(errors);
        const hasErrors = errorsArray.some(error => error !== null);
        if (hasErrors || Passwords.newpassword === '' || Passwords.confirmpassword === '') {
            toast.warning(changePasswordToastMessages.ENTER_VALID_PW,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            //    setopenpopup(true);
            setLoading(false);
        }
        else {
            if (Passwords.newpassword === Passwords.confirmpassword) {
                try {
                    const result = await UpdateUserPassword(Passwords.newpassword);

                    if (result == 200 || result == 204) {
                        console.log('Update successful:', result);
                        setopenpopup(true);
                        toast.success(changePasswordToastMessages.PW_UPDATED,
                            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                    }
                    else {
                        setLoading(false);
                    }
                } catch (error) {
                    setLoading(false);
                    toast.warning(changePasswordToastMessages.UNABLE_TO_UPDATE,
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                }
            }
            else {
                setLoading(false);
                toast.warning(changePasswordToastMessages.PWS_DO_NOT_MATCHED,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
        }
    }
    const Logout = () => {
        /* setLoading(false); */
        setopenpopup(false);
        secureLocalStorage.clear();
        msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
    };
    const reloginPopup = () => {
        return (
            <Dialog open>
                <DialogTitle>

                </DialogTitle>
                <DialogContent>
                    Password has been changed, please relogin.
                </DialogContent>
                <DialogActions>
                    <Button className='btn-primary' variant='contained' onClick={() => { Logout() }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const [verifybtn, setVerifybtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openOTPDialog, setOpenOTPDialog] = useState(false);
    const [OtpData, setOtpData] = useState(null)
    const handleOpenOTPDialog = () => {
        setOpenOTPDialog(true);
    };

    const handleCloseOTPDialog = () => {
        setOpenOTPDialog(false);
        setLoading(false);
    };

    const getOtpData = async () => {
        const ismandatoryErrors = MandatoryFieldErrors();
        if (ismandatoryErrors) {
            return;
        }
        setLoading(true);
        const errorsArray = Object.values(errors);
        const hasErrors = errorsArray.some(error => error !== null);
        if (hasErrors || Passwords.newpassword === '' || Passwords.confirmpassword === '') {
            toast.warning("Please Enter Valid Password",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            return
        }
        toast.warning('Please wait a Moment',
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        const data = await getOtpID();
        if (data?.responseCode == 200) {
            toast.success('The OTP has been sent to the email address',
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            setOpenOTPDialog(true);
            setOtpData(data?.responseData);
        } else {
            setOpenOTPDialog(false)
            toast.warning(data?.responseData,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            setLoading(true);
        }
    }

    return (
        <div>
            <h4>Change Password</h4>
            <div className='white-card'>
                <div className="fundHeaderWrapper">

                    <Grid container xs={12} md={4} spacing={4} style={{ marginTop: '25px' }}>
                        <Grid item xs={12} sx={{ cursor: 'pointer' }}>
                            <TextField
                                label='New Password'
                                variant="outlined"
                                name='newpassword'
                                type={showPassword ? "text" : "password"}
                                defaultValue={Passwords.newpassword}
                                onChange={handleChange}
                                error={!!errors.newpassword}
                                helperText={errors.newpassword}
                                InputProps={{
                                    autoComplete: "off",
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                // autoComplete='off'
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ cursor: 'pointer' }}>
                            <TextField
                                label='Confirm New Password'
                                variant="outlined"
                                name='confirmpassword'
                                type={showConfirmPassword ? "text" : "password"}
                                error={!!errors.confirmpassword}
                                helperText={errors.confirmpassword}
                                onPaste={handlePaste}
                                onChange={handleChange}
                                autoComplete='false'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickshowConfirmPassword}
                                                onMouseDown={handleMouseDownshowConfirmPassword}
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item container xs={12} spacing={1} justifyContent="center">
                            <Grid item>
                                <Button
                                    component="label"
                                    variant="contained"
                                    className='btn-primary'
                                    onClick={getOtpData}
                                /* disabled={
                                    Passwords.newpassword === '' ||
                                    Passwords.confirmpassword === '' ||
                                    !!errors.newpassword ||
                                    !!errors.confirmpassword ||
                                    loading
                                } */

                                >
                                    <SaveIcon /> Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {openpopup && reloginPopup()}
            <ToastContainer />
            <OTPVerificationDialog open={openOTPDialog} onClose={handleCloseOTPDialog} OtpData={OtpData} onlyOtpVerification={true} getOtpData={getOtpData} handleSave={handleonSave} />
        </div>

    )
}

export default ChangePassword