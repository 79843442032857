import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { investDetails } from '../../../APIDataTemplate';
import { Button, MenuItem, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddShareType from '../../../popups/AddShareType';
import AddRoundInvestment from '../../../popups/AddRoundInvestment';
import { createTransaction } from '../../../services/services';
import secureLocalStorage from 'react-secure-storage';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import DateField from '../../../../common/input-fields/DateField';
import ActionButton from '../../../../common/ActionButton/ActionButton';

const StockBuy = (props) => {

  //#region props
  const { shareTypes, transactionTypes, getAllTransactions, roundInvestments, getShareTypeValues, getRoundInvestments } = props;

  //#region validations
  const [loading, setLoading] = useState(false);
  const [addshareTypeIDPopup, setAddshareTypeIDPopup] = useState(false);
  const [addroundOfInvestmentIDPopup, setAddroundOfInvestmentIDPopup] = useState(false);

  //#region functions
  const calculateTotalTransactionCost = () => {
    let quantity = investmentValues.values.quantity === '' ? 0 : investmentValues.values.quantity;
    let sharePrice = investmentValues.values.sharePrice === '' ? 0 : investmentValues.values.sharePrice;
    let transactionCost = investmentValues.values.transactionCost === '' ? 0 : investmentValues.values.transactionCost;
    const totalTransactionCost = (parseInt(quantity) * parseInt(sharePrice)) + parseInt(transactionCost)
    return totalTransactionCost || 0;
  };

  //#region change events 
  const handleChange = (name, value) => {
    // debugger
    if (name === 'sharePrice') {
      let quantity = investmentValues.values.quantity === '' ? 0 : investmentValues.values.quantity;
      let sharePrice = value === '' ? 0 : value;
      let transactionCost = investmentValues.values.transactionCost === '' ? 0 : investmentValues.values.transactionCost;
      const amount = (parseFloat(quantity) * parseFloat(sharePrice)) + parseFloat(transactionCost)
      investmentValues.setFieldValue('amount', Math.round(amount * 100) / 100);
    }
    if (name === 'amount') {
      let quantity = investmentValues.values.quantity === '' ? 0 : investmentValues.values.quantity;
      let amount = value === '' ? 0 : value;
      let transactionCost = investmentValues.values.transactionCost === '' ? 0 : investmentValues.values.transactionCost;
      const sharePrice = quantity === 0 ? 0 : (parseFloat(amount) - parseFloat(transactionCost)) / parseFloat(quantity);
      investmentValues.setFieldValue('sharePrice', Math.round(sharePrice * 100) / 100);
    }
    if (name === 'transactionCost') {
      let quantity = investmentValues.values.quantity === '' ? 0 : investmentValues.values.quantity;
      let sharePrice = investmentValues.values.sharePrice === '' ? 0 : investmentValues.values.sharePrice;
      let transactionCost = value === '' ? 0 : value;
      const amount = (parseFloat(quantity) * parseFloat(sharePrice)) + parseFloat(transactionCost)
      investmentValues.setFieldValue('amount', Math.round(amount * 100) / 100);
    }
    if (name === 'quantity') {
      let quantity = value === '' ? 0 : value;
      let sharePrice = investmentValues.values.sharePrice === '' ? 0 : investmentValues.values.sharePrice;
      let transactionCost = investmentValues.values.transactionCost === '' ? 0 : investmentValues.values.transactionCost;
      const amount = (parseFloat(quantity) * parseFloat(sharePrice)) + parseFloat(transactionCost)
      investmentValues.setFieldValue('amount', Math.round(amount * 100) / 100);
    }
    investmentValues.setFieldValue(name, value);
  }

  //#region click events
  const onOpenshareTypeIDPopUp = () => {
    setAddshareTypeIDPopup(true);
  }
  const onCloseshareTypeIDPopup = () => {
    setAddshareTypeIDPopup(false);
  }
  const onNewInvestmentPopUp = () => {
    setAddroundOfInvestmentIDPopup(true);
  }
  const onCloseNewInvestmentPopUp = () => {
    setAddroundOfInvestmentIDPopup(false);
  }

  const onResetClick = () => {
    investmentValues.resetForm();
  }

  //#region api post calls
  const createBuyTransaction = async () => {
    setLoading(true);
    const companyID = secureLocalStorage.getItem("companyID");
    investmentValues.values.companyID = companyID;

    //convert string to integers
    // investmentValues.values.quantity = parseFloat(investmentValues.values.quantity);
    // investmentValues.values.sharePrice = parseFloat(investmentValues.values.sharePrice);
    // investmentValues.values.transactionCost = parseFloat(investmentValues.values.transactionCost);
    // investmentValues.values.amount = parseFloat(investmentValues.values.amount);

    //Transaction Type --Buy
    const buyTransaction = transactionTypes?.find((trans) => trans.listItemValue.toLowerCase() === "buy")
    investmentValues.values.transactionTypeID = buyTransaction?.listItemID;
    investmentValues.values.transactionTypeName = buyTransaction?.listItemValue;

    const data = await createTransaction(companyID, investmentValues.values);
    if (data.responseCode === 200) {
      /*  setShareTypes(data.responseData); */
      setLoading(false);
      getAllTransactions();
      investmentValues.resetForm();
      toast.success("New Transaction Completed Succesfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else {
      setLoading(false);
      toast.warning("Unable to Create Transaction", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  }

  //#region formik validations
  const investmentValidationSchema = yup.object().shape({
    transactionDate: yup.string().required('This Field is required'),
    shareTypeID: yup
      .string()
      .test(
        'not-zero',
        'This Field is required',
        value => value !== '0')
      .required('This Field is required'),
    quantity: yup.string()
      .required('This Field is required.')
      .matches(/^\d+$/, 'Number of Shares must be a numeric value.')
      .test('is-positive', 'Number of Shares must be a positive value.', value => {
        if (!value) return true; // Allow empty value as it's handled by 'required'
        return parseFloat(value) >= 0;
      }),
    sharePrice: yup.string()
      .required('This Field is required.')
      .matches(/^\d+(\.\d{1,2})?$/, 'Share Price accepts only 2 decimal values.')
      .test('is-positive', 'Share Price must be a positive value.', value => {
        if (!value) return true; // Allow empty value as it's handled by 'required'
        return parseFloat(value) >= 0;
      }),
    transactionCost: yup.string()
      .required('This Field is required.')
      .matches(/^\d+(\.\d{1,2})?$/, 'Transaction Cost accepts only 2 decimal values.')
      .test('is-positive', 'Transaction Cost must be a positive value.', value => {
        if (!value) return true; // Allow empty value as it's handled by 'required'
        return parseFloat(value) >= 0;
      }),
    amount: yup.string()
      .required('This Field is required.')
      .matches(/^\d+(\.\d{1,2})?$/, 'Total Purchase Price accepts only 2 decimal values.')
      .test('is-positive', 'Total Purchase Price must be a positive value.', value => {
        if (!value) return true; // Allow empty value as it's handled by 'required'
        return parseFloat(value) >= 0;
      }),
    roundOfInvestmentID: yup
      .string()
      .test(
        'not-zero',
        'This Field is required',
        value => value !== '0')
      .required('This Field is required'),
  });

  const investmentValues = useFormik({
    initialValues: {
      ...investDetails
    },
    validationSchema: investmentValidationSchema,
    onSubmit: (values) => {
      createBuyTransaction(values);
    },
  });

  //#region return
  return (
    <div>
      <form className='child-margin-15' onSubmit={investmentValues.handleSubmit} novalidate="novalidate">
        <div className='width-percent-30'>
          <DateField
            name="transactionDate"
            label="Investment Date"
            value={investmentValues.values.transactionDate || []}
            onChange={(name, value) => handleChange(name, value)}
            onBlur={investmentValues.handleBlur}
            error={investmentValues.touched.transactionDate && Boolean(investmentValues.errors.transactionDate)}
            errorMessage={investmentValues.touched.transactionDate && investmentValues.errors.transactionDate} />
        </div>
        <div className='child-row-margin-10'>
          <div className='margin-left-minus-2 width-percent-30'>
            <TextField
              select
              id="shareTypeID"
              name="shareTypeID"
              label="Share Type"
              variant='standard'
              required={true}
              fullWidth
              value={investmentValues.values.shareTypeID}
              // onChange={investmentValues.handleChange}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              InputLabelProps={{
                shrink: true,
                focused: true,
              }}
              onBlur={investmentValues.handleBlur}
              error={investmentValues.touched.shareTypeID && Boolean(investmentValues.errors.shareTypeID)}
              helperText={investmentValues.touched.shareTypeID && investmentValues.errors.shareTypeID}>
              {shareTypes?.length > 0 && shareTypes?.map((item) => (
                <MenuItem key={item.listItemID} value={item.listItemID}>
                  {item.listItemValue}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div>
            <Button
              label="ADD SHARE TYPE"
              variant="outlined"
              className="btn-primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={onOpenshareTypeIDPopUp}>
              ADD SHARE TYPE
            </Button>
          </div>
        </div>
        <div className='width-percent-30'>
          <TextField
            id="quantity"
            name="quantity"
            label="Number of Shares"
            variant='standard'
            required={true}
            fullWidth
            value={investmentValues.values.quantity}
            // onChange={investmentValues.handleChange}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={investmentValues.handleBlur}
            error={investmentValues.touched.quantity && Boolean(investmentValues.errors.quantity)}
            helperText={investmentValues.touched.quantity && investmentValues.errors.quantity} />
        </div>
        <div className='width-percent-30'>
          <TextField
            id="sharePrice"
            name="sharePrice"
            label="Share price"
            variant='standard'
            required={true}
            fullWidth
            value={investmentValues.values.sharePrice}
            // onChange={investmentValues.handleChange}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={investmentValues.handleBlur}
            error={investmentValues.touched.sharePrice && Boolean(investmentValues.errors.sharePrice)}
            helperText={investmentValues.touched.sharePrice && investmentValues.errors.sharePrice} />
        </div>
        <div className='width-percent-30'>
          <TextField
            id="transactionCost"
            name="transactionCost"
            label="Transaction Cost"
            variant='standard'
            fullWidth
            value={investmentValues.values.transactionCost}
            // onChange={investmentValues.handleChange}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={investmentValues.handleBlur}
            error={investmentValues.touched.transactionCost && Boolean(investmentValues.errors.transactionCost)}
            helperText={investmentValues.touched.transactionCost && investmentValues.errors.transactionCost} />
        </div>
        <div className='width-percent-30'>
          <TextField
            id="amount"
            name="amount"
            label="Total Purchase Price"
            variant='standard'
            fullWidth
            value={investmentValues.values.amount}
            // onChange={investmentValues.handleChange}
            onChange={(event) => handleChange(event.target.name, event.target.value)}
            InputLabelProps={{
              shrink: true,
              focused: true,
            }}
            onBlur={investmentValues.handleBlur}
            error={investmentValues.touched.amount && Boolean(investmentValues.errors.amount)}
            helperText={investmentValues.touched.amount && investmentValues.errors.amount} />
        </div>
        <div className='child-row-margin-10'>
          <div className='width-percent-30 margin-left-minus-2'>
            <TextField
              select
              id="roundOfInvestmentID"
              name="roundOfInvestmentID"
              label="Round of Investment"
              variant='standard'
              required={true}
              fullWidth
              value={investmentValues.values.roundOfInvestmentID}
              // onChange={investmentValues.handleChange}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              InputLabelProps={{
                shrink: true,
                focused: true,
              }}
              onBlur={investmentValues.handleBlur}
              error={investmentValues.touched.roundOfInvestmentID && Boolean(investmentValues.errors.roundOfInvestmentID)}
              helperText={investmentValues.touched.roundOfInvestmentID && investmentValues.errors.roundOfInvestmentID}>
              {roundInvestments?.length > 0 && roundInvestments?.map((item) => (
                <MenuItem key={item.listItemID} value={item.listItemID}>
                  {item.listItemValue}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div>
            <Button
              variant="outlined"
              className="btn-primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={onNewInvestmentPopUp}>
              ROUND OF INVESTMENT
            </Button>
          </div>
        </div>
        <div className='space-between width-percent-30'>
          <div></div>
          <div className='space-between'>
            <div>
              <ActionButton
                label="Cancel"
                variant="outlined"
                className="btn-primary"
                onClick={onResetClick}
                icon={<CancelIcon />}
              />
            </div>
            <div className='margin-left-10'>
              <LoadingButton
                variant='contained'
                className="btn-primary"
                startIcon={<SaveIcon />}
                loading={loading}
                type='submit'
                loadingPosition="start">
                <p className={`actionButtonLabel`}>SAVE</p>
              </LoadingButton>
            </div>
          </div>
        </div>
      </form>
      {
        addshareTypeIDPopup &&
        <AddShareType open={addshareTypeIDPopup} onClose={onCloseshareTypeIDPopup} getShareTypeValues={getShareTypeValues} />
      }
      {
        addroundOfInvestmentIDPopup &&
        <AddRoundInvestment open={addroundOfInvestmentIDPopup} onClose={onCloseNewInvestmentPopUp} getRoundInvestments={getRoundInvestments} />
      }
      <ToastContainer />
    </div>
  )
}

export default StockBuy