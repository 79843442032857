import React, { useEffect, useRef, useState } from 'react'
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader'
import ActionButton from '../../../common/ActionButton/ActionButton'
import FundamentalDetails from './FundamentalDetails';
import GovernanceDetails from './GovernanceDetails';
import DueDiligenceDetails from './DueDiligenceDetails';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { toast, ToastContainer } from 'react-toastify';
import { newCompanyDetails } from '../../APIDataTemplate';

const CreateCompanyModel = () => {

  //#region variables
  const [currStep,setCurrStep] = useState(1);
  const [currStepDetails, setCurrStepDetails] = useState(null);
  const [companyDetails, setCompanyDetails] = useState({...newCompanyDetails});

  //#region ref for validations
  const fundamentalDetailsRef = useRef(null);
  const dueDiligenceDetailsRef = useRef(null);
  const governanceDetailsRef = useRef(null);

  //#region click evnets
  const onNextClick = async () => {
      const isValid = await validateStepFields();
      if (isValid) {
        if (currStep === steps?.length) {
          onSaveClick();
        }
        else {
          setCurrStep(currStep + 1);
        }
      } else {
        toast.warning("Please fill in mandatory fields",
          { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
      }
    };

  const onPrevClick = () => {
    updateFields();
    setCurrStep(currStep-1);
  }

  const onSaveClick = () => {

  }

  //#region steps
  const steps = [
    {
      id: 1,
      title: 'Provide Fundamental Company Details',
      componentRef: fundamentalDetailsRef,
      component: <FundamentalDetails
                    ref={fundamentalDetailsRef}
                    companyDetails={companyDetails}
                    setCompanyDetails={setCompanyDetails} />
    },
    {
      id: 2,
      title: 'Provide Due Diligence Details',
      componentRef: dueDiligenceDetailsRef,
      component: <DueDiligenceDetails 
                    ref={dueDiligenceDetailsRef}
                    companyDetails={companyDetails}
                    setCompanyDetails={setCompanyDetails} />
    },
    {
      id: 3,
      title: 'Provide Funding and Governance Details',
      componentRef: governanceDetailsRef,
      component: <GovernanceDetails
                    ref={governanceDetailsRef}
                    companyDetails={companyDetails}
                    setCompanyDetails={setCompanyDetails} />
    }
  ]

  //#region step validation checks
  const updateFields = () => {
    return currStepDetails?.componentRef.current?.updateFields();
  }
  const validateStepFields = () => {
    return currStepDetails?.componentRef.current?.validateFields();
  };

  //#region useeffect
  useEffect(() => {
      const selectedStep = steps.find(step => step.id === currStep);
      setCurrStepDetails(selectedStep);
    }, [currStep])
  
  //#region return
  return (
    <div>
      <PageHeader
        primaryTitle="Add Portfolio Company"
        description="Provide key company details to add a new Portfolio Company"
        template={HeaderTemplates.BASIC} />
      <div className='wrapper-40'>
        <div>
          <h4>{steps[currStep-1].title}</h4>
          <div>
            {steps[currStep-1].component}
          </div>
        </div>
        <div className='space-between margin-top-20'>
          <div>
            {
              currStep > 1 &&
              <ActionButton 
                label="Previous"
                onClick={onPrevClick}
                icon={<ArrowBackOutlinedIcon />}/>
            }
          </div>
          <div>
            {
              currStep < steps.length 
                ? <ActionButton 
                   label="Next"
                   onClick={onNextClick}
                   endIcon={<ArrowForwardOutlinedIcon />}/>
                : <ActionButton 
                   label="Submit"
                   onClick={onNextClick}
                   icon={<TouchAppIcon />}/>
            }
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default CreateCompanyModel