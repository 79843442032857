import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import propTypes from 'prop-types';
import { Tooltip } from '@mui/material';

const filter = createFilterOptions();

const AutoSelectField = (props) => {

  //#region props
  const {
    name,
    label,
    value,
    onChange,
    options,
    variant,
    onBlur,
    required,
    disabled,
    error,
    errorMessage,
    helperText,
    readOnly,
    childName,
    childValue,
    isAddOption,
    isMultiSelect,
    toolTip
  } = props;

  //#region functions
  const renderValue = () => {
    const crtValue = value ? 
                      isMultiSelect 
                      ? options?.filter(item => value.includes(item.value)) || []
                       : 
                    value === -1 
                    ?  childValue ? { label : childValue } : "" : 
                    Number.isInteger(value) ? options?.find((option) => option.value === value)?.label || "" : value : ""
    return crtValue;
  }

  //#region change events 
  const handleChange = (event, newValue) => {
    let updatedValue;
    if (typeof newValue === 'string') {
      updatedValue = { label: newValue };
      onChange(childName, newValue);
      onChange(name, -1);
    } else if (newValue && newValue.inputValue) {
      updatedValue = { label: newValue.inputValue };
      onChange(childName, newValue.inputValue);
      onChange(name, -1);
    } else {
      if(isMultiSelect){
        const extractIds = newValue?.map((item) => {
          if (typeof item === 'object' && item !== null && 'value' in item) {
            return item.value;
          } 
          return item;
        });        
        onChange(name, extractIds);
      }
      else{
        updatedValue = newValue !== null ? newValue.value : "";
        onChange(name, updatedValue);
        onChange(childName, "");
      }
    }
  };

  return (
    <Autocomplete
      id={name}
      multiple={isMultiSelect}
      /* freeSolo={(isAddOption && !isMultiSelect) ? true : false} THIS WILL DASABLE THE DROPDOWN ICON */
      value={renderValue()}
      onChange={handleChange}
      filterOptions={
        isAddOption 
          ? (options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = options.some((option) => inputValue === option.label);
              
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `Add "${inputValue}"`,
                });
              }
              
              return filtered;
            }
          : undefined // If isAddOption is false, set filterOptions to undefined
      }
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.label;
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            {option.label}
          </li>
        );
      }}
      readOnly={readOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
              <Tooltip title={toolTip}>
                  {label}
              </Tooltip>
          }
          variant={variant}
          onBlur={onBlur}
          required={required}
          disabled={disabled}
          error={error}
          helperText={error ? errorMessage : helperText}
          InputProps={{
            ...params.InputProps,
            readOnly: readOnly,
          }}
          InputLabelProps={{
            shrink: true,
            focused: true,
          }} 
        />
      )}
    />
  );
}

AutoSelectField.propTypes = {
    type: propTypes.string,
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    value: propTypes.string,
    placeholder: propTypes.string,
    label: propTypes.string,
    disabled: propTypes.bool,
    options: propTypes.array,
    name: propTypes.string,
    size: propTypes.string,
    errorMessage: propTypes.string,
    helperText: propTypes.string,
    readOnly: propTypes.bool,
    childName: propTypes.string,
    childValue: "",
    isAddOption: propTypes.bool,
    isMultiSelect: propTypes.bool,
    toolTip: propTypes.string
};

AutoSelectField.defaultProps = {
    type: 'select',
    variant: 'standard',
    size: 'small',
    required: false,
    value: '',
    placeholder: '',
    label: '',
    options: [],
    disabled: false,
    name: '',
    errorMessage: '',
    helperText: '',
    readOnly: false,
    childName: propTypes.string,
    childValue: propTypes.any,
    isAddOption: true,
    isMultiSelect: false,
    toolTip: ""
};

export default AutoSelectField;
