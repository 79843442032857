import { Dialog, DialogTitle, DialogActions, DialogContent, Tooltip, LinearProgress, } from '@mui/material';
import { useEffect, useState } from 'react';
import ActionButton from '../../common/ActionButton/ActionButton';
import CloseIcon from '@mui/icons-material/Close';
import { FieldVariants, textFormatTypeEnum } from '../../common/TextInput/appInputenum';
import { formatAsUSACurrency } from '../../common/CurrencyFormater/formatAsUSACurrency';
import ALTTextField from '../../common/input-fields/ALTTextField';
import SaveIcon from '@mui/icons-material/Save';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getFundSubscriptions } from '../../dashboards/services/DashboardService';
import { subscriptionStatusEnum } from '../../investor-fund-subscription/services/apiDataTemplate';
import SubscriptionRejectionPopup from './SubscriptionRejectionPopup';
import { toast, ToastContainer } from "react-toastify";
import { AskToReduceCC } from '../services/services';
import { updateFund } from '../../marketplace/services/MarketplaceService';
import secureLocalStorage from 'react-secure-storage';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { validationsConstants } from '../../../utils/AppConstants';
import { ConvertToUSCurrency } from '../../common/Functions/CommonConvertions';
import ConfirmationPopup from '../../common/commonpopups/ConfirmationPopup';

const FundRaisePopup = (props) => {

  //#region props
  const { open, onClose, fundDetails,getFundDetails } = props;

  //#region initalData
  const initialData = {
    newTargetRaise: fundDetails?.fundSize
  }

  //#region variables
  const [loading, setLoading] = useState(false);
  const [investorsData,setInvestorsData] = useState(null);
  const [rejectionPopup,setRejectionPopup] = useState(false);
  const [currSubscriptionDetails,setCurrSubscriptionDetails] = useState();
  const [sendReductionBtnLoading,setSendReductionBtnLoading] = useState(false);
  const [targetRaiseConfirmationPopup,setTargetRaiseConfirmationPopup] = useState(false);

  //#region functions
  const totalReductionAmount = () => {
     return investorsData?.reduce((sum, inv) => sum + (inv.newAmount || 0), 0);
  }

  //#region columns
  const columns = [
    { field: 'rowName', headerName: 'Investor Name', flex: 1,
        renderCell: (params) => (
            <div>
                {
                    params.row?.rowName === "Total" 
                    ? <b>{params.row?.rowName}</b>
                    :  params.row?.rowName
                }
            </div>
        ),
    },
    { field: 'subscribed', headerName: 'Subscription Amount', flex: 1,
        renderCell: (params) => (
            <div>
                {
                    params.row?.isCustomRow 
                    ? params.row.id === "custom-total" ? <b>{ConvertToUSCurrency(params.row.subscribed)}</b> : <></>
                    :  ConvertToUSCurrency(params.row?.subscribed)
                }
            </div>
        ),
    },
    { field: 'askToReduceBy', headerName: 'Ask to reduce by', flex: 1,
        renderCell: (params) => (
            <div>
               {
                params.row?.isCustomRow 
                 ? params.row.id === "custom-total" ? <b>{ConvertToUSCurrency(params.row.askToReduceBy)}</b> : <></>
                 : params.row.subscribed === fundDetails?.minimumInvestmentAmount
                    ? ""
                    : parseInt(params.row.proposedSubscriptionAmount) > 0 
                    ? parseInt(params.row?.subscribed - params.row.proposedSubscriptionAmount)
                    : <ALTTextField
                        textFormatType={textFormatTypeEnum.US_CURRENCY} 
                        label=""
                        name={params.row.subscriptionsID}
                        variant={FieldVariants.OUTLINED}
                        onChange={(name,value)=>{handleAmountChange(name,value)}}
                        error={
                            params.row.subscribed - (params.row.askToReduceBy || 0) < fundDetails.minimumInvestmentAmount
                            ? "Minimum Investment Amount is not met"
                            : params.row.subscribed === fundDetails?.minimumInvestmentAmount
                            ? "Subscription Amount is equal to Minimum Investment Amount"
                            : ""
                        }
                        errorMessage=""/>
               }
            </div>
        ),
    },
    { field: 'newAmount', headerName: 'New Amount', flex: 1,
        renderCell: (params) => (
            <div>
                {
                    params.row?.isCustomRow 
                        ? <b>{ConvertToUSCurrency(params.row.newAmount)}</b>
                        : parseInt(params.row.proposedSubscriptionAmount) > 0
                          ?  <>{ConvertToUSCurrency(params.row.proposedSubscriptionAmount)}</>
                          :  <>{ConvertToUSCurrency(params.row?.newAmount)}</>
                }
            </div>
        ),
    },
    { field: 'sendReductionRequest', headerName: 'Send Reduction Request', flex: 1,
        renderCell: (params) => (
        <>
            {
                params.row?.isCustomRow ? "" :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <ActionButton 
                            label={parseInt(params.row.proposedSubscriptionAmount) > 0 ? "Sent" : "Send"}
                            loading={sendReductionBtnLoading}
                            disabled={loading ||
                                     !(parseInt(params.row.askToReduceBy) > 0) 
                                     || params.row.subscribed - (params.row.askToReduceBy || 0) < fundDetails.minimumInvestmentAmount
                                     || params.row.subscribed === fundDetails?.minimumInvestmentAmount
                                     || params.row.isRowDisabled}
                            variant={FieldVariants.OUTLINED}
                            onClick={()=>{sendReductionRequest(params.row)}} 
                            styleProps= {
                                {padding: '16px 36px'}
                            }
                            toolTip={
                                params.row.isRowDisabled 
                                ? ""
                                : !(parseInt(params.row.askToReduceBy) > 0)
                                ? "Please enter the  reduction amount" 
                                : params.row.subscribed - (params.row.askToReduceBy || 0) < fundDetails.minimumInvestmentAmount ?
                                  `The minimum investment amount for this fund is ${ConvertToUSCurrency(fundDetails?.minimumInvestmentAmount)}. Ensure the adjusted amount meets this threshold`
                                : params.row.subscribed === fundDetails?.minimumInvestmentAmount ? 
                                  "Subscription Amount is equal to Minimum Investment Amount"
                                : ""
                            }/>
                </div>
            }
        </>
        ),
    },
    { field: 'reject', headerName: 'Reject', flex: 1,
        renderCell: (params) => (
            <>
                {
                    params.row?.isCustomRow ? "" :
                        <div className='align-item-center'>
                            <ActionButton 
                                label="REJECT"
                                disabled={loading || sendReductionBtnLoading}
                                variant={FieldVariants.OUTLINED}
                                onClick={()=>{onOpenRejectionPopup(params.row)}} />
                        </div>
                }
            </>
        ),
    },
  ]

  //#region change events
  const handleAmountChange = (name, value) => {
    const currState = [...investorsData];
    const rowIndex = currState?.findIndex((item) => item.subscriptionsID === name);
    if (rowIndex >= 0) {
      currState[rowIndex].askToReduceBy = value;
      currState[rowIndex].newAmount = currState[rowIndex].subscribed - value;
      setInvestorsData(currState);
    }
  }

  const handleChangeTargetRaise = (field, value) => {
      targetRaiseDetails.setFieldValue(field,value)
  }

  //#region click events
  const refreshDetails = () => {
    /* setInvestorsData(null); */
    getSubscribers();
    getFundDetails();
  }

  const onOpenRejectionPopup = (rowDetails) => {
     setCurrSubscriptionDetails(rowDetails);
     setRejectionPopup(true);
  }

  const onCloseRejectionPopup = (isRefresh) => {
    if(isRefresh){
        refreshDetails();
    }
    setRejectionPopup(false);
    setCurrSubscriptionDetails(null);
  }

  const onOpenConfirmationPopup = () => {
    setTargetRaiseConfirmationPopup(true);
  }

  const onCloseConfirmationPopup = () => {
    setTargetRaiseConfirmationPopup(false);
  }

  //#region api get calls
  const getSubscribers = async (showRejected) => {
    const data = await getFundSubscriptions(fundDetails?.fundID);
    if (data.responseCode === 200) {
        const subs = data.responseData
                        .filter((u) =>
                                u.subscriptionStatus.includes("Review") ||
                                u.subscriptionStatus === subscriptionStatusEnum.ON_HOLD)
                        .map((inv) => ({
                            rowName: inv.investor,
                            askToReduceBy: null,
                            isRowDisabled: (inv.subscribed === fundDetails?.minimumInvestmentAmount 
                                            || parseInt(inv?.proposedSubscriptionAmount) > 0),
                            newAmount: parseInt(inv?.proposedSubscriptionAmount) > 0 ? parseInt(inv?.proposedSubscriptionAmount) : inv.subscribed,
                            ...inv,
                        }))
                        .sort((a, b) => {
                           return a.isRowDisabled - b.isRowDisabled;
                        });
      setInvestorsData(subs)
    }
    else {
        setInvestorsData([]);
    }
  }

  //#region api post calls
  const sendReductionRequest = async (rowDetails) => {
      setSendReductionBtnLoading(true);
      const data = await AskToReduceCC(rowDetails.subscriptionsID, rowDetails.newAmount);
      if (data.responseCode === 200) {
          refreshDetails();
          toast.success("Reduction Request Sent Successfully", 
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          setSendReductionBtnLoading(false);
      }
      else {
          setSendReductionBtnLoading(false);
          toast.error("Unable to send reduction request", 
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
  }

  const saveNewTargetRaise = async () => {
    setLoading(true);
    const requestedData = {
        "fundId": fundDetails.fundID,
        "fundName": fundDetails.fundName,
        "fundManager": fundDetails.fundManager,
        "fundDescription": fundDetails.fundDescription,
        "investmentStrategyId": fundDetails.investmentStrategyId,
        "fundTargetIndustryId": fundDetails.fundTargetIndustryId,
        "geographicFocusId": fundDetails.geographicFocusId,
        "fundStatus": fundDetails.fundStatus,
        "vintage": fundDetails.vintage,
        "investmentPeriodCriteria": fundDetails.investmentPeriodCriteria,
        "investmentCycle": fundDetails.investmentCycle,
        "visibility": fundDetails.visibility,
        "bgImage": "",
        "iconImage": "",
        "fundSize": targetRaiseDetails?.values.newTargetRaise,
        "minimumInvestmentAmount": fundDetails.minimumInvestmentAmount,
        "targettedIRR": 0,
        "createdBy": 1,
        "createdDate": new Date(),
        "updatedBy": 1,
        "updatedDate": new Date(),
        "userId": secureLocalStorage.getItem("userId"),
        "isFundMatured": fundDetails.isFundMatured,
        "isFirmAsGP": fundDetails.isFirmAsGP,
        "legalStructureId": 1,
        "fundStaff": fundDetails.fundStaff || ""
    };
    const formDataBody = new FormData();
          formDataBody.append("fundDetails", JSON.stringify(requestedData));
    const data = await updateFund(formDataBody);
    if (data.responseCode === 200) {
        refreshDetails();
        onCloseConfirmationPopup(false);
        setLoading(false);
        onClose();
        toast.success("Target Raise Updated Successfully", 
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
    else {
        setLoading(false);
        toast.error("Unable to update target raise", 
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region useeffect
  useEffect(()=>{
    getSubscribers();
  },[])

  //#region formik validations
  const validationSchema = yup.object().shape({
    newTargetRaise: yup
      .string()
      .required(validationsConstants.REQUIRED)
      .test(
        'is-less-than-fundSize',
        'New Target Raise should be greater than Minimum investment amount',
        function(value) {
          const  minimumInvetsment  = fundDetails?.minimumInvestmentAmount;
          return value && minimumInvetsment ? parseFloat(value) > parseFloat(minimumInvetsment) : true;
       },
      )
      .test(
        'is-less-than-fundSize',
        'New Target Raise should be greater than Total Capital Raised',
        function(value) {
          const  approvedAmount  = fundDetails?.capitalCommitted;
          return value && approvedAmount ? parseFloat(value) > parseFloat(approvedAmount) : true;
       },
      )
      .test(
        'is-less-than-fundSize',
        'New Target Raise should be greater or less than Current target raise',
        function (value) {
          const currentTargetRaise = fundDetails?.fundSize;
          return value && parseInt(currentTargetRaise) !== parseInt(value);
        },
      ),
  });

  const targetRaiseDetails = useFormik({
    initialValues: {
      ...initialData
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
            onOpenConfirmationPopup();
    },
  });

  //#region custom rows
  const customRows = [
    {
        id: 'custom-total',
        subscribed: investorsData?.reduce((sum, inv) => sum + (inv.subscribed || 0), 0),
        askToReduceBy: (investorsData?.reduce((sum, inv) => sum + (parseInt(inv.askToReduceBy) || 0) , 0) 
                        + investorsData
                          ?.reduce((sum, inv) => sum + ( parseInt(inv?.proposedSubscriptionAmount) > 0 
                               ? parseInt(inv?.subscribed - inv?.proposedSubscriptionAmount) || 0 
                               : 0) , 0)),
        newAmount: investorsData?.reduce((sum, inv) => sum + (inv.newAmount || 0), 0),
        label: 'Total', 
        isCustomRow: true,
        rowName: "Total (A)"
    },
    {
        id: 'custom-approved',
        newAmount: fundDetails?.capitalCommitted || 0,
        label: '', 
        isCustomRow: true,
        rowName: "Approved Subscription Amount (B)"
    },
    {
        id: 'custom-fund-raise',
        newAmount: targetRaiseDetails?.values.newTargetRaise,
        label: 'Total', 
        isCustomRow: true,
        rowName: "Fund Target Raise Amount (C)"
    },
    {
        id: 'custom-excess',
        newAmount: totalReductionAmount() + (fundDetails?.capitalCommitted || 0) - targetRaiseDetails?.values.newTargetRaise,
        label: 'Total', 
        isCustomRow: true,
        rowName: "Excess ( (A + B) - C )"
    },
  ]

  //#region return
  return (
        <Dialog open={open} maxWidth="lg" fullWidth>
            <DialogTitle className='space-between'>
                <h4>Increase the fund's target raise amount</h4>
                <div className='cursor-pointer' onClick={onClose}>
                  <Tooltip title="Close" >
                    <CloseIcon color="action" sx={{ width: "24px", height: "24px"}} />
                  </Tooltip>
                </div>
            </DialogTitle>
            <DialogContent className='child-margin-10'>
                <div className='increase-target-taise-sec child-margin-10'>
                    <div>
                        The total of subscription amounts from approved and under-review investors has exceeded the target raising amount for this fund.
                        To address this situation, you can take one or more of the following actions:
                    </div>
                    <div>
                       1. Increase Target Raise Amount Use the input box. 
                    </div>
                    <div>
                       2. Ask Investors to Reduce Subscription Amounts or Reject Requests 
                        This ensures the total remains within the current target raise limit. 
                    </div>
                    <div className='child-margin-5'>
                        <div>Increase Fund Target Raise Amount<br /></div>
                        <table className='width-300'>
                           <tr>
                              <td>Current Target Raise Amount</td >
                              <td>: <b>{formatAsUSACurrency(fundDetails?.fundSize !== null ? fundDetails?.fundSize : 0)}</b></td>
                           </tr>
                           <tr>
                              <td>Minimum Investment Amount</td>
                              <td>: <b>{formatAsUSACurrency(fundDetails.minimumInvestmentAmount)}</b></td>
                           </tr>
                           <tr>
                              <td>Total Capital Raised</td>
                              <td>: <b>{formatAsUSACurrency(fundDetails.capitalCommitted)}</b></td>
                           </tr>
                        </table>
                    </div>
                    <div className='child-row-margin-5 align-item-center'>
                       <div>New Target Raise Amount:</div>
                       <div>
                         <ALTTextField 
                           textFormatType={textFormatTypeEnum.US_CURRENCY}
                           variant={FieldVariants.OUTLINED} 
                           name="newTargetRaise"
                           value={targetRaiseDetails?.values.newTargetRaise}
                           onChange={(name,value) => {handleChangeTargetRaise(name,value)}}
                           onBlur={targetRaiseDetails.handleBlur}
                           error={targetRaiseDetails.touched.newTargetRaise && Boolean(targetRaiseDetails.errors.newTargetRaise)}
                           errorMessage={targetRaiseDetails.touched.newTargetRaise && targetRaiseDetails.errors.newTargetRaise} />
                       </div>
                    </div>
                    <div className='margin-top-10'>
                        <ActionButton 
                          label="SAVE NEW TARGET"
                          icon={<SaveIcon />}
                          loading={loading}
                          onClick={targetRaiseDetails.handleSubmit}
                          />
                    </div>
                </div>
                <div className='reduce-amount-sec'>
                    <div>
                        {
                            investorsData ?
                            <DataGridPro
                                columns={columns}
                                rows={[
                                    ...investorsData?.map((inv) => ({
                                    id: inv.subscriptionsID,
                                    ...inv,
                                    })),
                                    ...customRows,
                                ]}
                                getRowClassName={(params) => {
                                    if (params.row?.isRowDisabled) return '';
                                    if (params.id === 'total') return 'total-row';
                                    return '';
                                }}                                  
                                hideFooter={true}
                                /> : <LinearProgress />}
                    </div>
                    <div>

                    </div>
                </div>
                <div className='notes-sec child-margin-10'>
                    <b>Notes:</b>
                    <div>Increase Target Raise Amount:</div>
                    <div>a. Ask to Reduce By: Enter the amount to reduce for each investor in the Ask to Reduce By field.</div>
                    <div>b. The New Amount field updates automatically based on the reduction input.</div>
                    <div>c. Click <b>Send Reduction Request</b> to notify the investor.</div>
                    <div>d. Reject: Click <b>Reject</b> to decline a subscription request outright.</div>
                </div>
            </DialogContent>
            {
                rejectionPopup && currSubscriptionDetails &&
                <SubscriptionRejectionPopup 
                   open={rejectionPopup}
                   onClose={onCloseRejectionPopup}
                   currSubscriptionDetails={currSubscriptionDetails}/>
            }
            {
                targetRaiseConfirmationPopup &&
                <ConfirmationPopup 
                   open={targetRaiseConfirmationPopup}
                   onClose={onCloseConfirmationPopup}
                   loading={loading}
                   updateFunction={saveNewTargetRaise}
                   customMessage="Are you sure you want to Increase the target raise amount?" />
            }
            {/* <ToastContainer /> */}
        </Dialog>
  )
}
export default FundRaisePopup;