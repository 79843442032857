import React from 'react'
import DocumentsDasboard from './DocumentsDasboard'
import { ReportsNames } from '../jsonData';
import FoldersAccordian from './FoldersAccordian';
import { getRepotingFolders } from '../services/services';
import { useEffect, useState } from 'react';
import '../styles.css';
import ReactLoading from 'react-loading';
import { DocumentsGETType } from '../../InvestorReportingEnum';
import CircularProgress from '@mui/material/CircularProgress';
import CircleIcon from '../../../chatbot/Icon';
import { FeatureAccess, RolesAndPermissions } from '../../../../utils/enum';
import { checkFeaturePermissions } from '../../../../utils/common';

const FirmDocuments = () => {
  
  //#region variables
  const [foldersList, setFoldersList] = useState(null);
  const [componentRefresh,setcomponentRefresh] = useState(false)

  //#region api get calls
  const getFoldersDetails = async () => {
    const data = await getRepotingFolders();
    if (data?.responseCode === 200) {
      //TODO: Check if the parentTypeID is always 86
      const filteredData = data?.responseData?.filter((item) => item.title !== 'Other');

     // Need to add 'item.name' to each filtered item for tab component
      filteredData.forEach((item) => {
        item.name = item.title;
        item.relatedFiles = [];
      });

      const folderNamesWithComponent = filteredData?.map((folder) => {
        return {
          ...folder,
          component: <div className='accordian-wrapper'>
                         <div className="loading-container">
                            <CircularProgress />
                            Loading please wait...
                          </div>
                     </div>
        }
      });

      setFoldersList(folderNamesWithComponent);
    } else { 
      setFoldersList([]);
    }
  }

  //#region useEffect
  useEffect(() => {
    getFoldersDetails();
  }, []);

  useEffect(() => {
    if(componentRefresh){
      getFoldersDetails();
    }
  }, [componentRefresh]);

  return (
    <div>
      {
        foldersList  && !componentRefresh  ?
        <DocumentsDasboard isfileUpload={checkFeaturePermissions(RolesAndPermissions.INVESTOR_REPORTS, FeatureAccess.CREATE)} componentRefresh={componentRefresh} setcomponentRefresh = {setcomponentRefresh} reports={foldersList} setFoldersList={setFoldersList} getFoldersDetails={getFoldersDetails} documentsFor={DocumentsGETType.FIRM_USERS}/> : 
        <div>
          <div className="loading-container">
            <ReactLoading type="spin" color="#0000FF" height={100} width={100} />
          </div>
          <div className="alertMessage">
            Please Wait ...
          </div>
        </div>
      }
      <CircleIcon/>
    </div>
  )
}

export default FirmDocuments