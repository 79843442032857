import { ConvertToUSCurrency } from "../common/Functions/CommonConvertions";
import { stringOperators, dropdownOperators, subscriptionStatusOperator, investmentStatusOperator } from "../FundManagerDashboard/myfundFilterOperators";

export const fundColumns = [
    {
        id: 1,
        field: "iconImage",
        headerName: " ",
        width: 170,
        renderCell: (params) => {
            const labelStyle = {
                backgroundColor: '#1F61C5',
                color: 'white',
                padding: '2px 8px',
                borderRadius: '4px',
                position: 'absolute',
                top: '0',
                left: '0',
                transform: 'translate(10%, -50%)',
                zIndex: 1,
                fontSize: '10px',
            };

            const cellContentStyle = {
                position: 'relative',
            };

            return (
                <div style={cellContentStyle}>
                    <img className='list-icon-img-container' src={params.value !== "" ? params.value : "/DefaultLogo.png"} />
                    <span style={labelStyle}>{params.row.isOfflineSubscription ? "Offline" : "Online"}</span>
                </div>
            );
        },
    },
    {
        id: 2,
        field: "fundName",
        headerName: "Fund Name",
        width: 220,
        filterOperators: stringOperators,
        renderCell: (params) => {
            const labelStyle = {
                backgroundColor: 'white',
                color: 'blue',
                padding: '2px 8px',
                borderRadius: '35px',
                //position: 'absolute',
                transform: 'translate(10%, -50%)',
                zIndex: 1,
                fontSize: '10px',
                marginTop:'10px',
                marginLeft:'-5px'
            };

            const cellContentStyle = {
                position: 'relative',
            };

            return (
                <div className="child-margin-5">
                    <span>{params.row.fundName}</span>
                    <span className="fund-status-label">{params.row.fundStatusName}</span>
                </div>
            );
        },
    },
    {
        id: 4,
        field: "targetIndustry",
        headerName: "Target Sector",
        /* flex: 1, */
    },
    {
        id: 5,
        field: "capitalCommitted",
        headerName: "Capital Commited",
        renderCell: (params) => ConvertToUSCurrency(params.value),
        /* flex: 1, */
    },
    {
        id: 6,
        field: "subscriptionStatus",
        headerName: "Subscription Status",
        filterOperators: subscriptionStatusOperator,
        width: 170,
    },

    {
        id: 7,
        field: "contributedAmount",
        headerName: "Contributed Amount",
        renderCell: (params) => ConvertToUSCurrency(params.value),
    },
    {
        id: 8,
        field: "investmentStatusName",
        headerName: "Investment Current Status",
        width: 170,
        filterOperators: investmentStatusOperator,
    },
    {
        id: 9,
        field: "fundTypeName",
        headerName: "Fund Type",
        width: 170,
        // /filterOperators: investmentStatusOperator,
    },
];

// Columns for Private Equity
export const myInvestmentsprivateEquityColumns = [
    fundColumns[0],
    fundColumns[1],
    fundColumns[2],
    fundColumns[3],
    fundColumns[4],
    fundColumns[5],
    fundColumns[8],
    fundColumns[6],
    fundColumns[7],
];

// Columns for Private Credit
export const myInvestmentsprivateCreditColumns = [
    fundColumns[0],
    fundColumns[1],
    fundColumns[2],
    fundColumns[4],
    fundColumns[5],
    fundColumns[7],
    fundColumns[8],
];