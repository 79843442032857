import { LinearProgress } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { getDraftNotifications, getInvestorsandGroupsList } from '../../Services/GroupsServices';
import secureLocalStorage from 'react-secure-storage';
import { ToastContainer, toast } from 'react-toastify';
import CustomEmail from '../CustomEmail';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Accordian from '../../../common/Accordian/Accordian';
import { CommunicationType } from '../../../../utils/enum';
import { CircularProgres, TablePagination } from '@mui/material';
import Box from '@mui/material/Box';
import CustomNotificationFilters from './CustomNotificationFilters';
import { InvestorCommunicarionToastMessages } from '../../../../utils/AppConstants';


const DeletedNotifications = (props) => {

  const { getNotificationsCount, renderComponent, setRenderComponent } = props;

  //#region variable declaration
  const [deletedNotifications, setdeletedNotifications] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState('5');
  const [investorsList, setInvestorsList] = useState(null);
  const [selectedParentDraftNotification, setSelectedParentDraftNotification] = useState(null);
  const [filteredNotifications, setFilteredNotifications] = useState([]); // For filtered results
  const [page, setPage] = useState(0); // for pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // for pagination

  //#region service calls
  const getDraft = async () => {
    const data = await getDraftNotifications(secureLocalStorage.getItem('userId'), 'Deleted');
    if (data?.responseCode === 200) {
      setdeletedNotifications(data?.responseData);
      setFilteredNotifications(data.responseData);
    } else if (data?.responseCode === 404) {
      setdeletedNotifications(data?.responseData)
      setFilteredNotifications([]);
    }
    else {
      toast.warning(data?.responseData, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
  };

  const getInvestors = async () => {
    const data = await getInvestorsandGroupsList();
    if (data?.responseCode === 200) {
      setInvestorsList(data?.responseData);
    } else {
      toast.warning(InvestorCommunicarionToastMessages.ACTION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
  };

  //#region useffect
  useEffect(() => {
    getDraft();
    getInvestors();
  }, []);

  const selectedInvestors = (crntSelectedNotification) => {
    const oldSelectedUsersAndGroups = [
      ...(crntSelectedNotification?.notificationToUserIDs?.split(",") || []),
      ...(crntSelectedNotification?.notificationToGroupIDs?.split(",") || []),
    ];
    return (investorsList?.filter((u) =>
      oldSelectedUsersAndGroups?.some((id) => id.trim() === u.id.toString())
    ));
  }


  const notificationFiles = (crntSelectedNotification) => {
    if (!crntSelectedNotification?.notificationHasAction) {
      const fileNamesArray = crntSelectedNotification?.fileNames
        ? crntSelectedNotification.fileNames.split(",").map((fileName) => fileName.trim())
        : [];
      const filesData = fileNamesArray.map((fileName, index) => ({
        id: index + 1,
        file: fileName,
        path: `${crntSelectedNotification.containerPath}/${crntSelectedNotification.notificationDetailsID}/${crntSelectedNotification.fileNames}`
      }));
      return (filesData);
    } else {
      return (null);
    }
  }

  //#region render accordian body
  const renderBody = (item) => {
    return (
      <CustomEmail
        CommunicationType={CommunicationType.Deleted}
        DraftNotification={item?.notificationSubject}
        DraftNotificationsDetails={deletedNotifications}
        InvestorsItems={investorsList}
        getDraft={getDraft}
        selectedTabFromParent={item?.notificationHasAction === true ? 1 : 2}
        selectedActionItem={item?.notificationOnID === 0 ? item?.notificationType : item?.notificationOnID}
        selectedParentDraftNotification={selectedParentDraftNotification}
        setDraftNotifications={setdeletedNotifications}
        selectedInvestorsFromParent={selectedInvestors(item)}
        selectedSubjectFromParent={item?.notificationSubject}
        selectedContentFromParent={item?.notificationBody}
        getNotificationsCount={getNotificationsCount}
        renderComponent={renderComponent}
        setRenderComponent={setRenderComponent}
        notificationFiles={notificationFiles(item)}
      />
    );
  };

  //#region onchange events
  const handleChangeData = (item) => {
    setSelectedParentDraftNotification(item?.notificationSubject);
  };

  const handleChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    //setPage(0);
  };

  //#region dropdown dictionary
  const Options = [
    { value: '5', label: 'Any Time' },
    { value: '1', label: 'Older than a Week' },
    { value: '2', label: 'Older than a Month' },
    { value: '4', label: 'Older than a 6 Months' },
    { value: '3', label: 'Older than a Year' },
  ];

  //#region timestamp function ( eg. 1 hour ago , 2hrs ago)
  // const createdTimestamp = (time) => {
  //   const updatedDate = new Date(time);
  //   const now = new Date();

  //   // Calculate the difference in milliseconds
  //   const diffMs = now - updatedDate;

  //   // Convert milliseconds to hours and days
  //   const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  //   const diffDays = Math.floor(diffHours / 24);

  //   if (diffDays > 0) {
  //     return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  //   } else {
  //     return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
  //   }
  // }##TODO : need to find better solution for time conversion

  //#region filter functions
  const handleApplyFilter = ({ filterType, startDate, endDate }) => {
    let filtered = [];

    // Convert startDate and endDate to Date objects if they are strings
    if (filterType === 'Custom Range' && startDate && endDate) {
      startDate = new Date(startDate);
      endDate = new Date(endDate);
      // Ensure that the end date is inclusive by setting it to the end of the day
      endDate.setHours(23, 59, 59, 999);
    }

    if (filterType === 'Today') {
      filtered = deletedNotifications?.filter(item => new Date(item.updatedDate).toDateString() === new Date().toDateString());
    } else if (filterType === 'Last 7 Days') {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      filtered = deletedNotifications?.filter(item => new Date(item.updatedDate) >= sevenDaysAgo);
    } else if (filterType === 'This Month') {
      const now = new Date();
      filtered = deletedNotifications?.filter(item => {
        const updatedDate = new Date(item.updatedDate);
        return updatedDate.getMonth() === now.getMonth() && updatedDate.getFullYear() === now.getFullYear();
      });
    } else if (filterType === 'Custom Range' && startDate && endDate) {
      filtered = deletedNotifications?.filter(item => {
        const updatedDate = new Date(item.updatedDate);
        return updatedDate >= startDate && updatedDate <= endDate;
      });
    }

    setFilteredNotifications(filtered);
    setPage(0); // Reset to first page on filter
  };

  const handleResetFilter = () => {
    setFilteredNotifications(deletedNotifications);
    setPage(0);
  };

  return (
    <div>
      <div className='notifications-accordian-wrapper'>
        <Box>
          <CustomNotificationFilters
            onApplyFilter={handleApplyFilter}
            onResetFilter={handleResetFilter}
          />
        </Box>
        {filteredNotifications && investorsList ? (
          filteredNotifications?.length === 0 ? (
            'No Data Available'
          ) : (
            filteredNotifications?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
              <div key={item.notificationDetailsID} className='space-between'>
                <div className='groupview-accordian'>
                  <Accordian
                    title={item?.notificationSubject}
                    body={renderBody(item)}
                    onChange={() => handleChangeData(item)}
                    showtimestamp={true}
                    timestamp={(item?.updatedDate)}
                  />
                </div>
                {/* <div className='margin-top-9'>
                <ContextMenu MenuItemsTop={MenuItemsTop} ItemDetails={item} />
              </div> */}
              </div>
            ))
          )
        ) : (
          <div>
            <LinearProgress />
          </div>
        )}
        <div className='margin-top-10'>
          {/* Pagination */}
          <TablePagination
            component="div"
            count={deletedNotifications ? deletedNotifications?.length : 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  )

}
export default DeletedNotifications;