import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import ActionButton from '../../common/ActionButton/ActionButton';
import { subscriptionStatusEnum } from '../../investor-fund-subscription/services/apiDataTemplate';
import secureLocalStorage from 'react-secure-storage';
import { AppDashboards } from '../../../utils/enum';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import ALTTextField from '../../common/input-fields/ALTTextField';
import { updateSubscriptionDetails } from '../../CODashboard/Services/apiDataTempate';
import { updateFundSubscriptionStatus } from '../../CODashboard/Services/Services';
import { toast, ToastContainer } from "react-toastify";

const SubscriptionRejectionPopup = (props) => {

  //#region props
  const { open, onClose, currSubscriptionDetails } = props;

  //#region variables
  const [loading, setLoading] = useState(false);
  const [rejectionReason,setRejectionReason] = useState("");

  //#region change events
  const handleChange = (name, value) => {
    setRejectionReason(value);
  }

  //#region functions
  const renderRejectionText = () => {
    if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && currSubscriptionDetails?.subscriptionStatus === subscriptionStatusEnum.COMPLIANCE_REVIEW) {
        return "Please confirm whether you want to proceed anyway, acknowledging that the compliance review is still pending.";
    } else {
        return "Kindly provide the reason for rejection. This will be sent to the investor in the mail informing about the rejection of the subscription request."
    }
  }

  const rejectClickLabel = () => {
    if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && currSubscriptionDetails?.subscriptionStatus === subscriptionStatusEnum.COMPLIANCE_REVIEW) {
        return "Proceed with rejection"
    }else{
        return "Reject"
    }
  }

  //region api update calls
  const updateSubscriptionStatus = async() => {
    setLoading(true);
    const requestedData = updateSubscriptionDetails;
        requestedData.FundSubscriptionID = currSubscriptionDetails?.subscriptionsID;
        requestedData.FundSubscriptionStatus = "Rejected"
        requestedData.IsFundManager = true
        requestedData.RejectionComments = rejectionReason
    const data = await updateFundSubscriptionStatus(requestedData)
    if (data.responseCode === 200) {
        toast.success("Subscription Rejected Successfully",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
        onClose(true);
        setLoading(false);
    } else {
        setLoading(false);
        toast.warning("Something Went Wrong , Please Try Again",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
    }
  }

  //#region return
  return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                <h6> 
                    <InfoIcon className='info-i' />
                    Confirmation 
                </h6>
            </DialogTitle>
            <DialogContent>
                <table className=''>
                    <tr>
                        <td>Investor Name </td>
                        <td>: <b>{currSubscriptionDetails?.investor}</b></td>
                    </tr>
                    <tr>
                        <td>Subscription Amount </td>
                        <td>: <b>{currSubscriptionDetails?.subscribed}</b></td>
                    </tr>
                </table>
               <div className='margin-top-10'>{renderRejectionText()}</div>
               <div>
                    <ALTTextField
                        name="reason"
                        value={rejectionReason}
                        multiline={true}
                        rows={4}
                        onChange={(name, value) => handleChange(name, value)} />
               </div>
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton variant='outlined' disabled={loading} onClick={onClose} label='Cancel' icon={<CancelIcon />} />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                            label={rejectClickLabel()}
                            loading={loading}
                            disabled={rejectionReason === ""}
                            styleProps={{
                                padding: '8px 30px'
                            }}
                            onClick={updateSubscriptionStatus}
                            icon={<CheckCircleIcon />} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
  )
}
export default SubscriptionRejectionPopup;