import React, { useEffect, useState } from 'react';
import FundPerformance from '../FundPerformance/FundPerformance';
function ChartSelector() {
  return (
    <div>
      <FundPerformance type="performancetab" />
    </div>

  );
}

export default ChartSelector;