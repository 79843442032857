import React, { useEffect, useState } from 'react';
import { calenderevents } from '../calender';
import { Scheduler } from '@aldabil/react-scheduler';
import { useSelector } from "react-redux";
import { getHeaderColorConfig } from '../../../state/slices/colorConfigSlice';
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice';
import { Button } from '@mui/material';
import EventPopup from '../Popups/EventPopup';
import { ToastContainer, toast } from 'react-toastify';
import { deleteCalendarEvent, getScheduledEvents } from '../services/calendarservices';
import AddIcon from '@mui/icons-material/Add';
import { AppDashboards } from '../../../utils/enum';
import secureLocalStorage from 'react-secure-storage';
import { getInvestorsList } from '../../InvestorRelations/Services/IRServices';
import CircleIcon from '../../chatbot/Icon';
const AppCalendar = () => {

  //#region whitelabiling
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const headerColorConfig = useSelector(getHeaderColorConfig);

  //#region variables
  const [events, setEvents] = useState([]);
  const [openPopup,setOpenPopup] = useState(false);
  const [refreshCalender, setRefreshCalender] = useState(false);
  const [allusersDetails, setUsersDetails] = useState();

  //#region click events
  const handleAddEvent = () => {
    setOpenPopup(true);
  }

  //#region api get calls
  const getAllUsers = async () => {
    const data = await getInvestorsList();
    if (data?.responseCode == 200) {
      setUsersDetails(data?.responseData);
    } else {
      /* toast.warning("Failed to get users data",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" }); */
    }
  }

  const getEvents = async () => {
    const data = await getScheduledEvents();
    if (data?.responseCode == 200) {
      const eventsDataTemplate = data?.responseData?.map((eachevent) => {
      let formattedstartdate = new Date(eachevent.startDate);
      let formateedenddate = new Date(eachevent.endDate);
      let endDate = new Date(eachevent.startDate);
      if(eachevent.fundID > 0){
        formattedstartdate.setHours(0, 0, 0);
        formattedstartdate = formattedstartdate.toISOString();
        
        endDate.setHours(24, 0, 0);
        formateedenddate = endDate.toISOString();
      }
      else{
      formattedstartdate.setHours(formattedstartdate.getHours() + 5);
      formattedstartdate.setMinutes(formattedstartdate.getMinutes() + 30);
        
      formateedenddate.setHours(formateedenddate.getHours() + 5);
      formateedenddate.setMinutes(formateedenddate.getMinutes() + 30);
      }
      
        return {
          event_id: eachevent.eventID,
          title: eachevent.title,
          start: new Date(formattedstartdate),
          end: new Date(formateedenddate),
          attendees: eachevent.listOfInvestors,
          editable: eachevent.isEditable,
          deletable: eachevent.isDeletable,
          allDay : eachevent.fundID > 0 ? true : false
        }
      });
      setEvents(eventsDataTemplate);
    } else {
      toast.warning("No calendar events found for this user.",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  // #region api delete calls
  const handleDeleteEvent = async (event_id) => {
    const data = await deleteCalendarEvent(event_id);
    if (data?.responseCode == 200) {
      toast.success("Event deleted successfully",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      getEvents();
    } else {
      toast.warning("Failed to delete event",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region useEffect
  useEffect(() => {
    getAllUsers();
    getEvents();
  }, []);

  //#region custom component events
  const handleCustomEditor = (schedularHelpers) => {
    const handleClose = () => {
        schedularHelpers.close(); // Call the close method from SchedulerHelpers
    };
    /* as of not not using below method */
    const handleConfirm = () => {
        schedularHelpers.confirmEvent();
    }
    
    const selectedEventDetails = {
      event_id: schedularHelpers.state.event_id.value,
      title: schedularHelpers.state.title.value,
      start: schedularHelpers.state.start.value,
      end: schedularHelpers.state.end.value,
      attendees: null,
    }
    
    return <EventPopup onClose={handleClose} events={events} getEvents={getEvents} setEvents={setEvents} selectedEventDetails={selectedEventDetails} usersDropdown={allusersDetails} />;
  };


  return (
      <div style={{backgroundColor: rootBackColorConfig.color}}>
        <div className='s-banner'
            style={{
              background: `radial-gradient(50% 50% at 50% 50%, ${headerColorConfig.gradientSecondaryColor}, ${headerColorConfig.gradientPrimaryColor})`,
              backgroundSize: 'cover',
              // marginTop: '50px',
              }}>
            <div className='space-between'>
                <h1 style={{color: headerColorConfig.color}}>Calendar</h1> 
                {
                  secureLocalStorage.getItem('userrole') !== AppDashboards.INV_DASHBOARD && 
                  <div className='margin-top-5'>
                    <Button 
                        variant="contained" 
                        className='btn-primary'
                        startIcon={<AddIcon />}
                        onClick={()=>{handleAddEvent()}}>
                          Add Event
                    </Button>
                  </div>
                }
            </div>
        </div>
          <div className='wrapper' style={{marginTop: '50px'}}>
            <Scheduler className="appCalender"
              view="week"
              events={events}
              selectedDate={new Date()}
              week={{
                startHour: 0,
                endHour: 24,
              }}
              day={{
                startHour: 0,
                endHour: 24,
              }}
              onDelete={handleDeleteEvent}
              editable={secureLocalStorage.getItem('userrole') !== AppDashboards.INV_DASHBOARD}
              customEditor={(scheduler) => handleCustomEditor(scheduler)}
              translations={{
                navigation: {
                  week: "Week",
                  month: "Month",
                  day: "Day",
                  agenda: "Agenda",
                  today: "", // Remove the "today" label
                },
                form: {
                  addTitle: "Add Event",
                  editTitle: "Edit Event",
                  confirm: "Confirm",
                  delete: "Delete",
                  cancel: "Cancel",
                },
                event: {
                  title: "Title",
                  start: "Start",
                  end: "End",
                  allDay: "All Day",
                },
                moreEvents: "More events",
                noDataToDisplay: "No Data Available",
                loading: "Loading",
              }} /> 
             {/*  <Scheduler 
                view="week"
                events={events}
                selectedDate={new Date(2024, 4, 5)} 
                fields={[
                  {
                    name: 'audiences',
                    type: 'select',
                    options: [
                      {text : 'All', value: 'all'},
                      {text : 'Internal', value: 'internal'},
                      {text : 'External', value: 'external'}
                    ],
                    config: {
                      label: 'Audiences',
                      required: true,
                      errMsg: 'This Field is Required'
                    }
                  }
                ]} 
                onConfirm={onConfirm}/> */}
          </div>
        {
          openPopup && <EventPopup openPopup={openPopup} setOpen={setOpenPopup} onClose={()=>setOpenPopup(false)} events={events} setEvents={setEvents} usersDropdown={allusersDetails} getEvents={getEvents}/>
        }
        <ToastContainer />
        <CircleIcon/>
      </div>
  );
};

export default AppCalendar;
