import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ActionButton from '../../common/ActionButton/ActionButton';
import { MuiOtpInput } from 'mui-one-time-password-input';
import Text from '../../common/Text/Text';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from 'react-toastify';
import { getOtpID } from '../../user-profile/services/UserProfileService';
import { userProfileToastMessages } from '../../../utils/AppConstants';
import { VerifyOTP } from '../../administration/Services/TabsData';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../AppAuthentication/authConfig';
import secureLocalStorage from 'react-secure-storage';

const VerificationOtp = (props) => {
    //#region props
    const { open, onClose, emailID, otpAttemptsLeft, getOTPAttemptsIn24Hours, updateFunction, loading } = props;

    //#region initialdata
    const initialTimer = 120;

    //#region variables
    const totalSbtAttempts = 3;
    const [generatedOtp, setGeneratedOtp] = useState()
    const [otp, setOtp] = useState();
    const [sbtAttempts, setSbtAttempts] = useState(0);
    const [reLogin, setReLogin] = useState(false);
    const msalInstance = new PublicClientApplication(msalConfig);
    const [timer, setTimer] = useState(initialTimer);

    //#region change events
    const handleChange = (newValue) => {
        setOtp(newValue);
    };

    //#region click events
    const onSubmitClick = () => {
        setSbtAttempts(sbtAttempts + 1);
        verifyOtp();
    }

    const Logout = () => {
        secureLocalStorage.clear();
        msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
    };

    const onResendClick = () => {
        if (otpAttemptsLeft > 0) {
            getGeneratedOtp();
        }
        else {
            toast.error(userProfileToastMessages.ATTEMPTS_REACHED,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region api get calls
    const getGeneratedOtp = async () => {
        const data = await getOtpID(emailID);
        if (data?.responseCode == 200) {
            toast.success(`A verification code has been sent to your new email address at ${emailID}. Please enter the code below to save the new email address.`,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            getOTPAttemptsIn24Hours();
            setGeneratedOtp(data?.responseData);
            setSbtAttempts(0);
            setTimer(initialTimer);
        } else {
            toast.warning(data?.responseData,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    const verifyOtp = async () => {
        const data = await VerifyOTP(generatedOtp?.id, otp)
        if (data?.responseCode === 200) {
            const response = await updateFunction(true, otp, generatedOtp?.id);
            if (response) {
                setReLogin(true);
            }
            else {
                toast.warning(userProfileToastMessages.OTP_ERROR,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
        }
        else {
            toast.warning(userProfileToastMessages.OTP_ERROR,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region useeffect
    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [timer]);

    useEffect(() => {
        getGeneratedOtp();
    }, [])

    //#region render fucntions
    const reloginPopup = () => {
        return (
            <Dialog open>
                <DialogTitle>

                </DialogTitle>
                <DialogContent>
                    Your email address has been changed successfully. Please log in again using your new email address.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { Logout() }}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6>OTP Verification </h6>
                <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ marginTop: '10px' }}>
                    <MuiOtpInput
                        value={otp}
                        onChange={handleChange}
                        sx={{ width: '80%' }}
                        length={6} />
                </Grid>
            </DialogContent>
            <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ marginBottom: '10px' }}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item justifyContent='center'>
                            <ActionButton variant='contained'
                                /* label={`Verify & Proceed in ${Math.floor(timer / 60)}:${timer % 60 < 10 ? `0${timer % 60}` : timer % 60}`} */
                                label="Verify & Proceed"
                                onClick={onSubmitClick}
                                loading={loading}
                                disabled={sbtAttempts >= totalSbtAttempts || timer === 0}
                                styleProps={{
                                    padding: '8px 36px'
                                }} />

                        </Grid>

                    </Grid>
                    <Grid container spacing={2} justifyContent="center" marginTop={'10px'}>
                        {
                            timer === 0 ?
                                <div>
                                    Didn’t receive verification code  ?
                                    <a href="#" onClick={onResendClick} style={{ textDecoration: 'underline' }}>
                                        {'Resend'}
                                    </a>
                                </div> :
                                <div>
                                    Resend OTP in <b>{Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}</b>
                                </div>
                        }
                    </Grid>
                    {
                        <Grid container spacing={2} alignItems="center" justifyContent="center" marginTop={'5px'}>
                            <Typography>
                                You have [{otpAttemptsLeft}] attempts left to resend the code.
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {reLogin && reloginPopup()}
        </Dialog>
    )
}
export default VerificationOtp;