import React, { useEffect, useState } from 'react'
import StepContainer from '../../../investor-fund-subscription/components/StepContainer'
import secureLocalStorage from 'react-secure-storage'
import { stepsToShowWhile } from '../../../investor-fund-subscription/services/apiDataTemplate'
import { getQuestioinBank, getUserProfileQuestionBank, getUserQuestionBank } from '../../../investor-fund-subscription/services/services'
import { configurationTypesEnum } from '../../../administration/investor-subscription-questions/services/apiDataTemplate'
import { questionsTypesEnum } from '../../services/apiDataTemplate'
import { getKeyValuePairs } from '../../../portfolio-companies/services/services'

const SubscriptionDetails = () => {

  //#region variables
  const [investorSubscriptionDetails, setInvestorSubscriptionDetails] = useState();

  //#region api get calls
  const getSubscriptionDetails = async (questionsTypeId) => {
    const investorId = secureLocalStorage.getItem("userId")
    const investorTypeId = secureLocalStorage.getItem("investorTypeID");
    const data = await getQuestioinBank(investorId, questionsTypeId, investorTypeId);
    if (data.responseCode === 200) {
      setInvestorSubscriptionDetails(data.responseData);
    }
    else {
      setInvestorSubscriptionDetails(null);
    }
  }

  const getConfiguredQuestionTypes = async () => {
    const data = await getKeyValuePairs("OnBoardingQuestionType");
    if (data.responseCode === 200) {
      const questionsTypeId = data.responseData?.find((item) => item.listItemValue === questionsTypesEnum.SUBSCRIPTION)?.listItemID
      getSubscriptionDetails(questionsTypeId);
    }
    else {
      //#TODO:Need to log the error if any
    }
  }

  //#region useeffect
  useEffect(() => {
    getConfiguredQuestionTypes();
  }, [])

  return (
    <div>
      <StepContainer
        investorId={secureLocalStorage.getItem("userId")}
        type={stepsToShowWhile.USER_PROFILE_SAVE}
        fundID={0}
        subscriptionId={0}
        investorSubscriptionDetails={investorSubscriptionDetails}
        fundDeatils={investorSubscriptionDetails?.fundBasicDetails}
        getQuestionAnswerDetails={getConfiguredQuestionTypes} />
    </div>
  )
}

export default SubscriptionDetails