import { createSlice } from "@reduxjs/toolkit";
import { MarketPlaceView } from "../../utils/enum";
import { updateFund } from "../../components/marketplace/services/MarketplaceService";

let InitialData = {
    fundsDetails: [],
    compareFunds: [],
    favouriteFunds: [],
    view: MarketPlaceView.BOX_VIEW,
    filters: null,
    fundTypeFilter: null
};

const MarketPlaceSlice = createSlice({
    name: "marketPlace",
    initialState: InitialData,
    reducers: {
        updateAllFunds: (state, action) => {
            state.fundsDetails = action.payload; // Update entire array
        },
        addCompare: (state, action) => {
            state.compareFunds.push(action.payload.fund); // Add item to array
        },
        removeCompare: (state, action) => {
            const fundToRemove = action.payload.fundID;
            if (fundToRemove) {
                state.compareFunds = state.compareFunds.filter(item => item.fundID !== fundToRemove);
            } else {
                // Handle the case where action.payload.fund is undefined or does not have fundID property
                console.error("Invalid payload provided for removing compare.");
            }
        },
        addFavourite: (state, action) => {
            state.favouriteFunds.push(action.payload.fund); // Add item to array
        },
        removeFavourite: (state, action) => {
            const fundToRemove = action.payload.fundID;
            if (fundToRemove) {
                state.favouriteFunds = state.favouriteFunds.filter(item => item.fundID !== fundToRemove);
            } else {
                // Handle the case where action.payload.fund is undefined or does not have fundID property
                console.error("Invalid payload provided for removing favourite.");
            }
        },
        updateView: (state, action) => {
            state.view = action.payload.view; // Update view
        },
        updateFilters: (state, action) => {
            /* console.log(action.payload.value); // Log the value to
            debugger */
            return {
                ...state,
                filters: action.payload.value, // Replace filters immutably
            };
        },        
        updateFundTypeFilter: (state, action) => {
            state.fundTypeFilter = action.payload.value; // Update fund type filter
        }
    }
});

export const { addCompare, removeCompare, updateAllFunds ,addFavourite ,removeFavourite,updateView,updateFilters,updateFundTypeFilter } = MarketPlaceSlice.actions;

export const getAllFunds = (state) => state.marketPlace.fundsDetails; 
export const getCompareFunds = (state) => state.marketPlace.compareFunds; 
export const getFavouriteFundsSlice = (state) => state.marketPlace.favouriteFunds; 
export const getFundsView = (state) => state.marketPlace.view;
export const getFilters = (state) => state.marketPlace.filters;
export const getFundTypeFilter = (state) => state.marketPlace.fundTypeFilter; 

export default MarketPlaceSlice.reducer; 
