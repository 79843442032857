import React, { useEffect } from 'react'
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, Button, MenuItem } from '@mui/material';
import ActionButton from '../../../common/ActionButton/ActionButton';
import FileUploadButton from '../../../common/FileUploadButton/FileUploadButton';
import TextInput from '../../../common/TextInput/TextInput';
import { UpdateDocumnet } from '../services/services';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import CancelIcon from '@mui/icons-material/Cancel';
import { AppDashboards } from '../../../../utils/enum';
import secureLocalStorage from 'react-secure-storage';
const ReplaceFile = (props) => {
  const { open, onClose, DocumnetDetails, refreshAccordians, K1Accordians, getFoldersDetails, setRefreshComponent } = props;
  const [loading, setLoading] = React.useState(false);
  const [documentType, setDocumentType] = React.useState("");
  const [documnetName, setdocumnetName] = React.useState(DocumnetDetails?.documentPath?.split("/")[DocumnetDetails?.documentPath?.split("/")?.length - 1]);

  useEffect(() => {
    if (K1Accordians) {
      setDocumentType("K1Reporting");
    }
  }, []);

  useEffect(() => {
    setdocumnetName(DocumnetDetails?.documentPath?.split("/")[DocumnetDetails?.documentPath?.split("/")?.length - 1]);
  }, [DocumnetDetails]);

  const initialData = {
    document: ''
  }

  const [details, setDetails] = useState(initialData);

  const [errors, setErrors] = useState({});

  const handleFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);

      handleChange(name, file);
    }
  }

  const handleChange = (field, value) => {
    const validationResult = validateField(field, value);
    if (!validationResult.isValid) {
      setErrors(prevErrors => ({ ...prevErrors, [field]: validationResult.error }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [field]: null }));
      setDetails({
        ...details,
        [field]: value
      });
    }
  }

  const validateField = (field, value) => {
    const validationRules = {
      document: (value) => {
        const isNotEmpty = value !== "";
        return {
          isValid: isNotEmpty,
          error: isNotEmpty ? null : "Please select a document"
        };
      }
    };
    return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
  };

  const MandatoryFieldErrors = () => {
    const fieldsToKeep = ['document'];
    const trimmedValues = { ...details };

    const filteredFields = fieldsToKeep.map((field) => {
      if (trimmedValues.hasOwnProperty(field)) {
        return { field, value: trimmedValues[field] };
      }
      return null;
    }).filter(Boolean);

    const Mandatory = filteredFields.map(({ field, value }) => ({
      field,
      value: value === "" ? null : value,
    }));

    console.log(Mandatory);
    const EmptyFields = Mandatory.filter(entry => entry.value === "" || entry.value === null).map(entry => entry.field);
    const error = `This field is required.`;
    //  
    EmptyFields.length > 0 && EmptyFields.map((item) => {
      //           
      setErrors(prevErrors => ({ ...prevErrors, [item]: error }));
    })
    return EmptyFields.length === 0;
  };

  const handleSave = () => {
    const isMandatory = MandatoryFieldErrors();
    if (isMandatory) {
      handleUpdate();
    }
    else {
      return
    }
  };

  const handleUpdate = async () => {
    debugger;
    setLoading(true);

    const formData = new FormData();
    formData.append('file', details.document, details.document.name);
    const data = await UpdateDocumnet(DocumnetDetails, formData, documentType);
    if (data?.responseCode === 200) {
      setLoading(false);
      onClose();
      if (K1Accordians) {
        getFoldersDetails(secureLocalStorage.getItem('FundId'), (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD ? secureLocalStorage.getItem('userId') : 0))
        setRefreshComponent(false)
        setTimeout(() => {
          setRefreshComponent(true)
        }, 1000);
      } else {
        refreshAccordians();
      }
      toast.success("Document Replaced Successfully",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
    else {
      setLoading(false);
      onClose();
      refreshAccordians();
      toast.error("Unable to Replace the Document. Please try again later.",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }


  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        <h6>  Replace File</h6>
      </DialogTitle>
      <DialogContent>
        <div className='margin-top-15'>
          <FileUploadButton
            name="document"
            defaultFile={details.document}
            label="UPLOAD DOCUMENT"
            pdffileonly={K1Accordians ? false : true}
            PDFWarningMessage={() => toast.warning("Invalid file type. Please upload a PDF file.", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })}
            onChange={(name, value) => handleFileUpload(name, value)}
            errormessage={errors.document || undefined}
          />
        </div>
        <div className='margin-top-15'>
          <TextInput
            type="text"
            name="selectfund"
            label="Existing Document"
            defaultValue={documnetName}
            editable={false}
            required={true}
            onChange={(name, value) => handleChange(name, value)}
            errormessage={errors.selectfund || undefined} />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="space-between">
          <div>
            <ActionButton
              className="btn-primary"
              component="label"
              variant="outlined"
              icon={<CancelIcon />}
              label="Cancel"
              onClick={onClose} />
          </div>
          <div className="margin-left-10">
            <ActionButton
              className="btn-primary"
              variant="contained"
              icon={<FindReplaceIcon />}
              label="Replace"
              loading={loading}
              onClick={handleSave} />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ReplaceFile