import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { padding, width } from '@mui/system';
import { cancelbutton } from '../../CommonCss';
import TextInput from '../../common/TextInput/TextInput';
import Box from '@mui/system/Box';
import CheckboxCommon from '../../common/CheckBox/Checkbox';
import CheckBoxRenderingDemo from '../../administration/InvestorOnBoardingQues/Components/CheckBoxRenderingDemo';
import Card from '@mui/material/Card';
import './styles.css';
import { convertStringToArray } from '../../common/Functions/ConvertStringtoArray';
import { convertArrayToString } from '../../common/Functions/ConvertStringtoArray';
import { ToastContainer, toast } from 'react-toastify';
import ActionButton from '../../common/ActionButton/ActionButton';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CancelIcon from '@mui/icons-material/Cancel';
import secureLocalStorage from 'react-secure-storage';
import { CreateOBQAnswer, updateExcistingOBQAnswer } from '../../registration-profiling/services/RegistrationProfilingService';

const OBQPopup = (props) => {

  const {open,data,investmentData,setData,mcqType,textType,onCancel,userProfileData,getInvestorInfo,getUserProfileDetails,updateExcistingDetails} = props;

  const Texquestionformat = 5;
  const optionsformat = 4;

  const [questions,setQuestions] = useState([...investmentData]);
  const [loading,setLoading] = useState(false);

  /*   */

  const [selectedItem,setSelectedItem] = useState(data.questionAnswerID);


  const MultianswerArray = convertStringToArray(data.answer);

  const addCheckedFields = props?.popupoptions?.map(item => ({
    ...item,
    ischecked: MultianswerArray.includes(item.questionAnswerID)
  }));

  
  const [MCOptions,setMCOptions] = useState(addCheckedFields);


  const renderNormalOptions = (item) =>{
    return(
         <div className={selectedItem === item.questionAnswerID ? 'ss-optionselector-holder' : 'ss-option-holder'}
           onClick={() => handlechangesingleoption('questionAnswerID',item.questionAnswerID,item.answer)}>
                  {item.answer}
         </div>
    );
  }

  const handlechangesingleoption = (field,value,answervalue) =>{
    setSelectedItem(value);
    handleChange(field,value);
    handleChange("answer",answervalue);
  }

  const renderMultiSelectOptions = (item) => {
    return (
      <div key={item.id}>
      <Card variant="outlined" sx={{ borderColor: 'black', minHeight: '50px' , width:'100%',marginBottom: '10px'  }}>
            <Box display="flex" alignItems="center" p={1} height="100%">
                <CheckBoxRenderingDemo
                    name='ischecked'
                    label={item.answer}
                    value={item.ischecked}
                    handleChange={handleOptionChange}
                    params={item}
                />
            </Box>
      </Card>
      </div>
    );
  }

  const handleOptionChange = (optionItem, field, value) => {
        const updatedMCOptions = MCOptions.map(item =>
          item.questionAnswerID === optionItem.questionAnswerID
            ? { ...item, [field]: value }
            : item
        );
        
        setMCOptions(updatedMCOptions);
        
        //setting the answer in main data
        const CheckedIds = updatedMCOptions
                .filter((item) => item.ischecked)
                .map((item) => item.questionAnswerID);

        const convertelementstostring = convertArrayToString(CheckedIds);
        
        handleChange("answer",convertelementstostring);
        const selectedLabels  = updatedMCOptions?.filter((item)=> CheckedIds.includes(item?.questionAnswerID));
        const convertlabelstostring = convertArrayToString(selectedLabels.map((item) => item.answer));  
        handleChange("answerValue", convertlabelstostring);
    };


  const [errors, setErrors] = useState({});
  
  const handleChange = (field,value) =>{
    /*   */
    if(data.setValidations){
        const validationResult = validateField(field, value);
        if (!validationResult.isValid) {
            setErrors(prevErrors => ({ ...prevErrors, [field]: validationResult.error }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, [field]: null }));
            setQuestions((prevData) => {
                return prevData.map((item) => {
                  if (item.questionID === data.questionID) {
                    return {
                      ...item,
                      [field]: value,
                    };
                  }
                  return item;
                });
              });
        }
    }
    else{
        setQuestions((prevData) => {
            return prevData.map((item) => {
              if (item.questionID === data.questionID) {
                return {
                  ...item,
                  [field]: value,
                };
              }
              return item;
            });
          });
    console.log(questions)
    /*   */
        } 
  }

  const onSave = async() =>{
    /*   */
    setLoading(true);
    const errorsArray = Object.values(errors);
    const hasErrors = errorsArray.some(error => error !== null);
    const currQuestionDetails = questions.find((item) => item.questionID === data.questionID)
    const currQuestionUserProfile = userProfileData ? userProfileData?.find((item) => item.questionBankID === data.questionID) : null
    const requestData = {
      "userProfileID": currQuestionUserProfile?.userProfileID ? currQuestionUserProfile?.userProfileID : 0,
      "userID": secureLocalStorage.getItem("userId"),
      "questionBankID": currQuestionDetails?.questionID,
      "questionAnswerID": currQuestionDetails?.questionAnswerID,
      "createdBy": currQuestionUserProfile?.createdBy || secureLocalStorage.getItem("userId"),
      "createdDate": currQuestionUserProfile?.createdDate || new Date(),
      "updatedBy": secureLocalStorage.getItem("userId"),
      "updatedDate": new Date(),
      "answer": currQuestionDetails?.answer
    }
    if(data.onboardingQuestionPattern === Texquestionformat){
         if(hasErrors){
          setLoading(false);
            return
         }
         else{
          let response = '';
          if(requestData.userProfileID === 0){
            response = await CreateOBQAnswer(requestData); 
          }
          else{
            response = await updateExcistingOBQAnswer(requestData); 
          }
           if(response.responseCode === 200){
            setData(questions);
            onCancel();
            getUserProfileDetails();
            setLoading(false);
            getInvestorInfo();
            /* updateExcistingDetails(questions); */
            toast.success("Successfully Saved", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "colored",
            });
           }
           else{
            toast.error("Unable to Save the details", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "colored",
            });
           }
         }
    }
    else{
      let response = '';
      if(requestData.userProfileID === 0){
        response = await CreateOBQAnswer(requestData); 
      }
      else{
        response = await updateExcistingOBQAnswer(requestData); 
      }
       if(response.responseCode === 200){
        setData(questions);
        onCancel();
        getUserProfileDetails();
        setLoading(false);
        getInvestorInfo();
        toast.success("Successfully Saved", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
       }
       else{
        toast.error("Unable to Save the details", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
       }
     }
  }

  

  const validateMultiCheckBox = () => {
    const currQuestionDetails = questions.find((item) => item.questionID === data.questionID)
    if(data?.bookmarks === ""){
      return data?.answer === "" || null ? true : false;
    }
    else{
      const mimimumselectcount = parseInt(data?.bookmarks)
      const selectedOptions = convertStringToArray(currQuestionDetails?.answer);
      return !(selectedOptions.length >= mimimumselectcount);
    }
  }

  const validateField = (field, value) => {
    const validationRules = {
         answer: (value, currState) => {
          const isValid = value.length <= data.characterLength;
      
          return {
            isValid,
            error: isValid ? null : `Please enter a value with a maximum length of ${data.characterLength} characters`,
          };
        },
      };      
    return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
};

let textInputType = 'a';

  if (data.allowAlphabets && data.allowNumbers === false) {
    /*   */
    textInputType = 'Onlytext';
  } else if (data.allowNumbers && data.allowAlphabets === false) {
    /*   */
    textInputType = 'numberwithoutsymbol';
  } else {
    /*   */
    textInputType = '';
  }

  return (
    <Dialog open={open} fullWidth>
        <DialogTitle>
              {data.question}
        </DialogTitle>
        <DialogContent>
        {data.onboardingQuestionPattern === optionsformat && data.multipleAnswers ? (
                <div>
                    {(
                    <div className='margin-top-10'>
                        {MCOptions?.map((item) => renderMultiSelectOptions(item))}
                    </div>
                    )}
                </div>
                ) : data.onboardingQuestionPattern === Texquestionformat ? (
                    <div>
                       <TextInput
                            multiline
                            required={true}
                            name='answer'
                            type={textInputType}
                            defaultValue={data.answer}
                            placeholder="Please Write Your Answer"
                            onChange={(name, value) => handleChange(name, value)} 
                            errormessage={errors.answer || undefined}
                         />
                    </div>
                ) : data.onboardingQuestionPattern === optionsformat ? (
                        <div> {(
                            <div className='ss-option-container'>
                                {props?.popupoptions?.map((item) => renderNormalOptions(item))}
                            </div>)}
                         </div>
                ) : (
                    <div>
                        
                    </div>
                )}
        </DialogContent>
        <DialogActions>
            <div className="space-between">
                <div>
                    <ActionButton
                    component="label"
                    variant="outlined"
                    className='btn-primary'
                    label="Cancel"
                    /* sx={cancelbutton} */
                    onClick={onCancel}
                    icon={ <CancelIcon/>} 
                    >
                    </ActionButton>
                </div>
                
                <div className="margin-left-10">
                    <ActionButton
                    component="label"
                    variant="contained"
                    className='btn-primary'
                    loading={loading}
                    startIconName="Save"
                    onClick={onSave}
                    label="Save"
                    disabled={
                      data &&
                      data?.onboardingQuestionPattern == mcqType && data?.multipleAnswers === false ? //single chocie
                      (parseInt(questions.find((item) => item.questionID === data.questionID)?.questionAnswerID) > 0
                        ? false
                        : true) : (data?.multipleAnswers == true ? validateMultiCheckBox() //multi answers
                                    : questions.find((item) => item.questionID === data.questionID)?.answer === "" || null //text answers
                                    ? true
                                    : false)
                    }
                    >
                    </ActionButton>
                </div>
                </div>
        </DialogActions>
    </Dialog>
  )
}

export default OBQPopup