import React, { useEffect, useState } from 'react'
import ActionButton from '../../../../common/ActionButton/ActionButton';
import { KycAmlStuatus, KycStatusvalues } from '../../../../KYCVerification/enum';
import { subscriptioToastMessages, userProfileToastMessages } from '../../../../../utils/AppConstants';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ToastContainer, toast } from 'react-toastify';
import { updatekycamlstatus } from '../../../../KYCVerification/services/services';
import { Button, LinearProgress } from '@mui/material';
import DiscussionBox from '../../../../KYCVerification/components/DiscussionBox';
import VDRoom from '../../../../common/VirtualDataRoom/VDRoom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import secureLocalStorage from 'react-secure-storage';
import { convertStringToArrayWithEmptyArray, trimAndLowercaseArray } from '../../../../common/Functions/ConvertStringtoArray';
import { amlFieldsData, kycFieldsData } from '../../../services/fieldsData';
import SelectField from '../../../../common/input-fields/SelectField';
import ALTTextField from '../../../../common/input-fields/ALTTextField';
import { questionPatterns } from '../../../../investor-fund-subscription/services/apiDataTemplate';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DateField from '../../../../common/input-fields/DateField';
import FileUploadBtn from '../../../../common/FileUploadButton/FileUploadBtn';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { questionsTypesEnum } from '../../../services/apiDataTemplate';
import { getUserQuestionBank } from '../../../../investor-fund-subscription/services/services';
import MultiSelectField from '../../../../common/input-fields/MultiSelectField';
import QuestionsModel from './QuestionsModel';

const AmlDetails = (props) => {

  //#region variables
  const { investorDetails,isKycVerification,refreshVDR,setRefreshVDR,kycDetails,kycStatus,amlStatus,getInvestorKycAml,isViewOnly } = props;

  //#region variables
  const [verifyBtnLoading,setVerifyBtnLoading] = useState(false);
  const [rejectBtnLoading,setRejectBtnLoading] = useState(false);
  const [showComments, setshowComments] = useState(true);
  const [isSaveEnabled,setIsSaveEnabled]= useState(false);
  const [refreshDetails,setRefreshDetails] = useState(false);
  
  //#region click events
  const handleComments = () => {
    const details = {
      "id": investorDetails?.personalInformation?.userId
    }
    secureLocalStorage.setItem("KYCInvestorDetails", details);
    setshowComments(!showComments);
  }

  const onVerifyClick = () => {
    setVerifyBtnLoading(true);
    updateKycStatus(true);
  }

  const onRejectClick = () => {
    setRejectBtnLoading(true);
    updateKycStatus(false);
  }

  //#region api update calls
  const updateKycStatus = async (isVerified) => {
    const requestBody = {
      "userId": investorDetails.personalInformation.userId,
      "kycaml": KycAmlStuatus.AML_STATUS,
      "kycStatus": kycStatus,
      "amlStatus": isVerified ? KycStatusvalues.VERIFIED : KycStatusvalues.REJECTED,
    }
    const data = await updatekycamlstatus(requestBody);
    if (data.responseCode === 200) {
      getInvestorKycAml();
      setVerifyBtnLoading(false);
      setRejectBtnLoading(false);
      toast.success(userProfileToastMessages.VERIFICATION_SUCCESS, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else {
      toast.error(userProfileToastMessages.VERIFICATION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  }

  //#region useeffect
  useEffect(()=>{
    if(refreshDetails){
      getInvestorKycAml();
    }
  },[refreshDetails])

  useEffect(()=>{
    const details = {
      "id": investorDetails?.personalInformation?.userId
    }
    secureLocalStorage.setItem("KYCInvestorDetails", details);
  },[])

  //#region return
  return (
    <div className='child-margin-15'>
       <>
        {
          isKycVerification ? 
          <div className='firm-verify-btns space-between'>
             <div></div>
             {
              (amlStatus === KycStatusvalues.VERIFIED || amlStatus === KycStatusvalues.REJECTED) ? 
              <div>
                 <ActionButton 
                      label={amlStatus}
                      variant='contained'
                      disabled={true}
                      onClick={()=>{}}/>
              </div> :
              <div className='child-row-margin-10'>
                 <div>
                    <ActionButton 
                        label="VERIFY"
                        icon={<CheckCircleIcon />}
                        disabled={ amlStatus === KycStatusvalues.INCOMPLETE
                                   || rejectBtnLoading}
                        loading={verifyBtnLoading}
                        variant='contained'
                        onClick={onVerifyClick}/>
                 </div>
                 <div>
                  <ActionButton 
                        label="REJECT"
                        icon={<ThumbDownIcon />}
                        disabled={verifyBtnLoading}
                        loading={rejectBtnLoading}
                        variant='outlined'
                        onClick={onRejectClick}/>
                 </div>
              </div>
             }
          </div> : 
          ""
        }
       </>
       <div>
       <QuestionsModel refreshVDR={refreshVDR} setRefreshVDR={setRefreshVDR} verificationType = {questionsTypesEnum.AML} refreshDetails={refreshDetails} setRefreshDetails={setRefreshDetails} {...props} />
          {/* {
            isKycVerification &&
             <div>
                <Button
                  onClick={handleComments}
                  icon={<VisibilityIcon />}
                  variant="contained"
                  className="btn-primary">
                  {showComments ? 'Hide Comments' : 'Show Comments'}
                </Button>
             </div>
          } */}
       </div>
        {
          isKycVerification && 
          <>
          {
              showComments && !(kycStatus === KycStatusvalues.VERIFIED && amlStatus === KycStatusvalues.VERIFIED) &&
              <div item xs={12} className='whiteCard' sx={{ marginTop: '10px' }}>
                <DiscussionBox setRefreshDetails={setRefreshDetails} />
              </div>
            }
            {
              !refreshDetails &&
              <div className='white-card margin-top-10'>
                <VDRoom 
                  VDRType={'KYCAML'}
                  cnrtFundID={0}
                  crntSubscriptionID={0}
                  crntInvestorID={investorDetails?.personalInformation?.userId}
                  hideMenu={false} />
              </div> 
            }
          </>
        }
       <ToastContainer />
    </div>
  )
}

export default AmlDetails