import React from 'react'
import {
    DialogTitle,
    DialogContent,
    IconButton,
    Grid, Dialog, Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import Text from '../../common/Text/Text';

function BulkUploadDownloadModal(props) {
    //#region props
    const { DownloadBulkUploadSampleDocument, setdownloadTemplateModal, downloadTemplateModal } = props

    //#region click events
    const onCancelIconClick = () => {
        setdownloadTemplateModal(false)
    }

    return (
        <Dialog
            open={downloadTemplateModal}
            onClose={() => setdownloadTemplateModal(false)}
            fullWidth
        >
            <div>
                <DialogTitle md>
                    <Grid container >
                        <Grid item xs={10}>
                            <h6>Download Template</h6>
                        </Grid>
                        <Grid item xs={2} container justifyContent="flex-end">
                            <IconButton onClick={onCancelIconClick}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="medium"
                                startIcon={<SimCardDownloadIcon />}
                                onClick={() => DownloadBulkUploadSampleDocument(true)}
                                sx={{minWidth:'400px', display: 'flex', justifyContent: 'flex-start'}}
                            >
                                Download template for Individual Investor type
                            </Button>
                        </div>
                        <div className='mt2'>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="medium"
                                startIcon={<SimCardDownloadIcon />}
                                onClick={() => DownloadBulkUploadSampleDocument(false)}
                                sx={{minWidth:'400px', display: 'flex', justifyContent: 'flex-start'}}
                            >
                                Download template for Non-Individual Investor type
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </div>

        </Dialog>
    )
}

export default BulkUploadDownloadModal