import React from 'react'
import Accordian from '../../../common/Accordian/Accordian';
import '../styles.css'
import { Divider, Avatar, Grid, Paper, Link } from "@mui/material";
import ContextMenu from '../../../common/ContextMenu/ContextMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplaceFile from '../Popups/ReplaceFile';
import DeleteFile from '../Popups/DeleteFile';
import { getDownloadDocument } from '../services/services';
import Configurations from '../../../../Configurations';
import { ToastContainer, toast } from 'react-toastify';

//for context Menu need to implement genaric component
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { VirtualDataRoomToastMessages } from '../../../../utils/AppConstants';
import { axiosApiCallForFiles } from '../../../Services/axiosApiCallForFiles';

const ReportsAccordians = (props) => {

    //#region props
    const { reports, isfileUpload,refreshAccordians } = props;

    //#region variables
    const [replacepopup, setReplacePopup] = React.useState(false);
    const [deletepopup, setDeletePopup] = React.useState(false);
    const [currDeleteDocumnet, setCurrDeleteDocumnet] = React.useState(null);
    const [currReplace, setcurrReplace] = React.useState(null);

    //#region click events
    const openreplacePopup = (item) => {
        setcurrReplace(item);
        setReplacePopup(true);
        handleClose();
    }
    const openDeletePopup = (item) => {
        setCurrDeleteDocumnet(item);
        setDeletePopup(true);
        handleClose();
    }
    const closereplacePopup = () => {
        setReplacePopup(false);
    }
    const closeDeletePopup = () => {
        setDeletePopup(false);
    }

    //#region context menu items
    const MenuItemsTop = [
        {
            id: 1,
            label: "Replace",
            icon: "",
            isdisable: false,
            onClick: openreplacePopup,
        },
        {
            id: 1,
            label: "Delete",
            icon: "",
            isdisable: false,
            onClick: openDeletePopup,
        }
    ];

    //TODO : For Now Using FETCH to download the document

    /* const handleDownloadFile = (item) => {
        const data = getDownloadDocument(item?.vdrItemID);
         
        if (data?.responseCode === 200) {
             
            const url = window.URL.createObjectURL(new Blob([data?.responseData]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', item?.documentName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }        
        else {
            // Handle error
        }
    } */

    //#region api get calls 
    const handleDownloadFile = async (item) => {
        // fetch(`${Configurations.apiBaseUri}/v1/VirtualDataRooms/DownloadVDRItem?vdrID=${item?.vdrItemID}`, {
        //     method: 'GET',
        // })
        // .then((response) => {
        //     if (!response.ok) {
        //         toast.error("unable to downlaod the file",
        //              { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        //     }
        //     toast.success("File Successfully downloaded",
        //     { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        //       return response.blob();
        //     })
        //     .then(blob => {
        //         const url = URL.createObjectURL(blob);
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.download = item?.documentPath.documentPath.split('/').pop()
        //         link.click();
        //         URL.revokeObjectURL(url);
        //     })
        //     .catch(error => {
        //         console.error(error);
        //     });

        const data = await axiosApiCallForFiles(`/v1/VirtualDataRooms/DownloadVDRItem?vdrID=${item.vdrItemID}`, item.documentPath.split('/').pop())
        if (data.responseCode === 200) {
            toast.success(VirtualDataRoomToastMessages.FILE_SUCCESS_DOWNLOAD, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        } else {
            toast.warning(VirtualDataRoomToastMessages.ACTION_FAILED, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //contextmenu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //#region component methods
    const renderDocuments = (documents) => {
        return (
            <Grid item xs={12}>
                {documents?.map((item, index) => (
                    <div>
                        <div className='documents-wrapper' key={item?.vdrItemID}>
                            <div>
                                {item?.fundName}
                            </div>
                            <div className='space-between displaycenter'>
                                <div>
                                    <Link
                                        onClick={() => {
                                            handleDownloadFile(item);
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ cursor: 'pointer' }}>
                                        Download
                                    </Link>
                                </div>
                                {
                                    isfileUpload &&
                                    <div>
                                        <ContextMenu MenuItemsTop={MenuItemsTop} ItemDetails={item}/>
                                    </div>
                                }
                                {/* <MoreVertIcon sx={{marginLeft: '2px',marginBottom: '2px'}}/> */}
                            </div>
                        </div>
                        {documents.length - 1 === index ? null : <Divider sx={{ color: 'black', opacity: 1 }} />}
                    </div>
                ))}
            </Grid>
        );
    };

    return (
        <div className='accordian-wrapper'>
            {
                reports && reports.length === 0 ? "No Data Available" :

                    reports?.map((item) => {
                        return (
                            <div>
                                <Accordian title={item?.quarterYear} body={renderDocuments(item?.fundReportDetails)} />
                            </div>
                        )
                    })
            }
            {replacepopup && currReplace && <ReplaceFile open={replacepopup} onClose={closereplacePopup} DocumnetDetails={currReplace} refreshAccordians={refreshAccordians}/>}
            {deletepopup && currDeleteDocumnet && <DeleteFile open={deletepopup} onClose={closeDeletePopup} DocumnetDetails={currDeleteDocumnet} refreshAccordians={refreshAccordians}/>}
            <ToastContainer />
        </div>
    )
}

export default ReportsAccordians