import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { validationsConstants } from '../../../../utils/AppConstants';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import * as yup from 'yup';
import { useFormik } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { addQuestionBankStep, setSubscriptionQuestionStep } from '../services/services';
import { ToastContainer, toast } from 'react-toastify';

const AddStepPopup = (props) => {

    //#region props
    const { open, onClose, allQuestionDetails, investorTypeId, selectedFundType, questionTypeId } = props;

    //#region initialdata
    const initialData = {
        stepName: '',
        stepDescription: ''
    }

    //#region variables
    const [loading, setLoading] = useState(false);

    //#region change events
    const handleChange = (name, value) => {
        stepDetails.setFieldValue(name, value)
    }

    //#region click events
    const onSaveClick = () => {
        addNewStep();
    }

    //#region api post calls
    const addNewStep = async () => {
        setLoading(true);
        const requestBody = {
            "stepID": 0,
            "stepNumber": (allQuestionDetails[allQuestionDetails?.length - 1]?.questionBank?.step + 1) * 10 || 1,
            "stepTitle": stepDetails.values.stepName,
            "stepHeader": "",
            "stepDescription": stepDetails.values.stepDescription,
            "stepFooter": "",
            "investorTypeID": investorTypeId,
            "fundTypeID": selectedFundType,
            "QuestionTypeID": questionTypeId
        }
        const data = await addQuestionBankStep(requestBody);
        if (data.responseCode === 200) {
            toast.success("Step Added Successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            onClose({ isSave: true });
        }
        else {
            setLoading(false);
            toast.error("unable to add the setp",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region formikvalidations
    const stepValidationSchema = yup.object().shape({
        stepName: yup
            .string()
            .trim()
            .required(validationsConstants.REQUIRED),
        stepDescription: yup
            .string()
            .trim()
            .required(validationsConstants.REQUIRED),
    });


    const stepDetails = useFormik({
        initialValues: initialData,
        validationSchema: stepValidationSchema,
        onSubmit: (values) => {
            onSaveClick();
        },
    });

    //#region return  
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                ADD STEP
            </DialogTitle>
            <DialogContent>
                <div className='child-margin-15'>
                    <div>
                        <ALTTextField
                            name="stepName"
                            label="Step Name"
                            value={stepDetails?.values?.stepName || []}
                            onChange={(name, value) => handleChange(name, value)}
                            onBlur={stepDetails.handleBlur}
                            required={true}
                            error={stepDetails.touched.stepName && Boolean(stepDetails.errors.stepName)}
                            errorMessage={stepDetails.touched.stepName && stepDetails.errors.stepName} />
                    </div>
                    <div>
                        <ALTTextField
                            name="stepDescription"
                            label="Step Description"
                            value={stepDetails?.values?.stepDescription || []}
                            onChange={(name, value) => handleChange(name, value)}
                            onBlur={stepDetails.handleBlur}
                            required={true}
                            error={stepDetails.touched.stepDescription && Boolean(stepDetails.errors.stepDescription)}
                            errorMessage={stepDetails.touched.stepDescription && stepDetails.errors.stepDescription} />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton onClick={onClose} icon={<CloseIcon />} disabled={loading} variant='outlined' label='Cancel' />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                            label='SAVE'
                            loading={loading}
                            icon={<SaveIcon />}
                            startIconName=''
                            onClick={stepDetails.handleSubmit} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default AddStepPopup;