import axios from "axios";
import resolve from "../../common/resolve";
import Configurations from "../../../Configurations";
import secureLocalStorage from "react-secure-storage";
import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";

export async function getUsers() {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantUserDetails`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}


export async function getTenantFeatures() {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantFeatures?tenantID=${secureLocalStorage.getItem("tenantID")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getTenantDashboards() {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantDashboards?tenantID=${secureLocalStorage.getItem("tenantID")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getTenantRoleFeatures() {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantRoleFeatures?tenantID=${secureLocalStorage.getItem("tenantID")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getTenantRoles() {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantRoles?tenantID=${secureLocalStorage.getItem("tenantID")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function postNewUser(formDataBody) {
    return await axiosApiCall.post(`/v1/User/CreateTenantUser?tenantID=${secureLocalStorage.getItem("tenantID")}`, formDataBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function postRoleFeatures(data) {
    return await axiosApiCall.post(`/v1/UserRole/UpdateRoleFeature?tenantID=${secureLocalStorage.getItem("tenantID")}`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function resetingRoleFeautures(roleID,primaryRoleID) {
    return await axiosApiCall.get(`/v1/UserRole/ResetApplicationFeatureRoleAccess?roleID=${roleID}&primaryRoleID=${primaryRoleID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getPrimaryRoles() {
    return await axiosApiCall.get(`/v1/UserRole/GetTenantRoles?tenantID=${secureLocalStorage.getItem("tenantID")}&isSystemRole=0`)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function CreateUpdateTenantRole(data) {
    return await axiosApiCall.post(`/v1/UserRole/CreateUpdateTenantRole`, data)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function UpdateUserPassword(password) {
    const response = await fetch(`${Configurations.apiBaseUri}/v1/User/UpdateUserProfilePassword?userId=${secureLocalStorage.getItem("tenantUserId")}&password=${password}`, {
        method: 'PATCH',
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update password');
    }

    return await response.json();
}

export async function updateFirmRegistartionData(formData, password) {
    return await axiosApiCall.put(`/v1/User/UpdateOnboardingQuestionsForFirmEmployees?password=${password}`,formData)
    .then(res => {
        return getAPIResponse(res);
    }).catch(error => {
        return getAPIResponse(error);
    });
}

export async function VerifyOTP(id,otp) {
    return await axiosApiCall.get(`/v1/User/VerifyOTP?OTPId=${id}&OTP=${encodeURIComponent(otp)}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function reSendEmailNotificationToUser(user) {
    return await axiosApiCall.post(`/v1/User/ReSendEmailNotificationToUser?tenantID=${secureLocalStorage.getItem("tenantID")}&userEmailAddress=${user.userEmailAddress}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

