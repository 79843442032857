import React, { useRef, useState, useEffect } from 'react';
import './styles.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import secureLocalStorage from 'react-secure-storage';
import ProfileCompletionPopup from '../navigation-bar/ProfileCompletionPopup';

const TabsComponment = (props) => {

  //#region props
  const { tabs, selectedTab, setSelectedTab,isScrollable=false } = props;

  //#region variables
  const tabsListRef = useRef(null);
  const [leftArrowActive, setLeftArrowActive] = useState(false);
  const [rightArrowActive, setRightArrowActive] = useState(false);
  const [profileCompletionPopup,setProfileCompletionPopup] = useState(false);

  //#region click events
  const handleTabClick = (tab) => {
    if(!secureLocalStorage.getItem("isProfilingCompleted")){
      if(tabs[tab]?.isInActiveTab){
        onOpenProfileCompletionPopup();
        return;
      }
    }
    setSelectedTab(tab);
  };

  const handleRightArrowClick = () => {
    tabsListRef.current.scrollLeft += 200;
  };

  const handleLeftArrowClick = () => {
    tabsListRef.current.scrollLeft -= 200;
  };

  const onOpenProfileCompletionPopup = () => {
    setProfileCompletionPopup(true);
  }

  const onCloseProfileCompletionPopup = () => {
    setProfileCompletionPopup(false);
  }

  //#region useeffect
  //#region useeffect
  useEffect(() => {
    const manageIcons = () => {
      if (!tabsListRef.current) return;

      const tabsList = tabsListRef.current;

      // Check scroll position for left arrow
      if (tabsList.scrollLeft >= 20) {
        setLeftArrowActive(true);
      } else {
        setLeftArrowActive(false);
      }

      // Check scroll position for right arrow
      const maxScrollValue =
        tabsList.scrollWidth - tabsList.clientWidth - 20;
      if (tabsList.scrollLeft >= maxScrollValue) {
        setRightArrowActive(false);
      } else {
        setRightArrowActive(true);
      }
    };

    const tabsList = tabsListRef.current;

    // Add scroll listener to tabsList
    tabsList.addEventListener("scroll", manageIcons);

    // Add resize listener to window
    window.addEventListener("resize", manageIcons);

    // Initial check
    manageIcons();

    // Cleanup function
    return () => {
      tabsList.removeEventListener("scroll", manageIcons);
      window.removeEventListener("resize", manageIcons);
    };
  }, []);
  

  //#region styles
  const iconstyles = {
    width: '34px !important',
    height: '34px !important',
    color: 'white',
  };

  //#region return
  return (
    <>
<div className='commontabs'>
      <div className={isScrollable ? "scrollable-tabs-container max-width-view-87" : "scrollable-tabs-container"}>
        <div className={`left-arrow ${leftArrowActive ? 'active' : ''}`} onClick={handleLeftArrowClick}>
          <KeyboardArrowLeftIcon sx={iconstyles} />
        </div>
        <ul ref={tabsListRef} className="s-tab-container">
          {tabs?.map((tab, index) => (
            <li
              key={index}
              className={selectedTab === tab?.key ? 'active' : ''}
              onClick={() => handleTabClick(tab?.key)}
            >
              <div className="tab-icon">{tab.icon}</div>
              <div className="tabLabel">{tab.label}</div>
            </li>
          ))}
        </ul>
        <div className={`right-arrow ${rightArrowActive ? 'active' : ''}`} onClick={handleRightArrowClick}>
          <ChevronRightIcon sx={iconstyles} />
        </div>
      </div>
    
      <div>
        <div className={tabs[selectedTab]?.isBackgroundColor ? 'white-card margin-top-15' : 'margin-top-15'}>
          {tabs[selectedTab]?.component}
        </div>
      </div>
      </div>
      {
        profileCompletionPopup && 
        <ProfileCompletionPopup
          open={profileCompletionPopup}
          onClose={onCloseProfileCompletionPopup} />
      }
    </>
  );
};

export default TabsComponment;
