import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import secureLocalStorage from "react-secure-storage";
import AuthenticationPage from "../../AppAuthentication/AuthenticationPage";
import { msalInstance } from "../../Site/SiteHeader";
import { width } from "@mui/system";
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import { foulWords, reservedWords } from "../foulwords";
import { checkUserNameExcists, checkUserNameExcistsThroughHorizon, createUserviaSignUp, setUser } from "../services/RegistrationProfilingService";
import { ToastContainer, toast } from 'react-toastify';
import ALTTextField from "../../common/input-fields/ALTTextField";
import { useFormik } from "formik";
import * as yup from 'yup';
import { validationsConstants } from "../../../utils/AppConstants";
import { textFormatTypeEnum } from "../../common/TextInput/appInputenum";
import { addCustomYupMethods } from "../../common/input-fields/utlis/CustomYupValidations";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CircleIcon from "../../chatbot/Icon";
const CreateAccountWithEmail = () => {

  //#region initialdata
  const initialData = {
    userName: '',
    emailAddress: '',
    password: '',
    confirmPassword: ''
  }

  //#region variables
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);

  //#region change events
  const handleChange = (name,value) => {
    userDetails.setFieldValue(name, value);
  }

  //#region click events
  const onSaveClick = () => {
    handleSubmit();
  }

  //#region api post calls
  const handleSubmit = async () => {
     setLoading(true);
     const userNameData = await checkUserNameExcistsThroughHorizon(userDetails.values.userName)
     secureLocalStorage.setItem("registeredMailID", userDetails.values.emailAddress)
     if(userNameData.responseCode === 200){
      toast.error("UserName Already Exists", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      setLoading(false);
     }
     else{
      const requestedBody = {
        firstName: "",
        lastName: "",
        userName: userDetails.values.userName,
        emailAddress: userDetails.values.emailAddress,
        password: userDetails.values.password,
        userRoleId: 0,
        isActive: false,
        softDelete: false,
        createdBy: 0,
        createdDate: "2023-04-13T07:22:40.189Z",
        updatedBy: 0,
        updatedDate: "2023-04-13T07:22:40.189Z"
      }
      const data = await setUser(requestedBody);
      debugger;
      if(data.responseCode === 200){
        navigate('/thank-you');
      }
      else if (data.responseCode === 422 || data.responseCode === 409) {
        let errorMessage = "This email address is already in use. Please choose different one";
        if(data.responseData == "Invalid value specified for property 'mail' of resource 'User'."){
          errorMessage = "Please provide valid Email Address.";
        }
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
        setLoading(false);
      } 
      else {
        toast.error("unable to create user,please try again later", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
         });
         setLoading(false);
      }
    }
  };

  //#region formik validations
  addCustomYupMethods()

  const signupValidationSchema = yup.object().shape({
    userName: yup.string()
              .required(validationsConstants.REQUIRED)
              .customUserName(validationsConstants.USER_NAME), 
    emailAddress: yup
        .string()
        .required(validationsConstants.REQUIRED)
        .customEmail(validationsConstants.EMAIL_ADDRESS),
    password: yup.string()
              .required(validationsConstants.REQUIRED)
              .customPassword(validationsConstants.PASSWORD), 
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], validationsConstants.PASSWORD_MATCH)
        .required(validationsConstants.REQUIRED),
  });

  const userDetails = useFormik({
      initialValues: initialData,
      validationSchema: signupValidationSchema,
      onSubmit: (values) => {
          onSaveClick(values);
      },
  });

  //#region return
  return (
    <div className="regwithothersbox">
      <form onSubmit={handleSubmit} novalidate="novalidate">
        <img className="res-logo" src="/Alt360-Logo-Royal-SM.png" alt="Alt360" />
        <div className="signup-header">
          <h3>Create an Account</h3>
          <p>Start building a portfolio tailored to your unique goals.</p>
        </div>
        <div className="child-margin-15">
            <div>
              <ALTTextField
                 name="userName"
                 label="User Name"
                 value={userDetails.values.userName}
                 onChange={(name, value) => handleChange(name, value)}
                 required={true}
                 onBlur={userDetails.handleBlur}
                 error={userDetails.touched.userName && Boolean(userDetails.errors.userName)}
                 errorMessage={userDetails.touched.userName && userDetails.errors.userName} />
            </div>
            <div>
              <ALTTextField
                 name="emailAddress"
                 label="Email Address"
                 value={userDetails.values.emailAddress}
                 onChange={(name, value) => handleChange(name, value)}
                 required={true}
                 onBlur={userDetails.handleBlur}
                 error={userDetails.touched.emailAddress && Boolean(userDetails.errors.emailAddress)}
                 errorMessage={userDetails.touched.emailAddress && userDetails.errors.emailAddress}  />
            </div>
            <div>
              <ALTTextField
                 textFormatType={textFormatTypeEnum.PASSWORD}
                 name="password"
                 label="Password"
                 value={userDetails.values.password}
                 onChange={(name, value) => handleChange(name, value)}
                 required={true}
                 onBlur={userDetails.handleBlur}
                 error={userDetails.touched.password && Boolean(userDetails.errors.password)}
                 errorMessage={userDetails.touched.password && userDetails.errors.password} />
            </div>
            <div>
              <ALTTextField
                 textFormatType={textFormatTypeEnum.PASSWORD}
                 name="confirmPassword"
                 label="Confirm Password"
                 value={userDetails.values.confirmPassword}
                 onChange={(name, value) => handleChange(name, value)}
                 required={true}
                 onBlur={userDetails.handleBlur}
                 error={userDetails.touched.confirmPassword && Boolean(userDetails.errors.confirmPassword)}
                 errorMessage={userDetails.touched.confirmPassword && userDetails.errors.confirmPassword} />
            </div>
        </div>
        <div className="reg-form margin-top-20">  
          <LoadingButton className="btn-orange mt2" loading={loading} onClick={userDetails.handleSubmit}>
            <EmailIcon alt="email-logo" />
            <span className="text-decoration-none">
              SIGN UP
            </span>
          </LoadingButton>
          {/* <div className="SignupWith">
            <GoogleOAuthProvider className="btn" clientId="936331901467-lkb9pkj7mk2m940v1a5fm31t3tt8tb8c.apps.googleusercontent.com" >
              <Google />
            </GoogleOAuthProvider>

            <button className="btn">
              <AppleIcon alt="apple-logo" />
              SIGN UP WITH APPLE
            </button>
          </div> */}
          <div className="recaptcha mt3">
           <span className="already-ac"> Already have an account? </span>
            <span className="signup-LoginLink">
              <AuthenticationPage msalInstance={msalInstance} />
            </span>
          </div>

          {/* <div className="google-recaptcha">
            This site is protected by reCAPTCHA and the
            <p> Google<a href="#"> Privacy Policy </a>and <a href="#"> Terms of Service </a>apply.</p>
          </div> */}
        </div>
      </form >
      <ToastContainer />
      <CircleIcon/>   
    </div>
  );
};

export default CreateAccountWithEmail;

