import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  { name: "Q1 '22", GrossMargin: 45, EbitdaMargin: 29, NetProfitMargin: 15 },
  { name: "Q2 '22", GrossMargin: 46, EbitdaMargin: 30, NetProfitMargin: 16 },
  { name: "Q3 '22", GrossMargin: 47, EbitdaMargin: 31, NetProfitMargin: 17 },
  { name: "Q4 '22", GrossMargin: 48, EbitdaMargin: 32, NetProfitMargin: 18 },
];

const ThreeLineChart = () => {
    return (
      <div style={{ width: '100%', height: '400px', padding: '16px' }}>
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={data}
                margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                dataKey="name" 
                padding={{ left: 40}} // Add padding to X-axis
                />
                <YAxis 
                label={{ value: 'Margin (%)', angle: -90, position: 'insideLeft' }} 
                />
                <Tooltip />
                <Legend />
                <Line
                type="monotone"
                dataKey="GrossMargin"
                stroke="#FFBE00"
                activeDot={{ r: 8 }}
                name="Gross Margin (%)"
                />
                <Line
                type="monotone"
                dataKey="EbitdaMargin"
                stroke="#55D8FE"
                name="EBITDA Margin (%)"
                />
                <Line
                type="monotone"
                dataKey="NetProfitMargin"
                stroke="#1F61C5"
                name="Net Profit Margin (%)"
                />
            </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

export default ThreeLineChart;
