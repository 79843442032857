import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Typography,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Text from '../../common/Text/Text';
import InfoIcon from '@mui/icons-material/Info';
import BulkUploadInfo from './BulkUploadInfo';
import SaveIcon from '@mui/icons-material/Save';
import { BulkUploadFilePosting, nonNaturalBulkUpload, nonNaturalBulkUploadPosting } from '../Services/IRServices';
import ActionButton from '../../common/ActionButton/ActionButton';
import { ToastContainer, toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadBtn from '../../common/FileUploadButton/FileUploadBtn';
import { uploadFileTypes } from '../../common/FileUploadButton/uploadComponentenum';
import { validationMessages } from '../../common/TextInput/appInputenum';

function BulkUploadModal(props) {
    //#region props
    const { open, onClose, setappendRowOnBulkupload, setBulkUploadSaveData, setBulkUploadStatus } = props;

    //#region variables
    const [showBulkUploadInfo, setShowBulkUploadInfo] = useState(false);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [infoType, setInfoType] = useState('');
    const [isNaturalPerson, setIsNaturalPerson] = useState(''); // New state for radio buttons
    const formData = new FormData();

    //#region click events 
    const onInfoIconClick = (type) => {
        setShowBulkUploadInfo(true);
        setInfoType(type);
    };

    const onInfoCancelIconClick = () => {
        setShowBulkUploadInfo(false);
    };

    const handleFileUpload = (name, file) => {
        if (name === 'uploadFile') {
            if (file) {
                setFile(file);
            }
            else {
                setFile(null)
            }
        }
    };

    useEffect(() => {
        if (file) {
            formData.append('excelFile', file);
        } else {
            setFile(null)
        }
    }, [file]);

    const handleRadioChange = (event) => {
        setIsNaturalPerson(event.target.value);
    };

    //#region service calls
    const handleSave = async () => {
        setLoading(true);
        if (isNaturalPerson === 'true') {
            const data = await (BulkUploadFilePosting(formData));
            if ((data?.responseCode === 200)) {
                // Success logic
            } else if (data?.responseCode === 400) {
                setLoading(false);
                toast.error((data?.responseData?.errorMessage),
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            } else if (data?.responseCode === 500) {
                setLoading(false);
                toast.error("Please upload valid document",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            else {
                if (data?.responseData?.fileBytes != null) {
                    var arrayBuffer = data?.responseData?.fileBytes;
                    var binaryString = window.atob(arrayBuffer);
                    var binaryLen = binaryString?.length;
                    var bytes = new Uint8Array(binaryLen);
                    for (var i = 0; i < binaryLen; i++) {
                        var ascii = binaryString.charCodeAt(i);
                        bytes[i] = ascii;
                    }

                    var blob = new Blob([bytes], { type: "application/txt" });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    var fileName = data?.responseData?.fileName;
                    link.download = fileName;
                    link.click();
                }
            }
            setBulkUploadSaveData(data?.responseData?.users);
            setBulkUploadStatus(data?.responseCode);
            setappendRowOnBulkupload(data?.responseData.users?.map(user => ({
                id: user.emailAddress,
                userFullName: isNaturalPerson === 'true' ? `${user.firstName} ${user.lastName}` : `${user.firstName}`,
                userEmailAddress: user.emailAddress,
                investorType: user.investorType,
                ...user
            })));
            setLoading(false);
            onClose();

        } else {
            const data = await (nonNaturalBulkUploadPosting(formData));
            if ((data.responseCode === 200)) {
                // Success logic
            } else if (data?.responseCode === 400) {
                setLoading(false);
                toast.error((data?.responseData?.errorMessage),
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            } else if (data?.responseCode === 500) {
                setLoading(false);
                toast.error("Please upload valid document",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            else {
                if (data?.responseData?.fileBytes != null) {
                    var arrayBuffer = data?.responseData?.fileBytes;
                    var binaryString = window.atob(arrayBuffer);
                    var binaryLen = binaryString?.length;
                    var bytes = new Uint8Array(binaryLen);
                    for (var i = 0; i < binaryLen; i++) {
                        var ascii = binaryString.charCodeAt(i);
                        bytes[i] = ascii;
                    }

                    var blob = new Blob([bytes], { type: "application/txt" });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    var fileName = data?.responseData?.fileName;
                    link.download = fileName;
                    link.click();
                }
            }
            setBulkUploadSaveData(data?.responseData.users);
            setBulkUploadStatus(data?.responseData.status);
            setappendRowOnBulkupload(data?.responseData.users?.map(user => ({
                id: user.emailAddress,
                userFullName: isNaturalPerson === 'true' ? `${user.firstName} ${user.lastName}` : `${user.firstName}`,
                userEmailAddress: user.emailAddress,
                userPhonenumber: user.phoneNo,
            })));
            setLoading(false);
            onClose();
        }

    };

    return (
        <Dialog open={open} fullWidth>
            {showBulkUploadInfo ? (
                <BulkUploadInfo onInfoCancelIconClick={onInfoCancelIconClick} infoType={infoType} />
            ) : (
                <div>
                    <DialogTitle>
                        <Grid container alignItems="center">
                            <Grid item xs={10}>
                                <h6> Bulk Upload Investors</h6>
                            </Grid>
                            <Grid item xs={2} container justifyContent="flex-end">
                                <IconButton onClick={onClose}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'left' }}>
                        <Grid>
                            <Typography>Download the template file and follow the instructions. Then Upload the file using below button</Typography>
                        </Grid>
                        <Grid container className="margin-top-50" justifyContent="center">
                            <input type="file" style={{ display: 'none' }} />
                            <label htmlFor="fileInput">
                                <FileUploadBtn
                                    name="uploadFile"
                                    label='Upload File'
                                    fileType={uploadFileTypes.CSV}
                                    defaultFile={file}
                                    disabled={isNaturalPerson === ''}
                                    validationMessage={validationMessages.ONLY_CSV_FILE}
                                    onChange={(name, value) => handleFileUpload(name, value)}
                                />
                            </label>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 10 }}>
                            <FormControl component="fieldset">
                                {/* // <FormLabel component="legend">Investor Type</FormLabel> */}
                                <RadioGroup
                                    row
                                    aria-label="isNaturalPerson"
                                    name="isNaturalPerson"
                                    value={isNaturalPerson}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Individual Investors" />
                                    <FormControlLabel value={false} control={<Radio />} label="Non-Individual Investors" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid container justifyContent="start" alignItems="left" style={{ marginTop: 30, display: 'flex', }} onClick={() => onInfoIconClick('natural')}>
                            <IconButton size="small">
                                <InfoIcon />
                            </IconButton>
                            <Typography variant="caption" sx={{ cursor: 'pointer' }}>Bulk Upload guide for Individual investor type</Typography>
                        </Grid>
                        <Grid container justifyContent="start" alignItems="left" style={{ marginTop: 10, display: 'flex', }} onClick={() => onInfoIconClick('nonNatural')}>
                            <IconButton size="small">
                                <InfoIcon />
                            </IconButton>
                            <Typography variant="caption" sx={{ cursor: 'pointer' }}>Bulk Upload guide for Non-Individual investor type</Typography>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div className="space-between">
                            <div>
                                <ActionButton className="btn-primary" component="label" variant="outlined"
                                    icon={<CancelIcon />}
                                    label={'Cancel'}
                                    onClick={onClose} />
                            </div>
                            <div className="margin-left-10">
                                <ActionButton
                                    variant="contained"
                                    component="span"
                                    icon={<SaveIcon />}
                                    label={'Save File'}
                                    disabled={!file || !isNaturalPerson}
                                    loading={loading}
                                    onClick={handleSave}
                                />
                            </div>
                        </div>
                    </DialogActions>
                </div>
            )}
        </Dialog>
    );
}

export default BulkUploadModal;
