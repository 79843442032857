import { useFormik } from 'formik';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import * as yup from 'yup';
import { validationsConstants } from '../../../../utils/AppConstants';
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import ActionButton from '../../../common/ActionButton/ActionButton';
import AddIcon from '@mui/icons-material/Add';
import { FieldVariants } from '../../../common/TextInput/appInputenum';
import SelectField from '../../../common/input-fields/SelectField';
import { companyStatementsTemplate } from '../../APIDataTemplate';
import { yearsDropDownValues } from '../../../common/FundPerformance/apiDataTemplate';

const DueDiligenceDetails = forwardRef((props, ref) => {
  
  //#region variables
  const { companyDetails, setCompanyDetails } = props;

  //#region file upload events
  const handleFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleChange(name, file);
    }
    else {
      handleChange(name, '');
    }
  }

  const handleProfitFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleProfitStatementsChange(name, file);
    }
    else {
      handleProfitStatementsChange(name, '');
    }
  }

  const handleBalanceFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleBalanceSheetsChange(name, file);
    }
    else {
      handleBalanceSheetsChange(name, '');
    }
  }

  const handleCashFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleCashFlowStatementsChange(name, file);
    }
    else {
      handleCashFlowStatementsChange(name, '');
    }
  }

  //#region change events
  const handleProfitStatementsChange = (field, value) => {
    const fieldSplit = field.split(".");
    const currIndex = fieldSplit[1].split("-")[0];
    const fieldName = fieldSplit[1].split("-")[1];
    const currState = dueDiligenceDetails?.values?.profitLossStatements;
    const item = { ...currState[currIndex] };
    item[fieldName] = value;
    currState[currIndex] = item;
    handleChange("profitLossStatements", currState);
  };

  const handleBalanceSheetsChange = (field, value) => {
    const fieldSplit = field.split(".");
    const currIndex = fieldSplit[1].split("-")[0];
    const fieldName = fieldSplit[1].split("-")[1];
    const currState = dueDiligenceDetails?.values?.balanceSheets;
    const item = { ...currState[currIndex] };
    item[fieldName] = value;
    currState[currIndex] = item;
    handleChange("balanceSheets", currState);
  };

  const handleCashFlowStatementsChange = (field, value) => {
    const fieldSplit = field.split(".");
    const currIndex = fieldSplit[1].split("-")[0];
    const fieldName = fieldSplit[1].split("-")[1];
    const currState = dueDiligenceDetails?.values?.cashFlowStatements;
    const item = { ...currState[currIndex] };
    item[fieldName] = value;
    currState[currIndex] = item;
    handleChange("cashFlowStatements", currState);
  };

  const handleChange = (field, value) => {
    dueDiligenceDetails.setFieldValue(field, value);
  };

  //#region click events
  const addProfitLossDocument = () => {
    const newDocs = [...dueDiligenceDetails.values?.profitLossStatements, { ...companyStatementsTemplate }];
    handleChange("profitLossStatements", newDocs);
  };

  const addBalanceSheetDocument = () => {
    const newDocs = [...dueDiligenceDetails.values?.balanceSheets, { ...companyStatementsTemplate }];
    handleChange("balanceSheets", newDocs);
  };

  const addCashFlowDocument = () => {
    const newDocs = [...dueDiligenceDetails.values?.cashFlowStatements, { ...companyStatementsTemplate }];
    handleChange("cashFlowStatements", newDocs);
  };

  //#region formik validations
  const validationSchema = yup.object().shape({
    creditReport: yup.string().required(validationsConstants.REQUIRED),
    profitLossStatements: yup.array().of(
      yup.object().shape({
        year: yup.string().required(validationsConstants.REQUIRED),
        document: yup.string().required(validationsConstants.REQUIRED),
      })
    ),
    balanceSheets: yup.array().of(
      yup.object().shape({
        year: yup.string().required(validationsConstants.REQUIRED),
        document: yup.string().required(validationsConstants.REQUIRED),
      })
    ),
    cashFlowStatements: yup.array().of(
      yup.object().shape({
        year: yup.string().required(validationsConstants.REQUIRED),
        document: yup.string().required(validationsConstants.REQUIRED),
      })
    ),
  });

  const dueDiligenceDetails = useFormik({
    initialValues: companyDetails.dueDiligenceDetails,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return true;
    },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await dueDiligenceDetails.validateForm();
      if (Object.keys(errors).length === 0) {
        setCompanyDetails((prevValues) => {
          return { ...prevValues, dueDiligenceDetails: dueDiligenceDetails?.values };
        });
        return true;
      } else {
        dueDiligenceDetails?.handleSubmit();
      }
    },
    updateFields: async () => {
      setCompanyDetails((prevValues) => {
        return { ...prevValues, dueDiligenceDetails: dueDiligenceDetails?.values };
      });
    }
  }));

  //#region return
  return (
    <div className='child-margin-10'>
      <div className='white-card child-margin-10'>
        <b>Credit Report</b>
        <div className='display-row-items-flex'>
          <div className='width-98'>
            <div className="legalFieldWrapper mt1 align-center">
              <FileUploadBtn
                name='creditReport'
                label="UPLOAD CREDIT REPORT"
                fileType={uploadFileTypes.PDF}
                required={true}
                defaultFile={dueDiligenceDetails.values.creditReport}
                onChange={(name, value) => handleFileUpload(name, value)}
                parentError={dueDiligenceDetails.touched.creditReport && Boolean(dueDiligenceDetails.errors.creditReport)}
                validationMessage={dueDiligenceDetails.touched.creditReport && dueDiligenceDetails.errors.creditReport}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='white-card margin-top-15'>
        <b>Financial Statements</b>
        <div className='child-margin-5'>
          <div>Profit & Loss Statements</div>
          <div className='display-row-items-flex'>
            {
              dueDiligenceDetails.values.profitLossStatements.map((item, index) => {
                return (
                  <div className='width-48' key={index}>
                    <div className="legalFieldWrapper mt1 space-between">
                      <div className='width-150'>
                        <SelectField
                          label="Choose Year"
                          name={`profitLossStatements.${index}-year`}
                          value={item.year}
                          required={true}
                          options={yearsDropDownValues}
                          onChange={(name, value) => handleProfitStatementsChange(name, value)}
                          onBlur={(e) => {
                            dueDiligenceDetails.handleBlur(e);
                            dueDiligenceDetails.setFieldTouched(`profitLossStatements[${index}].year`, true, false);
                          }}
                          error={dueDiligenceDetails.touched?.profitLossStatements?.[index]?.year && Boolean(dueDiligenceDetails.errors?.profitLossStatements?.[index]?.year)}
                          errorMessage={dueDiligenceDetails.touched?.profitLossStatements?.[index]?.year && dueDiligenceDetails.errors?.profitLossStatements?.[index]?.year}
                        />
                      </div>
                      <div>
                        <FileUploadBtn
                          name={`profitLossStatements.${index}-document`}
                          label="UPLOAD DOCUMENT"
                          fileType={uploadFileTypes.PDF}
                          defaultFile={item.document}
                          required={true}
                          onChange={(name, value) => handleProfitFileUpload(name, value)}
                          parentError={dueDiligenceDetails.touched?.profitLossStatements?.[index]?.document && Boolean(dueDiligenceDetails.errors?.profitLossStatements?.[index]?.document)}
                          validationMessage={dueDiligenceDetails.touched?.profitLossStatements?.[index]?.document && dueDiligenceDetails.errors?.profitLossStatements?.[index]?.document}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div>
            <ActionButton
              label="Add Document"
              icon={<AddIcon />}
              variant={FieldVariants.OUTLINED}
              onClick={addProfitLossDocument}
              disabled={yearsDropDownValues.length === dueDiligenceDetails.values.profitLossStatements.length}
            />
          </div>
        </div>

        <div className='child-margin-5 margin-top-15'>
          <div>Balance Sheets</div>
          <div className='display-row-items-flex'>
            {
              dueDiligenceDetails.values.balanceSheets.map((item, index) => {
                return (
                  <div className='width-48' key={index}>
                    <div className="legalFieldWrapper mt1 space-between">
                      <div className='width-150'>
                        <SelectField
                          label="Choose Year"
                          name={`balanceSheets.${index}-year`}
                          value={item.year}
                          required={true}
                          options={yearsDropDownValues}
                          onChange={(name, value) => handleBalanceSheetsChange(name, value)}
                          onBlur={(e) => {
                            dueDiligenceDetails.handleBlur(e);
                            dueDiligenceDetails.setFieldTouched(`balanceSheets[${index}].year`, true, false);
                          }}
                          error={dueDiligenceDetails.touched?.balanceSheets?.[index]?.year && Boolean(dueDiligenceDetails.errors?.balanceSheets?.[index]?.year)}
                          errorMessage={dueDiligenceDetails.touched?.balanceSheets?.[index]?.year && dueDiligenceDetails.errors?.balanceSheets?.[index]?.year}
                        />
                      </div>
                      <div>
                        <FileUploadBtn
                          name={`balanceSheets.${index}-document`}
                          label="UPLOAD DOCUMENT"
                          fileType={uploadFileTypes.PDF}
                          defaultFile={item.document}
                          required={true}
                          onChange={(name, value) => handleBalanceFileUpload(name, value)}
                          parentError={dueDiligenceDetails.touched?.balanceSheets?.[index]?.document && Boolean(dueDiligenceDetails.errors?.balanceSheets?.[index]?.document)}
                          validationMessage={dueDiligenceDetails.touched?.balanceSheets?.[index]?.document && dueDiligenceDetails.errors?.balanceSheets?.[index]?.document} />
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div>
            <ActionButton
              label="Add Document"
              icon={<AddIcon />}
              variant={FieldVariants.OUTLINED}
              onClick={addBalanceSheetDocument}
              disabled={yearsDropDownValues.length === dueDiligenceDetails.values.balanceSheets.length}
            />
          </div>
        </div>

        <div className='child-margin-5 margin-top-15'>
          <div>Cash Flow Statements</div>
          <div className='display-row-items-flex'>
            {
              dueDiligenceDetails.values.cashFlowStatements.map((item, index) => {
                return (
                  <div className='width-48' key={index}>
                    <div className="legalFieldWrapper mt1 space-between">
                      <div className='width-150'>
                        <SelectField
                          label="Choose Year"
                          name={`cashFlowStatements.${index}-year`}
                          value={item.year}
                          required={true}
                          options={yearsDropDownValues}
                          onChange={(name, value) => handleCashFlowStatementsChange(name, value)}
                          onBlur={(e) => {
                            dueDiligenceDetails.handleBlur(e);
                            dueDiligenceDetails.setFieldTouched(`cashFlowStatements[${index}].year`, true, false);
                          }}
                          error={dueDiligenceDetails.touched?.cashFlowStatements?.[index]?.year && Boolean(dueDiligenceDetails.errors?.cashFlowStatements?.[index]?.year)}
                          errorMessage={dueDiligenceDetails.touched?.cashFlowStatements?.[index]?.year && dueDiligenceDetails.errors?.cashFlowStatements?.[index]?.year}
                        />
                      </div>
                      <div>
                        <FileUploadBtn
                          name={`cashFlowStatements.${index}-document`}
                          label="UPLOAD DOCUMENT"
                          fileType={uploadFileTypes.PDF}
                          defaultFile={item.document}
                          required={true}
                          onChange={(name, value) => handleCashFileUpload(name, value)}
                          parentError={dueDiligenceDetails.touched?.cashFlowStatements?.[index]?.document && Boolean(dueDiligenceDetails.errors?.cashFlowStatements?.[index]?.document)}
                          validationMessage={dueDiligenceDetails.touched?.cashFlowStatements?.[index]?.document && dueDiligenceDetails.errors?.cashFlowStatements?.[index]?.document}/>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div>
            <ActionButton
              label="Add Document"
              icon={<AddIcon />}
              variant={FieldVariants.OUTLINED}
              onClick={addCashFlowDocument}
              disabled={yearsDropDownValues.length === dueDiligenceDetails.values.cashFlowStatements.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default DueDiligenceDetails;