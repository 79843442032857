import React, { useEffect, useState } from 'react'
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, Button, MenuItem } from '@mui/material';
import ActionButton from '../../../common/ActionButton/ActionButton';
import MultiSelectDropdown from '../../../common/Multi-Select-Dropdown/MultiSelectDropdown';
import { ToastContainer, toast } from 'react-toastify';
import { AppDashboards } from '../../../../utils/enum';
import CancelIcon from '@mui/icons-material/Cancel';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { InputTypes, validationMessages, validationsTypeEnum } from '../../../common/TextInput/appInputenum';
import AppTextInput from '../../../common/TextInput/AppTextField';
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import { yearsdropdown } from '../../../InvestorReporting/FirmEmployees/jsonData';
import { convertArrayToString } from '../../../common/Functions/ConvertStringtoArray';
import { addNewK1Document } from './Services/K1Services';
import secureLocalStorage from 'react-secure-storage';

function AddK1Document(props) {

  //#region props
  const { open, onClose, reports, setCurrentTab, ApprovedInvestorsForFund, setRefreshComponent, refreshFolders, refreshComponent, getAccordianData, getFoldersDetails } = props;

  //#region initialdata
  const initialData = {
    year: '',
    selectInvestor: '',
    document: '',
  }

  //#region variables
  const [details, setDetails] = useState(initialData);
  const [otherOptionID, setOtherOptionID] = useState('');
  const [loading, setLoading] = useState(false);
  const [replaceDocumentPopup, setReplaceDocumentPopup] = useState(false);
  const [existingDocuments, setExistingDocument] = useState(null);
  const [fundsDropdown, setfundsDropdown] = useState(null);
  const [reportsDropdown, setReportsDropdown] = useState(null);

  //#region change events
  const handleChange = (field, value) => {
    setDetails({
      ...details,
      [field]: value
    });
  }

  const handleFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleChange(name, file);
    } else {
      handleChange(name, null)
    }
  }

  //#region click events
  const openreplacePopup = () => {
    setReplaceDocumentPopup(true);
  }
  const closereplacePopup = () => {
    setReplaceDocumentPopup(false);
  }

  const formDataBody = new FormData();
  const handleSubmit = async () => {
    const requestbody =
    {
      FundID: secureLocalStorage.getItem('FundId'),
      InvestorID: details.selectInvestor,
      UserName: null,
      Year: details.year
    }

    formDataBody.append("file", details.document, details.document.name)
    formDataBody.append("k1Reporting", JSON.stringify(requestbody))

    const data = await addNewK1Document(formDataBody)
    if (data.responseCode === 200) {
      onClose()
      getFoldersDetails(secureLocalStorage.getItem('FundId'), (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD ? secureLocalStorage.getItem('userId') : 0))
      setRefreshComponent(false)
      setTimeout(() => {
        setRefreshComponent(true)
      }, 1000);
    }
    else if (data.responseCode === 404) {
      toast.warning(`The K-1 report for ${(ApprovedInvestorsForFund.find(u => u.investorID === details.selectInvestor)).investor} already exists for ${details.year}.`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        style: {
          marginTop: '100px',
        },
      });
      onClose()
      getFoldersDetails(secureLocalStorage.getItem('FundId'), (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD ? secureLocalStorage.getItem('userId') : 0))
      setRefreshComponent(false)
      setTimeout(() => {
        setRefreshComponent(true)
      }, 1000);

    } else {
      toast.warning(data.responseData, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        style: {
          marginTop: '100px',
        },
      });
    }
    getFoldersDetails(secureLocalStorage.getItem('FundId'), (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD ? secureLocalStorage.getItem('userId') : 0))
  };

  //#region validations
  const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });
  const [checkIfValid, setcheckIfValid] = useState(false);
  let count = 0;   //to Show Toast Message only once

  const checknoofValidFields = (isValid, name, defaultValue) => {
    const TotalFiledsToValidate = 3;

    count = ++count;
    noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
    if (noofValidFields['validfields'] === TotalFiledsToValidate) {
      noofValidFields['validfields'] = 0;
      count = 0;
      //Post API
      handleSubmit();
    } else {
      if (count === TotalFiledsToValidate) {
        toast.warning("Please verify the input fields",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
        count = 0;
      }
    }
  };

  const handleSave = () => {
    setcheckIfValid(true);
    noofValidFields['validfields'] = 0;
  }


  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogTitle>
          Add New Document
        </DialogTitle>
        <DialogContent>
          <div className='space-between'>
            <div></div>
            <div>
              <FileUploadBtn
                name='document'
                label="UPLOAD DOCUMENT"
                fileType={uploadFileTypes.PDF}
                required={true}
                defaultFile={details.document}
                onChange={(name, value) => handleFileUpload(name, value)}
                checkIfValid={checkIfValid}
                maxSize={2 * 1024 * 1024}
                validationType={validationsTypeEnum.ONLY_PDF_FILE}
                validationMessage={validationMessages.ONLY_PDF_FILE}
                checknoofValidFields={checknoofValidFields}
                setcheckIfValid={setcheckIfValid} />
            </div>
          </div>
          <div className='margin-top-15'>
            <AppTextInput
              type={InputTypes.SELECT}
              name='year'
              label="Select Year"
              defaultValue={details?.year}
              onChange={(name, value) => handleChange(name, value)}
              options={yearsdropdown?.map(option => ({ label: option.name, value: option.id }))}
              validateField={true}
              validationType={validationsTypeEnum.REQUIRED}
              validationMessage={validationMessages.REQUIRED}
              checkIfValid={checkIfValid}
              checknoofValidFields={checknoofValidFields}
              setcheckIfValid={setcheckIfValid} />
          </div>
          <div className='margin-top-15'>
            <AppTextInput
              type={InputTypes.SELECT}
              name='selectInvestor'
              label="Select Investor"
              defaultValue={details?.selectInvestor}
              onChange={(name, value) => handleChange(name, value)}
              options={ApprovedInvestorsForFund?.map(option => ({ label: (option.investor + " " + "(" + option.investorEmail + ")"), value: option.investorID }))}
              validateField={true}
              sortDirection={'ascending'}
              validationType={validationsTypeEnum.REQUIRED}
              validationMessage={validationMessages.REQUIRED}
              checkIfValid={checkIfValid}
              checknoofValidFields={checknoofValidFields}
              setcheckIfValid={setcheckIfValid} />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="space-between">
            <div>
              <ActionButton
                className="btn-primary"
                icon={<CancelIcon />}
                component="label"
                variant="outlined"
                label="Cancel"
                onClick={onClose} />


            </div>
            <div className="margin-left-10">
              <ActionButton
                icon={<TouchAppIcon />}
                label="Submit"
                loading={loading}
                startIconName=""
                onClick={handleSave} />
            </div>
          </div>
        </DialogActions>
        <ToastContainer />
      </Dialog>
    </>
  )
}

export default AddK1Document