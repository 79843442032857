import React, { useEffect, useState } from 'react'
import ActionButton from '../../../common/ActionButton/ActionButton';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, LinearProgress, Tooltip } from '@mui/material';
import { getConfiguredQuestionsBasedOnQuestionType } from '../services/services';
import TreeGrid from '../../investor-subscription-questions/components/TreeGrid';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { addQuestionInitialData, branchTypes, configurationTypesEnum } from '../../investor-subscription-questions/services/apiDataTemplate';
import AddQuestionPopup from '../../investor-subscription-questions/popups/AddQuestionPopup';
import { getKeyValuePairs } from '../../../portfolio-companies/services/services';
import { ToastContainer } from 'react-toastify';

const KYCAMLQuestions = (props) => {

  //#region props
  const { investorTypeName } = props; 
  
  //#region variables
  const [allQuestionDetails,setAllQuestionDetails] = useState([]);
  const [openQuestionPopup,setQuestionPopup] = useState(false);
  const [childQuestionPopup,setChildQuestionPopup] = useState(false);
  const [currQuestionDetails,setCurrQuestionDetails] = useState(null);
  const [parentQuestionDetais,setParentQuestionDetails] = useState(null);
  const [questionPatternTypeIDsDetails,setquestionPatternTypeIDDetails] = useState(null);
  const [sectionTypes,setSectionTypes] = useState([]);
  const [readOnly,setReadOnly] = useState(false);
  const [sectionDetails,setSectionDetails] = useState([]);
  const [newQuestionDetails,setNewQuestionDetails] = useState(addQuestionInitialData);
  const columns = [
    /* { field: 'branchID', headerName: 'ID', width: 90 }, */
    { field: 'branchTitle', headerName: 'Question Name', width: 200,
      renderCell: (params) => (
         params.row.branchType === "Question" ? params.row.branchTitle: ""
       ),
    },
    { field: 'questionPatternType', headerName: 'Question Type', width: 200,
      renderCell: (params) => (
         params.row.questionBank?.questionPatternType
       ),
    },
    { field: 'isActive', headerName: 'Active Question',
      renderCell: (params) => (
        params.row.hierarchy.length === 1  ? "" :
        (
            params.row.questionBank.isActive 
            ? (
                  <IconButton>
                  <CheckBoxIcon/>
                  </IconButton>
               ) 
            : (
                  <Tooltip title="">
                  <IconButton>
                     <CheckBoxOutlineBlankIcon />
                  </IconButton>
                  </Tooltip>
               )
         )
      ),
    },
    { 
      field: 'isQuestionUsedByInvestor', 
      headerName: 'Editable',
      renderCell: (params) => (
        params.row.hierarchy.length === 1 
          ? "" 
          : (
            (params.row.questionBank.createdBy === 1|| params.row.questionBank.isQuestionUsedByInvestor) 
              ? (
               
                 <Tooltip title="This Question cannot be updated">
                    <IconButton>
                      <CheckBoxOutlineBlankIcon />
                    </IconButton>
                  </Tooltip>
                  
                ) 
              : (
                  <IconButton>
                     <CheckBoxIcon/>
                  </IconButton>
                )
          )
      ),
    }    
  ]

  //#region click events
  const onOpenPopup = (questionDetails,viewOnly) => {
    if(questionDetails){
       if(viewOnly){
          setReadOnly(true);
        }
        else{
           setReadOnly(false);
        }
       setCurrQuestionDetails(questionDetails)
    }
    else{
       newQuestionDetails.showIfParentAnswerIs = investorTypeName
       setCurrQuestionDetails(newQuestionDetails);
    }
    setQuestionPopup(true);
   }
 
   const onOpenChildQuestionPopup = (questionDetails,isAddChildQuestion,parentQuestion,viewOnly) => {
      if(isAddChildQuestion){
       setParentQuestionDetails(questionDetails);
       newQuestionDetails.showIfParentAnswerIs = investorTypeName
       setCurrQuestionDetails(newQuestionDetails);
      }
      else{
       if(viewOnly){
         setReadOnly(true);
       }
       else{
          setReadOnly(false);
       }
       setParentQuestionDetails(parentQuestion);
       setCurrQuestionDetails(questionDetails)
      }
      setChildQuestionPopup(true);
   }
 
   const onClose = ({ isSave } = {}) => {
    if(isSave){
      getAllQuestionsDetails();
    }
    setCurrQuestionDetails(null);
    setChildQuestionPopup(false);
    setParentQuestionDetails(null);
    setQuestionPopup(false);
    setReadOnly(false);
   }

  //#region api get calls
  const getAllQuestionsDetails = async() => {
    const data = await getConfiguredQuestionsBasedOnQuestionType(investorTypeName);
    if(data.responseCode === 200){
      if(data.responseData.length > 0){
        setAllQuestionDetails(data.responseData);
      }
      else{
        setAllQuestionDetails();
      }
      const allSteps = data.responseData?.filter(branch => branch.branchType === branchTypes.STEP);
      const stepsDropDown = allSteps?.map((item) => ({
        listItemID: item?.questionBank?.step,
        listItemValue: item?.questionBank?.stepName
      }));
      setSectionDetails(stepsDropDown)
    }
    else{
      setAllQuestionDetails();
    }
  }

  const getquestionPatternTypeIDs = async() =>{
    const data = await getKeyValuePairs("OnboardingQuestionPatterns");
    if(data.responseCode === 200){
        setquestionPatternTypeIDDetails(data?.responseData)
    }
    else{
        setquestionPatternTypeIDDetails([])
    }
  }

  const getSectionIDs = async() =>{
    const data = await getKeyValuePairs("OnBoardingQuestionType");
    if(data.responseCode === 200){
        const filteredData = data.responseData.filter((item)=> item.listItemValue === "KYC" || item.listItemValue === "AML")
        setSectionTypes(filteredData)
    }
    else{
        setSectionTypes([])
    }
  }

  //#region useeffect
  useEffect(()=>{
    getAllQuestionsDetails();
    getquestionPatternTypeIDs();
    getSectionIDs();
  },[])

  //#region return
  return (
    <div className='child-margin-10'>
        {
          allQuestionDetails ?
          allQuestionDetails.length > 0 ? 
          <>
            <div className='space-between margin-top-minus-10'>
            <div></div>
            <div className='child-margin-row-10'>
                <div>
                    <ActionButton 
                      label="ADD QUESTION"
                      icon={<AddIcon />}
                      onClick={onOpenPopup}/>
                </div>
            </div>
            </div>
            <div className=''>
              <TreeGrid 
                type={configurationTypesEnum.KYC_AML}
                initialColumns={columns}
                initialRows={allQuestionDetails} 
                onOpenEditPopup={onOpenPopup} 
                onOpenChildQuestionPopup={onOpenChildQuestionPopup}
                questionPatternTypeIDsDetails={questionPatternTypeIDsDetails}
                getAllQuestionsDetails={getAllQuestionsDetails} />
            </div>
            {
                openQuestionPopup && currQuestionDetails &&
                <AddQuestionPopup 
                    type={configurationTypesEnum.KYC_AML}
                    open={openQuestionPopup}
                    currQuestionDetails={currQuestionDetails}
                    onClose={onClose}
                    stepsDetails={sectionDetails}
                    isEdit={currQuestionDetails?.questionBankID > 0}
                    readOnly={readOnly}
                    isChildQuestion={false}
                    sectionTypes={sectionTypes}
                    questionPatternTypeIDsDetails={questionPatternTypeIDsDetails} allQuestionDetails={allQuestionDetails} />
            }
            {
                childQuestionPopup && currQuestionDetails &&
                  <AddQuestionPopup 
                    type={configurationTypesEnum.KYC_AML}
                    open={childQuestionPopup}
                    stepsDetails={sectionDetails}
                    parentQuestionDetails={parentQuestionDetais}
                    currQuestionDetails={currQuestionDetails}
                    onClose={onClose} 
                    isChildQuestion={true}
                    readOnly={readOnly} 
                    isEdit={currQuestionDetails?.questionBankID > 0}
                    sectionTypes={sectionTypes}
                    questionPatternTypeIDsDetails={questionPatternTypeIDsDetails} allQuestionDetails={allQuestionDetails} />
            }
          </> :
          <LinearProgress /> :
          "No Questions Found"
        }
        <ToastContainer />
    </div>
  )
}

export default KYCAMLQuestions