import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import ActionButton from '../ActionButton/ActionButton';
import WarningIcon from '@mui/icons-material/Warning';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CancelIcon from '@mui/icons-material/Cancel';

const ProfileCompletionPopup = (props) => {

    //#region props
    const { open, onClose } = props;

    //#region variables
    const [loading, setLoading] = useState(false);

    //#region return
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogContent>
                <div className='child-margin-15'>
                    <div className='icon-sec align-items-center '>
                        <WarningIcon color="primary" sx={{ width: "55px", height: "60px" }} />
                    </div>
                    <h4 className='align-items-center'>
                        Complete Your Profile
                    </h4>
                    <div>
                        Your profile is incomplete.
                        Please fill in all mandatory fields to proceed and unlock full access to the platform.
                        To gain access to all features, ensure you complete the following sections:
                        <ul>
                            <li>Personal Information Tab</li>
                            <li>Banking Information Tab</li>
                            <li>KYC/AML Tab</li>
                        </ul>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <div className='space-between margin-top-minus-10'>
                    <div>
                        <ActionButton variant='outlined' icon={<CancelIcon />} onClick={onClose} label='Cancel' />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                            label='FILL TO UNLOCK'
                            loading={loading}
                            startIconName=''
                            styleProps={{
                                padding: '8px 30px'
                            }}
                            onClick={onClose}
                            icon={<LockOpenIcon />} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default ProfileCompletionPopup;