import { useEffect, useRef, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const Tabs = (props) => {

    const [selectedTabIndex, setTabSelectIndex] = useState(props.tabSelected);
    const tabsListRef = useRef(null);
    const [leftArrowActive, setLeftArrowActive] = useState(false);
    const [rightArrowActive, setRightArrowActive] = useState(false);

    const handleTabSelect = (tabIndex, key) => {
        setTabSelectIndex(tabIndex);
        props.onSelectTab(key);
    }

    const handleRightArrowClick = () => {
        tabsListRef.current.scrollLeft += 200;
      };
    
      const handleLeftArrowClick = () => {
        tabsListRef.current.scrollLeft -= 200;
      };
    
      //#region useeffect
      useEffect(() => {
        const manageIcons = () => {
          if (!tabsListRef.current) return;
    
          const tabsList = tabsListRef.current;
    
          // Check scroll position for left arrow
          if (tabsList.scrollLeft >= 20) {
            setLeftArrowActive(true);
          } else {
            setLeftArrowActive(false);
          }
    
          // Check scroll position for right arrow
          const maxScrollValue =
            tabsList.scrollWidth - tabsList.clientWidth - 20;
          if (tabsList.scrollLeft >= maxScrollValue) {
            setRightArrowActive(false);
          } else {
            setRightArrowActive(true);
          }
        };
    
        const tabsList = tabsListRef.current;
    
        // Add scroll listener to tabsList
        tabsList.addEventListener("scroll", manageIcons);
    
        // Add resize listener to window
        window.addEventListener("resize", manageIcons);
    
        // Initial check
        manageIcons();
    
        // Cleanup function
        return () => {
          tabsList.removeEventListener("scroll", manageIcons);
          window.removeEventListener("resize", manageIcons);
        };
      }, []);
    
      //#region styles
      const iconstyles = {
        width: '34px !important',
        height: '34px !important',
        color: 'white',
      };

    useEffect(()=>{
        setTabSelectIndex(props.tabSelected)
    },[props.tabSelected])

    return(
          <>
            <div className="scrollable-tabs-container">
                <div className={`left-arrow ${leftArrowActive ? 'active' : ''}`} onClick={handleLeftArrowClick}>
                <KeyboardArrowLeftIcon sx={iconstyles} />
                </div>
                <ul ref={tabsListRef} className="s-tab-container">
                {props.tabs?.map((tab, index) => (
                    <li
                    key={index}
                    className={index==selectedTabIndex ? 'active' : ''}
                    onClick={() => handleTabSelect(index, tab.key)}
                    >
                    <div className="tab-icon">{tab.icon}</div>
                    <div className="tabLabel">{tab.label}</div>
                    </li>
                ))}
                </ul>
                <div className={`right-arrow ${rightArrowActive ? 'active' : ''}`} onClick={handleRightArrowClick}>
                <ChevronRightIcon sx={iconstyles} />
                </div>
            </div>
          </>
    )
}