import MailLockIcon from '@mui/icons-material/MailLock';
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TreeItem } from "@mui/x-tree-view";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { FormControlLabel, Switch, Tooltip } from "@mui/material"; // Import Tooltip
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import LockIcon from '@mui/icons-material/Lock';
import { AppDashboards } from '../../../utils/enum';
import secureLocalStorage from 'react-secure-storage';

const renderTree = (node, selectedVDRID, showToggle, isCompliance, handleTreeNodeClick, checkFeaturePermissions, RolesAndPermissions, FeatureAccess, rowSelected, popupVDR) => (
    <TreeItem
        key={node.vdrItemID}
        nodeId={node.vdrItemID}
        disabled={(popupVDR ? (!node.isFolder) : false) || (node.isDisabled ? true : false)}
        label={
            <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    checked={selectedVDRID.includes(node.vdrItemID)}
                    onChange={(event) => rowSelected && rowSelected(event, node.vdrItemID, node)}
                    disabled={(popupVDR ? (node.isFolder === false) : false) || (node.isDisabled ? true : false)}
                />

                {node?.isPlaceHolder && (
                    <div>
                        <FormControlLabel
                            control={
                                showToggle ? (
                                    <Switch
                                        checked={node?.isRequired}
                                        name="required"
                                        onChange={(ev) => {
                                            // handleSwitchChange(ev.target.checked, node.vdrItemID);
                                        }}
                                    />
                                ) : (
                                    <div></div>
                                )
                            }
                            label={
                                <span
                                    style={{
                                        color:
                                            node.isRequired && node.requestedFileUploaded
                                                ? "blue"
                                                : node.isRequired
                                                    ? "#FF0000"
                                                    : "#CCCCCC",
                                        marginRight: "12px",
                                    }}
                                >
                                    {node.isRequired ? "REQUIRED" : "OPTIONAL"}
                                </span>
                            }
                            labelPlacement="start"
                        />
                    </div>
                )}

                {node.isFolder ? (
                    <div className='display-row'>
                        <Tooltip title={node.isPrivate ? "This folder is accessible only to Firm Users." : "This folder is visible to all users."}>
                            <div>
                                <FolderIcon
                                    style={{ color: "#FFE9A2", marginRight: "8px", fontSize: '28px' }}
                                    fontSize="small"
                                />
                                {node.isPrivate && (
                                    <LockIcon
                                        style={{
                                            color: 'black',
                                            fontSize: "12px !important",
                                            position: 'absolute',
                                            marginLeft: '-24px',
                                            marginTop: '8px',
                                            zIndex: '999'
                                        }}
                                    />
                                )}
                            </div>
                        </Tooltip>
                        {node.title}
                    </div>
                ) : checkFeaturePermissions(
                    RolesAndPermissions.FUND_VDR,
                    FeatureAccess.READ
                ) || isCompliance === true ? (
                    <div>
                        {node.isFolder ? (
                            <div>
                                <FolderIcon
                                    style={{ color: "#FFE9A2", marginRight: "8px" }}
                                    fontSize="small"
                                />
                                {node.isPrivate && (
                                    <LockIcon
                                        style={{
                                            color: 'black',
                                            fontSize: "12px !important",
                                            position: 'absolute',
                                            marginLeft: '-24px',
                                            marginTop: '8px',
                                            zIndex: '999'
                                        }}
                                    />
                                )}
                            </div>
                        ) : node.title.split(".").pop() === "pdf" ? (
                            <PictureAsPdfIcon style={{ color: "#FF5733" }} />
                        ) : (
                            <DescriptionIcon
                                style={{ color: "#007FFF", marginRight: "8px" }}
                                fontSize="small"
                            />
                        )}
                        {node.title}
                    </div>
                ) : null}
            </div>
        }
        onClick={() => handleTreeNodeClick(node)} // Handle tree item click
    >
        {Array.isArray(node.children) && node.children.length > 0 ? (
            (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD || popupVDR) ? (
                node.children
                    .filter((u) => (popupVDR ? (u.canInvestorView !== true) : (u.canInvestorView === true)))
                    .map((childNode) =>
                        renderTree(
                            childNode,
                            selectedVDRID,
                            showToggle,
                            isCompliance,
                            handleTreeNodeClick,
                            checkFeaturePermissions,
                            RolesAndPermissions,
                            FeatureAccess,
                            rowSelected,
                            popupVDR
                        )
                    )
            ) : (
                node.children.map((childNode) =>
                    renderTree(
                        childNode,
                        selectedVDRID,
                        showToggle,
                        isCompliance,
                        handleTreeNodeClick,
                        checkFeaturePermissions,
                        RolesAndPermissions,
                        FeatureAccess,
                        rowSelected,
                        popupVDR
                    )
                )
            )
        ) : null}

    </TreeItem>
);

export default renderTree;
