import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import propTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';

const TextComponent = (props) => {
    const {
        type,
        onChange,
        variant,
        required,
        multiline,
        defaultValue,
        value,
        placeholder,
        rows,
        label,
        options,
        name,
        editable,
        styleProps,
        min,
        max,
    } = props;

    const [currentValue, setCurrentValue] = useState(defaultValue || value);

    useEffect(() => {
        setCurrentValue(value || defaultValue);
    }, [value, defaultValue]);

    const handleChange = (ev) => {
        const { name, value } = ev.target;
        setCurrentValue(value);
        onChange(name, value);
    };

    const renderMenuItems = () =>
        options &&
        options.length &&
        options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
                {label}
            </MenuItem>
        ));

    return (
        <TextField
            required={required}
            disabled={!editable}
            value={currentValue}
            variant={multiline ? 'outlined' : variant}
            type={type}
            placeholder={placeholder}
            multiline={multiline}
            rows={rows}
            size="small"
            fullWidth
            label={label}
            onChange={handleChange}
            name={name}
            sx={styleProps}
            InputProps={
                type === 'number'
                    ? {
                          inputProps: {
                              min: min,
                              max: max,
                          },
                      }
                    : {}
            }
            InputLabelProps={{
                shrink: true,
                focused: true,
            }}
            select={type === 'select'}
        >
            {type === 'select' && renderMenuItems()}
        </TextField>
    );
};

TextComponent.propTypes = {
    type: propTypes.oneOf(['text', 'password', 'number', 'search', 'select']),
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    multiline: propTypes.bool,
    defaultValue: propTypes.string,
    value: propTypes.string,
    placeholder: propTypes.string,
    rows: propTypes.number,
    label: propTypes.string,
    options: propTypes.array,
    name: propTypes.string,
    editable: propTypes.bool,
    styleProps: propTypes.object,
    min: propTypes.number,
    max: propTypes.number,
};

TextComponent.defaultProps = {
    type: 'text',
    variant: 'standard',
    required: false,
    multiline: false,
    defaultValue: '',
    value: '',
    placeholder: '',
    rows: 4,
    label: '',
    options: [],
    name: '',
    editable: true,
    styleProps: {},
};

export default TextComponent;
