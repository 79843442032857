import React from 'react'
import { useState, useEffect } from 'react';
import { getGroupsList } from '../../../Services/GroupsServices';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import Accordian from '../../../../common/Accordian/Accordian';
import '../styles.css'
import secureLocalStorage from 'react-secure-storage';
import { getInvestorsandGroupsList } from '../../../Services/GroupsServices';
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import AddGroups from '../../AddGroups';
import GroupsPopup from './Popups/GroupsPopup';
import { getInvestorsList } from '../../../Services/IRServices';
import { ToastContainer, toast } from 'react-toastify';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GroupDetailsView from './GroupDetailsView';
import ContextMenu from '../../../../common/ContextMenu/ContextMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import { getGroupByID } from '../../../Services/GroupsServices';
import GroupDeletePopup from './Popups/GroupDeletePopup';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LinearProgress } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import GroupIcon from '@mui/icons-material/Group';
import SelectField from '../../../../common/input-fields/SelectField';
import { FieldVariants } from '../../../../common/TextInput/appInputenum';
import { InvestorCommunicarionToastMessages } from '../../../../../utils/AppConstants';

const GroupsMain = (props) => {

    //#region Props from Parent
    const { navigateCustomEmail, getNotificationsCount } = props; //to move to custom email tab

    //#region useState Variables
    const [excistinggroups, setExcistingGroups] = useState(null);
    const [InvestorsGroupsList, setInvestorsGroupsList] = useState(null);
    const [selectedGroupType, setSelectedGroupType] = React.useState('1');
    const [investorsList, setInvestorsList] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [deletedGroup, setDeletedGroup] = useState(null);
    const [expanded, setExpanded] = useState({});

    //#region Popup Variables
    const [addGroupPopup, setAddGroupPopup] = useState(false);
    const [editPopup, setEditPopup] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);

    //#region dropdown options
    const Options = [
        { value: '1', label: 'All' },
        { value: 'Manual Groups', label: 'Manual Groups' },
        { value: 'Fund Groups', label: 'Fund Groups' },
    ]

    //#region events
    const handleChange = (name, value) => {
        setSelectedGroupType(value);
    };

    const handleOpenPopup = () => {
        setAddGroupPopup(true);
    }

    const handleClosePopup = () => {
        setAddGroupPopup(false);
    }

    const handleCloseEditPopup = () => {
        setEditPopup(false);
        setSelectedGroup(null);
    }

    const handleEdit = (item) => {
        getGroupDetails(item);
        setEditPopup(true);
    }

    const handleDelete = (item) => {
        setDeletePopup(true);
        setDeletedGroup(item);
    }

    const handleDeletePopupClose = () => {
        setDeletePopup(false);
        setDeletedGroup(null);
    }

    const naviagetoCustomEmail = (item) => {
        navigateCustomEmail(0, item?.userGroupID);
    }

    //#region Accordians events
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
    };

    const handleCloseAllAccordians = () => {
        setExpanded({});
        getExcistingGroups();
    }

    //#region Context Menu Items
    const MenuItemsTop = [
        {
            id: 1,
            label: 'Edit Group',
            icon: <EditIcon />,
            onClick: handleEdit
        },
        {
            id: 1,
            label: 'Notify Group',
            icon: <EmailIcon />,
            onClick: naviagetoCustomEmail
        },
        {
            id: 2,
            label: 'Delete Group',
            icon: <DeleteIcon />,
            onClick: handleDelete
        }
    ]


    //#region GET API Calls
    const getExcistingGroups = async () => {
        const data = await getGroupsList(secureLocalStorage.getItem('tenantID'));
        if (data?.responseCode == 200) {
            setExcistingGroups(data?.responseData)
        } else {
            setExcistingGroups([])
        }
    }

    const getGroupDetails = async (item) => {
        const data = await getGroupByID(item?.userGroupID);
        if (data?.responseCode === 200) {
            setSelectedGroup(data?.responseData);
        } else {
             toast.warning(InvestorCommunicarionToastMessages.ACTION_FAILED,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    const getInvestorsGroupsList = async () => {
        const data = await getInvestorsandGroupsList();
        if (data?.responseCode === 200) {
            setInvestorsGroupsList(data?.responseData);
        } else {
            toast.warning(InvestorCommunicarionToastMessages.ACTION_FAILED,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    const getInvestors = async () => {
        const data = await getInvestorsList();
        if (data?.responseCode === 200) {
            setInvestorsList(data?.responseData);
        } else {
            toast.warning(InvestorCommunicarionToastMessages.ACTION_FAILED,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region useEffect 
    useEffect(() => {
        getExcistingGroups();
        getInvestors();
        getInvestorsGroupsList();
    }, []);

    //#region Accordian View
    const accordianEndView = (item, isExpanded) => {
        return (
            <div className='space-between'>
                <div className='mr4 reciepients-count'>
                    Recipients : {item?.noOfGroupMembers}
                </div>
                <div>
                    {
                        isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }
                </div>
            </div>
        )
    };

    const getFilteredGroups = () => {
        if (selectedGroupType === 'Fund Groups') {
            return excistinggroups.filter(u => u.entityTypeName?.toLowerCase() === "fund");
        } else if (selectedGroupType === 'Manual Groups') {
            return excistinggroups.filter(u => u.entityTypeName?.toLowerCase() !== "fund");
        }
        return excistinggroups;
    };

    useEffect(() => {
        getFilteredGroups()
    }, [selectedGroupType])


    return (
        <div className='notifications-accordian-wrapper'>
            <div className='space-between'>
                <div className='min-width-100'>
                    <SelectField
                        label={'Group Type'}
                        name={'groupType'}
                        options={Options}
                        onChange={(name, value) => handleChange(name, value)}
                        value={selectedGroupType}
                        size={'Large'}
                        variant={FieldVariants.OUTLINED}
                    />
                </div>
                <div>
                    <Button variant="outlined" className="btn-primary" style={{ cursor: 'pointer' }} onClick={handleOpenPopup}>
                        <AddIcon />
                        <span>Add Group</span>
                    </Button>
                </div>
            </div>
            {
                excistinggroups && InvestorsGroupsList ? excistinggroups.length === 0 ? "No Data Available" :
                    getFilteredGroups()?.map((item) => {
                        return (
                            <div key={item.userGroupID} className='space-between'>
                                <div className='groupview-accordian'>
                                    <Accordion expanded={expanded[item.userGroupID] || false}
                                        onChange={handleAccordionChange(item.userGroupID)}
                                        style={{ border: '1px lightgray solid' }}>
                                        <AccordionSummary
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                            <div className='align-items-center'>
                                                <div className='margin-bottom-5'>{item.entityTypeName?.toLowerCase() !== "fund" ? <GroupIcon /> : <PaidIcon />}</div>
                                                <div className='margin-left-5'>{item?.userGroupName}</div>
                                            </div>
                                            <div style={{ marginLeft: 'auto' }}>
                                                {accordianEndView(item, expanded[item.userGroupID])}
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <GroupDetailsView
                                                groupID={item.userGroupID}
                                                isAccordianExpanded={expanded[item.userGroupID]}
                                                navigateCustomEmail={navigateCustomEmail}
                                                entityTypeName={item.entityTypeName} />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                {item.entityTypeName?.toLowerCase() === "fund" ? <div className='margin-right-30'></div>
                                    :
                                    <div className='margin-top-9'>
                                        <ContextMenu MenuItemsTop={MenuItemsTop} ItemDetails={item} />
                                    </div>}
                            </div>
                        )
                    }) : <div>
                    <LinearProgress />
                </div>
            }
            {
                addGroupPopup && <GroupsPopup
                    open={addGroupPopup}
                    data={[]}
                    getNotificationsCount={getNotificationsCount}
                    isNewGroup={true}
                    onClose={handleClosePopup}
                    refreshData={getExcistingGroups}
                    investorsList={investorsList} />
            }
            {
                editPopup && selectedGroup && <GroupsPopup
                    open={editPopup}
                    data={selectedGroup}
                    isNewGroup={false}
                    onClose={handleCloseEditPopup}
                    refreshData={getExcistingGroups}
                    refresGroupDetails={handleCloseAllAccordians}
                    investorsList={investorsList} />
            }
            {
                deletePopup && deletedGroup && <GroupDeletePopup
                    open={deletePopup}
                    getNotificationsCount={getNotificationsCount}
                    onClose={handleDeletePopupClose}
                    data={deletedGroup}
                    refreshData={getExcistingGroups} />
            }
            <ToastContainer />
        </div>
    )
}

export default GroupsMain