import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    name: "Q1 '22",
    valuation: 500, // First quarter's value (starting point)
    pv: 0 // No previous value
  },
  {
    name: "Q2 '22",
    valuation: 120, // Difference between Q2 and Q1
    pv: 500 // Starting point for Q2 is Q1's value
  },
  {
    name: "Q3 '22",
    valuation: -50, // Difference between Q3 and Q2
    pv: 620 // Starting point for Q3 is Q2's value
  },
  {
    name: "Q4 '22",
    valuation: 30, // Difference between Q4 and Q3
    pv: 570 // Starting point for Q4 is Q3's value
  },
  {
    name: "Total",
    valuation: 600, // The final total value
    pv: 0 // No previous value for the total
  }
];

const WaterFallChart = () => {
  return (
    <div style={{ width: "100%", height: "600px", padding: "16px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={650}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="pv" stackId="a" fill="transparent" />
          <Bar dataKey="valuation" stackId="a" fill="#82ca9d">
            {data.map((item, index) => {
              if (index === 0) {
                return <Cell key={index} fill="#6E8B3B" />; //green
              }
              if (item.valuation < 0) {
                return <Cell key={index} fill="#AB1D1D" />; //red
              }
              if (item.name === "Total") {
                return <Cell key={index} fill="#6E8B3B" />; //green
              }
              return <Cell key={index} fill="#1F61C5" />; //blue
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WaterFallChart;
