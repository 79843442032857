import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { fundTypesEnum } from '../../jsonData';

const TermFilter = (props) => {

  //#region props
  const { filterDetails, filterOptions, setFilterOptions, setActiveButton, currType, selectedFundType } = props;

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  //#region click events
  const onResetClick = () => {
    termValues.resetForm();
    setActiveButton(null);
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      termOptions: {
        ...filterOptions[filterDetails?.apiName],
        minimumvalue: -1,
        maximumvalue: -1
      }
    }));
  }

  //#region formik validations
  const InvestmentvalidationSchema = yup.object().shape({
    minimum: yup
      .number('Enter a valid number')
      .positive('Minimum value must be positive')
      .integer('Minimum value must be an integer')
      .min(0, 'Minimum value must be greater than or equal to zero') // Changed to min
      .required('Minimum value is required'),
    maximum: yup
      .number('Enter a valid number')
      .positive('Maximum value must be positive')
      .integer('Maximum value must be an integer')
      .min(yup.ref('minimum'), 'Maximum value must be greater than or equal to minimum value') // Changed to min
      .required('Maximum value is required')
  });


  const termValues = useFormik({
    initialValues: {
      minimum: filterOptions[filterDetails.apiName].minimumvalue === -1 ? '' : filterOptions[filterDetails.apiName].minimumvalue,
      maximum: filterOptions[filterDetails.apiName].maximumvalue === -1 ? '' : filterOptions[filterDetails.apiName].maximumvalue,
    },
    validationSchema: InvestmentvalidationSchema,
    onSubmit: (values) => {
      handleApplyClick(values);
    },
  });

  useEffect(() => {
    if (termValues.isValid && termValues.dirty) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [termValues.isValid, termValues.dirty]);

  const handleApplyClick = (values) => {
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      termOptions: {
        ...filterOptions[filterDetails?.apiName],
        minimumvalue: parseInt(values.minimum),
        maximumvalue: parseInt(values.maximum)
      }
    }));
  }

  //#region TODO : we need to define new classes for every filters, this is temprory solution
  const renderfilterLeftAllignonFundType = () => {
    if (
      filterDetails.fundtypes.includes(fundTypesEnum.PRIVATE_EQUITY) &&
      filterOptions?.fundType.find((option) => option.value === selectedFundType)?.label === fundTypesEnum.PRIVATE_EQUITY
    ) {
      if (filterDetails.id === 5) {
        return 'left-614';
      } else if (filterDetails.id === 6) {
        return 'left-793';
      } else {
        return '';
      }
    } else if (
      filterDetails.fundtypes.includes(fundTypesEnum.REAL_ESTATE_FUND) &&
      filterOptions?.fundType.find((option) => option.value === selectedFundType)?.label === fundTypesEnum.REAL_ESTATE_FUND
    ) {
      if (filterDetails.id === 5) {
        return 'left-299';
      } else if (filterDetails.id === 6) {
        return 'left-656';
      } else {
        return '';
      }
    } else if (
      filterDetails.fundtypes.includes(fundTypesEnum.VENTURE_CAPITAL) &&
      filterOptions?.fundType.find((option) => option.value === selectedFundType)?.label === fundTypesEnum.VENTURE_CAPITAL
    ) {
      if (filterDetails.id === 5) {
        return 'left-474';
      } else if (filterDetails.id === 6) {
        return 'left-656';
      } else {
        return '';
      }
    } else {
      return 'left-197'
    }
  };

  return (
    <div className={currType === fundTypesEnum.PRIVATE_EQUITY ? `ul-options-left-4 ${renderfilterLeftAllignonFundType()}` : `ul-options-left-pc-4 ${renderfilterLeftAllignonFundType()}`}>
      <div className='space-between'>
        <p className='filter-matter margin-top-5 margin-left-10'>Enter Term Period</p>
        <p className='reset-button margin-top-5 margin-right-5' onClick={onResetClick}>RESET</p>
      </div>
      <form onSubmit={termValues.handleSubmit}>
        <div className='display-row margin-left-10 space-between'>
          <div className='margin-right-5'>
            <TextField
              id="minimum"
              name="minimum"
              label="Min"
              value={termValues.values.minimum}
              onChange={(event) => termValues.setFieldValue('minimum', event.target.value.replace(/\D/g, ''))}
              onBlur={termValues.handleBlur}
              error={termValues.touched.minimum && Boolean(termValues.errors.minimum)}
              helperText={termValues.touched.minimum && termValues.errors.minimum}
              inputProps={{ inputMode: 'numeric' }}

            />

          </div>
          <div className='margin-right-5'>
            <TextField
              id="maximum"
              name="maximum"
              label="Max"
              value={termValues.values.maximum}
              onChange={(event) => termValues.setFieldValue('maximum', event.target.value.replace(/\D/g, ''))}
              onBlur={termValues.handleBlur}
              error={termValues.touched.maximum && Boolean(termValues.errors.maximum)}
              helperText={termValues.touched.maximum && termValues.errors.maximum}
              inputProps={{ inputMode: 'numeric' }}

            />
          </div>
        </div>
        <div className='space-between margin-top-10 margin-bottom-5'>
          <div></div>
          <div className='margin-right-5'>
            <Button
              label="APPLY"
              variant="contained"
              className="btn-primary"
              type="submit"
              disabled={isButtonDisabled}>
              APPLY
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default TermFilter;
