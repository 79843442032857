import { Grid, IconButton, Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import TreeGrid from './TreeGrid'
import ActionButton from '../../../common/ActionButton/ActionButton'
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import AddQuestionPopup from '../popups/AddQuestionPopup';
import AddStepPopup from '../popups/AddStepPopup';
import { addQuestionInitialData, branchTypes, configurationTypes, configurationTypesEnum } from '../services/apiDataTemplate';
import { getConfiguredQuestions, getDefaultConfigurations, getQuestionBankTree } from '../services/services';
import CheckBoxRenderingDemo from '../../InvestorOnBoardingQues/Components/CheckBoxRenderingDemo';
import { width } from '@mui/system';
import CheckBoxField from '../../../common/input-fields/CheckBoxField';
import { getKeyValuePairs } from '../../../portfolio-companies/services/services';
import { ToastContainer, toast } from 'react-toastify';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { getAllFunds } from '../../../InvestorRelations/Services/IRServices';

const SubscriptionQuestionsMain = (props) => {

  //#region props
  const { selectedFundType, selectedFundTypeName, investorTypeId, investorTypeName, allFundDeatils } = props;

  //#region variables
  const queryParams = new URLSearchParams(window.location.search);
  const showQuestionBankId = queryParams.get('showqbid');
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [openStepPopup, setStepPopup] = useState(false);
  const [openQuestionPopup, setQuestionPopup] = useState(false);
  const [childQuestionPopup, setChildQuestionPopup] = useState(false);
  const [currQuestionDetails, setCurrQuestionDetails] = useState(null);
  const [parentQuestionDetais, setParentQuestionDetails] = useState(null);
  const [allQuestionDetails, setAllQuestionDetails] = useState([]);
  const [newQuestionDetails, setNewQuestionDetails] = useState(addQuestionInitialData);
  const [stepsDetails, setStepsDetails] = useState([]);
  const [questionPatternTypeIDsDetails, setquestionPatternTypeIDDetails] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const columns = [
    // { field: 'branchID', headerName: 'ID', width: 90 }, // Commented out field
    {
      field: 'branchTitle',
      headerName: 'Question Name',
      width: 200,
      renderCell: (params) => (
        params.row.branchType === "Question" ? params.row.branchTitle : ""
      ),
    },
    {
      field: 'questionPatternType',
      headerName: 'Question Type',
      width: 200,
      renderCell: (params) => (
        params.row.questionBank?.questionPatternType
      ),
    },
    showQuestionBankId && {
      field: 'questionBankID',
      headerName: 'Question Bank ID',
      width: 200,
      renderCell: (params) => (
        params.row.branchType === "Question" ? params.row.questionBank?.questionBankID : ""
      ),
    },
    {
      field: 'isActive',
      headerName: 'Active Question',
      renderCell: (params) => (
        params.row.hierarchy.length === 1 ? "" :
          (
            params.row.questionBank?.isActive
              ? (
                <IconButton>
                  <CheckBoxIcon />
                </IconButton>
              )
              : (
                <Tooltip title="">
                  <IconButton>
                    <CheckBoxOutlineBlankIcon />
                  </IconButton>
                </Tooltip>
              )
          )
      ),
    },
    {
      field: 'isQuestionUsedByInvestor',
      headerName: 'Editable',
      renderCell: (params) => (
        params.row.hierarchy.length === 1 ? "" :
          (
            (params.row.questionBank?.createdBy === 1 || params.row.questionBank?.isQuestionUsedByInvestor)
              ? (
                <Tooltip title="This Question cannot be updated">
                  <IconButton>
                    <CheckBoxOutlineBlankIcon />
                  </IconButton>
                </Tooltip>
              )
              : (
                <IconButton>
                  <CheckBoxIcon />
                </IconButton>
              )
          )
      ),
    }
  ].filter(Boolean); // Filter out null/undefined entries



  //#region click events
  const onSaveClick = useCallback(() => {
    setIsSaveEnabled(prevState => !prevState); // Toggle isSaveEnabled state
  }, []);

  const onOpenPopup = (questionDetails, viewOnly) => {
    if (questionDetails) {
      if (viewOnly) {
        setReadOnly(true);
      }
      else {
        setReadOnly(false);
      }
      setCurrQuestionDetails(questionDetails)
    }
    else {
      setCurrQuestionDetails(newQuestionDetails);
    }
    setQuestionPopup(true);
  }

  const onOpenChildQuestionPopup = (questionDetails, isAddChildQuestion, parentQuestion, viewOnly) => {
    if (isAddChildQuestion) {
      setParentQuestionDetails(questionDetails);
      setCurrQuestionDetails(newQuestionDetails);
    }
    else {
      if (viewOnly) {
        setReadOnly(true);
      }
      else {
        setReadOnly(false);
      }
      setParentQuestionDetails(parentQuestion);
      setCurrQuestionDetails(questionDetails)
    }
    setChildQuestionPopup(true);
  }

  const onClose = ({ isSave } = {}) => {
    if (isSave) {
      getAllQuestionsDetails();
    }
    setCurrQuestionDetails(null);
    setChildQuestionPopup(false);
    setParentQuestionDetails(null);
    setQuestionPopup(false);
    setReadOnly(false);
    setStepPopup(false);
  }

  const onOpenStepPopup = () => {
    setStepPopup(true);
  }

  //#region api get calls
  const getAllQuestionsDetails = async () => {
    const data = await getQuestionBankTree(investorTypeId, selectedFundType, newQuestionDetails.questionTypeId);
    if (data.responseCode === 200) {
      setAllQuestionDetails(data.responseData);
      const allSteps = data.responseData?.filter(branch => branch.branchType === branchTypes.STEP);
      const stepsDropDown = allSteps?.map((item) => ({
        listItemID: item?.questionBank?.step,
        listItemValue: item?.questionBank?.stepName
      }));
      setStepsDetails(stepsDropDown)
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getQuestionDefaultConfigurations = async () => {
    const data = await getDefaultConfigurations();
    if (data.responseCode === 200) {
      const dataTemplate = addQuestionInitialData;
      dataTemplate.questionTypeId = data.responseData.questionType
      dataTemplate.parentQuestionID = 0
      dataTemplate.showIfParentAnswerIs = ""
      dataTemplate.investorTypeID = investorTypeId;
      dataTemplate.investorType = investorTypeName;
      dataTemplate.fundTypeID = selectedFundType;
      dataTemplate.fundType = selectedFundTypeName;
      dataTemplate.showInInvestProfile = false;
      setNewQuestionDetails(dataTemplate)
    }
    else {
      setNewQuestionDetails(addQuestionInitialData)
    }
  }

  const getquestionPatternTypeIDs = async () => {
    const data = await getKeyValuePairs("OnboardingQuestionPatterns");
    if (data.responseCode === 200) {
      setquestionPatternTypeIDDetails(data?.responseData)
    }
    else {
      setquestionPatternTypeIDDetails([])
    }
  }

  //#region useeffect
  useEffect(() => {
    getAllQuestionsDetails();
    getquestionPatternTypeIDs();
    getQuestionDefaultConfigurations();
  }, [])

  //#region return
  return (
    <Grid>
      <h4>{selectedFundTypeName}</h4>
      <div className='child-margin-15'>

        <div className='space-between'>
          <div></div>
          <div className='child-row-margin-5'>
            <div><ActionButton onClick={onOpenStepPopup} disabled={isSaveEnabled} className="btn-primary" variant="outlined" label="ADD STEP" icon={<AddToPhotosIcon />} /></div>
            <div className='margin-left-10'><ActionButton onClick={onOpenPopup} disabled={isSaveEnabled} className="btn-primary" variant="contained" label="ADD QUESTION" icon={<AddIcon />} /></div>
            {/* {
                  isSaveEnabled ? 
                  <>
                     <div><ActionButton variant="outlined" label="CANCEL" icon={<CloseIcon />} onClick={onSaveClick} /></div>
                     <div><ActionButton variant="contained" label="SAVE" icon={<SaveIcon />} onClick={onSaveClick} /></div>
                  </> 
                  : <div><ActionButton variant="outlined" label="EDIT" icon={<EditIcon />} onClick={onSaveClick} /></div> 
                } */}
          </div>
        </div>
        <div className='height-400-scroll'>
          <TreeGrid
            type={configurationTypesEnum.SUBSCRIPTION}
            initialColumns={columns}
            initialRows={allQuestionDetails}
            onOpenEditPopup={onOpenPopup}
            onOpenChildQuestionPopup={onOpenChildQuestionPopup}
            questionPatternTypeIDsDetails={questionPatternTypeIDsDetails}
            getAllQuestionsDetails={getAllQuestionsDetails}
            allFundDeatils={allFundDeatils} />
        </div>
      </div>
      {
        openQuestionPopup && currQuestionDetails &&
        <AddQuestionPopup
          type={configurationTypesEnum.SUBSCRIPTION}
          open={openQuestionPopup}
          currQuestionDetails={currQuestionDetails}
          onClose={onClose}
          isEdit={currQuestionDetails?.questionBankID > 0}
          stepsDetails={stepsDetails}
          readOnly={readOnly}
          isChildQuestion={false}
          allFundDeatils={allFundDeatils}
          selectedFundTypeName={selectedFundTypeName}
          investorTypeName={investorTypeName}
          questionPatternTypeIDsDetails={questionPatternTypeIDsDetails} allQuestionDetails={allQuestionDetails} />
      }
      {
        childQuestionPopup && currQuestionDetails &&
        <AddQuestionPopup
          type={configurationTypesEnum.SUBSCRIPTION}
          open={childQuestionPopup}
          parentQuestionDetails={parentQuestionDetais}
          currQuestionDetails={currQuestionDetails}
          onClose={onClose}
          isChildQuestion={true}
          readOnly={readOnly}
          isEdit={currQuestionDetails?.questionBankID > 0}
          stepsDetails={stepsDetails}
          allFundDeatils={allFundDeatils}
          selectedFundTypeName={selectedFundTypeName}
          investorTypeName={investorTypeName}
          questionPatternTypeIDsDetails={questionPatternTypeIDsDetails} allQuestionDetails={allQuestionDetails} />
      }
      {
        openStepPopup &&
        <AddStepPopup
          open={openStepPopup}
          onClose={onClose}
          allQuestionDetails={allQuestionDetails}
          investorTypeId={investorTypeId}
          selectedFundType={selectedFundType}
          questionTypeId={newQuestionDetails.questionTypeId} />
      }
      <ToastContainer />
    </Grid>
  )
}

export default SubscriptionQuestionsMain