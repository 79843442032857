import React from "react";
import MiniDrawer from "../../common/navigation-bar/NavBarGrid";
import secureLocalStorage from "react-secure-storage";
import { HeaderTemplates, PageHeader } from "../../common/PageHeader/PageHeader";
import BasicTab from "../../administration/TabComponent";
import { Box, height, margin } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { useEffect } from "react";
import FundDetails from "../../marketplace/components/FundDetails";
import TreeItem from '@mui/lab/TreeItem';
import Checkbox from '@mui/material/Checkbox';
import FolderIcon from '@mui/icons-material/Folder';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AppDashboards } from "../../../utils/enum";
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { toast } from "react-toastify";
import { CircularProgress, LinearProgress } from "@mui/material";
import { FinalUpdateSubscriptionStatus } from "../../InvestorFundSubscription/Services/Services";
import { ComponentsTab } from "../../common/Tabs/ComponentsTab";
import FundSubscriptionDetails from "./FundSubscriptionReview";
import { updateFundSubscriptionStatus } from "../Services/Services";
import { fundSubscriptionStatues, subscriptionBasicDetails, updateSubscriptionDetails } from "../Services/apiDataTempate";
import TabsComponment from "../../common/Tabs/TabsComponment";
import InvestorOverview from "../../investor-dashboard/components/subscriptions/InvestorOverview";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

//#this page is the container for the subscrition review for fund manager and investor 
const SubscriptionDetails = () => {

  //#region varibles
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [Tabs, setTabs] = useState([]);
  const [componentrefresh, setComponentRefresh] = useState(true)
  const location = useLocation();
  const navigate = useNavigate();
  const investorID = location?.state?.investorID;
  const FundID = secureLocalStorage.getItem("FundId");
  const SubscriptionID = location?.state?.subscriptionId;
  //const fromNotifications = location.state.fromNotifications;
  const selectedFundSubscriptionRow = location?.state?.selectedRow !== undefined && location?.state?.selectedRow !== null
    ? location?.state?.selectedRow
    : secureLocalStorage.getItem('InvestorAndFMNotificationsRow');
  const listview = location?.state?.listview
  const IconImage = location?.state?.IconImage
  const fundname = selectedFundSubscriptionRow?.FundName
  const previousURL = location?.state?.previousURL
  const [selectedTab, setSelectedTab] = useState(1);
  const [isComingFromDocSign, setIsComingFromDocSign] = useState(false);

  //#region tabs
  const defineTabs = (signedsubscriptionID, basicSubscriptionDetails) => {

    let crntSubscriptionID = 0;

    if (signedsubscriptionID) {
      crntSubscriptionID = parseInt(signedsubscriptionID);
    }
    else {
      if (selectedFundSubscriptionRow?.item !== undefined && selectedFundSubscriptionRow?.item !== null) {
        crntSubscriptionID = selectedFundSubscriptionRow?.item?.subscriptionID;
      }
      else {
        crntSubscriptionID = selectedFundSubscriptionRow?.subscriptionsID;
      }
    }


    if (secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD) {
      setTabs([
        {
          key: 0,
          label: 'Overview',
          icon: <InfoOutlinedIcon />,
          isBackgroundColor: false,
          component: <InvestorOverview
            //fromNotifications ={fromNotifications}
            fundId={secureLocalStorage.getItem("FundId")}
            investorId={basicSubscriptionDetails?.investorID} />
        },
        {
          key: 1,
          label: 'SUBSCRIPTION DETAILS',
          icon: <FactCheckOutlinedIcon />,
          component: <FundSubscriptionDetails
            //fromNotifications ={fromNotifications}
            subscriptionID={basicSubscriptionDetails?.subscriptionID}
            subscriptionDetails={basicSubscriptionDetails}
          />
        },
      ])
    }
    else {
      setTabs([
        {
          key: 0,
          label: 'Overview',
          icon: <InfoOutlinedIcon />,
          isBackgroundColor: false,
          component: <InvestorOverview
            fundId={secureLocalStorage.getItem("FundId")}
            investorId={((secureLocalStorage.getItem("investorId")) ? (secureLocalStorage.getItem("investorId")) : basicSubscriptionDetails?.investorID)} />
        },
        {
          key: 1,
          label: 'SUBSCRIPTION DETAILS',
          icon: <FactCheckOutlinedIcon />,
          component: <FundSubscriptionDetails
            //fromNotifications = {fromNotifications}
            subscriptionID={basicSubscriptionDetails?.subscriptionID}
            subscriptionDetails={basicSubscriptionDetails} />
        }
      ])
    }
  }

  //#region click events
  const onBackClick = () => {
    if (isComingFromDocSign) {
      navigate(-3);
    } else {
      navigate(-1);
    }
  }

  //#region api post calls
  const subscriptionApprovedByFM = async (isApproved, signedsubscriptionID, signedenvelopeId, signedisFundManager) => {
    setComponentRefresh(false)

    const basicSubscriptionDetails = subscriptionBasicDetails;
    subscriptionBasicDetails.fundID = 0
    subscriptionBasicDetails.subscriptionID = signedsubscriptionID
    subscriptionBasicDetails.investorID = 0

    const requestedData = updateSubscriptionDetails;

    requestedData.FundSubscriptionID = signedsubscriptionID;
    requestedData.FundSubscriptionStatus = fundSubscriptionStatues.APPROVED
    requestedData.IsFundManager = signedisFundManager?.toLowerCase() === "true" ? true : false
    requestedData.EnvelopID = signedenvelopeId

    const data = await updateFundSubscriptionStatus(requestedData)
    if (data.responseCode === 200) {
      /*  defineTabs(signedsubscriptionID,basicSubscriptionDetails); */
    } else {
      toast.warning("Something Went Wrong , Please Try Again",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
    }
    //#TODO: Need to call it after sucessful respons only
    defineTabs(signedsubscriptionID, basicSubscriptionDetails);
  };

  const investorSign = async (signedsubscriptionID, signedFundID, signedenvelopeId) => {
    setComponentRefresh(false)

    const requestedData = updateSubscriptionDetails;

    requestedData.FundSubscriptionID = signedsubscriptionID;
    requestedData.FundSubscriptionStatus = fundSubscriptionStatues.APPROVED
    requestedData.IsFundManager = false
    requestedData.EnvelopID = signedenvelopeId

    const data = await updateFundSubscriptionStatus(requestedData)

    const basicSubscriptionDetails = subscriptionBasicDetails;
    subscriptionBasicDetails.fundID = signedFundID
    subscriptionBasicDetails.subscriptionID = signedsubscriptionID
    subscriptionBasicDetails.investorID = secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?
      secureLocalStorage.getItem("userId") : 0

    if (data.responseCode === 200) {
      /*  defineTabs(signedsubscriptionID,basicSubscriptionDetails); */
    } else {
      toast.warning("Something Went Wrong , Please Try Again",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
    }

    //#TODO: Need to call it after sucessful respons only
    defineTabs(signedsubscriptionID, basicSubscriptionDetails);
  };

  //#region useeffect
  useEffect(() => {

    const queryParams = new URLSearchParams(window.location.search);
    const signedFundID = queryParams.get('fundID');

    const signedsubscriptionID = parseInt(queryParams.get('subscriptionID'));
    const signedenvelopeId = queryParams.get('envelopeId');
    const signedisFundManager = queryParams.get('isFundManager');
    const docuSignResponse = queryParams.get('event');

    if (signedFundID 
          && signedsubscriptionID 
          && signedenvelopeId 
          && docuSignResponse 
          && signedisFundManager 
          && docuSignResponse.toLowerCase() == "signing_complete") {
      setIsComingFromDocSign(true);
      if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD) {
        subscriptionApprovedByFM(true, signedsubscriptionID, signedenvelopeId, signedisFundManager);
      }
      else if (secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD) {
        investorSign(signedsubscriptionID, signedFundID, signedenvelopeId);
      }
    }
    else {
      const basicSubscriptionDetails = subscriptionBasicDetails;
      subscriptionBasicDetails.fundID = 0
      if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD) {
        subscriptionBasicDetails.fundID = secureLocalStorage.getItem("FundId")
        subscriptionBasicDetails.subscriptionID = selectedFundSubscriptionRow?.subscriptionsID || 0
        subscriptionBasicDetails.investorID = selectedFundSubscriptionRow?.investorID
        subscriptionBasicDetails.isInvestorVerified = selectedFundSubscriptionRow?.isInvestorVerified
      }
      else if (secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD) {
        subscriptionBasicDetails.fundID = secureLocalStorage.getItem("FundId")
        subscriptionBasicDetails.subscriptionID = SubscriptionID || 0
        subscriptionBasicDetails.investorID = investorID || secureLocalStorage.getItem("userId")
      }
      defineTabs(signedsubscriptionID, basicSubscriptionDetails);
    }

  }, []);

  //#region return
  return (
    <>
      <PageHeader
        title="Subscription Details"
        template={HeaderTemplates.NORMAL_VIEW}
        isNavigate={onBackClick} />
      <div className="wrapper margin-top-30">
        <Box sx={{ flexGrow: 1 }} overflowx={'auto'} >
          <Grid container spacing={1}>
            {Tabs && Tabs?.length > 0 ? (
              <Grid container xs={12} justifyContent="space-between" >
                <Grid item xs={12}>
                  <TabsComponment tabs={Tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                </Grid>
              </Grid>
            ) : <>
              <LinearProgress />
              <b>Loading please wait and do not refresh or click back button.....</b>
            </>
            }
          </Grid>
        </Box>
      </div>
    </>
  )
};
export default SubscriptionDetails;