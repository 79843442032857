export const fundToolTipMessages = {
    fundType : "Select the category that best defines the fund's focus area",
    fundName : "Enter a unique name for the fund. This will be displayed across dashboards and reports",
    fundLogo: "Upload the fund’s logo. Ensure it matches the required dimensions for optimal display across dashboards and marketplaces",
    fundBanner: "Add a banner image for the fund. This will appear as the background in various fund views. Use the predefined dimensions for the best results.",
    fundDescription: "Provide a concise summary of the fund's purpose and objectives. This description will help investors understand your fund better",
    investmentStrategy: "Choose a specific strategy",
    targetIndustry: "Select the industries or sectors the fund focuses on. Add custom sectors if needed",
    geographicsFocus: "Specify the geographic areas the fund targets for investment. Add custom regions if required",
    targetRaise: "Enter the total amount the fund aims to raise from investors",
    minimumInvestmentAmount: "Define the minimum commitment required from investors to participate in this fund",
    fundStatus: "Set the current status of the fund. This status affects how the fund appears to investors",
    vintageYear: "Specify the year the fund was initiated",
    term: "Enter the fund's lifespan in years. This value helps track maturity and informs investors looking for specific timelines.",
    liquidity: "Define the fund’s liquidity terms, indicating how easily investments can be converted to cash",
    fundVisibility: "Control whether this fund is visible to investors in the marketplace. 'Show' makes it accessible; 'Hide' keeps it private.",
    legalStructure: "Select the appropriate legal structure for the fund, such as LLC, LP, or other applicable forms",
    fundManager: "Assign team members who will manage the fund by selecting their names from the dropdown. Add profiles as necessary",
    fundManagerFirm: "Toggle this option to designate the firm itself as the fund manager. Common for firms managing multiple funds under a unified brand.",
    managementTeamMember: "Select one or more employees to act as fund managers for this fund. This option is unavailable if the firm is set as the fund manager.",
    investmentStrategy: "Select the appropriate investment strategy for the fund, such as growth, value, or hybrid",
    feesandExpenses: "Define the management and performance fees, along with any additional expenses, in percentage terms",
}