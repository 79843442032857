import secureLocalStorage from "react-secure-storage";
import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";


export async function getInvestorProfileDetails(emailId) {
    return await axiosApiCall.get(`/v1/User/GetUserDetails?emailAddress=${emailId}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function updateUserProfile(data,userId) {
    return await axiosApiCall.put(`/v1/User/UpdateProfileDetails?userId=${userId}`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getUserBankDetailsByUserId(userId) {
    return await axiosApiCall.get(`/v1/UserBankDetails/Get?userId=${userId}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function addUserBankDetails(data) {
    return await axiosApiCall.post(`/v1/UserBankDetails/Post`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}   

export async function updateUserBankDetails(data) {
    return await axiosApiCall.put(`/v1/UserBankDetails/Put`, data)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function deleteUserBankDetails(bankDetailId,userId){
    return await axiosApiCall.delete(`/v1/UserBankDetails/Delete?userBankDetailsID=${bankDetailId}&userID=${userId}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}