import React, { useEffect, useState } from 'react'
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import { Divider, FormControlLabel, Grid, LinearProgress, Radio } from '@mui/material';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { FieldVariants, textFormatTypeEnum } from '../../../common/TextInput/appInputenum';
import { bankingInformationTemplate, InvestorTypesEnum } from '../../services/apiDataTemplate';
import { toast, ToastContainer } from 'react-toastify';
import { addUserBankDetails, deleteUserBankDetails, getUserBankDetailsByUserId, updateUserBankDetails } from '../../services/services';
import secureLocalStorage from 'react-secure-storage';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ActionButton from '../../../common/ActionButton/ActionButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectField from '../../../common/input-fields/SelectField';
import { getStatesandCitieslist } from '../../../InvestorFundSubscription/Services/Statecountry';

const BankingInformation = (props) => {

  //#region props
  const { investorDetails, getInvestorInfo, investorTypesValues } = props;

  //#region initialdata
  const initialData = {
    bankDetails: [],
    primaryAccountIndex: -1
  }

  //#region variables
  const [isSaveEnabled, setIsSaveEnabled] = useState({});
  const [loading, setLoading] = useState(false);
  const userId = secureLocalStorage.getItem('userId');
  const [stateCountriesOptions,setStateCountryOptions] = useState([]);

  //#region change events
  const handleFieldsChange = (field, value) => {
    const fieldSplit = field.split(".");//for validation bankingInformationDetails
    const currIndex = fieldSplit[1].split("-")[0];
    const fieldName = fieldSplit[1].split("-")[1];
    const currState = bankingInformationDetails?.values?.bankDetails;
    const item = { ...currState[currIndex] };
    item[fieldName] = value;
    currState[currIndex] = item;
    handleChange("bankDetails", currState);
  }

  const handleChange = (field, value) => {
    bankingInformationDetails.setFieldValue(field, value);
  }

  //#region click events
  const addNewBankAccount = () => {
    const newBankAccount = [...bankingInformationDetails.values.bankDetails, bankingInformationTemplate];
    handleChange("bankDetails", newBankAccount);
  }

  const onEditClick = (currIndex) => {
    const activeIndex = Object.keys(isSaveEnabled).find(key => isSaveEnabled[key]);
    if (activeIndex !== undefined) {
      toast.error("Please save or cancel the current active item before editing another.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    } else {
      setIsSaveEnabled({ ...isSaveEnabled, [currIndex]: true });
    }
  }

  const onSaveClick = async (currIndex) => {
    const errors = await bankingInformationDetails.validateForm();
    if (!errors.bankDetails?.[currIndex]) {
      if (bankingInformationDetails.values.bankDetails[currIndex].userBankDetailsID === 0) {
        addNewBankAcccount(currIndex);
      } else {
        updateBankAccount(currIndex);
        /*  toast.warning("Update functionality is not implemented yet", {
           position: toast.POSITION.BOTTOM_RIGHT,
           theme: "colored",
         }); */
      }
    } else {
      console.log("Validation errors for the current item:", errors.bankDetails[currIndex]);
      bankingInformationDetails.handleSubmit();
    }
  };


  const onCancelClick = (currIndex) => {
    /* setIsSaveEnabled({ ...isSaveEnabled, [currIndex]: false }); */
    setIsSaveEnabled({});
    bankingInformationDetails.resetForm();
    getBankingInformation();
  }

  //#region api get calls
  const getBankingInformation = async () => {
    const data = await getUserBankDetailsByUserId(userId);
    if (data.responseCode === 200) {
      if (data.responseData.length === 0) {
        const initialDataTemplate = bankingInformationTemplate;
            initialDataTemplate.userId = userId;
        bankingInformationDetails.setFieldValue("bankDetails", [initialDataTemplate]);
        bankingInformationDetails.setFieldValue("primaryAccountIndex", 0);
      }
      else {
        bankingInformationDetails.setFieldValue("bankDetails", data.responseData);
        const primaryAccountIndex = data.responseData.findIndex((item) => item.isPrimary === true);
        bankingInformationDetails.setFieldValue("primaryAccountIndex", primaryAccountIndex);
      }
    }
    else {
      if (data.responseData.length === 0) {
        const initialDataTemplate = bankingInformationTemplate;
            initialDataTemplate.userId = userId;
        bankingInformationDetails.setFieldValue("bankDetails", [initialDataTemplate]);
        bankingInformationDetails.setFieldValue("primaryAccountIndex", 0);
      }else{
        toast.error("unable to get the bank details", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        }); 
      }
    }
  }

  const getStateCountriesOptions = async() => {
    const data = await getStatesandCitieslist();
    if(data.responseCode === 200){
      setStateCountryOptions(data.responseData);
    }
    else{
      setStateCountryOptions([]);
    }
  }

  //#region api post calls
  const addNewBankAcccount = async (currIndex) => {
    const isPrimaryBankExists = bankingInformationDetails.values.primaryAccountIndex !== -1 ? true : false;
    if (!isPrimaryBankExists) {
      toast.warning("Please select a primary bank account", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      return;
    }
    setLoading(true);
    const requestedData = bankingInformationDetails.values.bankDetails[currIndex];
     requestedData.isPrimary = currIndex === bankingInformationDetails.values.primaryAccountIndex ? true : false;
    const data = await addUserBankDetails(requestedData);
    if (data.responseCode === 200) {
      setLoading(false);
      toast.success("Bank Account added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      onCancelClick();
    }
    else {
      setLoading(false);
      onCancelClick();
      toast.error("unable to create the bank details", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  }

  //#region api update calls
  const updateBankAccount = async (currIndex) => {
    const isPrimaryBankExists = bankingInformationDetails.values.primaryAccountIndex !== -1 ? true : false;
    if (!isPrimaryBankExists) {
      toast.warning("Please select a primary bank account", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      return;
    }
    setLoading(true);
    const requestedData = bankingInformationDetails.values.bankDetails[currIndex];
     requestedData.isPrimary = currIndex === bankingInformationDetails.values.primaryAccountIndex ? true : false;
    const data = await updateUserBankDetails(requestedData);
    if (data.responseCode === 200) {
      setLoading(false);
      toast.success("Bank Account updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      onCancelClick();
    }
    else {
      setLoading(false);
      onCancelClick();
      toast.error("unable to update the bank details", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  }

  //#region api delete calls
  const deleteBankAccount = async (currIndex) => {
    setLoading(true);
    const data = await deleteUserBankDetails(bankingInformationDetails.values.bankDetails[currIndex].userBankDetailsID,userId);
    if (data.responseCode === 200) {
      setLoading(false);
      toast.success("Bank Account Deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      onCancelClick();
    }
    else {
      setLoading(false);
      onCancelClick();
      toast.error("unable to delete the bank details", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    bankDetails: yup.array().of(
      yup.object().shape({
        bankName: yup.string().required(validationsConstants.REQUIRED),
        addressLine1: yup.string().required(validationsConstants.REQUIRED),
        addressLine2: yup.string().required(validationsConstants.REQUIRED),
        accountNo: yup.string().required(validationsConstants.REQUIRED),
        countryID: yup.string().required(validationsConstants.REQUIRED),
        stateMasterId: yup.string().required(validationsConstants.REQUIRED),
        city: yup.string().required(validationsConstants.REQUIRED),
        branchLocation: yup.string().required(validationsConstants.REQUIRED),
        zipCode: yup.string().required(validationsConstants.REQUIRED).matches(/^\d{5}(?:-\d{4})?$/, validationsConstants.ZIP_CODE)
      })
    ),
  });

  const bankingInformationDetails = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert("ok");
    },
  });

  //#region useeffect
  useEffect(() => {
    getBankingInformation();
    getStateCountriesOptions();
  }, [])

  //#region return
  return (
    <form className='white-card child-margin-15' novalidate="novalidate">
      <Grid container spacing={2} alignItems="center">
        <Grid item lg='4' md='4' sm='12' xs='12'>
          <FileUploadBtn
            name="selectedImage"
            label="UPLOAD PROFILE ICON"
            defaultFile={investorDetails?.personalInformation?.profileIcon ? investorDetails?.personalInformation?.profileIcon : '/icons/defaultpropic.jpg'}
            fileType={uploadFileTypes.IMAGE}
            readOnly={true}
            fromWhiteLabeling={false}
            isSize={true}
            onChange={() => { }} />
        </Grid>
        {
          investorTypesValues?.
            find((item) => item?.listItemID === investorDetails?.personalInformation?.investorTypeID)?.listItemValue
            === InvestorTypesEnum.INDIVIDUAL_INVESTOR
            ? <>
              <Grid item lg='4' md='4' sm='6' xs='12'>
                <ALTTextField
                  textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                  name="firstName"
                  label="First Name"
                  value={investorDetails?.personalInformation?.firstName}
                  onChange={(name, value) => handleChange(name, value)}
                  disabled={true} />
              </Grid>
              <Grid item lg='4' md='4' sm='6' xs='12'>
                <ALTTextField
                  textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                  name="lastName"
                  label="Last Name"
                  value={investorDetails?.personalInformation?.lastName}
                  onChange={(name, value) => handleChange(name, value)}
                  required={true}
                  disabled={true} />
              </Grid>
            </>
            : <>
              <Grid item lg='4' md='4' sm='6' xs='12'>
                <ALTTextField
                  name="firstName"
                  label="Legal Entity Name"
                  value={investorDetails?.personalInformation?.firstName}
                  onChange={() => { }}
                  disabled={true} />
              </Grid>
            </>
        }
      </Grid>
      <div>
        {
          bankingInformationDetails?.values?.bankDetails?.length > 0
            ? bankingInformationDetails?.values?.bankDetails
              ?.map((item, index) => {
                return (
                  <div className='child-margin-15'>
                    <div className='space-between'>
                      <div>
                        <FormControlLabel 
                            control={
                              <Radio 
                                name="primaryAccountIndex"
                                checked={index === bankingInformationDetails?.values?.primaryAccountIndex}
                                defaultChecked={index === bankingInformationDetails?.values?.primaryAccountIndex}
                                onChange={() => { 
                                  bankingInformationDetails.setFieldValue('primaryAccountIndex', index); 
                                }} 
                              />
                            }
                            label={<div style={{ marginTop: '2px' }}>PRIMARY BANK ACCOUNT</div>}
                            disabled={!isSaveEnabled[index]} />
                      </div>
                      <div className='edit-save-sec child-row-margin-5 margin-right-10'>
                        {isSaveEnabled[index] ?
                          <>
                            <div>
                              <ActionButton
                                label="Cancel"
                                variant={FieldVariants.OUTLINED}
                                disabled={loading}
                                icon={<CancelIcon />}
                                onClick={() => onCancelClick(index)} />
                            </div>
                            <div>
                              <ActionButton
                                label="SAVE"
                                icon={<SaveIcon />}
                                loading={loading}
                                onClick={() => onSaveClick(index)} />
                            </div>
                          </>
                          :
                          <>
                            <div>
                              <ActionButton
                                label="DELETE"
                                icon={<DeleteIcon />}
                                variant={FieldVariants.OUTLINED}
                                loading={loading}
                                disabled={index === bankingInformationDetails?.values?.primaryAccountIndex}
                                toolTip={index === bankingInformationDetails?.values?.primaryAccountIndex 
                                          ? "Primary account cannot be deleted" 
                                          : ""
                                        }
                                onClick={() => deleteBankAccount(index)} />
                            </div>
                            <div>
                              <ActionButton
                                label="EDIT"
                                icon={<EditIcon />}
                                disabled={loading}
                                onClick={() => onEditClick(index)} />
                            </div>
                          </>
                        }
                      </div>
                    </div>
                    <div className='display-row-items-flex'>
                      <div className='width-33'>
                        <ALTTextField
                          name={`bankDetails.${index}-bankName`}
                          label="Bank Name"
                          value={item.bankName}
                          onChange={(name, value) => handleFieldsChange(name, value)}
                          required={true}
                          readOnly={!isSaveEnabled[index]}
                          onBlur={(e) => {
                            bankingInformationDetails.handleBlur(e);
                            bankingInformationDetails.setFieldTouched(`bankDetails.${index}-bankName`, true, false);
                          }}
                          error={bankingInformationDetails.touched?.bankDetails?.[index]?.bankName
                            && isSaveEnabled[index]
                            && Boolean(bankingInformationDetails.errors?.bankDetails?.[index]?.bankName)}
                          errorMessage={bankingInformationDetails.touched?.bankDetails?.[index]?.bankName && bankingInformationDetails.errors?.bankDetails?.[index]?.bankName} />
                      </div>
                      <div className='width-33'>
                        <ALTTextField
                          name={`bankDetails.${index}-addressLine1`}
                          label="Address Line 1"
                          value={item.addressLine1}
                          onChange={(name, value) => handleFieldsChange(name, value)}
                          required={true}
                          readOnly={!isSaveEnabled[index]}
                          onBlur={(e) => {
                            bankingInformationDetails.handleBlur(e);
                            bankingInformationDetails.setFieldTouched(`bankDetails.${index}-addressLine1`, true, false);
                          }}
                          error={bankingInformationDetails.touched?.bankDetails?.[index]?.addressLine1
                            && isSaveEnabled[index]
                            && Boolean(bankingInformationDetails.errors?.bankDetails?.[index]?.addressLine1)}
                          errorMessage={bankingInformationDetails.touched?.bankDetails?.[index]?.addressLine1 && bankingInformationDetails.errors?.bankDetails?.[index]?.addressLine1} />
                      </div>
                      <div className='width-33'>
                        <ALTTextField
                          name={`bankDetails.${index}-addressLine2`}
                          label="Address Line 2"
                          value={item.addressLine2}
                          onChange={(name, value) => handleFieldsChange(name, value)}
                          required={true}
                          readOnly={!isSaveEnabled[index]}
                          onBlur={(e) => {
                            bankingInformationDetails.handleBlur(e);
                            bankingInformationDetails.setFieldTouched(`bankDetails.${index}-addressLine2`, true, false);
                          }}
                          error={bankingInformationDetails.touched?.bankDetails?.[index]?.addressLine2
                            && isSaveEnabled[index]
                            && Boolean(bankingInformationDetails.errors?.bankDetails?.[index]?.addressLine2)}
                          errorMessage={bankingInformationDetails.touched?.bankDetails?.[index]?.addressLine2 && bankingInformationDetails.errors?.bankDetails?.[index]?.addressLine2} />
                      </div>
                      <div className='width-33'>
                        <ALTTextField
                          textFormatType={textFormatTypeEnum.POSITIVE_NUMBERS}
                          name={`bankDetails.${index}-accountNo`}
                          label="Account Number"
                          value={item.accountNo}
                          onChange={(name, value) => handleFieldsChange(name, value)}
                          required={true}
                          readOnly={!isSaveEnabled[index]}
                          onBlur={(e) => {
                            bankingInformationDetails.handleBlur(e);
                            bankingInformationDetails.setFieldTouched(`bankDetails.${index}-accountNo`, true, false);
                          }}
                          error={bankingInformationDetails.touched?.bankDetails?.[index]?.accountNo
                            && isSaveEnabled[index]
                            && Boolean(bankingInformationDetails.errors?.bankDetails?.[index]?.accountNo)}
                          errorMessage={bankingInformationDetails.touched?.bankDetails?.[index]?.accountNo && bankingInformationDetails.errors?.bankDetails?.[index]?.accountNo} />
                      </div>
                      <div className='width-33'>
                        <SelectField
                          name={`bankDetails.${index}-countryID`}
                          label="Country ID"
                          value={item.countryID}
                          onChange={(name, value) => handleFieldsChange(name, value)}
                          required={true}
                          readOnly={!isSaveEnabled[index]}
                          options={stateCountriesOptions?.countries?.map(option => ({ label: option.countryName, value: option.countryMasterID }))}
                          onBlur={(e) => {
                            bankingInformationDetails.handleBlur(e);
                            bankingInformationDetails.setFieldTouched(`bankDetails.${index}-countryID`, true, false);
                          }}
                          error={bankingInformationDetails.touched?.bankDetails?.[index]?.countryID
                            && isSaveEnabled[index]
                            && Boolean(bankingInformationDetails.errors?.bankDetails?.[index]?.countryID)}
                          errorMessage={bankingInformationDetails.touched?.bankDetails?.[index]?.countryID && bankingInformationDetails.errors?.bankDetails?.[index]?.countryID} />
                      </div>
                      <div className='width-33'>
                        <SelectField
                          name={`bankDetails.${index}-stateMasterId`}
                          label="State Master ID"
                          value={item.stateMasterId}
                          onChange={(name, value) => handleFieldsChange(name, value)}
                          required={true}
                          readOnly={!isSaveEnabled[index]}
                          options={stateCountriesOptions?.states?.map(option => ({ label: option.stateName, value: option.stateMasterId }))}
                          onBlur={(e) => {
                            bankingInformationDetails.handleBlur(e);
                            bankingInformationDetails.setFieldTouched(`bankDetails.${index}-stateMasterId`, true, false);
                          }}
                          error={bankingInformationDetails.touched?.bankDetails?.[index]?.stateMasterId
                            && isSaveEnabled[index]
                            && Boolean(bankingInformationDetails.errors?.bankDetails?.[index]?.stateMasterId)}
                          errorMessage={bankingInformationDetails.touched?.bankDetails?.[index]?.stateMasterId && bankingInformationDetails.errors?.bankDetails?.[index]?.stateMasterId} />
                      </div>
                      <div className='width-33'>
                        <ALTTextField
                          name={`bankDetails.${index}-city`}
                          label="City"
                          value={item.city}
                          onChange={(name, value) => handleFieldsChange(name, value)}
                          required={true}
                          readOnly={!isSaveEnabled[index]}
                          onBlur={(e) => {
                            bankingInformationDetails.handleBlur(e);
                            bankingInformationDetails.setFieldTouched(`bankDetails.${index}-city`, true, false);
                          }}
                          error={bankingInformationDetails.touched?.bankDetails?.[index]?.city
                            && isSaveEnabled[index]
                            && Boolean(bankingInformationDetails.errors?.bankDetails?.[index]?.city)}
                          errorMessage={bankingInformationDetails.touched?.bankDetails?.[index]?.city && bankingInformationDetails.errors?.bankDetails?.[index]?.city} />
                      </div>
                      <div className='width-33'>
                        <ALTTextField
                          name={`bankDetails.${index}-branchLocation`}
                          label="Branch Location"
                          value={item.branchLocation}
                          onChange={(name, value) => handleFieldsChange(name, value)}
                          required={true}
                          readOnly={!isSaveEnabled[index]}
                          onBlur={(e) => {
                            bankingInformationDetails.handleBlur(e);
                            bankingInformationDetails.setFieldTouched(`bankDetails.${index}-branchLocation`, true, false);
                          }}
                          error={bankingInformationDetails.touched?.bankDetails?.[index]?.branchLocation
                            && isSaveEnabled[index]
                            && Boolean(bankingInformationDetails.errors?.bankDetails?.[index]?.branchLocation)}
                          errorMessage={bankingInformationDetails.touched?.bankDetails?.[index]?.branchLocation && bankingInformationDetails.errors?.bankDetails?.[index]?.branchLocation} />
                      </div>
                      <div className='width-33'>
                        <ALTTextField
                          name={`bankDetails.${index}-zipCode`}
                          textFormatType={textFormatTypeEnum.ZIPCODE}
                          label="Zip Code"
                          value={item.zipCode}
                          onChange={(name, value) => handleFieldsChange(name, value)}
                          required={true}
                          readOnly={!isSaveEnabled[index]}
                          onBlur={(e) => {
                            bankingInformationDetails.handleBlur(e);
                            bankingInformationDetails.setFieldTouched(`bankDetails.${index}-zipCode`, true, false);
                          }}
                          error={bankingInformationDetails.touched?.bankDetails?.[index]?.zipCode
                            && isSaveEnabled[index]
                            && Boolean(bankingInformationDetails.errors?.bankDetails?.[index]?.zipCode)}
                          errorMessage={bankingInformationDetails.touched?.bankDetails?.[index]?.zipCode && bankingInformationDetails.errors?.bankDetails?.[index]?.zipCode} />
                      </div>
                    </div>
                    {
                      index === bankingInformationDetails?.values?.bankDetails?.length - 1
                        ? <div className='add-sec'>
                          <ActionButton
                            icon={<AddIcon />}
                            label="ADD BANK ACCOUNT"
                            onClick={addNewBankAccount} />
                        </div>
                        : null
                    }
                    <div className='divider-sec margin-top-10'>
                      <Divider />
                    </div>
                  </div>
                )
              })
            : <div>
              <LinearProgress />
            </div>
        }
      </div>
      <ToastContainer />
    </form>
  )
}

export default BankingInformation