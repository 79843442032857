import React, { useState } from 'react'
import SelectField from '../../../common/input-fields/SelectField';
import { FieldVariants } from '../../../common/TextInput/appInputenum';
import { portfolioPerformanceCharts, portfolioPerformanceChartTypes } from '../../APIDataTemplate';
import RevenueEbitdaChart from '../performance-charts/RevenueEbitdaChart';
import ThreeLineChart from '../performance-charts/ThreeLineChart';
import SingleLineChart from '../performance-charts/SingleLineChart';
import ValuationGraph from '../performance-charts/ValuationGraph';

const PortfolioPerformanceMain = () => {

  //#region variables
  const [selectedChart,setsSelectedChart] = useState(portfolioPerformanceChartTypes.REVENUE_EBITDA);
  const freeCashFlow = [
    { name: "Q1 '22", FreeCashFlow: 10 },
    { name: "Q2 '22", FreeCashFlow: 12 },
    { name: "Q3 '22", FreeCashFlow: 15 },
    { name: "Q4 '22", FreeCashFlow: 18 },
  ];

  const irrData = [
    { name: "Q1 '22", IRR: 12 },
    { name: "Q2 '22", IRR: 13 },
    { name: "Q3 '22", IRR: 14 },
    { name: "Q4 '22", IRR: 15 },
  ];
  
  const moicData = [
    { name: "Q1 '22", MOIC: 1.2 },
    { name: "Q2 '22", MOIC: 1.3 },
    { name: "Q3 '22", MOIC: 1.35 },
    { name: "Q4 '22", MOIC: 1.4 },
  ];
  

  //#region change events
  const handleChartTypeChange = (name, value) => {
    setsSelectedChart(value);
  }

  //#region render functions
  const renderChart = () => {
    switch (selectedChart) {
        case portfolioPerformanceChartTypes.REVENUE_EBITDA:
            return <RevenueEbitdaChart />
        case portfolioPerformanceChartTypes.MARGINS:
            return <ThreeLineChart />
        case portfolioPerformanceChartTypes.FREE_CASH_FLOW:
          return (
            <SingleLineChart
              data={freeCashFlow}
              metricKey="FreeCashFlow"
              metricLabel="Free Cash Flow ($M)"
              yAxisLabel="Cash Flow ($M)"
              yAxisTicks={[10, 12, 14, 16, 18]} 
              yAxisDomain={[10, 18]} 
            />
          );
        case portfolioPerformanceChartTypes.VALUATION_GRAPH:
          return <ValuationGraph />;
        case portfolioPerformanceChartTypes.IRR:
          return (
            <SingleLineChart
              data={irrData}
              metricKey="IRR"
              metricLabel="IRR (%)"
              yAxisLabel="Rate (%)"
              yAxisTicks={[12.0,12.5,13.0,13.5,14.0,14.5,15.0]} 
              yAxisDomain={[12.0, 15.0]} 
            />
          );
        case portfolioPerformanceChartTypes.MOIC:
          return (
            <SingleLineChart
              data={moicData}
              metricKey="MOIC"
              metricLabel="MOIC (X)"
              yAxisLabel="Multiplier (X)"
              yAxisTicks={[1.200,1.225,1.250,1.275,1.300,1.325,1.350,1.375,1.400]} 
              yAxisDomain={[1.200, 1.400]} 
            />
          );
        default:
          return <div>No data available</div>;
    } 
       
  }

  //#region return
  return (
    <div className='child-margin-15'>
      <h6>Performance</h6>
      <div className='child-margin-10 white-card'>
            <div className='space-between'>
                <b>{selectedChart !== portfolioPerformanceChartTypes.VALUATION_GRAPH ? selectedChart : ""}</b>
                <div className='min-width-150'>
                    <SelectField 
                        label="Chart Type"
                        name="chartType"
                        variant={FieldVariants.OUTLINED}
                        value={selectedChart}
                        options={portfolioPerformanceCharts}
                        onChange={(name,value)=> { handleChartTypeChange(name,value) }} />
                </div>
            </div>
            <div>
                {renderChart()}
            </div>
       </div>
    </div>
  )
}

export default PortfolioPerformanceMain