import React, { useEffect, useState } from 'react'
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import ActionButton from '../../common/ActionButton/ActionButton';
import SelectField from '../../common/input-fields/SelectField';
import { FieldVariants } from '../../common/TextInput/appInputenum';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { getAllFunds } from '../Services/FundNewsService';
import { allColumns, privateCreditColumns, privateEquityColumns, ventureCapitalColumn } from '../fundTypeValues';
import AddIcon from '@mui/icons-material/Add';
import { fundStatusEnum, fundStatusesEnum, fundTypesEnum } from '../../marketplace-dashboard/jsonData';
import { ToastContainer, toast } from 'react-toastify';
import CreateFundModel from '../../create-edit-fund/components/create-fund/CreateFundModel';
import AltDataGridPro from '../../common/DataGridPro/AltDataGridPro';
import { numericOperators } from '../myfundFilterOperators';
import CurrencyIndicator from '../../common/DataGrid/CurrencyIndicator';

const MyFundsGrid = (props) => {

  //#region props
  const { fundTypes } = props;

  //#region initialData
  const initialFilters = {
    "fundType": fundTypesEnum.ALL,
  }

  //#region variables
  const queryParams = new URLSearchParams(window.location.search);
  const showNewPopup = queryParams.get('newpopup');
  const navigate = useNavigate();
  const [allFundsData, setAllFundsData] = useState(null);
  const [refreshFundGrid, setRefreshFundGrid] = useState(true);
  const [fundsGridmessage, setFundsGridsMessage] = useState(/* "Loading..." */"No Data Available");
  const [showCreateFund, setShowCreateFund] = useState(false);
  const [filteredFunds, setFilteredFunds] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [fundColumns, setFundColumns] = useState(allColumns);

  //#region functions
  const currFundType = () => {
    return fundTypes?.find((item) => item.value === filters.fundType)?.label;
  }

  //#region chnage events
  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  //#region click events
  const onAddFundClick = () => {
    setShowCreateFund(true);
  }

  const onCloseCreateFund = () => {
    setShowCreateFund(false);
  }

  const fundClick = (params) => {
    params = { ...params, row: { ...params.row, VisibilityIcon: undefined } }
    const havepermission = checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.READ);

    const selectedFund = {
      "FundMaturity": params.row.isFundMatured,
      "FundTermination": params.row.fundStatusName === 'Terminated',
      "BGImage": params.row.bgImage,
      "IconImage": params.row.iconImage,
    }

    if (havepermission) {
      secureLocalStorage.setItem("FundId", params?.id);
      secureLocalStorage.setItem("FundName", params.row.fundName);
      secureLocalStorage.setItem('FundTypeName', params.row.fundTypeName)
      secureLocalStorage.setItem("FundMaturity", params.row.isFundMatured);
      secureLocalStorage.setItem("FundTermination", params?.row.fundTerminated);
      secureLocalStorage.setItem("selectedFund", selectedFund);
      secureLocalStorage.setItem("navigatingFromMarketPlace", false);
      navigate("/marketplace/OverviewPage");
    }
  };

  //#region api get calls
  const fetchFundDetails = async () => {
    const response = await getAllFunds();
    if (response?.responseCode == 200) {
      if (response?.responseData.length > 0) {
        setAllFundsData(response?.responseData)
        setRefreshFundGrid(false);
      }
      else {
        setFundsGridsMessage("No Data Available");
      }
    }
    else {
      setFundsGridsMessage("No Data Available");
    }
  }

  //#region useeffect
  useEffect(() => {
    if (refreshFundGrid) {
      fetchFundDetails();
    }
  }, [refreshFundGrid]);

  useEffect(() => {
    const selectedFundType = currFundType();
    const sortedFunds = allFundsData?.sort((a, b) => {
      let dateA = new Date(a.updatedDate);
      let dateB = new Date(b.updatedDate);

      return dateB - dateA;
    });
    if (selectedFundType === "All") {
      setFundColumns(allColumns);
      setFilteredFunds(sortedFunds);
    }
    else if (selectedFundType) {
      const selectedFunds = sortedFunds.filter((fund) => fund.fundTypeName === selectedFundType);
      if (selectedFundType === fundTypesEnum.PRIVATE_CREDIT) {
        setFundColumns([
          ...privateCreditColumns.slice(0, 2),
          {
            id: 8,
            field: "capitalCommitted",
            headerName: "SUBSCRIPTION AMOUNT",
            width: 200,
            renderCell: (params) => {
              return <CurrencyIndicator params={params} />;
            },
            filterOperators: numericOperators,
          }, ...privateCreditColumns.slice(2)])
      }
      else if (selectedFundType === fundTypesEnum.VENTURE_CAPITAL) {
        setFundColumns(ventureCapitalColumn);
      }
      else {
        setFundColumns(privateEquityColumns);
      }
      setFilteredFunds(selectedFunds);
    }
    else {
      setFilteredFunds(sortedFunds);
      setFundColumns(allColumns);
    }
  }, [allFundsData, filters])

  //#region return
  return (
    <div>
      <div className='white-card'>
        <div className='space-between align-item-center'>
          <h6>My Funds</h6>
          <div className='child-row-margin-10'>
            <div className='width-180'>
              <SelectField
                name="fundType"
                label="Fund Type"
                variant={FieldVariants.OUTLINED}
                value={filters.fundType}
                options={fundTypes}
                onChange={(name, value) => { handleFilterChange(name, value) }} />
            </div>
            <div>
              <ActionButton
                label="Add a Fund"
                // className="addfund-btn"
                variant="outlined"
                onClick={onAddFundClick}
                icon={<AddIcon />}
              />

            </div>
          </div>
        </div>
        <div className="FM-myfund-grid">
          {checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.READ) ? (
            <AltDataGridPro
              lg="12"
              columns={fundColumns}
              rows={filteredFunds ? filteredFunds?.map(fund => ({
                id: fund.fundID,
                ...fund
              })) : []}
              onRowClick={fundClick}
              onRowsSelectionHandler={() => { }}
              rowHeight={39}
              localeText={{ noRowsLabel: fundsGridmessage ? fundsGridmessage : "No Data" }}
              getRowClassName={(params) => {
                return (params.row.fundStatusName === fundStatusEnum.TERMINATED || params.row.isFundMatured) ? "gray-out" : "";
              }}
              sx={{
                ".gray-out": {
                  color: "#FF0000",
                },
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer" // Clickable row with hand symbol
                },
                "& .MuiDataGrid-columnHeader.dummydata-yellow": {
                  backgroundColor: "yellow",
                  color: "#ffffff",
                  fontWeight: "bold"
                }
              }}
              hideFooter
            />
          ) : (
            <p className="noPermission">
              <LockPersonIcon />
              You do not have permission to view the Funds
            </p>
          )}
        </div>
      </div>
      {(showCreateFund && fundTypes?.length > 0) &&
        <>
        <CreateFundModel
            open={showCreateFund}
            onClose={onCloseCreateFund}
            fetchFundDetails={fetchFundDetails}
            fundTypes={fundTypes} />
          {/* <CreateFundModal
            fetchFundDetails={fetchFundDetails}
            showCreateFund={showCreateFund}
            setShowCreateFund={setShowCreateFund}
            handleCancelModel={(data) => { setRefreshFundGrid(data) }} /> */}
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default MyFundsGrid