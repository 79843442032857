import React, { useEffect } from 'react';
import Accordian from "../../../common/Accordian/Accordian";
import { Grid, Link } from "@mui/material";
import { AppDashboards, FeatureAccess, RolesAndPermissions } from '../../../../utils/enum';
import ContextMenu from "../../../common/ContextMenu/ContextMenu";
import { ToastContainer } from 'react-toastify';
import ReplaceFile from "../../../InvestorReporting/FirmEmployees/Popups/ReplaceFile";
import DeleteFile from "../../../InvestorReporting/FirmEmployees/Popups/DeleteFile";
import secureLocalStorage from 'react-secure-storage';
import { getDownloadDocument } from '../../../InvestorReporting/FirmEmployees/services/services';
import { VirtualDataRoomToastMessages } from '../../../../utils/AppConstants';
import { toast } from 'react-toastify';
import { axiosApiCallForFiles } from '../../../Services/axiosApiCallForFiles';
import { checkFeaturePermissions } from '../../../../utils/common';

const YearAccordianDocuments = (props) => {

    const { report, isfileUpload, refreshAccordians, currenttab, getFoldersDetails, setRefreshComponent } = props;

    const [replacepopup, setReplacePopup] = React.useState(false);
    const [deletepopup, setDeletePopup] = React.useState(false);
    const [currDeleteDocument, setCurrDeleteDocument] = React.useState(null);
    const [currReplace, setCurrReplace] = React.useState(null);
    const [MenuItemsTop, setMenuItemsTop] = React.useState([]);

    const openReplacePopup = (item) => {
        setCurrReplace(item);
        setReplacePopup(true);
        handleClose();
    }
    const openDeletePopup = (item) => {
        setCurrDeleteDocument(item);
        setDeletePopup(true);
        handleClose();
    }
    const closeReplacePopup = () => {
        setReplacePopup(false);
    }
    const closeDeletePopup = () => {
        setDeletePopup(false);
    }

    useEffect(()=>{
         if(checkFeaturePermissions(RolesAndPermissions.INVESTOR_REPORTS, FeatureAccess.UPDATE)){
            setMenuItemsTop([
                {
                    id: 1,
                    label: "Replace",
                    icon: "",
                    isdisable: false,
                    onClick: openReplacePopup,
                },
                {
                    id: 2,
                    label: "Delete",
                    icon: "",
                    isdisable: false,
                    onClick: openDeletePopup,
                }
            ]);
         }
    },[])

    // const handleDownloadFile = (item) => {
    //     // Your download logic here
    // }

    //TODO : For Now Using FETCH to download the document

    const handleDownloadFile = async (item) => {
        const data = await axiosApiCallForFiles(`/v1/VirtualDataRooms/DownloadVDRItem?vdrID=${item.vdrItemID}`, item.documentPath.split('/').pop())
        if (data.responseCode === 200) {
            toast.success(VirtualDataRoomToastMessages.FILE_SUCCESS_DOWNLOAD, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        } else {
            toast.warning(VirtualDataRoomToastMessages.ACTION_FAILED, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderDocuments = (documents) => {
        return (
            <Grid item xs={12}>
                {documents.map((doc) => (
                    <div className='documents-wrapper' key={doc.vdrItemID}>
                        <div>{doc.documentPath.split('/').pop()}</div>
                        <div className='space-between displaycenter'>
                            <div>
                                <Link
                                    onClick={() => handleDownloadFile(doc)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ cursor: 'pointer' }}
                                >
                                    Download
                                </Link>
                            </div>
                            {secureLocalStorage.getItem('userrole') !== AppDashboards.INV_DASHBOARD &&
                                <div>
                                    <ContextMenu MenuItemsTop={MenuItemsTop} ItemDetails={doc} />
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </Grid>
        );
    };

    // Group documents by investor
    const groupedDocuments = report?.fundReportDetails?.reduce((acc, item) => {
        const key = item.investorName + " (" + item.investorEmail + ")";
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});

    return (
        <div className='accordian-wrapper'>
            {report && Object.keys(groupedDocuments).length === 0 ? "No Data Available" :
                Object.keys(groupedDocuments).map((key) => (
                    <div key={key}>
                        <Accordian title={key} body={renderDocuments(groupedDocuments[key])} />
                    </div>
                ))
            }
            {replacepopup && currReplace && <ReplaceFile getFoldersDetails={getFoldersDetails} setRefreshComponent={setRefreshComponent} report={report} K1Accordians={true} open={replacepopup} onClose={closeReplacePopup} DocumnetDetails={currReplace} refreshAccordians={refreshAccordians} />}
            {deletepopup && currDeleteDocument && <DeleteFile getFoldersDetails={getFoldersDetails} setRefreshComponent={setRefreshComponent} K1Accordians={true} open={deletepopup} onClose={closeDeletePopup} DocumnetDetails={currDeleteDocument} refreshAccordians={refreshAccordians} />}
            <ToastContainer />
        </div>
    )
}

export default YearAccordianDocuments;
