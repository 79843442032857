import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectField from '../../../common/input-fields/SelectField';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { FundCreationModes, initialFundDataTemplate, percentageDropdownValues } from '../../services/apiDataDemplate';
import { FieldVariants, textFormatTypeEnum } from '../../../common/TextInput/appInputenum';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ActionButton from '../../../common/ActionButton/ActionButton';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import { updateFundFeesAndExpenses } from '../../services/services';
import { fundToolTipMessages } from '../../services/toolTipMessages';
import Text from '../../../common/Text/Text';
import InfoIcon from '@mui/icons-material/Info';

const FeesandExpenses = forwardRef((props, ref) => {

  //#region props
  const { type,fundDetails, setFundDetails, getFundDetails } = props;

  //#region intialData
  const initialData = {
    feesDetails: [...fundDetails?.feesExpenses] || []
  };

  //#region varibles
  const [loading,setLoading] = useState(false);
  const [isSaveEnabled,setIsSaveEnabled] = useState(type === FundCreationModes.EDIT ? false : true);

  //#region change events
  const handleFieldsChange = (field, value) => {
    const fieldSplit = field.split(".");//for validation feesexpenses
    const currIndex = fieldSplit[1].split("-")[0];
    const fieldName = fieldSplit[1].split("-")[1];
    const currState = feesExpenses?.values?.feesDetails;
    const item = { ...currState[currIndex] };
    item[fieldName] = value;
    currState[currIndex] = item;
    handleChange("feesDetails", currState);
  }

  const handleChange = (field,value) => {
    feesExpenses.setFieldValue(field, value);
  }

  //#region click events
  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const addFeesandExpenses = () => {
    const newFees= [...feesExpenses.values.feesDetails, initialFundDataTemplate.feesExpenses[0]]
    handleChange("feesDetails", newFees);
  }

  const deleteFeesandExpenses = (value) => {
    const deletedFees = feesExpenses.values?.feesDetails?.filter((item,index) => index !== value);
    handleChange("feesDetails", deletedFees);
  }

  const onSaveClick = async() => {
    const errors = await feesExpenses.validateForm();
    if (Object.keys(errors).length === 0) {
      updateFeesandExpenses();
    }
    else {
      feesExpenses?.handleSubmit()
    }
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    feesExpenses?.resetForm();
  }

  //#region api update calls
  const updateFeesandExpenses = async() => {
      setLoading(true);
      const existingFees = fundDetails?.feesExpenses || [];
      const updatedFees = feesExpenses?.values?.feesDetails || [];

      const requestedData = [
        // Handle CREATE and UPDATE
        ...updatedFees.map((item) => {
          const existingFee = existingFees.find(
            (fee) => fee.id === item.id // Match based on unique identifier (e.g., `id`)
          );

          return {
            id: existingFee?.id || 0,
            fundID: fundDetails?.fundID,
            managementFees: item.managementFees,
            performanceFeesOrCarriedInterest: item.performanceFeesOrCarriedInterest,
            additionalFeesAndExpenses: item.additionalFeesAndExpenses,
            createdBy: existingFee?.createdBy || 0,
            createdDate: existingFee?.createdDate || new Date(),
            updatedBy: 0,
            updatedDate: new Date(),
            transaction: existingFee ? "UPDATE" : "CREATE",
          };
        }),

        // Handle DELETE
        ...existingFees
          .filter((fee) => !updatedFees.some((item) => item.id === fee.id))
          .map((deletedFee) => ({
            id: deletedFee.id,
            fundID: deletedFee.fundID,
            managementFees: deletedFee.managementFees,
            performanceFeesOrCarriedInterest: deletedFee.performanceFeesOrCarriedInterest,
            additionalFeesAndExpenses: deletedFee.additionalFeesAndExpenses,
            createdBy: deletedFee.createdBy,
            createdDate: deletedFee.createdDate,
            updatedBy: 0,
            updatedDate: new Date(),
            transaction: "DELETE",
          })),
      ];

      const data = await updateFundFeesAndExpenses(requestedData);
      if (data.responseCode === 200) {
        setIsSaveEnabled(false);
        setLoading(false);
        getFundDetails();
        toast.success("Fees and Expenses Details Updated Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        }); 
      }
      else {
        setLoading(false);
        toast.error("unable to update the details", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      }
      setLoading(false);
  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    feesDetails: yup.array().of(
      yup.object().shape({
        managementFees: yup.number().required(validationsConstants.REQUIRED),
        performanceFeesOrCarriedInterest: yup.string().required(validationsConstants.REQUIRED),
        additionalFeesAndExpenses: yup.string().required(validationsConstants.REQUIRED),
      })
    ),
  });

  const feesExpenses = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return true;
    },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await feesExpenses.validateForm();
      if (Object.keys(errors).length === 0) {
        //need to update main set of values
        const details = fundDetails;
        details["feesExpenses"] = feesExpenses?.values?.feesDetails;
        setFundDetails(details);
        return true;
      }
      else {
        feesExpenses?.handleSubmit()
      }
    },
    updateFields: async () => {
      //need to update main set of values
      const details = fundDetails;
      details["feesExpenses"] = [feesExpenses?.values?.feesDetails];
      setFundDetails(details);
    }
  }));

  //#region return
  return (
    <>
      {
        type === FundCreationModes.EDIT 
        ? <div className='child-margin-10'>
            <div className='edit-save-sec space-between'>
                <div></div>
                <div className='edit-save-sec child-row-margin-5 margin-right-10'>
                    {isSaveEnabled ?
                      <>
                        <div>
                          <ActionButton 
                              label="Cancel"
                              variant={FieldVariants.OUTLINED}
                              disabled={loading}
                              icon={<CancelIcon />}
                              onClick={() => onCancelClick()}/>
                        </div>
                        <div>
                          <ActionButton
                            label="SAVE"
                            icon={<SaveIcon />}
                            loading={loading}
                            onClick={() => onSaveClick()} />
                        </div>
                      </> 
                      :
                      <div>
                        <ActionButton 
                            label="Edit"
                            icon={<EditIcon />}
                            onClick={() => onEditClick()}/>
                      </div>}
                  
                  <div></div>
                </div>
            </div>
            <div className='header-sec width-98 internalText margin-top-15'>
                  Fees and Expenses
            </div>
            <div className='space-between'>
                <div></div>
                <div>
                  <ActionButton
                      label="ADD Fees And Expenses"
                      icon={<AddIcon />}
                      className="btn-primary"
                      disabled={!isSaveEnabled}
                      variant="outlined"
                      onClick={() => addFeesandExpenses()} />
                </div>
            </div>
          </div>
        : <div className='child-margin-10 space-between'>
            <div className='child-row-margin-5 align-item-center'>
              <Text label="Fees and Expenses " />
              <Tooltip title={fundToolTipMessages.investmentStrategy}>
                <InfoIcon color="action" />
              </Tooltip>
            </div> 
            <div>

            </div>
          </div>
      }
      {
        feesExpenses && feesExpenses?.values?.feesDetails?.map((item, index) => {
          return (
            <div className='fees-expenses-sec child-margin-5 margin-top-10'>
              {
                type === FundCreationModes.EDIT &&
                <div className='space-between'>
                  <div>
                    {"Primary Expenses" + " " + (index + 1)}
                  </div>
                  <div>
                    <Tooltip
                        className='deleteicon'
                        onClick={() => deleteFeesandExpenses(index)}
                        disabled={index === 0}
                        title={index === 0 ? "Please add another Primary Fees to delete this" : "Remove"}
                        arrow>
                        <IconButton>
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                  </div>
                </div>
              }
              <div className='display-row-items-flex' key={index}>
                <div className='width-48'>
                  <ALTTextField
                    textFormatType={textFormatTypeEnum.PERCENTAGE}
                    name={`feesDetails.${index}-managementFees`}
                    label="Management Fees"
                    value={item.managementFees}
                    onChange={(name, value) => handleFieldsChange(name, value)}
                    required={true}
                    readOnly={!isSaveEnabled}
                    onBlur={(e) => {
                      feesExpenses.handleBlur(e);
                      feesExpenses.setFieldTouched(`feesDetails.${index}-managementFees`, true, false); // Explicitly set touched
                    }}
                    error={feesExpenses.touched?.feesDetails?.[index]?.managementFees && Boolean(feesExpenses.errors?.feesDetails?.[index]?.managementFees)}
                    errorMessage={feesExpenses.touched?.feesDetails?.[index]?.managementFees && feesExpenses.errors?.feesDetails?.[index]?.managementFees} />
                </div>
                <div className='width-48'>
                  <SelectField
                    name={`feesDetails.${index}-performanceFeesOrCarriedInterest`}
                    label="Performance Fees / Carried Interest"
                    value={item.performanceFeesOrCarriedInterest}
                    onChange={(name, value) => handleFieldsChange(name, value)}
                    required={true}
                    readOnly={!isSaveEnabled}
                    options={percentageDropdownValues}
                    onBlur={(e) => {
                      feesExpenses.handleBlur(e);
                      feesExpenses.setFieldTouched(`feesDetails.${index}-performanceFeesOrCarriedInterest`, true, false); // Explicitly set touched
                    }}
                    error={feesExpenses.touched?.feesDetails?.[index]?.performanceFeesOrCarriedInterest && Boolean(feesExpenses.errors?.feesDetails?.[index]?.performanceFeesOrCarriedInterest)}
                    errorMessage={feesExpenses.touched?.feesDetails?.[index]?.performanceFeesOrCarriedInterest && feesExpenses.errors?.feesDetails?.[index]?.performanceFeesOrCarriedInterest} />
                </div>
                <div className='width-48'>
                  <ALTTextField
                    textFormatType={textFormatTypeEnum.PERCENTAGE}
                    name={`feesDetails.${index}-additionalFeesAndExpenses`}
                    label="Additional Fees and Expenses"
                    value={item.additionalFeesAndExpenses}
                    onChange={(name, value) => handleFieldsChange(name, value)}
                    required={true}
                    readOnly={!isSaveEnabled}
                    onBlur={(e) => {
                      feesExpenses.handleBlur(e);
                      feesExpenses.setFieldTouched(`feesDetails.${index}-additionalFeesAndExpenses`, true, false); // Explicitly set touched
                    }}
                    error={feesExpenses.touched?.feesDetails?.[index]?.additionalFeesAndExpenses && Boolean(feesExpenses.errors?.feesDetails?.[index]?.additionalFeesAndExpenses)}
                    errorMessage={feesExpenses.touched?.feesDetails?.[index]?.additionalFeesAndExpenses && feesExpenses.errors?.feesDetails?.[index]?.additionalFeesAndExpenses} />
                </div>
              </div>
            </div>
          )
        })
      }
    </>
  );
});

export default FeesandExpenses;