import * as React from 'react';
import './styles.css'
import TextField from '../common/TextInput/TextField';
import { HexColorPicker } from 'react-colorful';
import { Button, Popover } from '@mui/material';
import { ColorLensOutlined } from '@mui/icons-material';
import { FieldType, fontOptions, bodyfontSize, HeadingsfontSize, SubHeadingsfontSize, fontSize } from './Constants';
import FileUploadButton from '../common/FileUploadButton/FileUploadButton';
import secureLocalStorage from 'react-secure-storage';
import Configurations from '../../Configurations';
import ActionButton from '../common/ActionButton/ActionButton';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

export const WhileLabelingInputField = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFileUpload = (name, file) => {
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
      const uploadLogo = async () => {
        fetch(`${Configurations.apiBaseUri}/v1/UserRole/UploadTenantLogo?tenantID=${secureLocalStorage.getItem("tenantID")}`, {
          method: 'POST',
          body: formData
        })
          .then(response => {
            if (response.ok) {
              response.text().then(data => {

                console.log('labelling data' + data);
                props.onChange(data);
              }).catch(err => console.log(err));
              console.log(response)
            } else {
              // handle the error response
              console.error(response);
              throw new Error("Failed to fetch Data");
            }
          })
      }
      uploadLogo();
    }
  }

  const open = Boolean(anchorEl);

  const handleColorInputChange = (value) => {
    if (value && !value.startsWith('#')) {
      props.onChange(`#${value}`);
    } else {
      props.onChange(value);
    }
  };

  return (
    <>
      <div className="colorInputFieldWrapper">
        <div className='labelWrapper'>{props.label}:</div>
        {props.type === FieldType.COLOR &&
          (
            <><TextField
              name={props.name}
              variant="outlined"
              value={props.value}
              defaultValue={props.value}
              styleProps={{
                marginTop: '0px !important',
                marginLeft: '0px !important'
              }}
              // defaultValue={fundDetails.fundName}
              onChange={(name, value) => handleColorInputChange(value)} /><div className='displayColorWrapper'>
                <div style={{ backgroundColor: props.value , border:'0.5px solid black'}} className='displayColor'></div>
              </div><Button
                variant="outlined"
                startIcon={<ColorLensOutlined />}
                onClick={handleClick}
                sx={{
                  borderColor: '#b7b7b7',
                  color: '#b7b7b7',
                  fontSize: '14px',
                  width: '350px',
                }}
              ><span style={{fontSize:'12px'}}>Pick Color</span>
                </Button><Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <HexColorPicker color={props.value} onChange={(color) => props.onChange(color)} style={{border : '1px solid black'}} />
              </Popover></>)}
        {
          props.type === FieldType.FILE && (
            <FileUploadButton
              name={props.name}
              label='UPLOAD LOGO'
              defaultFile={props.value}
              defaultValue={props.value}
              onChange={handleFileUpload}
              isImage={true}
              fromWhiteLabeling={true}
            />
          )
        }
        {
          props.type === FieldType.SELECT && (
            <TextField
              type='select'
              name={props.name}
              variant="outlined"
              value={props.value}
              options={fontOptions}
              styleProps={{
                marginTop: '0px !important',
                marginLeft: '0px !important',
              }}
              // defaultValue={fundDetails.fundName}
              onChange={(name, value) => props.onChange(value)}
            />)
        }
        {
          props.type === FieldType.NUMBER &&
          <TextField
            type='number'
            name={props.name}
            value={props.value}
            variant="outlined"
            styleProps={{
              marginTop: '0px !important',
              marginLeft: '0px !important'
            }}
            defaultValue={props.value}
            min='30'
            max='45'
            onChange={(name, value) => props.onChange(value < 30 ? '30' : (value > 45 ? 45 : value))}
          />
        }
        {
          props.type === FieldType.SelectFontSize &&
          <TextField
            type='select'
            name={props.name}
            value={props.value}
            variant="outlined"
            styleProps={{
              marginTop: '0px !important',
              marginLeft: '0px !important'
            }}
            options={bodyfontSize}
            defaultValue={props.value}
            onChange={(name, value) => props.onChange(value)}
          />
        }
        {
          props.type === FieldType.HeadingsfontSize &&
          <TextField
            type='select'
            name={props.name}
            value={props.value}
            variant="outlined"
            styleProps={{
              marginTop: '0px !important',
              marginLeft: '0px !important'
            }}
            options={HeadingsfontSize}
            defaultValue={props.value}
            onChange={(name, value) => props.onChange(value)}
          />
        }
        {
          props.type === FieldType.SubHeadingsfontSize &&
          <TextField
            type='select'
            name={props.name}
            value={props.value}
            variant="outlined"
            styleProps={{
              marginTop: '0px !important',
              marginLeft: '0px !important'
            }}
            options={SubHeadingsfontSize}
            defaultValue={props.value}
            onChange={(name, value) => props.onChange(value)}
          />
        }{
          props.type === FieldType.FONTSIZE &&
          <TextField
            type='select'
            name={props.name}
            value={props.value}
            variant="outlined"
            styleProps={{
              marginTop: '0px !important',
              marginLeft: '0px !important'
            }}
            options={fontSize}
            defaultValue={props.value}
            onChange={(name, value) => props.onChange(value)}
          />
        }
      </div>
      <div>{
        props.type === FieldType.resetButton &&
        <ActionButton
          variant="outlined"
          icon={<RotateLeftIcon />}
          label='Reset'
          //onClick={() => onOpenResetPopup()} 
          styleProps={{ margin: '10px' }}
        />
      }
        {
          props.type === FieldType.applyButton &&
          <ActionButton
            icon={<TouchAppIcon />}
            label='Apply'
          //onClick={() => onApplyClick()} 
          />
        }</div></>

  )
}