import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import {
  Button,
  Popover,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { ColorLensOutlined } from '@mui/icons-material';

const AddThemePopup = ({ open, onClose, themes, onSave,themeName,setThemeName,setThemeColor,themeColor,mirroredFrom,setMirroredFrom }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleColorPickerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColorPickerClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    if (themeName && mirroredFrom) {
      onSave({ themeName, themeColor, mirroredFrom });
      onClose();
    }
  };

  const openPopover = Boolean(anchorEl);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Theme Name</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Theme Name Field and Color Picker in a Row */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
              label="Theme Name"
              variant="standard"
              value={themeName}
              onChange={(e) => setThemeName(e.target.value)}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: themeColor,
                  borderRadius: 1,
                  border: '1px solid #ccc',
                }}
              />
              <Button
                variant="outlined"
                startIcon={<ColorLensOutlined />}
                onClick={handleColorPickerClick}
                sx={{
                  borderColor: '#b7b7b7',
                  color: '#b7b7b7',
                  fontSize: '14px',
                  textTransform: 'none',
                }}
              >
                Pick Color
              </Button>
              <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleColorPickerClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ p: 2 }}>
                  <HexColorPicker
                    color={themeColor}
                    style={{minWidth:'100px !important'}}
                    onChange={(color) => setThemeColor(color)}
                  />
                </Box>
              </Popover>
            </Box>
          </Box>

          {/* Mirrored From Dropdown */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {themes?.length > 0 ? (
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  id="choose-theme-label"
                  shrink={Boolean(mirroredFrom || themes.length)}
                >
                  Mirrored From
                </InputLabel>
                <Select
                  labelId="choose-theme-label"
                  value={mirroredFrom}
                  onChange={(e) => setMirroredFrom(e.target.value)}
                  displayEmpty
                >
                  {themes.map((theme) => (
                    <MenuItem
                      key={theme.whiteLabellingID}
                      value={theme.themeName}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          borderRadius: '50%',
                          backgroundColor: theme.themeColor,
                        }}
                      />
                      {theme.themeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box sx={{ color: '#999' }}>Loading...</Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!themeName || !mirroredFrom}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddThemePopup;
