import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CircleIcon from "../../chatbot/Icon";
const EmailVerificationScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    if (!email) {
      setEmailError("please enter valid email address");
    } else if (!/^[^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*@\w+(\.\w+)+$/.test(email)) {
      setEmailError("please enter valid email address");
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Please enter password");
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(
        password
      )
    ) {
      setPasswordError(
        "Wrong password"
      );
    } else {
      console.log('else');
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({ emailAddress: email, password: password })
      };
      console.log(requestOptions);
     /*  fetch(`/v1/User/GetUser?emailAddress=${email}&password=${password}&origin=${window.location.origin}`, requestOptions)
        .then(response => {
          console.log(response);
          if (response.ok) {
            console.log(response);
            // successful login
            // redirect to dashboard or update state accordingly
            navigate("/marketplace");
          } else {
            console.log(response);
            alert("Please enter valid Email and Password");
            // Add Error message field and show error message there
            // login failed
            // handle error or update state accordingly
          }
        })
        .catch(error => {
          // handle network errors
        }); */
    }


  };

  return (
    <div className="login d-flex align-items-center py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-lg-8 mx-auto">
            <div className="login-header">
              <h3 className="login-heading text-center">Thank You</h3>
              <p className="text-center sub-heading">
                for confirming your email account <br /> your account has been activated
              </p>
            </div>
            <div className="login-form">
              <div className="position-relative my-3 inputGroup text-center">
                <input
                  type="email"
                  className="border-0 w-100"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <span className="text-danger">{emailError}</span>
                )}
              </div>
              <div className="position-relative my-3 inputGroup text-center">
                <input
                  type="password"
                  className="border-0 w-100"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                {passwordError && (
                  <span className="text-danger">{passwordError}</span>
                )}
              </div>

              <div className="d-flex w-full my-3">
                <div className="flex-fill checkbox">
                  <label>
                    <input type="checkbox" className="margin-right-10"/>
                    Remember me
                  </label>
                </div>
                <div className="flex-fill text-end">
                  <Link to="/forget-password" className="forgot-pswd">
                    Forgot Password
                  </Link>

                </div>
              </div>
              <button
                className="btn w-100 my-1 shadow-none btn-login"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                Log in
              </button>

            </div>
            <div className="recaptcha1 mt-5 text-center">
              This site is protected by reCAPTCHA and the
              <p> Google
             <a className="privacy_TC-links" href="#"> Privacy Policy </a>
             and 
              <a className="privacy_TC-links" href="#"> Terms of Service </a>
              Apply
               </p>



            </div>

             <CircleIcon/>          
          </div>
        </div>
      </div>
    </div>






  );
};
export default EmailVerificationScreen;